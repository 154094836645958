<template>
    <CarouselElement v-if="items.length" :items="items" :title="title" :description="description" />
</template>
<script setup>
import CarouselElement from './CarouselElement.vue'
import { computed, watch } from 'vue'
import CarouselImage from './CarouselImage.vue'
import CarouselImageSkeleton from './CarouselImageSkeleton.vue'
import BaseElement from './BaseElement.vue'

const props = defineProps({
    ...BaseElement.props,
    data: {
        type: Object,
        required: false,
        default: null,
    },
})

const items = computed(() => {
    if (props.data === null) {
        const skeletons = []
        for (let i = 0; i < (props.configuration.limit || 3); i++) {
            skeletons.push({
                component: CarouselImageSkeleton,
                props: { index: i, aspectRatio: '2 / 1' },
            })
        }
        return skeletons
    }
    return props.data.items.map((blog) => {
        return {
            component: CarouselImage,
            props: {
                alt: blog.name,
                caption: blog.entry?.title || blog.name,
                description: blog.entry?.date,
                src: blog.entry?.artworkSrc || blog.artworkSrc,
                srcSet: blog.entry?.artworkSrc ? null : blog.artworkSrcSet,
                href: blog.entry?.permalink || blog.permalink,
                aspectRatio: '2 / 1',
            },
        }
    })
})
</script>
