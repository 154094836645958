/**
 *
 * @param el - HTML element
 * @returns {void}
 */
export const initPlaceGallery = (el = document) => {
    if (window.innerWidth < 768) {
        el.querySelectorAll('.place-card__image').forEach((item) => {
            const gallery = item.querySelector('.image-gallery')
            if (!gallery) return

            gallery.classList.remove('image-gallery')
            item.classList.add('place-card__image--mobile')
        })

        return
    }
}
