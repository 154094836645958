<template>
    <div class="wizard box rounded shadow">
        <transition name="fade-up" mode="out-in">
            <!-- Loader -->
            <div
                v-if="loading"
                class="text-center"
                key="loader"
                style="display: flex; flex-direction: column; height: 100%; align-items: center"
            >
                <div class="is-loading is-loading--large mt-4"></div>
            </div>
            <!-- Intro -->
            <div class="wizard-intro text-center" v-else-if="!userType" key="intro">
                <div class="p-4">
                    <template v-if="type !== 'edit'">
                        <img
                            class="tw-mx-auto"
                            src="/media/img/place/add.svg"
                            width="130"
                            height="119"
                            alt=""
                        />
                        <h2 class="pt-3">
                            {{ $t('placeWizard.start.title') }}
                        </h2>
                        <p class="pb-3">
                            {{ $t('placeWizard.start.description') }}
                        </p>
                    </template>

                    <template v-else>
                        <img
                            class="tw-mx-auto"
                            src="/media/img/place/edit.svg"
                            width="130"
                            height="119"
                            alt=""
                        />
                        <h2 class="pt-3">
                            {{
                                $t('placeWizard.start.title_edit', {
                                    placeName: place.name,
                                })
                            }}
                        </h2>
                        <p class="pb-3">
                            {{ $t('placeWizard.start.description') }}
                        </p>
                    </template>

                    <button
                        @click="selectUserType('user')"
                        class="flat-button flat-button--blue flat-button--large flat-button--full my-2"
                    >
                        <span class="icon--place-user"></span>
                        <span>{{ $t('placeWizard.start.user') }}</span>
                    </button>
                    <button
                        @click="selectUserType('owner')"
                        class="flat-button flat-button--blue flat-button--large flat-button--full my-2"
                    >
                        <span class="icon--place-owner"></span>
                        <span>{{ $t('placeWizard.start.owner') }}</span>
                    </button>
                </div>
            </div>

            <!-- Dynamic step components -->
            <div v-else key="components">
                <BaseStep
                    :title="step.title"
                    :progress="stepIndex"
                    :max-progress="flows[type].length"
                    @prev="prev"
                    :type="type"
                    @goToStep="goToStep"
                    @set-type="type = $event"
                >
                    <div class="transition-wrap">
                        <transition :name="slideName">
                            <component
                                :type="type"
                                :user-type="userType"
                                :original-place="originalPlace"
                                :is="step.component"
                                v-model="place"
                                @next="next"
                                @prev="prev"
                                @setPlace="setPlace"
                                @goToStep="goToStep"
                                @changePrevStepTo="overridePrevStep = $event"
                            ></component>
                        </transition>
                    </div>
                </BaseStep>
            </div>
        </transition>
    </div>
</template>

<script>
import BaseAutocomplete from '../../Shared/components/BaseAutocomplete.vue'
import BaseStep from './Shared/BaseStep.vue'
import axios from 'axios'
import cloneDeep from 'lodash-es/cloneDeep'
import clone from 'lodash-es/clone'
import { defineAsyncComponent, markRaw } from 'vue'
import { useCityStore } from '../../../store/city.js'
import { useUserStore } from '../../../store/user.js'

function lazyLoadComponent(component) {
    return markRaw(defineAsyncComponent(component))
}

export default {
    name: 'PlaceWizard',
    components: { BaseStep, BaseAutocomplete },
    data() {
        const cityStore = useCityStore()
        return {
            type: null,
            userType: null,
            stepIndex: 0,
            slideName: null,
            loading: false,
            overridePrevStep: null,

            beforeUnloadHandler: (event) => {
                if (
                    !this.place.persisted &&
                    JSON.stringify(this.place) !== JSON.stringify(this.originalPlace)
                ) {
                    event.returnValue = `Are you sure you want to leave?`
                }
            },

            originalPlace: this.place,
            place: {
                persisted: false,
                uuid: null,

                name: '',
                phone: '',
                website: '',
                noWebsite: null,
                facebookUrl: '',
                instagramUrl: '',

                address: '',
                city: clone(cityStore.currentCity),
                postalCode: '',
                cityId: null,
                cityName: '',
                latitude: null,
                longitude: null,

                problem: '',
                problemDescription: '',

                bodyText: '',
                otherInformation: '',

                categories: [],
                addedCategories: [],
                removedCategories: [],

                removedOpeningHours: [],
                openingHours: [
                    {
                        day: 1,
                        hours: [],
                    },
                    {
                        day: 2,
                        hours: [],
                    },
                    {
                        day: 3,
                        hours: [],
                    },
                    {
                        day: 4,
                        hours: [],
                    },
                    {
                        day: 5,
                        hours: [],
                    },
                    {
                        day: 6,
                        hours: [],
                    },
                    {
                        day: 0,
                        hours: [],
                    },
                ],
                openingHoursNone: null,
                openingHoursAlways: null,
                openInMonths: [],

                isOwner: false,
                premiumOwner: null,

                reviewRating: 0,
                reviewBody: '',

                userName: '',
                userPhone: '',
                userEmail: '',
                userCreateAccount: false,
            },

            flows: {
                owner: [
                    'premiumOwner',
                    'selectPlace',
                    'address',
                    'addCategories',
                    'openingHoursOverview',
                    'general',
                    'description',
                    'userInfo',
                    'summary',
                    'thankYou',
                ],
                user: [
                    'selectPlace',
                    'address',
                    'addCategories',
                    'openingHoursOverview',
                    'general',
                    'description',
                    'review',
                    'userInfo',
                    'summary',
                    'thankYou',
                ],

                edit: [
                    'overview',
                    'editCategories',
                    'addCategories',
                    'openingHoursOverview',
                    'general',
                    'address',
                    'description',
                    'userInfo',
                    'summary',
                    'problem',
                    'thankYou',
                ],
            },

            steps: {
                selectPlace: {
                    title: this.$t('placeWizard.steps.selectPlace.title'),
                    component: lazyLoadComponent(() => import('./Steps/SelectPlace.vue')),
                },
                premiumOwner: {
                    title: this.$t('placeWizard.steps.premiumOwner.title'),
                    component: lazyLoadComponent(() => import('./Steps/PremiumOwner.vue')),
                },
                addCategories: {
                    title: this.$t('placeWizard.steps.addCategories.title'),
                    component: lazyLoadComponent(() => import('./Steps/AddPlaceCategories.vue')),
                },
                editCategories: {
                    title: this.$t('placeWizard.steps.editCategories.title'),
                    component: lazyLoadComponent(() => import('./Steps/EditPlaceCategories.vue')),
                },
                general: {
                    title: this.$t('placeWizard.steps.general.title'),
                    component: lazyLoadComponent(() => import('./Steps/General.vue')),
                },
                description: {
                    title: this.$t('placeWizard.steps.description.title'),
                    component: lazyLoadComponent(() => import('./Steps/Description.vue')),
                },
                address: {
                    title: this.$t('placeWizard.steps.address.title'),
                    component: lazyLoadComponent(() => import('./Steps/Address.vue')),
                },
                problem: {
                    title: this.$t('placeWizard.steps.problem.title'),
                    component: lazyLoadComponent(() => import('./Steps/Problem.vue')),
                },
                review: {
                    title: this.$t('placeWizard.steps.review.title'),
                    component: lazyLoadComponent(() => import('./Steps/Review.vue')),
                },
                userInfo: {
                    title: this.$t('placeWizard.steps.userInfo.title'),
                    component: lazyLoadComponent(() => import('./Steps/UserInfo.vue')),
                },
                summary: {
                    title: this.$t('placeWizard.steps.summary.title'),
                    component: lazyLoadComponent(() => import('./Steps/Summary.vue')),
                },
                openingHours: {
                    title: this.$t('placeWizard.steps.openingHours.title'),
                    component: lazyLoadComponent(() => import('./Steps/OpeningHours.vue')),
                },
                openingHoursOverview: {
                    title: this.$t('placeWizard.steps.openingHoursOverview.title'),
                    component: lazyLoadComponent(() => import('./Steps/OpeningHoursOverview.vue')),
                },
                overview: {
                    title: this.$t('placeWizard.steps.overview.title'),
                    component: lazyLoadComponent(() => import('./Steps/Overview.vue')),
                },
                thankYou: {
                    title: null,
                    component: lazyLoadComponent(() => import('./Steps/ThankYou.vue')),
                },
            },
        }
    },

    watch: {
        // Go to first step if the type changes
        userType(newVal) {
            this.place.isOwner = newVal === 'owner'

            this.stepIndex = 0
        },
        stepIndex(newVal) {
            const type = this.flows[this.type][newVal]

            if (type === 'summary') {
                this.type = 'edit'
            }
        },
    },

    computed: {
        flow() {
            return this.flows[this.type][this.stepIndex]
        },
        step() {
            return this.steps[this.flow]
        },
    },

    methods: {
        selectUserType(val) {
            if (val === 'owner' && !this.place.featured) {
                // Add the premiumOwner step to edit flow
                this.flows.edit.unshift('premiumOwner')
            } else {
                // Remove premiumOwner step from edit flow
                const items = this.flows.edit
                this.flows.edit = items.filter((item) => item !== 'premiumOwner')
                // Reset place.premiumOwner value
                this.place.premiumOwner = null
            }

            if (this.type === 'edit') {
                this.userType = val
            } else {
                this.type = val
                this.userType = val
            }
        },
        next() {
            this.slideName = 'slide-left'
            this.overridePrevStep = null
            this.stepIndex++
        },
        prev() {
            // Return to user selection screen if on step 1
            if (this.stepIndex === 0) {
                this.selectUserType(null)
                return
            }

            this.slideName = 'slide-right'

            if (typeof this.overridePrevStep === 'function') {
                this.overridePrevStep()
                this.overridePrevStep = null
                return
            }

            if ((this.type === 'edit' && this.flow !== 'overview') || this.overridePrevStep) {
                return this.goToStep(this.overridePrevStep || 'overview')
            }

            if (this.stepIndex - 1 !== -1) {
                this.stepIndex--
            } else {
                this.type = null
            }
        },
        goToStep(step) {
            this.overridePrevStep = null
            const index = this.flows[this.type].findIndex((item) => item === step)

            this.overridePrevStep = null

            if (index < this.stepIndex) {
                this.slideName = 'slide-right'
            } else {
                this.slideName = 'slide-left'
            }

            this.stepIndex = index
        },
        setPlace(uuid) {
            this.loading = true

            axios
                .get('/api/place/' + uuid)
                .then((res) => {
                    this.type = 'edit'

                    const data = res.data.data

                    this.place.name = data.name
                    this.place.currentBodyText = data.bodyText
                    this.place.website = data.website
                    this.place.phone = data.phone
                    this.place.facebookUrl = data.facebookUrl
                    this.place.instagramUrl = data.instagramUrl
                    this.place.address = data.address
                    this.place.postalCode = data.postalCode
                    this.place.closed = data.closed
                    this.place.closedTemporarily = data.closedTemporarily
                    this.place.uuid = uuid
                    this.place.longitude = data.longitude
                    this.place.latitude = data.latitude
                    this.place.categories = data.traits
                    this.place.permalink = data.permalink
                    this.place.featured = data.featured
                    this.loading = false

                    this.place.openingHours = data.openingHours.map((day, index) => {
                        let hours = day.map((hour) => {
                            return hour
                        })

                        return {
                            day: index,
                            hours,
                        }
                    })

                    // Move sunday to last in array
                    this.place.openingHours.push(this.place.openingHours.shift())

                    // Save to original place
                    this.originalPlace = cloneDeep(this.place)
                })
                .catch((error) => {})
                .finally(() => {
                    this.loading = false
                })
        },
    },

    created() {
        // Save to original place
        this.originalPlace = cloneDeep(this.place)

        const uri = window.location.search.substring(1)
        const params = new URLSearchParams(uri)
        const uuid = params.get('p')
        const userType = params.get('user-type')
        if (uuid) {
            this.setPlace(uuid)
        }

        if (userType) {
            this.selectUserType(userType)
        }

        // Remove review step if not logged in
        const userStore = useUserStore()
        if (!userStore.isLoggedIn) {
            const i = this.flows.user.indexOf('review')
            this.flows.user.splice(i, 1)
        }

        if (window.innerWidth <= 900) {
            document.querySelector('body').classList.add('small-header--force')
        }

        document.querySelector('body').classList.add('no-footer')

        // Add before unload event handler
        window.addEventListener('beforeunload', this.beforeUnloadHandler)
    },

    unmounted() {
        window.removeEventListener('beforeunload', this.beforeUnloadHandler)
    },
}
</script>

<style scoped>
/** TODO: Make this global? */
.flat-button [class^='icon--'],
.flat-button [class*=' icon--'] {
    width: 24px;
    height: 24px;
    margin-top: -5px;
    top: 6px;
    right: 5px;
    position: relative;
}
</style>

<style lang="scss">
.wizard-intro h2 {
    font-size: 20px;
    font-weight: 600;
}

.wizard.box {
    min-height: calc(100vh - 47px);
    overflow: hidden;

    body:not(.small-header--force) & {
        min-height: calc(100vh - 95px);
    }
}

@media screen and (min-width: 768px) {
    .wizard.box {
        min-height: calc(100vh - 109px - 2rem);
        margin: 1rem;
    }

    .wizard-intro {
        max-width: 514px;
        margin: 0 auto;
    }
}

.place-box {
    display: flex;
    padding: 25px;

    h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
    }

    h3 {
        font-size: 14px;
    }
}

.place-box__description {
    flex: 1;
}

.place-box__image {
    width: 45px;
    height: 45px;

    & + .place-box__description {
        margin-left: 20px;
    }
}

.place-box__button {
    align-self: center;

    i {
        width: 29px;
        height: 29px;
    }
}
</style>

<style lang="scss">
.listable {
    li {
        background-color: #f6f6f6;
        padding: 0.875rem;
        font-weight: 600;
        margin-bottom: 3px;

        @media screen and (min-width: 768px) {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            border-radius: 6px;
        }

        &.new {
            background: #bee7f9;
        }

        &.removed {
            opacity: 0.7;
        }
    }
}

.wizard {
    @media screen and (min-width: 768px) {
        margin-top: 1rem;
    }
}

.wizard-components {
    padding-top: 38px;

    @media screen and (min-width: 768px) {
        margin: 0 auto;
        max-width: 500px;
    }

    @media screen and (max-width: 767px) {
        padding-top: 74px;
    }
}

/* Intro */
.intro-leave-active,
.intro-enter-active {
    transition: 350ms;
}

.intro-enter {
    opacity: 1;
}

.intro-leave-to {
    opacity: 0;
}

/* Slides */

// Slide left
.slide-left-leave-active,
.slide-left-enter-active {
    transition: 350ms;
}

.slide-left-enter-from {
    transform: translateX(100%);

    @media screen and (min-width: 768px) {
        transform: translateX(150px);
        opacity: 0;
    }
}

.slide-left-enter-to {
    transform: translate(0);
    opacity: 1;
}

.slide-left-leave-from {
    transform: translate(0);

    @media screen and (min-width: 768px) {
        transform: translateX(0);
        opacity: 1;
    }
}
.slide-left-leave-to {
    transform: translateX(-100%);
    opacity: 0;

    @media screen and (min-width: 768px) {
        transform: translateX(-150px);
    }
}

// Slide right
.slide-right-leave-active,
.slide-right-enter-active {
    transition: 350ms;
}

.slide-right-enter-from {
    transform: translateX(-100%);

    @media screen and (min-width: 768px) {
        transform: translateX(-150px);
        opacity: 0;
    }
}

.slide-right-enter-to {
    transform: translate(0);
    opacity: 1;
}

.slide-right-leave-from {
    transform: translate(0);

    @media screen and (min-width: 768px) {
        transform: translateX(0);
        opacity: 1;
    }
}
.slide-right-leave-to {
    transform: translateX(100%);
    opacity: 0;

    @media screen and (min-width: 768px) {
        transform: translateX(150px);
    }
}

.transition-wrap {
    position: relative;
}

.transition-wrap > div:nth-child(2) {
    position: absolute;
    top: 0;
    width: 100%;
}
</style>

<!-- Fade up transition -->
<style lang="scss">
.fade-up-enter-active,
.fade-up-leave-active {
    transition: all 350ms ease;
    background-color: #fff;
}

.fade-up-leave-active {
    transform: scale(0.8);
}

.fade-up-enter-active {
    transform: scale(1.2);
    z-index: 10;
}

.fade-up-enter-to {
    transform: scale(1);

    header {
        position: relative;
        top: initial;
    }

    .wizard-components {
        padding-top: 21px;
    }
}

.fade-up-enter,
.fade-up-leave-to {
    opacity: 0;
}
</style>
