<template>
    <div class="text-center">
        <img :src="user.thumbnail" class="artwork circle large mx-auto mb-4" />
        <h2 class="weight-700 mb-2">
            {{ $t('authForm.welcome_user', { name: user.firstName }) }}
        </h2>
        <div class="mb-2" v-if="!didAuth && !isLoading">
            <a :href="user.permalink" class="auth-button auth-button--blue">
                {{ $t('authForm.profile_link') }}
            </a>
        </div>
        <p class="explanation" v-if="!didAuth && !isLoading">
            {{ $t('authForm.not_you_question') }}
            <a :href="logoutUrl" @click="isLoading = true">
                {{ $t('authForm.log_out') }}
            </a>
        </p>
        <p v-else>
            <i class="icon--loading-ring icon--loading-ring--gray x21"></i>
        </p>
    </div>
</template>
<script>
import AuthButton from './AuthButton.vue'

export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
        didAuth: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
        }
    },
    computed: {
        logoutUrl() {
            return (
                '/logout/?redirect=' +
                window.encodeURIComponent(window.location.pathname + window.location.search)
            )
        },
    },
}
</script>
