import { defineStore } from 'pinia'
import http from '@utils/http.js'
import { useUserStore } from './user.js'

let debounceStack = []
let debounceTimeout = null

export const useListsStore = defineStore('lists', {
    state: () => ({
        containsPlace: {},
        checkedPlaces: [],
    }),
    getters: {
        allSavedPlaces(store) {
            return Object.values(store.containsPlace).reduce((a, i) => {
                return a.concat(
                    i.filter((place) => {
                        return a.indexOf(place) === -1
                    }),
                )
            }, [])
        },
    },
    actions: {
        isSaved(place, type = null) {
            if (type === null) {
                return this.allSavedPlaces.indexOf(place) !== -1
            } else {
                return this.containsPlace[type] && this.containsPlace[type].indexOf(place) !== -1
            }
        },
        async debounceCheckContainsPlaces(ids) {
            ids = ids.filter((id, i) => {
                return this.checkedPlaces.indexOf(id) === -1 && ids.indexOf(id) === i
            })
            debounceStack = debounceStack.concat(ids)
            if (!debounceTimeout) {
                debounceTimeout = window.setTimeout(() => {
                    this.checkContainsPlaces([...debounceStack])
                    debounceTimeout = null
                    debounceStack = []
                }, 100)
            }
        },
        async checkContainsPlaces(ids) {
            const userStore = useUserStore()
            if (!userStore.user) {
                return null
            }

            ids = ids.filter((id, i) => {
                return this.checkedPlaces.indexOf(id) === -1 && ids.indexOf(id) === i
            })

            if (!ids.length) {
                return
            }
            const chunkSize = 100
            let all = []
            for (let i = 0; i < ids.length; i += chunkSize) {
                const chunk = ids.slice(i, i + chunkSize)

                const { data } = await http.post('/user/lists/with-places/', {
                    ids: chunk,
                })

                this.addCheckedPlaces(chunk)
                this.mergeContainsPlaces(data.data)

                all = all.concat(data.data)
            }

            return all
        },
        removeContainsPlaces({ type, places }) {
            if (type in this.containsPlace) {
                let newState = { ...this.containsPlace }
                places.forEach((place) => {
                    const index = newState[type].indexOf(place)
                    if (index > -1) {
                        newState[type].splice(index, 1)
                    }
                })
                this.containsPlace = newState
            }
        },
        addCheckedPlaces(ids) {
            this.checkedPlaces = this.checkedPlaces.concat(ids)
        },
        mergeContainsPlaces(data) {
            let newState = {
                ...this.containsPlace,
            }
            for (const type in data) {
                if (!Object.prototype.hasOwnProperty.call(data, type)) {
                    continue
                }
                if (!(type in newState)) {
                    newState[type] = data[type]
                } else {
                    newState[type] = newState[type].concat(
                        data[type].filter((place) => {
                            return this.containsPlace[type].indexOf(place) === -1
                        }),
                    )
                }
            }
            this.containsPlace = newState
        },
        addContainsPlaces({ type, places }) {
            if (!(type in this.containsPlace)) {
                this.containsPlace[type] = places
            } else {
                this.containsPlace[type] = [...this.containsPlace[type], ...places]
            }
        },
    },
})
