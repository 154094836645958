export function showMobileNav() {
    document.querySelector('html').classList.add('mobile-nav')
}

export function hideMobileNav() {
    document.querySelector('html').classList.remove('mobile-nav')
}

export function toggleMobileNav() {
    if (document.querySelector('html').matches('.mobile-nav')) {
        hideMobileNav()
    } else {
        showMobileNav()
    }
}

export function handleMobileNavToggle() {
    const el = document.getElementById('hamburger')

    if (!el) {
        return
    }

    el.addEventListener('click', toggleMobileNav)
}

export function handleMobileNavClose() {
    const el = document.getElementById('mobile-nav-closer')

    if (!el) {
        return
    }

    el.addEventListener('click', hideMobileNav)

    const mobileNavCloser = document.getElementById('mobile-nav').querySelector('.closer')
    if (mobileNavCloser) {
        mobileNavCloser.addEventListener('click', hideMobileNav)
    }
}

export function handleMobileNavCloserTouch() {
    const el = document.getElementById('mobile-nav-closer')

    if (!el) {
        return
    }

    el.addEventListener('touchmove', (e) => {
        e.preventDefault()
    })
}

export function handleMobileNavSubMenuToggle() {
    const expanderLinks = document.querySelectorAll('#mobile-nav li a.expander')
    expanderLinks.forEach((expander) => {
        expander.addEventListener('click', (e) => {
            e.preventDefault()

            const subMenu = expander.nextElementSibling
            if (subMenu && subMenu.tagName === 'UL') {
                toggleSubMenu(subMenu)
                const icon = expander.querySelector('i')
                if (icon) {
                    icon.classList.toggle('rotate-180')
                }
            }
        })
    })
}

function toggleSubMenu(subMenu) {
    const maxHeight = subMenu.scrollHeight
    const isOpen = subMenu.style.maxHeight !== '0px'

    if (isOpen) {
        subMenu.style.maxHeight = '0'
    } else {
        subMenu.style.maxHeight = `${maxHeight}px`
    }
}
