import { translate } from '@modules/locale/locale.js'
import http from '@utils/http.js'
import { activePopup, popup } from '@modules/popup/popup.js'

export const report = function (type, id, data, callback, reason = '') {
    const container = document.createElement('form')
    container.classList.add('pretty-form', 'text-center')

    const textarea = document.createElement('textarea')
    textarea.style.width = '100%'
    textarea.style.minHeight = '5em'
    textarea.style.marginBottom = '2em'
    textarea.required = true
    textarea.placeholder = translate('report_reason') + '...'
    container.appendChild(textarea)

    const submitButton = document.createElement('button')
    submitButton.classList.add('flat-button', 'blue')
    submitButton.textContent = translate('report')
    submitButton.type = 'submit'
    container.appendChild(submitButton)

    const cancelButton = document.createElement('a')
    cancelButton.classList.add('flat-button', 'transparent')
    cancelButton.textContent = translate('confirm_cancel')
    cancelButton.role = 'button'

    const reportOptions = { type, id, reason, data }

    const submitReport = function () {
        submitButton.classList.add('disabled')

        http.post('/community/report/', reportOptions).then(() => {
            popup({
                content: `<h1>${translate('report_thank_you')}</h1>`,
                width: 500,
            })
        })
    }

    container.addEventListener('submit', function (e) {
        e.preventDefault()
        if (submitButton.classList.contains('disabled')) {
            return false
        }
        reportOptions.reason = textarea.value
        if (reportOptions.reason.length < 1) {
            textarea.classList.add('invalid')
            textarea.focus()
        } else {
            textarea.classList.remove('invalid')
            submitReport()
        }
        return false
    })
    cancelButton.addEventListener('click', (e) => {
        e.preventDefault()
        if (activePopup) {
            activePopup.close()
        }
    })

    return popup({
        title: translate('report'),
        content: container,
        width: 500,
        afterOpen: function () {
            textarea.focus()
        },
    })
}

export const initReport = () => {
    document.querySelectorAll('#community-report-list .report-information').forEach((el) => {
        el.addEventListener('click', function () {
            const popupContainer = document.querySelector('#community-report-' + this.dataset.id)

            popup({
                content: popupContainer,
                width: 500,
            })
        })
    })

    document.querySelectorAll('#review-report-list .report-information').forEach((el) => {
        el.addEventListener('click', function () {
            const popupContainer = document.querySelector('#report-' + this.dataset.id)

            popup({
                content: popupContainer,
                width: 500,
            })
        })
    })
}
