import { useLocaleStore } from '../../../store/locale.js'

export const formatNumber = function (number, locale, decimals = undefined, options = {}) {
    return number === null
        ? null
        : parseFloat(number).toLocaleString(locale, {
              minimumFractionDigits: decimals,
              maximumFractionDigits: decimals,
              ...options,
          })
}
export const formatCurrency = function (
    number,
    locale,
    currency,
    decimals = undefined,
    options = {},
) {
    return formatNumber(number, locale, decimals, {
        style: currency ? 'currency' : undefined,
        currency,
        ...options,
    })
}

class FormattedNumber {
    constructor(number, locale) {
        this.number = number
        this.locale = locale
        this.options = {}
    }
    value(number) {
        this.number = number
        return this
    }
    locale(locale) {
        this.locale = locale
        return this
    }
    decimals(decimals) {
        this.options.minimumFractionDigits = decimals
        this.options.maximumFractionDigits = decimals
        return this
    }
    minDecimals(decimals) {
        this.options.minimumFractionDigits = decimals
        return this
    }
    maxDecimals(decimals) {
        this.options.maximumFractionDigits = decimals
        return this
    }
    currency(currency) {
        this.options.style = 'currency'
        this.options.currency = currency
        return this
    }
    signed() {
        this.options.signDisplay = 'always'
        return this
    }
    option(key, value) {
        this.options[key] = value
        return this
    }
    compact() {
        return this.option('notation', 'compact')
    }
    percent() {
        return this.option('style', 'percent')
    }
    addOptions(options) {
        this.options = {
            ...this.options,
            ...options,
        }
        return this
    }
    toString() {
        return parseFloat(this.number).toLocaleString(this.locale, this.options)
    }
    clone() {
        const instance = new FormattedNumber(this.number, this.locale)
        return instance.addOptions(this.options)
    }
}

export const useNumberFormat = function () {
    const localeStore = useLocaleStore()
    return {
        number(number, decimals = undefined, options = {}) {
            return new FormattedNumber(number, localeStore.code)
                .decimals(decimals)
                .addOptions(options)
        },
        currency(number, currency, decimals = undefined, options = {}) {
            return new FormattedNumber(number, localeStore.code)
                .currency(currency)
                .decimals(decimals)
                .addOptions(options)
        },
    }
}
