<template>
    <li class="base-filter__item" :class="activeItem ? 'base-filter__item--active' : null">
        <span class="base-filter__item-label">
            <i
                v-if="activeItem"
                @click="onClear"
                role="button"
                class="icon--close-x-small blue"
            ></i>
            <span role="button" @click="openDropdown">
                {{ !activeItem ? filter.placeholder : activeItem.label }}
            </span>
        </span>
        <div ref="popupEl" class="hidden pretty-form">
            <ul class="d-flex flex-wrap">
                <li
                    v-for="(item, index) in filter.items"
                    :key="item.value"
                    class="base-filter__sub-item"
                >
                    <span :class="tagType">
                        <input
                            :type="tagType"
                            :name="filter.key"
                            @input="onInput($event, index)"
                            :value="item.value"
                            :checked="item.value === modelValue"
                            :id="id + '_' + index"
                        />
                        <label :for="id + '_' + index">{{ item.label }}</label>
                    </span>
                </li>
            </ul>
        </div>
    </li>
</template>

<script>
import { popup } from '../../../es6/src/modules/popup/popup.js'

let thatsupPopup = null
export default {
    emits: ['update:modelValue'],
    props: {
        filter: {
            type: Object,
            required: false,
            default: () => {
                return {}
            },
        },
        modelValue: {
            type: [String, Number],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            activeIndex: null,
            _id: Math.floor(10000 * Math.random()),
            open: false,
        }
    },
    computed: {
        activeItem() {
            return this.filter.items.find((i) => i.value === this.modelValue)
        },
        tagType() {
            return this.filter.multi === true ? 'checkbox' : 'radio'
        },
        id() {
            return this.filter.key + '-' + this._id
        },
    },
    methods: {
        onInput(event, index) {
            if (thatsupPopup) {
                thatsupPopup.close()
            }
            this.activeIndex = index
            // this.$emit('change', { key: this.filter.key, value: event.target.value});
            this.$emit('update:modelValue', event.target.value)
        },

        onClear() {
            this.activeIndex = null
            // this.$emit('change', { key: this.filter.key, value: undefined });
            this.$emit('update:modelValue', null)
        },

        openDropdown() {
            console.log(this.$refs.popupEl)
            thatsupPopup = popup({
                content: this.$refs.popupEl,
                width: 600,
            })
            // this.open = true;

            // window.setTimeout(() => {
            //     document.addEventListener('click', this.closeDropdown);
            // }, 300);
        },
        closeDropdown() {
            this.open = false

            document.removeEventListener('click', this.closeDropdown)
        },
    },
    unmounted() {
        document.removeEventListener('click', this.closeDropdown)
    },
}
</script>

<style scoped>
.base-filter__sub-item {
    flex-basis: 50%;
    width: 50%;
    min-width: 250px;
}
</style>
