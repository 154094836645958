import { photoswipe } from '../photoswipe/photoswipe.js'

export const storyGallery = function (gallery, options = {}) {
    this.options = {
        autoStart: true,
        ...options,
    }
    const timeline = document.createElement('div')
    timeline.classList.add('timeline')
    gallery.appendChild(timeline)

    const controls = document.createElement('div')
    controls.classList.add('controls')
    const enlargeButton = document.createElement('button')
    enlargeButton.type = 'button'
    enlargeButton.setAttribute('aria-label', 'Enlarge image')
    enlargeButton.className = 'control enlarge'
    controls.appendChild(enlargeButton)
    gallery.appendChild(controls)

    const imgs = Array.from(gallery.querySelectorAll('figure'))

    const count = imgs.length

    if (count <= 1) {
        timeline.style.display = 'none'
    }

    let active = 0

    for (let i = 0; i < count; i++) {
        const step = document.createElement('div')
        step.classList.add('step')
        if (i === active) {
            step.classList.add('active')
        }
        timeline.appendChild(step)
    }

    const timelines = Array.from(timeline.children)

    const TIME_PER_SLIDE = 3500

    let previousTime = null
    let elapsed = 0
    let paused = true

    const loadImage = (figure) => {
        if (!figure || figure.classList.contains('loaded')) return
        figure.classList.add('loaded')

        const img = figure.querySelector('img')
        if (!img || !img.hasAttribute('data-src')) return
        img.setAttribute('draggable', 'false')

        img.setAttribute('src', img.getAttribute('data-src'))
        img.removeAttribute('data-src')
        const srcset = img.getAttribute('data-srcset')
        if (srcset) {
            img.setAttribute('srcset', img.getAttribute('data-srcset'))
            img.removeAttribute('data-srcset')
        }
    }

    imgs[0].classList.add('active')

    const setElapsed = (newElapsed) => {
        elapsed = newElapsed
        if (elapsed < 0) {
            elapsed += TIME_PER_SLIDE * count
        } else if (elapsed >= TIME_PER_SLIDE * count) {
            elapsed = 0
            hasLoopedAround = true
        }
        const activeSlide = Math.floor(elapsed / TIME_PER_SLIDE)
        imgs.forEach((img, i) => {
            img.classList.toggle('active', i === activeSlide)
            if (i <= activeSlide + 1) {
                loadImage(img)
            }
        })
        for (let i = 0; i < timelines.length; i++) {
            const t = timelines[i]
            const min = i * TIME_PER_SLIDE
            const max = min + TIME_PER_SLIDE
            if (elapsed > max) {
                t.style.setProperty('--gallery-progress', '100%')
            } else if (elapsed > min && elapsed < max) {
                t.style.setProperty(
                    '--gallery-progress',
                    Math.round(((elapsed - min) / TIME_PER_SLIDE) * 100) + '%',
                )
                imgs[i].style.setProperty(
                    '--gallery-progress',
                    Math.round(((elapsed - min) / TIME_PER_SLIDE) * 100) + '%',
                )
            } else {
                t.style.removeProperty('--gallery-progress')
            }
        }
    }

    let hasLoopedAround = false
    const step = () => {
        window.requestAnimationFrame((timestamp) => {
            if (paused) {
                previousTime = null
                gallery.classList.remove('started')
                return
            }
            setElapsed(elapsed + (previousTime === null ? 0 : timestamp - previousTime))
            previousTime = timestamp
            step()
        })
    }

    const getActiveIndex = () => {
        return Math.floor(elapsed / TIME_PER_SLIDE)
    }

    const start = () => {
        if (!paused) {
            return
        }
        paused = false
        gallery.classList.add('started')
        if (count > 1) {
            step()
        }
    }

    const stop = () => {
        paused = true
    }
    let previousIntersect = 0
    const observer = new IntersectionObserver(
        (entries) => {
            const entry = entries[0]
            if (imgs.length) {
                loadImage(imgs[0])
            }
            if (
                this.options.autoStart !== false &&
                entry.isIntersecting &&
                entry.intersectionRatio >= 0.7
            ) {
                // previousTime = null;
                start()
            } else if (!paused && previousIntersect >= entry.intersectionRatio) {
                stop()
            }
            previousIntersect = entry.intersectionRatio
        },
        {
            threshold: [0, 0.7],
        },
    )

    observer.observe(gallery)

    const getItemSize = (figure, img) => {
        return new Promise((resolve, reject) => {
            if (figure.dataset.size) {
                const size = figure.dataset.size.split('x')
                resolve({
                    width: parseInt(size[0]),
                    height: parseInt(size[1]),
                })
            } else if (img.getAttribute('width') && img.getAttribute('height')) {
                resolve({
                    width: parseInt(img.getAttribute('width')),
                    height: parseInt(img.getAttribute('height')),
                })
            } else {
                const img2 = new Image()
                img2.onload = () => {
                    figure.dataset.size = [img2.width, 'x', img2.height].join('')
                    resolve({
                        width: img2.width,
                        height: img2.height,
                    })
                }
                img2.onerror = () => reject()
                img2.src = img.dataset.src || img.src
            }
        })
    }
    const makeItem = async (figure) => {
        const img = figure.querySelector('img')
        if (!img) return Promise.reject('No image')
        const size = await getItemSize(figure, img)
        return {
            ...size,
            src: img.dataset.src || img.src,
            srcset: img.dataset.srcset || img.srcset,
            alt: img.alt,
            caption: img.alt,
            element: figure,
        }
    }
    const enlarge = async () => {
        stop()
        const promises = imgs.map(async (figure) => {
            return makeItem(figure).catch(() => null)
        })
        openPhotoswipe(await Promise.all(promises))
    }
    const openPhotoswipe = (items) => {
        const photoswipeInstance = photoswipe(items.filter((i) => i !== null))

        photoswipeInstance.init()
        photoswipeInstance.loadAndOpen(Math.floor(elapsed / TIME_PER_SLIDE))

        photoswipeInstance.on('close', () => {
            const index = photoswipeInstance.pswp.currIndex
            setElapsed(TIME_PER_SLIDE * index)
            if (options.autoStart) {
                start()
            }
        })
    }

    enlargeButton.addEventListener('click', () => {
        enlarge()
    })

    const normalizePosition = function (event) {
        if (event.constructor.name === 'TouchEvent') {
            return { x: event.touches[0].clientX, y: event.touches[0].clientY }
        } else {
            return { x: event.clientX, y: event.clientY }
        }
    }
    let didTouchStart = false
    let didTouchStartReset = null
    const touchStart = (e) => {
        if ((e.touches && e.touches.length > 1) || (e.button && e.button === 2)) {
            return
        }
        if (e.type !== 'touchstart' && didTouchStart) {
            // A touchstart event has already been fired, skip mousedown
            return
        }
        if (controls.contains(e.target)) {
            return
        }
        if (didTouchStartReset) {
            window.clearTimeout(didTouchStartReset)
        }
        didTouchStart = e.type === 'touchstart'
        didTouchStartReset = window.setTimeout(() => (didTouchStart = false), 1000)

        const startPosition = normalizePosition(e)
        const touchTime = new Date().getTime()

        let pauseTimeout = window.setTimeout(() => {
            paused = true
            pauseTimeout = null
        }, 50)
        // previousTime = null;

        // Bind release
        const endEvent = e.constructor.name === 'TouchEvent' ? 'touchend' : 'mouseup'
        let movePosition = startPosition
        let shouldPreventScroll = null

        const onMove = (e) => {
            movePosition = normalizePosition(e)
            if (
                shouldPreventScroll === null &&
                Math.abs(movePosition.x - startPosition.x) >
                    Math.abs(movePosition.y - startPosition.y)
            ) {
                shouldPreventScroll = true
            }
            if (shouldPreventScroll) {
                e.preventDefault()
            }
        }
        const onEnd = () => {
            if (pauseTimeout) {
                window.clearTimeout(pauseTimeout)
            }
            let shouldStart = true
            if (
                // Swipe
                Math.abs(movePosition.x - startPosition.x) >= 50 &&
                Math.abs(movePosition.y - startPosition.y) < 50
            ) {
                // Math.sign gives the direction ([-1, 1]) multiplied by the time per slide
                setElapsed(
                    Math.floor(
                        (elapsed - Math.sign(movePosition.x - startPosition.x) * TIME_PER_SLIDE) /
                            TIME_PER_SLIDE,
                    ) * TIME_PER_SLIDE,
                )
            } else if (
                // Short press (< 100ms)
                new Date().getTime() - touchTime < 300 &&
                Math.abs(movePosition.y - startPosition.y) < 10
            ) {
                const rect = gallery.getBoundingClientRect()
                if (startPosition.x < rect.x + rect.width * 0.35) {
                    // Left click
                    setElapsed(
                        Math.max(
                            hasLoopedAround ? -1 * TIME_PER_SLIDE : 0,
                            Math.floor((elapsed - TIME_PER_SLIDE) / TIME_PER_SLIDE),
                        ) * TIME_PER_SLIDE,
                    )
                } else if (startPosition.x > rect.x + rect.width * 0.65) {
                    // Right click
                    setElapsed(
                        Math.floor((elapsed + TIME_PER_SLIDE) / TIME_PER_SLIDE) * TIME_PER_SLIDE,
                    )
                } else {
                    // Center click
                    enlarge()
                    shouldStart = false
                }
            }
            if (shouldStart) {
                start()
            }
            document.removeEventListener(endEvent, onEnd)
            document.removeEventListener('touchmove', onMove)
        }
        document.addEventListener(endEvent, onEnd)
        document.addEventListener('touchmove', onMove, { passive: false })
    }
    gallery.addEventListener('mousedown', touchStart, { passive: true })
    gallery.addEventListener('touchstart', touchStart, { passive: false })

    this.start = start
    this.stop = stop
    this.isPaused = () => paused
    this.getActiveIndex = getActiveIndex
}
