<template>
    <CarouselElement v-if="items.length" :items="items" :title="title" :description="description" />
</template>
<script setup>
import CarouselElement from './CarouselElement.vue'
import BaseElement from './BaseElement.vue'
import { computed } from 'vue'
import CarouselImage from './CarouselImage.vue'
import CarouselImageSkeleton from './CarouselImageSkeleton.vue'

const props = defineProps({
    ...BaseElement.props,
    data: {
        type: Object,
        required: false,
        default: null,
    },
})

const items = computed(() => {
    if (props.data === null) {
        const skeletons = []
        for (let i = 0; i < (props.configuration.limit || 3); i++) {
            skeletons.push({
                component: CarouselImageSkeleton,
                props: { index: i },
            })
        }
        return skeletons
    }
    return props.data.items.map((teaser) => {
        return {
            component: CarouselImage,
            props: {
                caption: teaser.subtitle,
                description: teaser.title,
                src: teaser.artworkSrc,
                srcSet: teaser.artworkSrcSet,
                href: teaser.permalink,
                icon: 'icon category large ' + teaser.icon,
            },
        }
    })
})
</script>
