<template>
    <div class="place-list__information place-list__information--skeleton">
        <div class="place-list__information-header">
            <div class="place-list__title-container">
                <h1 class="place-list__title" style="width: 60%">&nbsp;</h1>
                <div class="place-list__subtitle" style="width: 80%">&nbsp;</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {}
    },

    computed: {
        titleWidth() {
            return 50 + Math.floor(Math.random() * 300)
        },
    },

    watch: {},

    created() {},

    destroyed() {},
}
</script>
