<template>
    <fieldset class="form-group">
        <input
            v-autofocus
            name="email"
            type="email"
            required="required"
            autocomplete="username"
            :placeholder="$t('authForm.email')"
            :value="modelValue.email"
            :class="{ invalid: isInvalid(invalidFields, 'email') }"
            @input="change"
        />
        <p class="explanation text-center mt-2" v-if="emailSuggestion">
            {{ $t('authForm.email_suggestion', emailSuggestion) }}
        </p>
        <input
            name="password"
            type="password"
            tabindex="-1"
            aria-hidden="true"
            :value="modelValue.password"
            :class="autocompleteHiddenFieldClasses"
            @input="change"
        />
    </fieldset>
</template>
<script setup>
import { computed, ref } from 'vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
    emailSuggestion: {
        type: String,
        required: false,
        default: null,
    },
    modelValue: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    invalidFields: {
        type: Array,
        required: false,
        default: () => [],
    },
})

const autocompleteFieldsAreHidden = ref(false)

const autocompleteHiddenFieldClasses = computed(() => {
    return {
        'autocomplete-hidden-field': true,
        'autocomplete-hidden-field--hidden': autocompleteFieldsAreHidden.value,
    }
})

const isInvalid = (fields, field) => {
    return fields.indexOf(field) !== -1
}

const change = (event) => {
    emit('update:modelValue', {
        ...props.modelValue,
        [event.target.name]: event.target.value,
    })
}

const hideAutocompleteHiddenFields = () => {
    window.setTimeout(() => {
        autocompleteFieldsAreHidden.value = true
    }, 100)
}

hideAutocompleteHiddenFields()
</script>
