import clickOutside from '../../utils/clickOutside'

const makeWrapper = () => {
    const element = window.document.createElement('div')
    element.classList.add('thatsup-datepicker')
    element.classList.add('popup')
    element.classList.add('bubble')
    element.classList.add('shadow')
    element.classList.add('drop-in')
    element.style.minWidth = '280px'
    element.style.display = 'none'
    return element
}

const BASE_MARKUP = `
    <div class="thatsup-datepicker__head">
        <button type="button" class="navigation navigation--prev thatsup-datepicker__prev">
            <i class="icon--chevron-left icon--chevron-left--white"></i>
        </button>
        <span class="thatsup-datepicker__headline"></span>
        <button type="button" class="navigation navigation--next thatsup-datepicker__next">
            <i class="icon--chevron-left icon--chevron-left--white rotate-180"></i>
        </button>
    </div>
    <div class="thatsup-datepicker__body">
    </div>
`

const cleanValue = (value, addCentury = true) => {
    let newValue = value
    if (addCentury) {
        const century = new Date().getFullYear().toString().substring(0, 2)
        newValue = newValue.replace(/^(\d{2})-$/g, century + '$1-')
    }

    newValue = newValue.replace(/^(\d{4})(\d{1,})/g, '$1-$2')
    newValue = newValue.replace(/^(\d{4})-([2-9]{1})/g, '$1-0$2')
    newValue = newValue.replace(/^(\d{4})-(\d{2})-([4-9]{1})/g, '$1-$2-0$3')
    newValue = newValue.replace(/^(\d{4})-(\d{2})(\d{1,})/g, '$1-$2-$3')

    return newValue
}

const dateToString = (date, format = 'YYYY-MM-DD') => {
    const zeroFill = function (s) {
        return s.toString().length === 1 ? '0' + s : s
    }
    if (Number.isInteger(date)) {
        date = new Date(date)
    }

    return format
        .replace('YYYY', date.getFullYear())
        .replace('MM', zeroFill(date.getMonth() + 1))
        .replace('DD', zeroFill(date.getDate()))
}

const DEFAULT_OPTIONS = {
    maxDates: null,
    multi: false,
    range: false,
    displayDate: null,
    minDate: null,
    maxDate: null,
    value: null,
    minRange: 0,
    firstDayOfWeek: 0,
    dayNames: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    displayValueWhenOpen: 'block',

    onSelect: function () {},
    onDeselect: function () {},
    onChange: function () {},
    onRangeChange: function () {},
    onDisplayDateChange: function () {},
}

class datepicker {
    element = null
    input = null

    constructor(container = null, options = {}) {
        this.options = { ...DEFAULT_OPTIONS, ...options }

        if (!container) {
            this.container = makeWrapper()
        } else if (typeof container === 'string') {
            this.container = window.document.querySelector(container)
        } else {
            this.container = container
        }

        if (!this.container) {
            return
        }

        this.#initElement()

        this.range = this.options.range
        this.minRange = this.options.minRange
        this.multi = this.options.multi
        this.maxDates = !this.options.multi ? 1 : this.options.maxDates || Math.Infinity
        this.minDate = this.options.minDate ? this.#parseDate(this.options.minDate).getTime() : null
        this.maxDate = this.options.maxDate ? this.#parseDate(this.options.maxDate).getTime() : null

        let selected = this.options.value || (this.input ? this.input.value : [])
        if (!Array.isArray(selected)) {
            selected = [selected]
        }
        this.selectedDates = selected.map((d) => {
            return this.#parseDate(d).getTime()
        })

        this.setDisplayDate(this.options.displayDate || this.selectedDates[0] || new Date())
    }

    setValue(value) {
        let selected = value
        if (!Array.isArray(selected)) {
            selected = [selected]
        }
        this.selectedDates = selected.map((d) => {
            return this.#parseDate(d).getTime()
        })
        if (this.input) {
            this.input.value = this.selectedDates.length
                ? this.selectedDates.map((date) => dateToString(date)).join(', ')
                : ''
        }
        this.#onChange()
        this.#refreshDays()
    }

    setMinDate(date) {
        this.minDate = date === null ? null : this.#parseDate(date).getTime()
        this.#refreshDays()
    }

    setMaxDate(date) {
        this.maxDate = date === null ? null : this.#parseDate(date).getTime()
        this.#refreshDays()
    }

    #initElement() {
        const isInput = this.container.tagName === 'INPUT'
        if (!isInput) {
            this.container.innerHTML = BASE_MARKUP
        } else {
            this.input = this.container
            this.container = makeWrapper()
            this.container.innerHTML = BASE_MARKUP

            if (this.input.nextSibling) {
                this.input.parentElement.insertBefore(this.container, this.input.nextSibling)
            } else {
                this.input.parentElement.appendChild(this.container)
            }

            this.#addInputListeners()
        }

        this.head = this.container.querySelector('.thatsup-datepicker__head')
        this.prevButton = this.container.querySelector('.thatsup-datepicker__prev')
        this.headline = this.container.querySelector('.thatsup-datepicker__headline')
        this.nextButton = this.container.querySelector('.thatsup-datepicker__next')
        this.body = this.container.querySelector('.thatsup-datepicker__body')

        this.nextButton.addEventListener('click', () => {
            const currentDate = this.displayDate
            const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
            this.setDisplayDate(newDate)
        })
        this.prevButton.addEventListener('click', () => {
            const currentDate = this.displayDate
            const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
            this.setDisplayDate(newDate)
        })
    }

    #addInputListeners() {
        this.input.setAttribute('autocomplete', 'off')
        this.input.addEventListener('focus', () => {
            this.open()
        })
        this.input.addEventListener('click', () => {
            if (!this.isOpen()) {
                this.open()
            }
        })
        let isRemoving = false
        this.input.addEventListener('keydown', (e) => {
            isRemoving = e.which === 8 || e.which === 46
            if (e.which === 9 || e.which === 27) {
                // Close the datepicker when the tab or escape key is pressed
                this.close()
            } else if (e.which === 40 && !this.isOpen()) {
                // Open the datepicker when the down arrow key is pressed
                this.open()
            } else if (e.which === 13) {
                // Add the date when the enter key is pressed
                const newValue = cleanValue(this.input.value, true)
                const date = this.#parseDate(newValue)
                if (!isNaN(date) && !this.dateIsOutsideBounds(date)) {
                    this.toggleDate(date)
                } else {
                    e.preventDefault()
                }
            }
        })
        this.input.addEventListener('input', () => {
            if (!this.isOpen()) {
                this.open()
            }
            const newValue = cleanValue(this.input.value, !isRemoving)
            const date = this.#parseDate(newValue)
            if (!isNaN(date)) {
                const y = date.getFullYear()
                const cy = new Date().getFullYear()
                if (y < cy + 100 && y > cy - 100) {
                    this.setDisplayDate(new Date(date))
                }
            }
            if (newValue === this.input.value) {
                return
            }
            if (!isNaN(date)) {
                const oldLocation = this.input.selectionStart
                const oldValue = this.input.value
                this.input.value = newValue
                this.input.selectionStart = oldLocation + (newValue.length - oldValue.length)
                this.input.selectionEnd = this.input.selectionStart
            }
            isRemoving = false
        })
    }

    setDisplayDate(date) {
        date = this.#parseDate(date)
        if (this.displayDate && dateToString(date) === dateToString(this.displayDate)) {
            return
        }
        const isInView = this.#isDateInView(date)
        this.displayDate = date
        this.#onDisplayDateChange(date)
        if (!isInView) {
            window.requestAnimationFrame(() => {
                this.#render()
            })
        }
    }

    #isDateInView(date) {
        if (!this.displayDate) {
            return false
        }
        date = this.#parseDate(date)
        const displayDate = this.#parseDate(this.displayDate)
        return (
            date.getMonth() === displayDate.getMonth() &&
            date.getFullYear() === displayDate.getFullYear()
        )
    }

    #parseDate(date) {
        if (date instanceof Date) {
            return date
        } else if (Number.isInteger(date)) {
            return new Date(date)
        }
        const newDate = new Date(Date.parse(date))
        if (newDate instanceof Date) {
            newDate.setHours(0, 0, 0, 0)
        }
        return newDate
    }

    #onDisplayDateChange(date) {
        this.options.onDisplayDateChange.apply(this, [date])
    }

    open() {
        this.container.style.display = this.options.displayValueWhenOpen
        if (this.input) {
            this.container.style.left = this.input.offsetLeft + 'px'
        }
        if (!this.clickOutside) {
            this.clickOutside = clickOutside(this.container, () => {
                this.close()
            })
            if (this.input) {
                this.clickOutside.addElement(this.input)
            }
        }
    }
    close() {
        this.container.style.display = 'none'
        if (this.clickOutside) {
            this.clickOutside.remove()
            this.clickOutside = null
        }
    }

    isOpen() {
        return this.container.style.display === this.options.displayValueWhenOpen
    }

    setSelectedDates(selectedDates) {
        if (selectedDates === null) {
            selectedDates = []
        } else if (!Array.isArray(selectedDates)) {
            selectedDates = [selectedDates]
        }
        this.selectedDates = selectedDates
        this._onChange.apply(this, [null, null])
        this.setClasses()
    }

    dateIsInRange(date) {
        if (!this.range || this.selectedDates.length !== 2) {
            return false
        }
        return (
            date > Math.min.apply(null, this.selectedDates) &&
            date < Math.max.apply(null, this.selectedDates)
        )
    }

    dateIsOutsideBounds(date) {
        if (this.minDate === null && this.maxDate === null) {
            return false
        }
        var min = this.minDate !== null && date < this.minDate
        var max = this.maxDate !== null && date > this.maxDate

        return min || max
    }

    dateIsActive(date) {
        return this.dateIndex(this.#parseDate(date).getTime()) > -1
    }
    dateIsDisplayDate(date) {
        return dateToString(date) === dateToString(this.displayDate)
    }
    dateIndex(date) {
        return this.selectedDates.indexOf(this.#parseDate(date).getTime())
    }

    toggleDate(date) {
        if (this.dateIsOutsideBounds(date)) {
            return null
        }
        const dateString = dateToString(date)
        const dateIndex = this.dateIndex(dateString)
        if (this.range) {
            this.#onSelect(date, dateString)
        } else if (dateIndex === -1 || (this.maxDates === 1 && this.input)) {
            this.#onSelect(date, dateString)
        } else {
            this.#onDeselect(date, dateString, dateIndex)
        }
    }

    #onSelect(date, dateString) {
        date = this.#parseDate(date)
        const validate = this.options.onSelect.apply(this, [date, dateString])
        if (validate === false) {
            return
        }
        if (this.range) {
            if (this.selectedDates.length >= 2) {
                this.selectedDates = []
            }
            this.selectedDates.push(date.getTime())
            this.selectedDates.sort(function (a, b) {
                return a - b
            })
            if (this.selectedDates.length === 1 && this.minRange) {
                this.previousMinDate = this.minDate
                this.minDate = this.selectedDates[0] + this.minRange * 24 * 60 * 60 * 1000
                this.#refreshDays()
            }
            if (this.selectedDates.length === 2) {
                if (this.previousMinDate !== undefined) {
                    this.minDate = this.previousMinDate
                    this.previousMinDate = undefined
                    this.#refreshDays()
                }
                this.#onRangeChange()
            }
        } else {
            if (this.selectedDates.length >= this.maxDates) {
                this.selectedDates.splice(0, this.selectedDates.length - this.maxDates + 1)
            }
            this.selectedDates.push(date.getTime())
        }
        this.setDisplayDate(date)
        this.#onChange(date, dateString)
        this.#refreshDays()
        // this._onChange.apply(this, [date, dateString])
        // this.setClasses()
    }

    #onDeselect(date, dateString, dateIndex) {
        const validate = this.options.onDeselect.apply(this, [date, dateString, dateIndex])
        if (validate === false) {
            return
        }
        this.selectedDates.splice(dateIndex, 1)
        this.#onChange(date, dateString)
        this.#refreshDays()
    }

    #onChange(date = null, dateString = null) {
        if (this.input && dateString) {
            this.input.value = dateString
            this.close()
        }
        if (this.range) {
            this.options.onChange.apply(this, [
                {
                    date,
                    dateString,
                    value: this.selectedDates.length === 2 ? this.getFullRange() : null,
                    selected: this.selectedDates.map((date) => dateToString(date)),
                    from: this.selectedDates[0] ? dateToString(this.selectedDates[0]) : null,
                    to: this.selectedDates[1] ? dateToString(this.selectedDates[1]) : null,
                    instance: this,
                },
            ])
        } else {
            this.options.onChange.apply(this, [
                {
                    date,
                    dateString,
                    value: !this.multi
                        ? this.selectedDates.length
                            ? dateToString(this.selectedDates[0])
                            : null
                        : this.selectedDates.map((date) => dateToString(date)),
                    instance: this,
                },
            ])
        }
    }

    #onRangeChange() {
        return this.options.onRangeChange.apply(this, [
            {
                value: this.getFullRange(),
                selected: this.selectedDates.map((date) => dateToString(date)),
                from: this.selectedDates[0] ? dateToString(this.selectedDates[0]) : null,
                to: this.selectedDates[1] ? dateToString(this.selectedDates[1]) : null,
                instance: this,
            },
        ])
    }

    getFullRange() {
        const fromDate = new Date(this.selectedDates[0])
        const toDate = new Date(this.selectedDates[1])
        const theDate = new Date(fromDate.getTime())
        const range = []
        while (theDate <= toDate) {
            range.push(new Date(theDate.getTime()))
            theDate.setDate(theDate.getDate() + 1)
        }
        return range.map((date) => {
            return dateToString(date)
        })
    }

    #render() {
        this.#renderMonth(this.displayDate)
        // this.setClasses()
        this.headline.innerText =
            this.getMonthName(this.displayDate.getMonth()) + ' ' + this.displayDate.getFullYear()
    }

    #renderMonth(date) {
        const wrapper = window.document.createElement('div')
        wrapper.classList.add('month')

        wrapper.appendChild(this.#renderDayNames())

        const firstDay = this.options.firstDayOfWeek

        const weeks = []

        const currentDate = new Date(date.getFullYear(), date.getMonth(), 1)
        const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)

        let activeWeek = null
        this.days = []
        while (currentDate <= lastDate) {
            if (activeWeek === null || currentDate.getDay() === firstDay) {
                if (activeWeek) {
                    weeks.push(activeWeek)
                }
                activeWeek = window.document.createElement('div')
                activeWeek.classList.add('week')
            }
            // Clone the date to prevent modifying the original
            const activeDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
            )
            const day = window.document.createElement('button')
            day.classList.add('day')
            day.setAttribute('type', 'button')
            day.textContent = currentDate.getDate()
            if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
                day.classList.add('weekend')
            }
            this.#refreshDay(currentDate, day)
            day.dataset.year = currentDate.getFullYear()
            day.dataset.month = currentDate.getMonth()
            day.dataset.day = currentDate.getDate()
            day.addEventListener('click', () => {
                this.toggleDate(activeDate.getTime())
            })
            activeWeek.appendChild(day)
            this.days.push({
                element: day,
                date: activeDate.getTime(),
            })

            currentDate.setDate(currentDate.getDate() + 1)
        }
        weeks.push(activeWeek)
        const weekWrapper = window.document.createElement('div')
        weekWrapper.classList.add('weeks')
        weeks.forEach((week) => {
            weekWrapper.appendChild(week)
        })

        wrapper.appendChild(weekWrapper)
        this.body.innerHTML = ''
        this.body.appendChild(wrapper)
        return wrapper
    }

    #renderDayNames() {
        const wrapper = window.document.createElement('div')
        wrapper.classList.add('daynames')
        const firstDay = this.options.firstDayOfWeek
        for (let i = 0; i < 7; i++) {
            const day = (i + firstDay) % 7
            const dayName = window.document.createElement('span')
            dayName.classList.add('dayname')
            if (day === 0 || day === 6) {
                dayName.classList.add('weekend')
            }
            dayName.textContent = this.getDayName(day)
            wrapper.appendChild(dayName)
        }
        return wrapper
    }

    getDayName(dayOfWeek) {
        return this.options.dayNames[dayOfWeek]
    }

    getMonthName(month) {
        return this.options.monthNames[month]
    }

    #refreshDays() {
        this.days.forEach((day) => {
            this.#refreshDay(day.date, day.element)
        })
    }

    #refreshDay(date, elm) {
        const today = new Date()
        today.setHours(0, 0, 0, 0)

        elm.classList.toggle('disabled', this.dateIsOutsideBounds(date))
        elm.classList.toggle('active', this.dateIsActive(date))
        elm.classList.toggle('display-date', this.dateIsDisplayDate(date))
        elm.classList.toggle('range', this.dateIsInRange(date))
        elm.classList.toggle('today', this.#parseDate(date).getTime() === today.getTime())
    }
}

export default function (container, options) {
    if (container instanceof NodeList) {
        return Array.from(container).map((el) => {
            return new datepicker(el, options)
        })
    } else if (Array.isArray(container)) {
        return container.map((el) => {
            return new datepicker(el, options)
        })
    }
    return new datepicker(container, options)
}
