import { defineStore } from 'pinia'
import { setLocale as setDateLocale } from '../es6/src/utils/dateFormatter.js'

export const useLocaleStore = defineStore('locale', {
    state: () => ({
        code: window.globalVars.locale.code,
        fullCode: window.globalVars.locale.fullCode,
    }),
    getters: {},
    actions: {
        setLocale(code, fullCode = null) {
            this.code = code
            this.fullCode = fullCode

            setDateLocale(code)
        },
    },
})
