<template>
    <div class="box shadow" :class="$style.place">
        <a
            :href="modelValue.permalink"
            :title="modelValue.name"
            class="bg-dark-slate-blue"
            :class="$style.thumbnail"
        >
            <ImageWithSizes
                v-if="modelValue.thumbnail"
                loading="lazy"
                width="113"
                height="87"
                :class="$style.image"
                :src="modelValue.thumbnail"
                :alt="modelValue.name"
            />
            <div v-else class="bg-dark-slate-blue" :class="[$style.imagePlaceholder, $style.image]">
                <i class="icon category medium dark-slate-blue" :class="[modelValue.icon]" />
            </div>
        </a>

        <div :class="$style.main">
            <div>
                <div :class="$style.header">
                    <h5 :class="$style.title">
                        <a :href="modelValue.permalink" :title="modelValue.name">
                            <span v-if="index">{{ index }}.</span>
                            {{ modelValue.shortName || modelValue.name }}
                        </a>
                    </h5>

                    <PlaceListSavedIndicator :place="modelValue" />
                </div>

                <div :class="$style.reviewsText">
                    {{
                        $t('x_review' + (modelValue.reviewCount !== 1 ? 's' : ''), {
                            reviewCount: modelValue.reviewCount,
                        })
                    }}
                </div>
            </div>

            <div class="review-star-container review-star-container--small">
                <i
                    v-for="index in 5"
                    class="icon--review-star review-star--small"
                    :class="{
                        empty: Math.round(modelValue.rating) < index,
                        half: modelValue.rating >= index - 0.7 && modelValue.rating <= index - 0.3,
                    }"
                ></i>
                <span :class="$style.ratingText">{{ formattedRating }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import ImageWithSizes from './ImageWithSizes.vue'
import PlaceListSavedIndicator from './PlaceListSavedIndicator.vue'

import { useNumberFormat } from '../util/number-format'
import { computed } from 'vue'

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
    index: {
        type: Number,
        required: false,
        default: null,
    },
})
const { number } = useNumberFormat()

const formattedRating = computed(() => {
    return number(props.modelValue.rating || 0, 1).toString()
})
</script>

<style module>
.place {
    padding: 8px 14px 8px 8px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    gap: 12px;
}

.main {
    min-width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.thumbnail {
    flex: 0 0 80px;
    max-width: 80px;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    background: #dadada;
    border-radius: 7px;
}

@media screen and (min-width: 768px) {
    .thumbnail {
        /*flex: 0 0 113px;*/
        /*max-width: 113px;*/
        /*aspect-ratio: 4 / 3;*/
    }
}

.image {
    width: 100%;
    height: 100%;
    aspect-ratio: inherit;
    object-fit: cover;
}
.imagePlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    width: 100%;
    height: 100%;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.reviewsText {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
}

.savePlace {
    width: 19px;
    flex-shrink: 0;
    margin-left: 5px;
}

.ratingText,
.title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
}

.title a {
    color: var(--body-font-color);
}

.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
