<template>
    <div class="place-explorer__filter-modal__navigation d-flex items-center justify-between">
        <div class="not-flex-shrink mr-1">
            <span
                v-if="hasGroup"
                class="d-flex items-center"
                role="button"
                @click="$emit('close-group')"
            >
                <i class="icon icon--chevron-left icon--chevron-left--gray mr-1"></i>
                {{ $t('pagination.back') }}
            </span>
        </div>
        <div class="overflow-ellipsis place-explorer__filter-modal__navigation-title">
            {{ $t('explore.filter.label') }}
        </div>
        <div class="not-flex-shrink ml-1">
            <a v-if="hasChanged" role="button" @click="$emit('apply')">
                {{ $t('explore.filter.apply_label') }}
            </a>
            <span
                v-else
                :title="$t('explore.filter.close_label')"
                role="button"
                @click="$emit('close')"
            >
                <i class="icon icon--close-x icon--x16"></i>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        filter: Object,
        isOpen: Boolean,
        hasGroup: Boolean,
        hasChanged: Boolean,
        hasParams: Boolean,
    },
}
</script>
