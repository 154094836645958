import { translationExists, translate } from '../../../../es6/src/modules/locale/locale.js'
import { googleMaps } from '../../../../es6/src/modules/map/googleMaps.js'

export const prettyAddress = function (location) {
    let locality = null
    let route = null
    let streetNumber = null

    const shouldUsePrettyAddress = !!location.address_components.find((component) => {
        return component.types.includes('country') && ['SE'].includes(component.short_name)
    })

    if (!shouldUsePrettyAddress) {
        return location.formatted_address
    }

    let hasGoodMatch = false
    for (let i = 0; i < location.address_components.length; i++) {
        const address = location.address_components[i]

        if (
            route === null &&
            (address.types.length === 0 ||
                address.types.includes('route') ||
                address.types.includes('neighborhood') ||
                address.types.includes('sublocality') ||
                address.types.includes('transit_station') ||
                address.types.includes('establishment') ||
                address.types.includes('premise'))
        ) {
            route = address.long_name
            hasGoodMatch = true
        } else if (
            route === null &&
            streetNumber === null &&
            address.types.indexOf('street_number') > -1
        ) {
            streetNumber = address.long_name
        } else if (
            address.types.indexOf('locality') > -1 ||
            address.types.indexOf('postal_town') > -1
        ) {
            if (locality != null && route == null && locality != address.long_name) {
                route = locality
            }
            locality = address.long_name
        }
    }
    if (!hasGoodMatch) {
        return location.formatted_address
    }

    const locationTitle =
        (route != null ? route : '') +
        (streetNumber != null ? ' ' + streetNumber : '') +
        (locality != null ? ', ' + locality : '')
    return locationTitle.replace(/^(\s|,)|(\s|,)$/, '').trim()
}

const memoize = {}

const filterResults = function (result, previousResults) {
    const previousgids = previousResults
        .map((p) => {
            return p.data ? p.data.gid : false
        })
        .filter((p) => !!p)

    const filtered = result.filter((result) => {
        return previousgids.indexOf(result.data.gid) === -1
    })

    return filtered
}

export default function (options, limit, previousResults = []) {
    const key = JSON.stringify(options)
    if (key in memoize) {
        const items = memoize[key]
        return Promise.resolve(limit ? [...items].splice(0, limit) : items)
    }
    return new Promise(async function (resolve, reject) {
        const maps = await googleMaps()

        if (!maps?.Geocoder) {
            return reject('Geocoder not found.')
        }
        const geocoder = new maps.Geocoder()
        geocoder.geocode(options, function (result) {
            if (result == null || result.length === 0) {
                resolve([])
                return
            }
            let items = []
            for (let i = 0; i < result.length; i++) {
                if (
                    result[i].types.indexOf('administrative_area_level_1') > -1 ||
                    result[i].types.indexOf('administrative_area_level_2') > -1
                ) {
                    continue
                }
                const location = result[i].geometry.location
                const hashCode = result[i].place_id

                const title = prettyAddress(result[i])
                items.push({
                    id: hashCode,
                    title: title,
                    subtitle: translationExists('address_type_' + result[i].types[0])
                        ? translate('address_type_' + result[i].types[0])
                        : translate('address_type_unknown'),
                    hitwords: [title.toLowerCase(), result[i].formatted_address.toLowerCase()],
                    // icon: "address " + result[i].types[0] + "",
                    type: 'position',
                    data: {
                        lat: location.lat(),
                        lng: location.lng(),
                        gid: result[i].place_id,
                    },
                })
            }
            items = filterResults(items, previousResults)
            memoize[key] = [...items]
            resolve(limit ? [...items].splice(0, limit) : items)
        })
    })
}
