import { pageView } from '../modules/tracking/tracking.js'

export const pushState = (data, title, url) => {
    if (!window.history) {
        return false
    }
    window.history.pushState(data, title, url)
    pageView(url)
}
export const replaceState = (data, title, url, triggerPageView) => {
    if (!window.history) {
        return false
    }
    window.history.replaceState(data, title, url)
    if (triggerPageView) {
        pageView(url)
    }
}
