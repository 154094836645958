<template>
    <UserActionCard
        :user="placeReview.user"
        :action="$t('elements.user_action.user_reviewed')"
        :class="$style.card"
    >
        <a :href="placeReview.permalink" :class="$style.card">
            <div :class="$style.place">
                {{ placeReview.place?.name }}
            </div>
            <div class="review-star-container review-star-container--small" :class="$style.stars">
                <i
                    v-for="index in 5"
                    class="icon--review-star"
                    :class="[
                        $style.star,
                        {
                            empty: index > Math.round(placeReview.rating),
                        },
                    ]"
                ></i>
            </div>
            <p v-if="excerpt" :class="$style.excerpt">
                {{ '“' + excerpt + '”' }}
            </p>
            <div v-if="visibleImages.length" :class="$style.images">
                <div
                    v-for="(image, i) in visibleImages"
                    :key="image.id"
                    :class="$style.imageWrapper"
                >
                    <img :src="image.url" alt="" :class="$style.image" />
                    <div
                        v-if="!!additionalImages && i === visibleImages.length - 1"
                        :class="$style.additionalImages"
                    >
                        +{{ additionalImages + 1 }}
                    </div>
                </div>
            </div>
        </a>
    </UserActionCard>
</template>

<script setup>
import { computed } from 'vue'
import UserActionCard from './UserActionCard.vue'

const props = defineProps({
    placeReview: {
        type: Object,
        required: true,
    },
})

const visibleImages = computed(() => {
    return [...(props.placeReview.images || [])].splice(0, 3)
})

const additionalImages = computed(() => {
    return (props.placeReview.images || []).length - visibleImages.value.length
})

const excerpt = computed(() => {
    const excerpt = props.placeReview.excerpt
    if (!excerpt || !visibleImages.value.length || excerpt.length < 100) return excerpt

    return excerpt.substring(0, 97).replace(/[^\w]+$/, '') + '...'
})
</script>

<style module>
.card {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    color: var(--body-font-color);
}
.place {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 8px;
}
.stars {
    margin-bottom: 16px;
}
.star {
    width: 26px;
    height: 26px;
    margin-right: 2px;
}
.excerpt {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.63;
    margin-bottom: 16px;
    overflow-wrap: break-word;
}
.images {
    display: flex;
    margin: auto -4px 0 -4px;
}
.imageWrapper {
    border-radius: 7px;
    overflow: hidden;
    aspect-ratio: 3 / 4;
    position: relative;
    flex-basis: 33%;
    max-width: calc(33% - 8px);
    flex-grow: 0;
    margin: 0 4px;
}
.image {
    width: 100%;
    height: 100%;
    aspect-ratio: inherit;
    object-fit: cover;
}
.additionalImages {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.86);
    font-size: 20px;
    font-weight: bold;
}
</style>
