import { defineStore } from 'pinia'
import { useUserStore } from './user.js'
import { computed, ref } from 'vue'
import { trackEvent } from '@modules/tracking/tracking'
import http from '../es6/src/utils/http.js'
import Eventbus from '@modules/eventbus/Eventbus.js'

const EMPTY_REVIEW = {
    id: null,
    placeId: null,
    bodyText: '',
    rating: null,
    images: [],
}

export const useReviewsStore = defineStore('reviews', () => {
    const reactions = ref({})
    const reactionTypes = ref([])
    const currentReview = ref({ ...EMPTY_REVIEW })
    const userReviews = ref({})
    const userStore = useUserStore()

    const getReaction = (reviewId) => {
        return reviewId in reactions.value ? reactions.value[reviewId] : false
    }

    const availableReactions = computed(() => {
        if (reactionTypes.value.length === 0) {
            loadAvailableReactions().then((reactions) => {
                reactionTypes.value = reactions
            })
        }

        return reactionTypes.value
    })

    const addReaction = ({ reviewId, reaction }) => {
        reactions.value = {
            ...reactions.value,
            [reviewId]: reaction,
        }
    }

    const clearReview = () => {
        if (!currentReview.value.id) {
            currentReview.value.bodyText = ''
            currentReview.value.rating = null
        }
    }

    const loadAvailableReactions = () => {
        return http.get('/place/review/available-reactions/').then((response) => {
            return response.data
        })
    }

    const fetchReviewForUser = (placeId) => {
        const userStore = useUserStore()

        if (!userStore.isLoggedIn) {
            return Promise.resolve(null)
        }

        return fetchReview(placeId, userStore.user.username)
    }

    const getReviewForUser = (placeId) => {
        const review = computed({
            get() {
                return userReviews.value[placeId]
            },
            set(value) {
                userReviews.value[placeId] = value
            },
        })

        if (!(placeId in userReviews.value)) {
            review.value = { ...EMPTY_REVIEW, loading: true }
            fetchReviewForUser(placeId).then((response) => {
                review.value = response || { ...EMPTY_REVIEW }
                if (location.hash === '#edit-review') {
                    review.value.isEditing = true
                }
            })
        }
        return review
    }

    const fetchReview = (placeId, username) => {
        return http
            .get(`/api/place/${placeId}/reviews`, {
                params: {
                    user: username,
                },
            })
            .then(({ data }) => {
                const review = data.data[0]
                if (!review) {
                    return { ...EMPTY_REVIEW }
                }
                return review
            })
    }

    const storeRating = (placeId, rating) => {
        if (!userStore.isLoggedIn) {
            return Promise.reject('User is not logged in')
        }
        const oldValue = { ...(userReviews.value[placeId] || EMPTY_REVIEW) }
        userReviews.value[placeId] = { ...rating, pending: true }
        return http
            .post(`/api/place/${placeId}/ratings/`, rating)
            .then((response) => {
                const review = response.data.data
                if (!review) {
                    return { ...EMPTY_REVIEW }
                }

                if (review.place) {
                    trackEvent('rated', {
                        category: 'place',
                        label: review.place.name,
                    })
                }

                userReviews.value[placeId] = review

                userStore.updateUser(true)

                return review
            })
            .catch((e) => {
                userReviews.value[placeId] = oldValue
                throw e
            })
    }

    const storeReview = (placeId, review) => {
        if (!userStore.isLoggedIn) {
            return Promise.reject('User is not logged in')
        }
        const oldValue = { ...(userReviews.value[placeId] || EMPTY_REVIEW) }
        userReviews.value[placeId] = { ...review, pending: true }
        return http
            .post(`/api/place/${placeId}/reviews`, review)
            .then((response) => {
                const review = response.data.data
                if (!review) {
                    return { ...EMPTY_REVIEW }
                }

                userReviews.value[placeId] = review

                userStore.updateUser(true)

                return review
            })
            .catch((e) => {
                userReviews.value[placeId] = oldValue
                throw e
            })
    }

    const deleteReview = (placeId, review) => {
        if (!userStore.isLoggedIn) {
            return Promise.reject('User is not logged in')
        }
        const oldValue = { ...(userReviews.value[placeId] || EMPTY_REVIEW) }
        userReviews.value[placeId] = { ...EMPTY_REVIEW, pending: true }
        return http
            .delete(`/api/place/${placeId}/reviews/${review.id}`)
            .then(() => {
                userReviews.value[placeId] = { ...EMPTY_REVIEW }

                userStore.updateUser(true)
            })
            .catch((e) => {
                userReviews.value[placeId] = oldValue
                throw e
            })
    }

    Eventbus.on('review.remove', ({ placeId, id }) => {
        deleteReview(placeId, { id })
    })

    Eventbus.on('review.edit', async ({ placeId }) => {
        const review = await getReviewForUser(placeId)
        review.value.isEditing = true
    })

    return {
        reactions,
        reactionTypes,
        currentReview,
        getReaction,
        availableReactions,
        addReaction,
        clearReview,
        loadAvailableReactions,
        getReviewForUser,
        fetchReviewForUser,
        fetchReview,
        userReviews,
        storeReview,
        storeRating,
        deleteReview,
    }
})

// export const useReviewsStore = defineStore('reviews', {
//     state: () => ({
//         reactions: {},
//         reactionTypes: [],
//         currentReview: {
//             id: null,
//             placeId: null,
//             bodyText: '',
//             rating: null,
//             photos: [],
//         },
//     }),
//     getters: {
//         getReaction: (store, reviewId) => {
//             return reviewId in store.reactions ? store.reactions[reviewId] : false
//         },
//         availableReactions(store) {
//             if (store.reactionTypes.length === 0) {
//                 this.loadAvailableReactions().then((reactions) => {
//                     store.reactionTypes = reactions
//                 })
//             }

//             return store.reactionTypes
//         },
//     },
//     actions: {
//         addReaction({ reviewId, reaction }) {
//             this.reactions = {
//                 ...this.reactions,
//                 [reviewId]: reaction,
//             }
//         },
//         clearReview() {
//             if (!this.currentReview.id) {
//                 this.currentReview.bodyText = ''
//                 this.currentReview.rating = null
//             }
//         },
//         loadAvailableReactions() {
//             return http.get('/place/review/available-reactions/').then((response) => {
//                 return response.data
//             })
//         },
//         fetchReviewForUser(placeId) {
//             const userStore = useUserStore()

//             if (!userStore.isLoggedIn) {
//                 return Promise.resolve(null)
//             }

//             return this.fetchReview(placeId, userStore.user.username).then((response) => {
//                 const review = response.data.data[0]

//                 if (!review) {
//                     return []
//                 }

//                 this.currentReview = review

//                 return response.data
//             })
//         },
//         fetchReview(placeId, username) {
//             return http.get(`/api/place/${placeId}/reviews/`, {
//                 params: {
//                     user: username,
//                 },
//             })
//         },
//     },
// })
