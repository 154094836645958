import { defineStore } from 'pinia'

export const useExploreStore = defineStore('explore', {
    state: () => ({
        isActive: false,
        filter: {},
    }),
    actions: {
        active() {
            this.isActive = true
        },
        inactive() {
            this.isActive = false
        },
        setFilter(filter) {
            this.filter = filter
        },
        updateFilter(filter) {
            this.filter = { ...this.filter, ...filter }
        },
    },
})
