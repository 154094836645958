import { auth } from '../../../es6/src/modules/authentication/authentication.js'
import Eventbus from '../../../es6/src/modules/eventbus/Eventbus.js'

export function showAuth(options = {}) {
    return new Promise((resolve, reject) => {
        const handleFailure = (response) => {
            reject(response)
            Eventbus.off('auth:failure', handleFailure)
        }

        Eventbus.on('auth:failure', handleFailure)

        const handleSuccess = (response) => {
            resolve(response)
        }

        auth(handleSuccess, options)
    })
}
