export default class EventEmitter {
    constructor(listeners = {}) {
        this.listeners = listeners
    }

    on(event, listener) {
        if (!(event in this.listeners)) {
            this.listeners[event] = []
        }
        this.listeners[event].push(listener)
        return this
    }

    trigger(event, data = null) {
        return this.emit(event, data)
    }

    emit(event, data = null) {
        if (event in this.listeners) {
            this.listeners[event].forEach((listener) => {
                listener.apply(this, [data])
            })
        }
        return this
    }

    off(event, listener) {
        if (event in this.listeners) {
            const i = this.listeners[event].indexOf(listener)
            if (i > -1) {
                this.listeners[event].splice(i, 1)
            }
        }
        return this
    }

    one(event, listener) {
        if (!(event in this.listeners)) {
            this.listeners[event] = []
        }
        const newListener = () => {
            listener.apply(this, arguments)
            this.off(event, newListener)
        }
        this.listeners[event].push(newListener)
        return this
    }
}
