const isLocalStorageAvailable = () => !!window.localStorage

const parseValue = (value, deep) => {
    if (value === null) return {}

    const parsedValue = JSON.parse(value)
    return deep ? parsedValue[deep] : parsedValue
}

export const localStorage = {
    get: (key, parse = false, deep = null) => {
        if (!isLocalStorageAvailable()) {
            return null
        }

        const value = window.localStorage.getItem(key)
        return parse ? parseValue(value, deep) : value
    },

    set: (key, value) => {
        if (!isLocalStorageAvailable()) {
            return
        }

        window.localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value))
    },

    add: (key, itemKey, itemValue) => {
        const value = localStorage.get(key, true) || {}
        value[itemKey] = itemValue
        localStorage.set(key, value)
    },

    remove: (key, itemKey = null) => {
        if (!isLocalStorageAvailable()) {
            return
        }

        if (!itemKey) {
            window.localStorage.removeItem(key)
        } else {
            const value = localStorage.get(key, true) || {}
            if (typeof value === 'object' && value[itemKey]) {
                delete value[itemKey]
            }
            localStorage.set(key, value)
        }
    },
}
