<template>
    <fieldset class="form-group">
        <input
            name="email"
            type="email"
            required="required"
            autocomplete="username"
            :placeholder="$t('authForm.email')"
            :value="modelValue.email"
            :class="{ invalid: isInvalid(invalidFields, 'email') }"
            @input="change"
            v-autofocus
        />
    </fieldset>
</template>
<script setup>
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
    modelValue: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    invalidFields: {
        type: Array,
        required: false,
        default: () => [],
    },
})

const isInvalid = (fields, field) => {
    return fields.indexOf(field) !== -1
}

const change = (event) => {
    emit('update:modelValue', {
        ...props.modelValue,
        [event.target.name]: event.target.value,
    })
}
</script>
