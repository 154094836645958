import { headerHeight } from '../header/header'

document.addEventListener('DOMContentLoaded', () => {
    const floater = document.querySelector('.cta-floater')
    const trigger = document.querySelector('.js-cta-floater-trigger')

    if (floater && trigger) {
        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        floater.classList.add('show')
                    } else {
                        floater.classList.remove('show')
                    }
                })
            },
            {
                rootMargin: -1 * headerHeight(true) + 'px',
            },
        )
        intersectionObserver.observe(trigger)
    }
})
