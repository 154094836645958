<template>
    <a
        role="button"
        class="tchook flat-button flat-button--blue flat-button--large flat-button--full mb-2"
        data-action="share"
        :data-url="url"
        :data-title="userPoll.title"
    >
        {{ $t('userPoll.share') }}
    </a>
</template>

<script>
import { useUserStore } from '@/store/user.js'

export default {
    props: {
        userPoll: {
            type: Object,
            required: true,
        },
    },

    setup() {
        const userStore = useUserStore()

        return { userStore }
    },

    computed: {
        url() {
            const url = window.location.href.replace(/([\?&])voter=([^\?&]+)/, '')
            return url + (url.indexOf('?') === -1 ? '?' : '&') + 'voter=' + userStore.user.username
        },
    },

    data() {
        return {}
    },
}
</script>
