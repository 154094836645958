import { translate } from '../locale/locale.js'
import clickOutside from '../../utils/clickOutside.js'
import http from '../../utils/http.js'
import { checkVisibility, putContent } from '../../utils/html.js'

export let activePopup = null

export const popupQueue = []

export const popup = (options) => {
    return new PopupClass(options)
}

function addCssClasses(elem, classes) {
    classes.split(' ').forEach((str) => {
        elem.classList.add(str)
    })
}

function addOptionsToContainer(popup, options) {
    if (options.cssClass) {
        addCssClasses(popup, options.cssClass)
    }
    if (options.ribbon) {
        handleRibbonClass(popup, options.ribbon)
    }
}

function handleRibbonClass(popup, ribbon) {
    if (ribbon === true) {
        ribbon = {}
    }
    addCssClasses(popup, 'w-ribbon')
    if (ribbon.color) {
        addCssClasses(popup, 'ribbon--' + ribbon.color)
    }
    if (ribbon.label) {
        popup.setAttribute('data-ribbon', ribbon.label)
    }
}

/**
 * TODO: Maybe refactor this class a bit.
 */
class PopupClass {
    constructor(options) {
        if (activePopup) {
            if (options.queue === true) {
                popupQueue.push(options)
                return
            } else {
                activePopup.close(true)
            }
        }

        let overlayId = 'thatsup-popup-overlay'
        let overlay = document.getElementById(overlayId)

        if (!overlay) {
            overlay = document.createElement('div')
            overlay.id = overlayId
            document.body.appendChild(overlay)
        }

        if (options.content instanceof DocumentFragment) {
            options.content = options.content.firstElementChild
        }

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        activePopup = this

        this.beforeClose = options?.beforeClose
        this.afterClose = options?.afterClose
        this.afterOpen = options?.afterOpen

        this.buttons = options.buttons

        this.variation = options.variation
        this.isDirty = !!options.dirty

        this.dirty = function (dirty) {
            this.isDirty = dirty
        }

        const closeWithEscape = (e) => {
            if (e.key === 'Escape') {
                this.close()
            }
        }

        window.addEventListener('keydown', closeWithEscape)

        this.close = function (newPopup) {
            window.removeEventListener('keydown', closeWithEscape)

            if (this.isDirty && !confirm(translate('popup_dirty_confirm'))) {
                return false
            }
            this.isDirty = false
            if (this.beforeClose) {
                const result = this.beforeClose.apply(this, [])
                if (result === false) {
                    return false
                }
            }
            if (this.placeholder) {
                if (this.wasHidden) {
                    options.content.style.display = 'none'
                }
                this.placeholder.replaceWith(options.content)
            }
            if (this.clickOutsideEventTimeout) {
                window.clearTimeout(this.clickOutsideEventTimeout)
            }
            if (this.clickOutsideEvent) {
                this.clickOutsideEvent.remove()
            }
            if (newPopup !== true) {
                if (this.overlay) {
                    this.overlay.remove()
                }
                document.querySelector('html').classList.remove('popup-open')
            }
            if (this.variation) {
                document.querySelector('html').classList.remove('popup-open--' + this.variation)
            }

            if (activePopup && activePopup === this) {
                activePopup = null
            }
            if (popupQueue.length && newPopup !== true) {
                const newPopupOptions = popupQueue.shift()
                popup(newPopupOptions)
            }
            if (this.afterClose) {
                this.afterClose.apply(this, [])
            }

            return true
        }
        this.wasHidden = false
        this.overlay = overlay
        // Clean
        this.overlay.removeAttribute('class')
        this.overlay.innerHTML = ''

        if (this.variation) {
            this.overlay.classList.add('popup--' + this.variation)
        }
        if (options.overlayClass) {
            this.overlay.classList.add(options.overlayClass)
        }
        if (options.zIndex) {
            this.overlay.style['z-index'] = options.zIndex
        }

        if (options.content) {
            const isJqueryElement = !!options.content?.jquery

            if (isJqueryElement) {
                options.content = options.content.get(0)
            }

            document.querySelector('html').classList.add('popup-open')
            if (this.variation) {
                document.querySelector('html').classList.add('popup-open--' + this.variation)
            }

            let popupContainer = document.createElement('div')
            popupContainer.classList.add('popup-container', 'invisible')
            this.overlay.appendChild(popupContainer)

            addOptionsToContainer(popupContainer, options)

            this.contentHeader = document.createElement('div')
            this.contentHeader.className = 'popup-header'
            popupContainer.appendChild(this.contentHeader)
            if (options.titleHtml) {
                this.contentHeader.innerHTML = options.titleHtml
            } else if (options.title) {
                let h3 = document.createElement('h3')
                h3.textContent = options.title
                this.contentHeader.appendChild(h3)
            } else if (options.title === false) {
                this.contentHeader.parentNode.removeChild(this.contentHeader)
            } else {
                this.contentHeader.classList.add('no-title')
            }

            if (!options.modal) {
                let anchor = document.createElement('a')
                anchor.classList.add('close-button')
                this.contentHeader.appendChild(anchor)
                anchor.addEventListener('click', () => {
                    this.close()
                })

                // Delay for some reason
                this.clickOutsideEventTimeout = window.setTimeout(() => {
                    this.clickOutsideEventTimeout = null

                    this.clickOutsideEvent = clickOutside(popupContainer, () => {
                        // Fix for CMP overlay
                        if (document.getElementById('qc-cmp2-ui')) {
                            return false
                        }

                        this.close()
                    })
                }, 300)
            }

            let popupBody

            if (options.separateBody) {
                const node = document.createElement('div')
                popupContainer.appendChild(node).classList.add('popup-body')
                popupBody = node
            } else {
                popupContainer.classList.add('popup-body')
                popupBody = popupContainer
            }

            let contentContainer = popupBody.appendChild(document.createElement('div'))
            contentContainer.classList.add('content-container')

            if (typeof options.content === 'string' && options.type === 'ref') {
                options.content = document.querySelector(options.content)
            }

            if (typeof options.content === 'string') {
                if (options.type === 'ajax') {
                    contentContainer.innerHTML = '<div class="background--loading"></div>'
                    const config = {
                        url: options.content,
                        method: 'get',
                        ...options.ajaxOptions,
                    }
                    http.request(config).then(({ data }) => {
                        putContent(contentContainer, data)
                        contentContainer.classList.remove('invisible')
                    })
                } else if (!options.type || options.type === 'html') {
                    contentContainer.innerHTML = options.content
                }
            } else if (options.content.length || typeof options.content === 'object') {
                this.placeholder = document.createElement('div')
                this.placeholder.classList.add('thatsup-popup-placeholder')
                this.placeholder.style.display = 'none'

                options.content.insertAdjacentElement('afterend', this.placeholder)
                contentContainer.appendChild(options.content)

                if (!checkVisibility(options.content)) {
                    this.wasHidden = true
                    options.content.style.display = 'block'
                }
                options.content.setAttribute('data-thatsup-popup', this)
            }

            if (options.buttons) {
                let buttonContainer = popupBody.appendChild(document.createElement('div'))
                buttonContainer.classList.add('button-container')
                if (options.buttons[0] instanceof Element) {
                    options.buttons.forEach((el) => buttonContainer.appendChild(el))
                } else {
                    options.buttons.forEach((button) => {
                        let buttonEl
                        if (button instanceof Element) {
                            buttonEl = button
                        } else {
                            buttonEl = document.createElement('button')
                            buttonEl.classList.add('popup-button', 'fw-6')
                            buttonEl.innerHTML = button.label
                            buttonEl.classList.add(button.class)
                            buttonEl.onclick = () => {
                                if (button.onclick) {
                                    button.onclick(button, this)
                                } else {
                                    this.close()
                                }
                            }
                        }
                        buttonContainer.appendChild(buttonEl)
                    })
                }
            }

            let dimensionAttributes = [
                'height',
                'width',
                'maxHeight',
                'maxWidth',
                'minHeight',
                'minWidth',
            ]
            dimensionAttributes.forEach((attr) => {
                if (options[attr]) {
                    const value = options[attr]

                    if (typeof value === 'number') {
                        popupContainer.style[attr] = value + 'px'
                    } else {
                        popupContainer.style[attr] = value
                    }
                }
            })

            this.popupContainer = popupContainer
            this.contentContainer = contentContainer
            if (options.containerCss) {
                Object.keys(options.containerCss).forEach(
                    (key) => (this.popupContainer.style[key] = options.containerCss[key]),
                )
            }
            if (options.containerClass) {
                this.popupContainer.classList.add(options.containerClass)
            }

            window.setTimeout(
                () => {
                    popupContainer.classList.remove('invisible')
                    if (this.afterOpen) {
                        this.afterOpen.apply(this, [])
                    }
                },
                parseInt(options.invisibleDelay) || 0,
            )
        }

        return this
    }
}
