import http from '../../utils/http.js'
import { translate } from '../locale/locale.js'
import confirm from '@utils/confirm.js'
import { useUserStore } from '../../../../store/user.js'
import { interaction } from '../tracking/tracking.js'
import { slideTo } from '../../utils/animation.js'
import { popup } from '../popup/popup.js'
import { checkVisibility } from '../../utils/html.js'
import { useDirtyStore } from '../../../../store/dirty.js'

export const editReview = function (id, silent = false) {
    const form = document.querySelector('#reviews form')

    if (checkVisibility(form) === false) {
        document.querySelector('#reviews .review-thank-you').style.display = 'none'
        form.style.display = 'block'
    }

    http.get(`/place/review/${id}/json/`)
        .then(({ data }) => {
            if (data != null) {
                const editIdInput = document.createElement('input')
                editIdInput.setAttribute('type', 'hidden')
                editIdInput.setAttribute('name', 'editId')
                editIdInput.setAttribute('value', id)
                form.appendChild(editIdInput)

                const ratingTrigger = form.querySelector(
                    '.review-trigger-star.rating-' + data.rating,
                )
                if (ratingTrigger) ratingTrigger.click()

                if (data.photos) {
                    addPhotosToForm(form, data.photos)
                }

                if (silent === true) {
                    const dirtyStore = useDirtyStore()
                    dirtyStore.setIsDirty(false)
                }

                if (silent === false) {
                    const textarea = form.querySelector('textarea')
                    if (textarea) {
                        textarea.value = data.content
                        textarea.focus()
                    }
                    slideTo('#reviews')
                }
            }
        })
        .catch((error) => console.error('Error fetching review data:', error))
}

export const addPhotosToForm = function (form, photos) {
    if (typeof form === 'string') {
        form = document.querySelector(form)
    }

    const pictureList = form.querySelector('.picture-container .pictures')
    pictureList.innerHTML = ''
    photos.forEach((file) => {
        const node = document.createElement('li')
        node.classList.add('upload')

        const handle = document.createElement('span')
        handle.classList.add('handle')
        handle.innerHTML = '<i class="icon--move-handle gray"></i>'
        node.appendChild(handle)

        const imageContainer = document.createElement('span')
        imageContainer.classList.add('image-container')
        node.appendChild(imageContainer)

        const img = document.createElement('img')
        img.setAttribute('src', file.thumbUrl)
        img.setAttribute('alt', file.title)
        imageContainer.appendChild(img)

        const link = document.createElement('a')
        link.setAttribute('target', '_blank')
        link.setAttribute('href', file.url)
        if (file.height && file.width) {
            link.setAttribute('data-gallery', 'upload-gallery')
            link.setAttribute('data-size', file.width + 'x' + file.height)
            link.classList.add('photoswipe')
            link.setAttribute('tabindex', '-1')
        }
        imageContainer.appendChild(link)

        node.dataset.id = file.id

        const id = document.createElement('input')
        id.setAttribute('type', 'hidden')
        id.setAttribute('name', 'photos[id][]')
        id.setAttribute('value', file.id)
        node.appendChild(id)

        const titleContainer = document.createElement('span')
        titleContainer.classList.add('title-container')

        const title = document.createElement('input')
        title.setAttribute('maxlength', 100)
        title.setAttribute('type', 'text')
        title.setAttribute('value', file.title || '')
        title.setAttribute('name', 'photos[title][]')
        title.setAttribute('placeholder', translate('user_place_image_title_placeholder'))
        titleContainer.appendChild(title)

        const small = document.createElement('small')
        const charCount = document.createElement('span')
        charCount.classList.add('char-count')
        charCount.textContent = translate(
            'x_characters_left',
            100 - (file.title ? file.title.length : 0),
        )
        small.appendChild(charCount)
        small.innerHTML += ' &middot; '
        titleContainer.appendChild(small)

        node.appendChild(titleContainer)

        const deleteButton = document.createElement('a')
        deleteButton.setAttribute('title', translate('delete'))
        deleteButton.innerHTML = '<i></i>'
        deleteButton.classList.add('remove-button', 'sli', 'sli-trash')
        node.appendChild(deleteButton)

        pictureList.appendChild(node)
    })
}

export const checkExistingPhotos = (placeId) => {
    const userStore = useUserStore()

    if (!userStore.isLoggedIn) {
        return
    }

    const form = document.querySelector('#reviews form')

    http.get(
        '/user/photos/',
        {
            params: {
                user: userStore.user.username,
                place: placeId,
            },
        },
        async function ({ data }) {
            if (
                data.contentSize &&
                (await confirm(
                    translate('review_add_photos'),
                    translate('review_add_existing_photos'),
                    translate('confirm_yes'),
                    translate('confirm_no'),
                ))
            ) {
                addPhotosToForm(form, data.content)
            }
        },
    )
}

export const generateReviewSummary = (placeId, force = false) => {
    return http
        .post(`/api/place-review-summaries/generate/${placeId}`, { force })
        .then(({ data }) => {
            return data.data.summary
        })
}

export const archivedReviews = (id) => {
    return popup({
        content: '/place/review/' + id + '/archived/?popup',
        type: 'ajax',
        width: '95%',
        maxWidth: 800,
    })
}

export const reportReview = (id) => {
    const container = document.createElement('div')
    container.classList.add('pretty-form', 'text-center')

    const textarea = document.createElement('textarea')
    textarea.style.width = '100%'
    textarea.style.minHeight = '5em'
    textarea.style.marginBottom = '2em'
    textarea.setAttribute('placeholder', translate('review_report_reason'))
    container.appendChild(textarea)

    const isOwner = document.createElement('input')
    isOwner.setAttribute('type', 'checkbox')
    isOwner.id = 'reviewReportIsOwner'
    isOwner.name = 'isOwner'
    isOwner.value = '1'

    const submitButton = document.createElement('a')
    submitButton.classList.add('flat-button', 'blue')
    submitButton.textContent = translate('review_report')
    submitButton.setAttribute('role', 'button')
    container.appendChild(submitButton)

    const isOwnerContainer = document.createElement('div')
    isOwnerContainer.classList.add('pretty-form', 'text-center')

    const h2 = document.createElement('h2')
    h2.textContent = translate('edit_place_label_business_owner')
    isOwnerContainer.appendChild(h2)

    const yesButton = document.createElement('a')
    yesButton.classList.add('flat-button', 'transparent')
    yesButton.textContent = translate('edit_place_label_business_owner_yes')
    yesButton.setAttribute('role', 'button')
    isOwnerContainer.appendChild(yesButton)

    const noButton = document.createElement('a')
    noButton.classList.add('flat-button', 'transparent')
    noButton.textContent = translate('edit_place_label_business_owner_no')
    noButton.setAttribute('role', 'button')
    isOwnerContainer.appendChild(noButton)

    const reportOptions = {
        isOwner: false,
        reason: null,
        review: id,
    }

    const submitReport = () => {
        http.post('/place/review/report/', reportOptions).then(() => {
            popup({
                content: `<div class="large-title">${translate('review_report_thank_you')}</div>`,
                width: 500,
            })
        })
    }

    submitButton.addEventListener('click', (e) => {
        e.preventDefault()
        reportOptions.reason = textarea.value

        popup({
            title: translate('review_report'),
            content: isOwnerContainer,
            width: 500,
        })

        noButton.addEventListener('click', function (e) {
            e.preventDefault()
            console.log(this)
            if (this.classList.contains('disabled')) {
                return false
            }
            reportOptions.isOwner = false
            this.classList.add('disabled')
            submitReport()
            return false
        })

        yesButton.addEventListener('click', function (e) {
            e.preventDefault()
            if (this.classList.contains('disabled')) {
                return false
            }
            reportOptions.isOwner = true
            this.classList.add('disabled')

            interaction('BusinessOwner')

            const ownerInformationContainer = document.createElement('div')
            ownerInformationContainer.classList.add('pretty-form', 'text-center')
            const h2 = document.createElement('h2')
            h2.textContent = translate('edit_place_owner_information')
            ownerInformationContainer.appendChild(h2)

            const nameInput = document.createElement('input')
            nameInput.name = 'name'
            nameInput.type = 'text'
            nameInput.style.width = '100%'
            nameInput.setAttribute('placeholder', translate('edit_place_label_user_name'))
            ownerInformationContainer.appendChild(nameInput)

            const emailInput = document.createElement('input')
            emailInput.name = 'email'
            emailInput.type = 'text'
            emailInput.style.width = '100%'
            emailInput.setAttribute('placeholder', translate('edit_place_label_user_email'))
            ownerInformationContainer.appendChild(emailInput)

            const phoneInput = document.createElement('input')
            phoneInput.name = 'phone'
            phoneInput.type = 'text'
            phoneInput.style.width = '100%'
            phoneInput.setAttribute('placeholder', translate('edit_place_label_user_phone'))
            ownerInformationContainer.appendChild(phoneInput)

            const submitButton = document.createElement('button')
            submitButton.classList.add('flat-button', 'blue')
            submitButton.textContent = translate('confirm_ok')
            submitButton.setAttribute('role', 'button')
            ownerInformationContainer.appendChild(submitButton)

            submitButton.addEventListener('click', function () {
                if (this.classList.contains('disabled')) {
                    return false
                }
                reportOptions.name = nameInput.value
                reportOptions.email = emailInput.value
                reportOptions.phone = phoneInput.value
                this.classList.add('disabled')
                submitReport()
            })

            popup({
                title: translate('review_report'),
                content: ownerInformationContainer,
                width: 500,
            })
            return false
        })
        return false
    })

    return popup({
        title: translate('review_report'),
        content: container,
        width: 500,
        afterOpen: () => {
            textarea.focus()
        },
    })
}

export const translateReview = (id, original, container) => {
    original = !!original

    let loader = null

    if (container) {
        loader = document.createElement('i')
        loader.classList.add('icon', 'icon--loading-ring', 'grey')

        container = document.querySelector(container)

        if (container) {
            const translateButton = container.querySelector('.translate-button')
            translateButton.style.display = 'none'
            translateButton.parentNode.insertBefore(loader, translateButton)
        }
    }

    http.get('/place/review/' + id + '/translate/?original=' + original)
        .then(function ({ data }) {
            if (data.success) {
                if (container) {
                    const fullBody = container.querySelector('.review-body.full')
                    const shortBody = container.querySelector('.review-body.short')

                    if (shortBody) {
                        shortBody.style.display = 'none'
                    }
                    fullBody.style.display = 'block'
                    fullBody.innerHTML = data.text
                    fullBody.setAttribute('lang', data.language)

                    const disclaimer = container.querySelector('.disclaimer')
                    if (disclaimer) {
                        disclaimer.remove()
                    }

                    if (data.disclaimer) {
                        const newDisclaimer = document.createElement('p')
                        newDisclaimer.classList.add('disclaimer', 'subtle')
                        newDisclaimer.innerHTML = data.disclaimer
                        fullBody.parentNode.insertBefore(newDisclaimer, fullBody)
                    }

                    const translateButton = container.querySelector('.translate-button')
                    if (translateButton) {
                        translateButton.remove()
                    }

                    if (loader) {
                        loader.remove()
                    }
                }
            }

            if (data.message) {
                alert(data.message)
                if (container) {
                    const translateButton = container.querySelector('.translate-button')
                    if (translateButton) {
                        translateButton.style.display = 'block'
                    }
                    if (loader) {
                        loader.remove()
                    }
                }
            }
        })
        .catch(function (error) {
            console.error('Error:', error)
        })
}
