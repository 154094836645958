import { overlayScroll } from '../../es6/src/utils/overlay'
export default {
    mounted: (el, binding) => {
        if (binding.value === false) {
            return
        }
        const fadeSize = binding.value === true ? '20%' : binding.value || '20%'
        overlayScroll(el, fadeSize)
    },
}
