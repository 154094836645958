<template>
    <main ref="body" class="place-list__body">
        <slot />
    </main>
</template>
<script>
export default {
    methods: {
        transitionEnd(e) {
            if (e && ['width', 'max-width'].indexOf(e.propertyName) !== -1) {
                this.$emit('width-change')
            }
        },
    },
    mounted() {
        if (this.$refs.body) {
            this.$refs.body.addEventListener('transitionend', this.transitionEnd)
        }
    },
    destroyed() {
        if (this.$refs.body) {
            this.$refs.body.removeEventListener('transitionend', this.transitionEnd)
        }
    },
}
</script>
