<template>
    <component :is="as" :href="href" :class="$style.wrapper">
        <div :class="$style.imageWrapper">
            <ImageWithSizes
                v-if="src && !hasError"
                @error="hasError = true"
                :class="$style.image"
                :src="src"
                :srcset="srcSet"
                :alt="alt || caption"
                loading="lazy"
                width="300"
                height="200"
            />
            <div v-else :class="[$style.imagePlaceholder, $style.image]">
                <i v-if="icon" :class="icon" />
            </div>
        </div>
        <h4 :class="$style.caption" v-if="caption">{{ caption }}</h4>
        <p :class="$style.description" v-if="description">{{ description }}</p>
    </component>
</template>

<script setup>
import { computed, ref } from 'vue'
import ImageWithSizes from '../../../../Shared/components/ImageWithSizes.vue'

const props = defineProps({
    src: {
        type: String,
        required: false,
        default: null,
    },
    srcSet: {
        type: String,
        required: false,
        default: null,
    },
    alt: {
        type: String,
        required: false,
        default: null,
    },
    description: {
        type: String,
        required: false,
        default: null,
    },
    href: {
        type: String,
        required: false,
        default: null,
    },
    caption: {
        type: String,
        required: false,
        default: null,
    },
    icon: {
        type: String,
        required: false,
        default: null,
    },
    aspectRatio: {
        type: String,
        required: false,
        default: '3 / 2',
    },
})

const as = computed(() => {
    if (props.href !== null) {
        return 'a'
    }

    return 'div'
})

const aspectRatioPercent = computed(() => {
    const parts = props.aspectRatio.split('/')
    if (parts.length < 2) return null
    return 100 * (parseFloat(parts[1]) / parseFloat(parts[0])) + '%'
})

const hasError = ref(false)
</script>

<style module>
.wrapper {
    color: var(--body-font-color);
    display: block;
}

.caption {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 10px;
}

.description {
    font-size: 16px;
    font-weight: 600;
    color: #134154;
    margin-top: 4px;
    margin-bottom: 0;
}

.imageWrapper {
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: v-bind(aspectRatio);
    background: #dadada;
}

.image {
    width: 100%;
    aspect-ratio: inherit;
    object-fit: cover;
    height: 100%;
}
@supports not (aspect-ratio: auto) {
    .imageWrapper {
        padding-top: v-bind(aspectRatioPercent);
        position: relative;
    }
    .image {
        position: absolute;
        top: 0;
    }
}

.imagePlaceholder {
    background-color: #dadada;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
