<template>
    <component :is="as" ref="container"></component>
</template>

<script setup>
import { ref, watch } from 'vue'
import datepicker from '../../../es6/src/modules/datepicker/datepicker'
import { $t } from '../../helpers.js'

const props = defineProps({
    modelValue: {
        type: [String, Object, Array],
        required: false,
        default: null,
    },
    as: {
        type: String,
        required: false,
        default: 'div',
    },
    minDate: {
        type: String,
        required: false,
        default: null,
    },
    maxDate: {
        type: String,
        required: false,
        default: null,
    },
    range: {
        type: Boolean,
        required: false,
        default: false,
    },
    minRange: {
        type: Number,
        required: false,
        default: 0,
    },
    multi: {
        type: Boolean,
        required: false,
        default: false,
    },
    maxDates: {
        type: Number,
        required: false,
        default: null,
    },
    from: {
        type: String,
        required: false,
        default: null,
    },
    to: {
        type: String,
        required: false,
        default: null,
    },
    dayNames: {
        type: Array,
        required: false,
        default: null,
    },
    monthNames: {
        type: Array,
        required: false,
        default: null,
    },
    firstDayOfWeek: {
        type: Number,
        required: false,
        default: null,
    },
})
const emit = defineEmits(['update:modelValue', 'update:from', 'update:to', 'update:range'])

const container = ref(null)

let datepickerInstance = null

const handleOnChange = (e) => {
    if (e.value !== localValue.value) {
        localValue.value = e.value
        emit('update:modelValue', localValue.value)
    }
    if (props.range) {
        emit('update:from', e.from)
        emit('update:to', e.to)
        emit('update:range', e.value)
    }
}

const handleOnRangeChange = (e) => {
    emit('update:from', e.from)
    emit('update:to', e.to)
    emit('update:range', e.value)
}

const localValue = ref(props.modelValue)

watch(
    () => props.modelValue,
    (newVal) => {
        if (datepickerInstance && newVal !== localValue.value) {
            datepickerInstance.setValue(newVal)
        }
    },
)

watch(
    () => container.value,
    (newVal) => {
        if (newVal) {
            datepickerInstance = datepicker(newVal, {
                onChange: (e) => {
                    handleOnChange(e)
                },
                onRangeChange: (e) => {
                    handleOnRangeChange(e)
                },
                value: props.modelValue,
                minDate: props.minDate,
                maxDate: props.maxDate,
                range: props.range,
                multi: props.multi,
                maxDates: props.maxDates,
                minRange: props.minRange,
                firstDayOfWeek:
                    props.firstDayOfWeek !== null
                        ? props.firstDayOfWeek
                        : $t('datepicker.firstDayOfWeek'),
                dayNames: props.dayNames || $t('datepicker.dayNames'),
                monthNames: props.monthNames || $t('datepicker.monthNames'),
            })
        }
    },
)
</script>
