<template>
    <div v-if="$slots['left-column'] && !isMobile" :class="$style.leftColumn">
        <slot
            name="left-column"
            :active-place="activePlace"
            :places="places"
            :navigation="{ goToNextPlace, goToPrevPlace, setActivePlace }"
        />
    </div>

    <ThatsupMap
        :class="$style.mapBox"
        :markers="markers"
        :padding="mapPadding"
        :auto-zoom="true"
        :active-marker="activePlaceId"
        :options="{ keyboard: false }"
        @map-click="mapClick"
        @marker-click="markerClick"
        @load="mapLoaded"
        v-bind="$attrs"
        :center="computedCenter"
    >
        <div :class="$style.controls">
            <slot
                name="controls"
                :active-place="activePlace"
                :places="places"
                :navigation="{ goToNextPlace, goToPrevPlace, setActivePlace }"
            ></slot>
        </div>
    </ThatsupMap>

    <ExploreMapPlaceList
        v-if="controls"
        :class="$style.placeListControls"
        :place-page="{ data: places, from: 1 }"
        :active-place="activePlace"
        @active-place-change="setActivePlace($event.id)"
    />
</template>

<script setup>
import ThatsupMap from '../../Shared/components/ThatsupMap.vue'
import ExploreMapPlaceList from '../explore/ExploreMapPlaceList.vue'
import { computed, onMounted, onUnmounted, ref, unref, watch } from 'vue'
import useViewport from '../../Shared/util/use-viewport'
import mapMarkers from '@modules/map/markers'

function getCoordinatesFromPlace(place) {
    if (!place) return null
    if (place.location) {
        return {
            lat: place.location.latitude,
            lng: place.location.longitude,
        }
    }

    return {
        lat: place.latitude,
        lng: place.longitude,
    }
}

const props = defineProps({
    center: [String, Array],
    activePlaceId: String,
    smartPan: Boolean,
    controls: {
        type: Boolean,
        default: true,
    },
    places: {
        type: Array,
        required: true,
    },
    startFromIndex: {
        type: Number,
        required: false,
        default: null,
    },
    firstActive: {
        type: Boolean,
        default: true,
    }, // Set first place in places array as activePlace
})

const activePlaceId = ref(null)

onMounted(() => {
    if (props.firstActive && !props.activePlaceId) {
        setActivePlace(props.places[0].id)
    } else if (props.activePlaceId) {
        setActivePlace(props.activePlaceId)
    }
})
const { width: windowWidth } = useViewport()

const isMobile = computed(() => {
    return windowWidth.value < 992
})

const mapPadding = ref({ top: 50, bottom: 50, left: 50, right: 50 })

const mapLoaded = (map) => {
    if (!props.smartPan) {
        return
    }

    map.resize()

    watch(
        () => activePlace.value,
        (newVal) => {
            if (!newVal) return
            const coordinates = getCoordinatesFromPlace(newVal)
            const placeIsVisibleOnMap = map.getBounds().contains(coordinates)

            if (!placeIsVisibleOnMap) {
                map.panTo(coordinates.lat, coordinates.lng)
            }
        },
    )
}

const keydownListener = (e) => {
    if (e.key === 'ArrowRight') {
        goToNextPlace()
    }

    if (e.key === 'ArrowLeft') {
        goToPrevPlace()
    }
}

window.addEventListener('keydown', keydownListener)

onUnmounted(() => {
    window.removeEventListener('keydown', keydownListener)
})

const computedCenter = computed(() => {
    if (typeof props.center === 'string') {
        const place = props.places.find((place) => place.id === props.center)
        return [place.latitude, place.longitude]
    }

    return props.center || null
})

const activePlaceIndex = computed(() => {
    if (!activePlaceId.value) return -1

    return props.places.findIndex((place) => place.id === activePlaceId.value)
})

const activePlace = computed(() => {
    if (!props.places || !activePlaceId.value) {
        return null
    }

    return props.places.find((place) => place.id === activePlaceId.value)
})

const markers = computed(() => {
    if (!props.places) {
        return []
    }

    const filteredPlaces = props.places.filter((p) => !p.isRemoved)

    return filteredPlaces
        .map((place, i) => {
            const { lat, lng } = getCoordinatesFromPlace(place)

            if (!lat || !lng) {
                return false
            }

            const label = props.startFromIndex ? props.startFromIndex + i : i + 1

            let markerOption = {}

            if (place.sponsored) {
                markerOption = {
                    label: 'S',
                    icon: mapMarkers.PLACE_SPONSORED,
                }
            }

            return {
                lat: lat,
                lng: lng,
                label: filteredPlaces.length > 1 ? label : null,
                id: place.uuid || place.id,
                data: {
                    placeId: place.uuid || place.id,
                },
                quicklook: {
                    type: 'place',
                    id: place.uuid || place.id,
                },
                ...(place?.markerOptions || {}),
                ...markerOption,
            }
        })
        .filter((p) => !!p)
})

const setActivePlace = (placeId) => {
    activePlaceId.value = placeId
}

const goToPrevPlace = () => {
    if (activePlaceIndex.value === 0) return

    activePlaceId.value = props.places[activePlaceIndex.value - 1]?.id
}

const goToNextPlace = () => {
    if (activePlaceIndex.value === props.places.length - 1) return

    activePlaceId.value = props.places[activePlaceIndex.value + 1]?.id
}

const mapClick = (map, e) => {
    if (isMobile.value && !e.originalEvent?.defaultPrevented) {
        activePlaceId.value = null
    }
}

const markerClicked = ref(false)

const markerClick = (marker, e) => {
    activePlaceId.value = marker.id
    e.preventDefault()
}
</script>

<style module>
.mapBox {
    grid-area: map;
    grid-column: 1 / span 2;
    overflow: hidden;
}

.leftColumn {
    grid-area: aside;
    overflow: auto;
    display: none;
}

.leftColumn + .mapBox {
    grid-column: 2;
}

@media (min-width: 992px) {
    .leftColumn {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.controls {
}

.placeListControls {
    display: block;
    overflow: hidden;
}

@media (min-width: 992px) {
    .placeListControls {
        display: none;
    }
}
</style>
