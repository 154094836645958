<template>
    <UserActionCard
        :user="placeList.user"
        :action="$t('elements.user_action.place_list_' + action)"
        :class="$style.card"
    >
        <a :href="placeList.permalink" :class="$style.card">
            <div :class="$style.title">
                {{ placeList.name }}
            </div>
            <ul v-if="visiblePlaces.length">
                <li v-for="place in visiblePlaces" :key="place.id" :class="$style.place">
                    {{ place.name }}
                </li>
                <li v-if="visiblePlaces.length < placeList.places.length">...</li>
            </ul>
        </a>
    </UserActionCard>
</template>

<script setup>
import { computed } from 'vue'
import UserActionCard from './UserActionCard.vue'

const props = defineProps({
    placeList: {
        type: Object,
        required: true,
    },
})

const action = computed(() => {
    const added = new Date(props.placeList.dateAdded)
    const updated = new Date(props.placeList.dateUpdated)
    return updated.getTime() - added.getTime() < 1000 * 60 * 60 * 12 ? 'created' : 'updated'
})

const visiblePlaces = computed(() => {
    return [...props.placeList.places].splice(0, 6)
})
</script>

<style module>
.card {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    color: var(--body-font-color);
}

.title {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 8px;
}
.place {
    list-style-type: disc;
    list-style-position: inside;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.8;
}
</style>
