<template>
    <div v-if="data && data.html" :class="$style.wrapper" v-html="data.html"></div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>
<script setup>
import BaseElement from './BaseElement.vue'

const props = defineProps({
    ...BaseElement.props,
    data: {
        type: Object,
        required: false,
        default: null,
    },
})
</script>

<style module>
.wrapper {
    padding: var(--element-padding);
}
</style>
