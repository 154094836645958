export const headerHeight = (includeFixedElements, includeNav) => {
    includeFixedElements = !!includeFixedElements
    const headerSticky = window.document.getElementById('header-sticky')
    let height = headerSticky.offsetHeight
    if (includeFixedElements) {
        window.document
            .querySelectorAll('.fixed-below-header, .js-sticky-nav')
            .forEach((element) => {
                height += element.offsetHeight
            })
    }
    if (includeNav) {
        height += window.document.getElementById('main-nav')?.offsetHeight || 0
    }
    return height
}

export const currentHeaderHeight = (includeFixedElements) => {
    includeFixedElements = !!includeFixedElements
    const header = window.document.getElementById('header')
    let height = header.offsetHeight
    if (includeFixedElements) {
        window.document
            .querySelectorAll('.fixed-below-header, .js-sticky-nav')
            .forEach((element) => {
                height += element.offsetHeight
            })
    }
    return height
}

export const setNavWidth = () => {
    const nav = document.getElementById('main-nav')

    if (nav && !nav.offsetHeight && !nav.offsetWidth) {
        return
    }

    const list = document.querySelector('#main-nav > ul')

    if (!list) {
        return
    }

    const more = document.querySelector('#main-nav > ul > li.more')
    const moreWidth = more ? more.offsetWidth : 0
    const navWidth = list.offsetWidth - (nav.classList.contains('expanded') ? 0 : moreWidth)
    let width = nav.classList.contains('expanded') ? 0 : moreWidth
    let currentRow = 1

    const listItems = document.querySelectorAll('#main-nav > ul > li')

    listItems.forEach((item) => {
        if (!item.classList.contains('more')) {
            item.classList.remove('bottom-most', 'left-most')

            let myWidth = item.offsetWidth
            if (!item.offsetWidth) {
                // If the item is hidden, we need to temporarily show it to get the width
                const previousDisplay = item.style.display
                const previousVisibility = item.style.visibility
                item.style.visibility = 'hidden'
                item.style.display = 'inline-block'
                myWidth = item.offsetWidth
                item.style.display = previousDisplay
                item.style.visibility = previousVisibility
            }
            width += myWidth

            if (width > navWidth) {
                width = myWidth
                currentRow++
            }

            item.classList.toggle('outside-scope', currentRow > 1)
            item.classList.toggle('left-most', width === myWidth)
            item.dataset.row = currentRow.toString()
        }
    })

    if (more) {
        more.classList.toggle('shown', currentRow !== 1)
    }

    const lastRowItems = list.querySelectorAll(`[data-row="${currentRow}"]`)
    lastRowItems.forEach((item) => {
        item.classList.add('bottom-most')
    })

    nav.classList.add('width-set')
}
