class ClickOutside {
    constructor(element, handler, add = true) {
        this.elements = element
        if (!Array.isArray(this.element)) {
            this.elements = [this.elements]
        }
        this.handler = handler
        this.clickFunction = this.click.bind(this)
        this.hasListeners = false
        if (add) {
            this.addListeners()
        }
    }

    addListeners() {
        if (!this.hasListeners) {
            this.hasListeners = true
            document.addEventListener('mousedown', this.clickFunction)
            // Delay binding to prevent instant hit
            window.setTimeout(() => {
                document.addEventListener('touchend', this.clickFunction)
            }, 300)
        }
    }

    removeListeners() {
        if (this.hasListeners) {
            this.hasListeners = false
            document.removeEventListener('mousedown', this.clickFunction)
            document.removeEventListener('touchend', this.clickFunction)
        }
    }

    click(e) {
        if (this.isOutside(e)) {
            this.handler.apply(e.target, [e])
            this.removeListeners()
        }
    }

    isOutside(e) {
        const target = e.target
        if (!target) {
            return true
        }
        let isOutside = true
        for (let i = 0; i < this.elements.length; i++) {
            const element = this.elements[i]
            if (element && element.contains(target)) {
                isOutside = false
                break
            }
        }
        return isOutside
    }

    add(element) {
        return this.addElement(element)
    }

    addElement(element) {
        if (Array.isArray(element)) {
            this.elements = this.elements.concat(element)
        } else {
            this.elements.push(element)
        }
    }

    removeElement(element) {
        if (Array.isArray(element)) {
            for (let i = 0; i < element.length; i++) {
                const index = this.elements.indexOf(element[i])
                if (index !== -1) {
                    this.elements.splice(index, 1)
                }
            }
        } else {
            const index = this.elements.indexOf(element)
            if (index !== -1) {
                this.elements.splice(index, 1)
            }
        }
    }

    remove() {
        this.removeListeners()
    }
}

export default function clickOutside(element, handler, bindEvents = true) {
    return new ClickOutside(element, handler, bindEvents)
}
