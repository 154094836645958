import axios from 'axios'
import { ref, watch } from 'vue'

export const csrfToken = ref(window.globalVars?.token)
export const localeCode = ref(window.globalVars?.locale.code)

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
    baseURL: '/',
    headers: {
        common: {
            ['X-Requested-With']: 'XMLHttpRequest',
            'x-thatsup-locale': window.globalVars?.locale.code,
            'X-CSRF-TOKEN': csrfToken.value,
        },
    },
})

if (!csrfToken.value) {
    console.error('CSRF token not found')
}

watch(csrfToken, (value) => {
    client.defaults.headers.common['X-CSRF-TOKEN'] = value
})

watch(localeCode, (value) => {
    client.defaults.headers.common['x-thatsup-locale'] = value
})

export default client
