<template>
    <slot :open="open"></slot>

    <template v-if="isActive">
        <div ref="popupEl" :class="$style.popup" v-if="isActive">
            <component
                :is="popups[popupComponent].component"
                v-bind="popupProps"
                @close="close"
            ></component>
        </div>
    </template>
</template>

<script setup>
import { defineAsyncComponent, nextTick, ref, shallowRef } from 'vue'
import { popup } from '../../../es6/src/modules/popup/popup.js'

import { $t } from '../../helpers'
import { translate } from '../../../es6/src/modules/locale/locale.js'

const props = defineProps({
    popupComponent: {
        type: [String],
        required: true,
    },
    popupProps: {
        type: [Object],
        required: false,
        default: () => ({}),
    },
})

const popups = {
    ArticleElementsMap: {
        component: defineAsyncComponent(() => import('./ArticleElementsMapPopup.vue')),
        popupOptions: {
            title: false,
            cssClass: 'popup--full popup--full-screen',
            width: '100%',
            height: '100%',
        },
    },
    PlaceMap: {
        component: defineAsyncComponent(() => import('./PlaceMapPopup.vue')),
        popupOptions: {
            title: false,
            cssClass: 'popup--full popup--full-screen',
            width: '100%',
            height: '100%',
        },
    },
    UserPlaceInteraction: {
        component: defineAsyncComponent(() => import('./UserPlaceInteractionPopup.vue')),
        popupOptions: {
            title: $t(`popup.userPlaceInteraction.${props.popupProps.interactionType}.title`),
            width: '450px',
            height: 'auto',
        },
    },
    ReviewReactionsPopup: {
        component: defineAsyncComponent(() => import('./ReviewReactionsPopup.vue')),
        popupOptions: {
            title: $t(`popup.reviewReaction.title`),
            width: '450px',
            height: 'auto',
        },
    },
    ReviewSettingsPopup: {
        component: defineAsyncComponent(() => import('./PlaceReviewSettings.vue')),
        popupOptions: {
            title: $t('common.settings'),
            width: '450px',
            height: 'auto',
        },
    },
}

const popupInstance = shallowRef(null)
const popupEl = ref(null)
const isActive = ref(false)

const close = () => {
    isActive.value = false
    popupInstance.value.close()
    popupInstance.value = null
}

const open = () => {
    isActive.value = true

    nextTick(() => {
        popupInstance.value = popup({
            content: popupEl.value,
            width: '100%',
            height: '100%',
            beforeClose() {
                isActive.value = false
            },
            ...popups[props.popupComponent].popupOptions,
        })
    })
}
</script>

<style module>
.popup {
    width: 100%;
    height: 100%;
}
</style>
