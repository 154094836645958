<template>
    <label
        for="q"
        class="tw-cursor-pointer"
        @click="searchAutoComplete.open"
        :title="$t('SearchAutocomplete.begin_cta')"
    >
        {{ searchAutoComplete.term || $t('SearchAutocomplete.begin_cta') }}
    </label>
</template>
<script setup>
import { useSearchAutocompleteStore } from '../../../store/searchAutocomplete.js'

const searchAutoComplete = useSearchAutocompleteStore()
</script>
