import emitter from 'tiny-emitter/instance'
import ThatsupEventbus from '../../es6/src/modules/eventbus/Eventbus.js'

const EventBus = {
    $on: (...args) => emitter.on(...args),
    $once: (...args) => emitter.once(...args),
    $off: (...args) => emitter.off(...args),
    $emit: (...args) => emitter.emit(...args),
}

ThatsupEventbus.on('*', (event, data) => {
    EventBus.$emit('thatsup:' + event, data)
})

export default EventBus
