<template>
    <div class="place-explorer__map__control-wrapper">
        <div
            class="place-explorer__map__controls"
            :class="{
                'place-explorer__map__controls--bottom': isMobile,
            }"
        >
            <ExploreControl
                :title="$t('explore.map.my_location_label')"
                :loading="isLoadingUserLocation || placePage.has_no_location"
                :active="!isLoadingUserLocation && !userLocationHasError && isAtUserLocation"
                :radar="!isLoadingUserLocation && !userLocationHasError && isWatchingUserLocation"
                circular
                @click="$emit('go-to-user-position')"
            >
                <i
                    :class="{
                        'sli sli-compass-arrow sli-outlined': userLocationHasError,
                        'sli sli-compass-arrow sli-blue': !userLocationHasError && isAtUserLocation,
                        'sli sli-compass-arrow sli-outlined':
                            !userLocationHasError && !isAtUserLocation,
                    }"
                ></i>
            </ExploreControl>

            <ExploreControl
                v-if="placePage.total"
                as="div"
                class="place-explorer__map__controls__pagination"
            >
                <component
                    class="pr-1 mr-1 flex-shrink-0"
                    :is="placePage.prev_page_url ? 'a' : 'i'"
                    :style="placePage.prev_page_url ? '' : 'opacity: 0.4'"
                    :href="placePage.prev_page_url || undefined"
                    :title="$t('pagination.previous_page')"
                    @click.prevent="placePage.prev_page_url ? $emit('prev') : undefined"
                >
                    <i class="icon icon--chevron-right icon--chevron-left--gray"></i>
                </component>
                <div class="m-0">
                    {{
                        $t('pagination.from_to_total', {
                            from: formatNumber(placePage.from),
                            to: formatNumber(placePage.to),
                            total:
                                formatNumber(Math.min(1000, placePage.total)) +
                                (placePage.total >= 1000 ? '+' : ''),
                        })
                    }}
                </div>
                <component
                    class="pl-1 ml-1 flex-shrink-0"
                    :is="placePage.next_page_url ? 'a' : 'i'"
                    :style="placePage.next_page_url ? '' : 'opacity: 0.4'"
                    :href="placePage.next_page_url || undefined"
                    :title="$t('pagination.next_page')"
                    @click.prevent="placePage.next_page_url ? $emit('next') : undefined"
                >
                    <i class="icon icon--chevron-right icon--chevron-right--gray"></i>
                </component>
            </ExploreControl>
            <ExploreControl
                v-show="!placePage.has_no_location"
                :loading="isLoadingFilterOnViewport"
                :active="isAtViewport"
                :circular="isMobile"
                :title="$t('explore.map.search_in_area_label')"
                @click="$emit('filter-on-viewport')"
            >
                <i
                    v-if="isMobile"
                    class="sli sli-maps-pin"
                    :class="{
                        'sli-blue': isAtViewport,
                    }"
                ></i>
                <span v-if="!isMobile">
                    {{ $t('explore.map.search_in_area_label') }}
                </span>
            </ExploreControl>
        </div>
        <div
            v-if="!isMobile"
            class="place-explorer__map__controls place-explorer__map__controls--bottom"
        >
            <ExploreControl
                @click="$emit(isExpanded ? 'contract' : 'expand')"
                :title="$t(`explore.map.${isExpanded ? 'contract' : 'expand'}`)"
                :active="isExpanded"
                circular
            >
                <i
                    :class="{
                        'sli sli-expand': !isExpanded,
                        'sli sli-shrink sli-blue': isExpanded,
                    }"
                ></i>
            </ExploreControl>
        </div>
    </div>
</template>

<script>
import ExploreControl from './ExploreControl.vue'
import { numberFormat } from '../../../es6/src/modules/locale/locale.js'
export default {
    components: { ExploreControl },
    props: {
        placePage: Object,

        isLoadingUserLocation: Boolean,
        isLoadingFilterOnViewport: Boolean,

        userLocationHasError: Boolean,

        isAtUserLocation: Boolean,
        isWatchingUserLocation: Boolean,
        isAtViewport: Boolean,

        isExpanded: Boolean,
        isMobile: Boolean,
    },
    data() {
        return {}
    },
    computed: {},
    mounted() {},
    destroyed() {},
    methods: {
        formatNumber(number) {
            return numberFormat(number)
        },
    },
}
</script>
