<template>
    <div class="autocomplete pretty-form">
        <div
            :class="[
                {
                    'autocomplete-input': true,
                    loading: isLoading,
                    'is-max': isMax,
                    focus: isFocus,
                    'is-manual': create,
                },
                icon,
            ]"
        >
            <input
                class="input full-width load"
                type="text"
                :value="isFocus ? query : query || initialQuery"
                :placeholder="placeholder"
                :disabled="isMax"
                :required="required"
                ref="input"
                @input="onChange"
                @focus="onFocus"
                @blur="onBlur"
                @keydown.up="onArrowUp"
                @keydown.down="onArrowDown"
                @keydown.enter.prevent="onEnter"
                @keydown.esc="hideResults"
            />
        </div>
        <p class="add-new" v-if="create && query.trim().length">
            <a @click="onEnter()" class="icon-plus" :class="{ visible: query }">
                Lägg till
                <span v-show="query">{{ query }}</span>
            </a>
            <span v-show="query">eller tryck Enter</span>
        </p>
        <ul v-show="isOpen && query.length" class="box rounded shadow" ref="resultsList">
            <li v-if="!results.length && noResultsText">
                {{ noResultsText }}
            </li>
            <li
                v-for="(item, index) in results"
                :key="item.id"
                @click="selectItem(item)"
                :class="{ active: index === highlightedIndex }"
            >
                <slot name="item" :item="item" :index="index">
                    {{ item.name }}
                </slot>
            </li>
        </ul>
    </div>
</template>

<script>
// Inspiration: https://alligator.io/vuejs/vue-autocomplete-component/
export default {
    emits: ['select', 'change', 'input', 'focus', 'blur'],
    props: {
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        initiallySelected: {
            type: Array,
            required: false,
            default: () => [],
        },
        max: {
            type: Number,
            required: false,
            default: 1,
        },
        create: {
            type: Boolean,
            required: false,
            default: false,
        },
        async: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
            required: false,
        },
        selected: {
            type: Array,
            required: false,
            default: () => [],
        },
        clearOnSelect: {
            type: Boolean,
            default: false,
        },
        initialQuery: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
        },
        inputAutofocus: {
            type: Boolean,
        },
        noResultsText: {
            type: String,
        },
    },
    data() {
        return {
            query: '',
            results: [],
            highlightedIndex: -1,
            isOpen: false,
            isLoading: false,
            isFocus: false,
        }
    },
    computed: {
        isMax() {
            return this.max > -1 ? this.selected.length >= this.max : false
        },
    },
    methods: {
        onChange(event) {
            this.query = event.target.value
            this.$emit('change', this.query)
            this.$emit('input', this.query)

            if (this.query.length) {
                if (this.async) {
                    this.isLoading = true
                } else {
                    this.filterResults()
                    this.showResults()
                }
            } else {
                this.hideResults()
            }
        },
        onFocus(e) {
            this.isFocus = true

            if (this.query.length && this.results.length) {
                this.showResults()
            }
            this.$emit('focus', e)
        },
        onBlur(e) {
            this.isFocus = false
            this.$emit('blur', e)
        },
        showResults() {
            this.isOpen = true
            this.highlightedIndex = -1
            this.$refs.resultsList.scrollTop = 0
        },
        hideResults() {
            this.isOpen = false
            this.highlightedIndex = -1
        },
        filterResults() {
            this.results = this.items.filter((i) => {
                return i.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1
            })

            this.results = this.results.filter((i) => {
                return !this.selected.filter((ii) => ii.id === i.id).length
            })
        },
        selectItem(item) {
            if (
                (this.create || this.selected.filter((i) => i.id === item.id).length === 0) &&
                (this.max === -1 || this.selected.length < this.max)
            ) {
                if (this.clearOnSelect) {
                    this.query = ''
                }

                this.hideResults()

                this.$emit('select', {
                    item: item,
                    selected: this.selected,
                })
            }
        },
        onArrowUp() {
            let nextItem = this.results[this.highlightedIndex - 1] || null

            if (nextItem && nextItem.parentId === null) {
                this.highlightedIndex--
            }

            if (this.highlightedIndex > 0) {
                this.highlightedIndex--

                this.scrollIntoViewUp()
            } else {
                this.highlightedIndex = this.results.length - 1

                if (this.results[this.highlightedIndex].parentId === null) {
                    this.highlightedIndex--
                }

                this.scrollIntoViewDown()
            }
        },
        onArrowDown() {
            let nextItem = this.results[this.highlightedIndex + 1] || null

            if (nextItem && nextItem.parentId === null) {
                this.highlightedIndex++
            }

            if (this.highlightedIndex < this.results.length - 1) {
                this.highlightedIndex++

                this.scrollIntoViewDown()
            } else {
                this.highlightedIndex = 0

                if (this.results[this.highlightedIndex].parentId === null) {
                    this.highlightedIndex++
                }

                this.scrollIntoViewUp()
            }
        },
        scrollIntoViewDown() {
            // Scroll into view
            let ul = this.$refs.resultsList
            let li = ul.children[this.highlightedIndex]

            if (li.offsetTop + li.offsetHeight > ul.scrollTop + ul.offsetHeight) {
                ul.scrollTop = li.offsetTop + li.offsetHeight - ul.offsetHeight
            }
        },
        scrollIntoViewUp() {
            // Scroll into view
            let ul = this.$refs.resultsList
            let li = ul.children[this.highlightedIndex]

            if (li.offsetTop < ul.scrollTop) {
                ul.scrollTop = li.offsetTop
            }
        },
        onEnter() {
            if (this.create && this.query.trim().length > 0) {
                return this.selectItem(this.query)
            }

            if (typeof this.results[this.highlightedIndex] !== 'undefined') {
                return this.selectItem(this.results[this.highlightedIndex])
            }
        },
        onOutsideClick(e) {
            if (!this.$el.contains(e.target)) {
                this.hideResults()
            }
        },
        focus() {
            this.$refs.input.focus()
        },
    },
    created() {
        if (this.initiallySelected.length) {
            this.selected = this.initiallySelected
        }
    },
    mounted() {
        document.addEventListener('click', this.onOutsideClick)
    },
    destroyed() {
        document.removeEventListener('click', this.onOutsideClick)
    },
    watch: {
        initialQuery(val) {
            this.query = val
        },
        initiallySelected() {
            if (this.async && this.selected.length === 0 && this.initiallySelected.length > 0) {
                this.selected = this.initiallySelected
            }
        },
        items(value) {
            if (this.async) {
                this.results = value
                this.isOpen = true
                this.isLoading = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.autocomplete {
    display: block;
    text-align: left; // Just in case
    position: relative;

    // The input field
    .autocomplete-input {
        margin-bottom: 1rem;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        &.focus input {
            border: 1px solid blue;
        }

        &.is-max {
            input {
                pointer-events: none;
            }
        }

        .item {
            margin: 0 1rem 1rem 0;
            white-space: nowrap;
        }

        &.loading::after {
            background-image: url(/media/img/loading-ring-gray.gif);
            background-size: 1.3em 1.3em;
            background-position: center center;
            background-repeat: no-repeat;
            content: '';
            right: 0.65em;
            top: 50%;
            width: 1.3em;
            height: 1.3em;
            position: absolute;
            transform: translateY(-50%);
        }
    }

    .autocomplete-input[class*='icon-'] {
        padding-left: 1rem;
        position: relative;

        &:before {
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    // List of results
    > ul {
        background: #fff;

        max-height: 20rem;
        overflow: auto;

        margin: 0;
        list-style: none;

        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        z-index: 99;

        border-top-left-radius: 0;
        border-top-right-radius: 0;

        li {
            display: block;
            padding: 1rem 1rem;
            cursor: pointer;

            &.title {
                pointer-events: none;
                font-weight: bold;
            }

            &:hover,
            &.active {
                background: #eeeeee;
            }
        }
    }
}
</style>
