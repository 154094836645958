<template>
    <li>
        <div
            class="place-card tw-border-b tw-border-gray-200 tw-px-2.5 tw-py-4 md:tw-px-6"
            :class="isHighlighted ? 'place-list__item--highlight' : null"
            ref="item"
            @mouseenter="$emit('mouseenter')"
            @mouseleave="$emit('mouseleave')"
        >
            <div class="place-card__item__image">
                <a
                    :href="place.permalink"
                    class="place-card__artwork place-card__artwork--icon artwork rounded"
                >
                    <img
                        v-if="place.thumbnail"
                        :src="place.thumbnail"
                        :alt="place.name"
                        width="120"
                        height="120"
                        loading="lazy"
                    />
                    <span v-else :class="place.icon"></span>
                </a>
            </div>
            <div class="flex min-w-0 w-full">
                <PlaceListSavedIndicator
                    class="float-right flex-shrink-0 ml-1 p-1 link-w-icon tchook"
                    :place="place"
                />

                <a
                    class="place-card__head color-inherit"
                    :href="place.permalink"
                    :title="place.name"
                    @click.stop
                >
                    <h2 class="place-card__title">
                        <span v-if="index !== null">{{ index + 1 }}.</span>
                        {{ place.name }}
                        <em class="color-blue ml-1" v-if="place.isNew">
                            {{ $t('placeList.place_new') }}
                        </em>
                        <em class="color-redish ml-1" v-if="place.closed">
                            {{ $t('placeList.place_closed') }}
                        </em>
                    </h2>
                </a>
                <div class="place-list__item-body" @click="$emit('click')">
                    <address
                        class="subtitle"
                        @click="$emit('address-click', place.id)"
                        style="margin-top: 1px"
                    >
                        {{ (canShowDistance ? distanceText + ' · ' : '') + place.address }}
                    </address>

                    <div class="place-card__rating">
                        <div class="flex-shrink-0 tw-flex">
                            <i
                                class="icon--review-star ml-0"
                                v-for="n of 5"
                                :key="n"
                                :class="{
                                    empty: Math.round(place.rating) < n,
                                    half: place.rating >= n - 0.7 && place.rating <= n - 0.3,
                                }"
                                style="width: 18px; height: 18px"
                            ></i>

                            <span class="fw-7">{{ totalRatingText }}</span>
                            <span v-if="place.reviewCount">
                                &nbsp;({{ place.reviewCount }}
                                {{ $t('common.reviews').toLowerCase() }})
                            </span>
                        </div>
                        <div
                            class="artwork-stack ai-center ml-1"
                            @click="openReviewsPopup(place)"
                            role="button"
                        >
                            <span
                                v-for="(review, i) in place.reviews.length < 6
                                    ? place.reviews
                                    : [...place.reviews].splice(0, 5)"
                                :key="review.user.username"
                                class="user artwork artwork--reversed x-small circle"
                                :data-annotation="
                                    place.reviews.length >= 6 && i === 4
                                        ? '+' + (place.reviews.length - 4)
                                        : undefined
                                "
                            >
                                <img :src="review.user.thumbnail" :alt="review.user.name" />
                            </span>
                            <strong
                                style="margin-left: 4px"
                                class="text-xs"
                                v-if="averageRating(place.reviews)"
                            >
                                {{ averageRating(place.reviews) }}
                            </strong>
                        </div>
                    </div>

                    <ul class="mt-1">
                        <li v-for="trait in place.traits" class="place-card__tag">
                            {{ trait }}
                        </li>
                    </ul>

                    <div class="d-flex text-blue gap-2 md:gap-4 fw-6 mt-2">
                        <div v-if="place.website" class="d-flex items-center">
                            <div
                                class="sli sli-laptop sli-outlined sli-blue mr-1 flex-shrink-0"
                            ></div>
                            <div>
                                <a
                                    rel="noopener"
                                    class="website-link referral-page tctrack"
                                    :title="place.website"
                                    :href="place.website"
                                    target="_blank"
                                >
                                    {{ $t('common.website') }}
                                </a>
                            </div>
                        </div>
                        <div
                            v-if="place.phone"
                            class="phone-reveal d-flex items-center min-w-0"
                            :class="{ revealed: showPhone }"
                        >
                            <div
                                class="sli sli-phone sli-outlined sli-blue mr-1 flex-shrink-0"
                            ></div>
                            <span class="label ellipsis" @click="showPhone = true">
                                {{ $t('common.phone_number') }}
                            </span>
                            <span class="revelation">
                                <a :title="$t('common.phone')" :href="`tel:${place.phone}`">
                                    {{ place.phone }}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <p
                class="place-list__description"
                v-if="place.notes"
                v-html="place.notes.replace(/(?:\r\n|\r|\n)/g, '<br/>')"
            ></p>
        </div>
    </li>
</template>

<script>
import ThatsupGallery from '../../../Shared/components/ThatsupGallery.vue'
import RatingStars from '../../../Shared/components/RatingStars.vue'
import BaseImageSlider from '../../../Shared/components/BaseImageSlider.vue'
import { meanBy } from 'lodash-es'
import PlaceListSavedIndicator from '../../../Shared/components/PlaceListSavedIndicator.vue'
import { numberFormat } from '../../../../es6/src/modules/locale/locale.js'
import { popup } from '../../../../es6/src/modules/popup/popup.js'
import { auth } from '../../../../es6/src/modules/authentication/authentication.js'
import { useUserStore } from '../../../../store/user.js'
import { placeList } from '../../../../es6/src/modules/place-list/placeList.js'

export default {
    props: {
        place: {
            type: Object,
            required: true,
        },
        isMobile: {
            type: Boolean,
            required: true,
        },
        allExpanded: {
            type: Boolean,
            required: false,
            default: false,
        },
        isActive: {
            type: Boolean,
            required: false,
            default: false,
        },
        isHighlighted: {
            type: Boolean,
            required: false,
            default: false,
        },
        isClose: {
            type: Boolean,
            required: false,
            default: false,
        },
        userPosition: {
            type: Object,
            required: false,
            default: null,
        },
        index: {
            type: Number,
            required: false,
            default: null,
        },
    },
    components: {
        PlaceListSavedIndicator,
        BaseImageSlider,
        ThatsupGallery,
        RatingStars,
    },
    data() {
        return {
            showPhone: false,
            expanded: false,
            galleryOptions: {
                constrain: false,
                showControls: true,
            },
            isShown: false,
        }
    },

    computed: {
        hasImages() {
            return this.place.images && this.place.images.length
        },
        canShowDistance() {
            return (
                this.userPosition && this.place.latitude !== null && this.place.longitude !== null
            )
        },
        distanceInMeters() {
            if (!this.canShowDistance) {
                return null
            }
            return Math.haversine(
                this.userPosition.lat,
                this.userPosition.lng,
                this.place.latitude,
                this.place.longitude,
            )
        },
        distanceText() {
            const meters = parseFloat(this.distanceInMeters)
            if (meters === null) {
                return null
            } else if (meters < 999) {
                return numberFormat(meters, 0) + ' m'
            } else {
                return numberFormat(meters / 1000, 1) + ' km'
            }
        },
        totalRatingText() {
            return numberFormat(this.place.rating || 0, 1, true)
        },
    },

    watch: {
        allExpanded(newVal) {
            this.expanded = newVal
        },
        isActive() {
            if (this.isActive && 'scrollIntoView' in this.$refs.item) {
                this.$refs.item.scrollIntoView({
                    behavior: 'smooth',
                })
            }
        },
        isClose(newVal) {
            if (newVal && !this.isShown) {
                this.isShown = true
            }
        },
    },

    created() {
        this.expanded = this.allExpanded
    },

    mounted() {
        if ('IntersectionObserver' in window) {
            let intersectionObserver = new window.IntersectionObserver(
                (entries) => {
                    const inView = entries[0].isIntersecting
                    if (inView) {
                        this.$emit('appear')
                    } else {
                        this.$emit('disappear')
                    }
                },
                {
                    root: null,
                    threshold: [0],
                },
            )
            intersectionObserver.observe(this.$refs.item)

            this.isShown = this.isClose
        } else {
            this.isShown = true
        }
    },

    methods: {
        averageRating(reviews) {
            if (!reviews.length) return null

            const avg = meanBy(reviews, 'rating')

            return numberFormat(avg, 1, true)
        },
        openReviewsPopup(place) {
            let html = ''

            html += `
					<header class="d-flex mb-3">
						<h2 class="ellipsis mb-0 pr-1 text-md fw-6">
							<a class="color-inherit" href="${place.permalink}">
								${place.name}
							</a>
						</h2>
						<div class="place-list__item-rating ml-auto">
							<i class="icon--review-star review-star--small"></i>
							<span>${place.rating}</span>
						</div>
					</header>
				`

            place.reviews.forEach((review) => {
                let starsHtml = ''

                for (let n = 0; n < 5; n++) {
                    starsHtml += `<i class="icon--review-star review-star--small ${
                        n < review.rating ? '' : 'empty'
                    }"></i>`
                }

                html += `
		  				<div class="list-item">
							<a href="${review.user.permalink}" class="list-item__artwork list-item__artwork--small artwork circle">
								<img src="${review.user.thumbnail}" alt="">
							</a>

							<div class="list-item__information d-flex">
								<a href="${review.user.permalink}">
									<h3 class="list-item__title list-item__title--small">${review.user.name}</h3>
								</a>

								<a href="${review.permalink}" class="rating-container ml-auto">
									${starsHtml}
								</a>
							</div>
						</div>
		  			`
            })
            popup({
                title: null,
                content: `<div class="item-list">${html}</div>`,
                width: 380,
            })
        },
        savePlaceToList(place) {
            const userStore = useUserStore()

            if (!userStore.isLoggedIn) {
                return auth(
                    () => {
                        window.location.reload()
                    },
                    {
                        variation: 'lists',
                    },
                )
            }

            if (place) {
                return placeList.userPopup(place)
            }
        },
    },

    destroyed() {},
}
</script>
