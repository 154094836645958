<template>
    <ElementTitle :title="title" :description="description" />
    <div :class="$style.fadeWrapper">
        <div
            data-text-fade-overlay
            :data-text-fade-overlay-label="$t('common.show_more_text')"
            class="tw-max-h-[30vh]"
        >
            <article v-if="data && data.html" :class="$style.wrapper" v-html="data.html"></article>
        </div>
    </div>
</template>

<script setup>
import { computed } from '@vue/reactivity'
import BaseElement from './BaseElement.vue'
import ElementTitle from './ElementTitle.vue'

const props = defineProps({
    ...BaseElement.props,
    data: {
        type: Object,
        required: false,
        default: null,
    },
})

const columns = computed(() => {
    if (props.data && props.data.columns) {
        return props.data.columns
    }

    return (props.data?.html?.length || 0) > 500 ? 2 : 1
})
</script>

<style module lang="scss">
.fadeWrapper {
    padding: var(--element-padding);
}
.wrapper {
    @screen lg {
        columns: v-bind(columns);
        gap: 16px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        break-after: avoid;
        font-weight: 700;
    }

    h1 {
        font-size: 26px;
    }

    h2 {
        font-size: 22px;
    }

    h3 {
        font-size: 18px;
    }

    p {
        font-weight: 600;
        line-height: 1.69;
        break-inside: avoid;
    }

    figure {
        margin-left: 0;
        margin-right: 0;
    }
}
</style>
