<template>
    <div :key="uuid" ref="container" class="image-gallery">
        <ul>
            <li v-for="image in images">
                <figure>
                    <img
                        :src="image.src || image.url || image.mediumUrl"
                        :srcset="image.srcset"
                        :alt="image.title"
                    />
                </figure>
            </li>
        </ul>
    </div>
</template>

<script>
import { computed, nextTick, ref, watch } from 'vue'
import { LegacyGallery } from '@modules/gallery/LegacyGallery.js'
import { guid } from '../../../es6/src/utils/string.js'

export default {
    props: {
        images: {
            type: Array,
            required: true,
        },
        options: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    setup(props) {
        const container = ref(null)

        const uuid = ref(guid())

        watch(
            () => props.images,
            (newVal) =>
                nextTick(() => {
                    uuid.value = guid()
                }),
        )

        const galleryOptions = computed(() => {
            return {
                height: '67%',
                visibleCount: 1,
                itemMargin: 0,
                justify: true,
                endless: false,
                showControls: true,
                constrain: false,
                container: container.value,
                ...props.options,
            }
        })

        watch(
            () => galleryOptions.value,
            (newVal) => {
                if (newVal.container) {
                    // TODO: Shouldn't this be newVal instead of galleryOptions.value?
                    new LegacyGallery(galleryOptions.value)
                }
            },
        )
        return {
            uuid,
            container,
        }
    },
}
</script>
