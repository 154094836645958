<template>
    <component
        :is="link ? 'a' : 'div'"
        class="artwork user-artwork circle"
        :href="link ? user.permalink : null"
        :title="link ? user.name : null"
        :class="{
            'has-trait': !!traits.length,
            'artwork--shadow': shadow,
        }"
    >
        <template v-if="traits.length">
            <span
                v-for="trait in traits"
                :key="trait"
                class="user-trait"
                :class="[$style.trait, trait]"
            ></span>
        </template>
        <img :src="user.thumbnail" :alt="user.name" />
    </component>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    user: {
        type: Object,
        required: true,
    },
    shadow: Boolean,
    link: Boolean,
})

const traits = computed(() => {
    return props.user?.traits || []
})
</script>

<style module>
.review {
    display: flex;
}
.trait {
    transform: translate(15%, -15%);
}
</style>
