<script setup>
import { computed } from 'vue'
import useViewport from '../../../../Shared/util/use-viewport'
import ImageWithSizes from '../../../../Shared/components/ImageWithSizes.vue'
import BaseElement from './BaseElement.vue'
import HeroElement from './HeroElement.vue'
import ElementTitle from './ElementTitle.vue'

const props = defineProps({
    ...BaseElement.props,
    data: {
        type: Object,
        required: false,
        default: null,
    },
})

const items = computed(() => {
    if (props.data === null) {
        const skeletons = []
        for (let i = 0; i < Math.min(9, props.configuration.limit || 3); i++) {
            skeletons.push({
                skeleton: (i % 4) + 1,
            })
        }
        return skeletons
    }
    return props.data.items
})

const { width: windowWidth } = useViewport()

const isMobile = computed(() => {
    return windowWidth.value < 768
})

const hero = computed(() => {
    return items.value.length && items.value[0].temperature >= 5 ? items.value[0] : null
})

const large = computed(() => {
    return items.value.length ? items.value[hero.value ? 1 : 0] : null
})

const semiLarge = computed(() => {
    const newItems = [...items.value]
    if (hero.value) {
        newItems.shift()
    }
    if (isMobile.value) {
        return newItems.splice(1, 2)
    }

    if (newItems.length > 5) {
        const excess = newItems.length - 5
        const number = Math.ceil(excess / 4) * 2
        return newItems.splice(1, number)
    }
    return []
})

const small = computed(() => {
    return items.value.filter((article) => {
        return (
            article !== hero.value && article !== large.value && !semiLarge.value.includes(article)
        )
    })
})
</script>
<template>
    <ElementTitle :title="title" :description="description" />
    <div :class="$style.wrapper">
        <HeroElement
            v-if="hero"
            :class="$style.hero"
            :image="{
                src: hero.artworkSrc,
                srcset: hero.artworkSrcSet,
                alt: hero.title,
                anchor: hero.anchor,
            }"
            :title="hero.title"
            :description="hero.excerpt"
            :href="hero.permalink"
            class="mb-2 w-full"
        />
        <div>
            <a v-if="large" :class="$style.large" :href="large.permalink" :title="large.title">
                <div
                    :class="[
                        $style.artwork,
                        $style.largeArtwork,
                        large.skeleton ? $style.artworkSkeleton : null,
                    ]"
                >
                    <ImageWithSizes
                        v-if="large.artworkSrc"
                        :class="$style.image"
                        :src="large.artworkSrc"
                        :srcset="large.artworkSrcSet"
                        :anchor="large.anchor"
                        :alt="large.title"
                        width="400"
                        height="200"
                        loading="lazy"
                    />
                </div>
                <h2 :class="[$style.largeTitle, large.skeleton ? $style.titleSkeleton : null]">
                    {{ large.title }}
                </h2>
                <p :class="[$style.largeExcerpt, large.skeleton ? $style.excerptSkeleton : null]">
                    {{ large.excerpt }}
                </p>
                <p v-if="large.skeleton" :class="[$style.largeExcerpt, $style.excerptSkeleton]"></p>
                <p
                    v-if="large.skeleton"
                    :class="[$style.largeExcerpt, $style.excerptSkeleton]"
                    style="width: 20%"
                ></p>
            </a>
            <div v-if="semiLarge.length" :class="$style.semiLargeWrapper">
                <a
                    v-for="item in semiLarge"
                    :key="item.id"
                    :class="$style.semiLarge"
                    :href="item.permalink"
                    :title="item.title"
                >
                    <div
                        :class="[
                            $style.artwork,
                            $style.semiLargeArtwork,
                            item.skeleton ? $style.artworkSkeleton : null,
                        ]"
                    >
                        <ImageWithSizes
                            v-if="item.artworkSrc"
                            :class="$style.image"
                            :src="item.artworkSrc"
                            :srcset="item.artworkSrcSet"
                            :anchor="item.anchor"
                            :alt="item.title"
                            width="200"
                            height="100"
                            loading="lazy"
                        />
                    </div>
                    <h3
                        :class="[
                            $style.semiLargeTitle,
                            item.skeleton ? $style['titleSkeleton' + item.skeleton] : null,
                        ]"
                    >
                        {{ item.title }}
                    </h3>
                </a>
            </div>
        </div>
        <div>
            <a
                v-for="item in small"
                :key="item.id"
                :class="$style.small"
                :href="item.permalink"
                :title="item.title"
            >
                <div
                    :class="[
                        $style.artwork,
                        $style.smallArtwork,
                        item.skeleton ? $style.artworkSkeleton : null,
                    ]"
                >
                    <ImageWithSizes
                        v-if="item.thumbnail"
                        :class="$style.image"
                        :src="item.thumbnail"
                        :alt="item.title"
                        width="130"
                        height="96"
                        loading="lazy"
                    />
                </div>
                <h4
                    class="line-clamp-3"
                    :class="[
                        $style.smallTitle,
                        item.skeleton ? $style['titleSkeleton' + item.skeleton] : null,
                    ]"
                >
                    {{ item.title }}
                </h4>
            </a>
        </div>
    </div>
</template>
<style module>
.wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: var(--element-padding);
    flex-wrap: wrap;
}
.hero {
    flex-basis: 100% !important;
}
.large {
    margin-bottom: 32px;
    display: block;
    color: var(--body-font-color);
}

.titleSkeleton,
.titleSkeleton1,
.titleSkeleton2,
.titleSkeleton3,
.titleSkeleton4,
.excerptSkeleton {
    display: block;
    width: 70%;
    background: #dadada;
    border-radius: 6px;
    min-height: 1em;
}

.titleSkeleton1 {
    width: 70%;
}

.titleSkeleton2 {
    width: 60%;
}

.titleSkeleton3 {
    width: 30%;
}

.titleSkeleton4 {
    width: 50%;
}

.excerptSkeleton {
    width: 100%;
}

.artwork {
    overflow: hidden;
    border-radius: 10px;
    flex-shrink: 0;
    background: #dadada;
}

.image {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: inherit;
}
@supports not (aspect-ratio: auto) {
    .image {
        position: absolute;
        top: 0;
    }
}

.largeArtwork {
    aspect-ratio: 2 / 1;
    margin-bottom: 10px;
}
@supports not (aspect-ratio: auto) {
    .largeArtwork {
        padding-top: 50%;
        position: relative;
    }
}

.largeTitle {
    font-size: 35px;
    line-height: 48px;
    font-weight: bold;
    margin-bottom: 10px;
}

.largeExcerpt {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
}

.semiLargeWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.semiLarge {
    margin-bottom: 16px;
    display: block;
    color: var(--body-font-color);
}

.semiLargeArtwork {
    aspect-ratio: 2 / 1;
    margin-bottom: 8px;
}
@supports not (aspect-ratio: auto) {
    .semiLargeArtwork {
        padding-top: 50%;
        position: relative;
    }
}

.semiLargeTitle {
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5;
}

.small {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    flex-wrap: nowrap;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
    color: var(--body-font-color);
}

.small:first-child {
    padding-top: 0;
}

.smallArtwork {
    width: 130px;
    aspect-ratio: 4 / 3;
}
@supports not (aspect-ratio: auto) {
    .smallArtwork {
        padding-top: 100px;
        position: relative;
    }
}

.smallTitle {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.65;
}

@media screen and (min-width: 768px) {
    .wrapper,
    .semiLargeWrapper {
        display: flex;
        flex-direction: row;
    }

    .wrapper > *,
    .semiLargeWrapper > * {
        flex: 1;
    }

    .semiLargeWrapper {
        flex-wrap: wrap;
    }

    .semiLarge {
        flex-basis: calc(50% - 8px);
        flex-grow: 0;
        margin-bottom: 0;
    }
}
</style>
