<template>
    <img
        @error="$emit('error')"
        sizes="1px"
        :style="styles"
        onload="this.onload=null;this.removeAttribute('loading');this.sizes=Math.ceil(this.getBoundingClientRect().width/window.innerWidth*100)+'vw';"
    />
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    anchor: {
        type: Array,
        default: () => [],
    },
})

const styles = computed(() => {
    return {
        objectPosition: props.anchor ? props.anchor.join(' ') : null,
    }
})
</script>
