<template>
    <component
        :is="as"
        class="file-upload"
        @drop.prevent="onDrop"
        @drag.prevent
        @dragover.prevent="dragging = true"
        @dragleave.prevent="dragging = false"
    >
        <slot :dragging="dragging"></slot>

        <div class="file-upload__button">
            <input
                type="file"
                :accept="accept"
                :multiple="multiple"
                @change="onFileChange"
                ref="fileInput"
                class="file-upload__input"
                :disabled="disabled"
            />
            <slot name="input-button" :dragging="dragging"></slot>
        </div>

        <slot name="after" :dragging="dragging"></slot>
    </component>
</template>

<script setup>
import { ref } from 'vue'
import http from '../util/http'

const props = defineProps({
    as: {
        type: String,
        required: false,
        default: 'div',
    },
    accept: {
        type: String,
        required: false,
        default: '',
    },
    multiple: {
        type: Boolean,
        required: false,
        default: false,
    },
    maxFiles: {
        type: Number,
        required: false,
        default: 1,
    },
    maxFileSize: {
        type: Number,
        required: false,
        default: Infinity,
    },
    onFileChange: {
        type: Function,
        required: false,
        default: () => {},
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
})

const emit = defineEmits(['add', 'done', 'error'])

const dragging = ref(false)

const fileInput = ref(null)

const onDrop = (event) => {
    if (props.disabled) return
    event.preventDefault()
    dragging.value = false
    if (!event.dataTransfer.files.length) return
    // const file = event.dataTransfer.files.forEach((file) => {

    //     const fileRef = uploadFile(file);
    // })
    fileInput.value.files = event.dataTransfer.files
    onFileChange(event)
}

const isImage = (file) => {
    return file.type.startsWith('image/')
}

const generateThumbnail = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
            resolve(event.target.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
    })
}

const validateFile = (file) => {
    if (file.size > props.maxFileSize) {
        return `File is too large. Max size is ${Math.round(props.maxFileSize / 1024 / 1024)}MB`
    }
    if (!validateFileType(file)) {
        return `File type is not allowed.`
    }
    return null
}

// Function to validate the file type based on the accepted types
const validateFileType = (file) => {
    // Split the accepted types string into an array
    const acceptedTypes = props.accept.split(',')

    // Check if the file type matches any of the accepted types
    return acceptedTypes.some((type) => {
        // Check if the accepted type starts with a dot (e.g. ".jpg")
        if (type.startsWith('.')) {
            // Check if the file name ends with the accepted type
            return file.name.endsWith(type)
        }
        // Check if the accepted type ends with "/*" (e.g. "image/*")
        else if (type.endsWith('/*')) {
            // Get the category by removing the last character from the accepted type
            const category = type.slice(0, -1)
            // Check if the file type starts with the category
            return file.type.startsWith(category)
        }
        // Check if the file type matches the accepted type exactly
        else {
            return file.type === type
        }
    })
}

const uploadFile = (file) => {
    const fileRef = ref({
        loading: true,
        progress: 0,
        thumbnail: null,
        id: null,
        file: file,
        error: null,
    })
    emit('add', fileRef)

    const error = validateFile(file)
    if (error) {
        fileRef.value.error = error
        fileRef.value.loading = false
        emit('error', fileRef.value.error)
        return fileRef
    }

    const fileIsImage = isImage(file)

    if (fileIsImage) {
        generateThumbnail(file, fileRef).then((thumbnail) => {
            fileRef.value.thumbnail = thumbnail
        })
    }

    const formData = new FormData()
    formData.append('file', file)
    const endpoint = '/api/upload/' + (fileIsImage ? 'image' : '')

    http.post(endpoint, formData, {
        onUploadProgress: (progressEvent) => {
            fileRef.value.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        },
    })
        .then(({ data }) => {
            const response = data.data[0]
            fileRef.value.id = response.id
            fileRef.value.loading = false
            fileRef.value.progress = 100
            fileRef.value.url = response.url
            emit('done', fileRef)
        })
        .catch((error) => {
            fileRef.value.loading = false
            if (error?.response?.data?.error?.message) {
                fileRef.value.error = error.response.data.error.message
            } else {
                fileRef.value.error = 'An error occurred while uploading the file'
            }
            emit('error', fileRef.value.error)
            throw error
        })

    return fileRef
}

/*
dropzone.addEventListener('drop', function(e) {
  this.classList.remove('dropzone-dragging');
  var files = e.dataTransfer.files;
  var dataTransfer = new DataTransfer();

  var for_alert = "";
  Array.prototype.forEach.call(files, file => {
    for_alert += "# " + file.name +
		" (" + file.type + " | " + file.size +
		" bytes)\r\n";
    dataTransfer.items.add(file);
    if (!multiple) {
      return false;
    }
  });

  var filesToBeAdded = dataTransfer.files;
  dropzone_input.files = filesToBeAdded;
  alert(for_alert);

}, false);
*/
const onFileChange = (event) => {
    Array.from(fileInput.value.files).forEach((file) => {
        uploadFile(file)
    })
    fileInput.value.value = ''
}
</script>

<style scoped>
.file-upload {
    position: relative;
}
.file-upload__button {
    position: relative;
    cursor: pointer;
}
.file-upload__button ::-webkit-file-upload-button {
    cursor: pointer;
}
.file-upload__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
</style>
