<template>
    <li
        v-bind="$attrs"
        class="search-autocomplete__result"
        :class="[
            'search-autocomplete__result--' + result.type,
            {
                'search-autocomplete__result--highlighted': highlight,
                'search-autocomplete__result--disabled': !!result.disabled,
                ['search-autocomplete__result--group-' + result.group]: !!result.group,
                'search-autocomplete__result--with-subtitle': !!subtitle,
                'search-autocomplete__result--with-pretitle': !!pretitle,
            },
        ]"
        ref="element"
    >
        <component
            :is="result.url ? 'a' : 'div'"
            :href="result.url"
            :title="result.title"
            @click.prevent
            class="search-autocomplete__result__content"
        >
            <span
                v-if="thumbnail"
                class="search-autocomplete__result__artwork search-autocomplete__result__artwork--thumbnail"
            >
                <img :src="thumbnail" :alt="result.title" />
            </span>
            <span
                v-else-if="iconClass"
                class="search-autocomplete__result__artwork search-autocomplete__result__artwork--icon"
            >
                <i :class="['icon', iconColor, iconClass]"></i>
            </span>
            <span class="search-autocomplete__result__information">
                <span v-if="pretitle" class="search-autocomplete__result__subtitle">
                    {{ pretitle }}
                </span>
                <span
                    v-if="formattedTitle"
                    v-html="formattedTitle"
                    class="search-autocomplete__result__title"
                />
                <span v-else class="search-autocomplete__result__title">
                    {{ result.title }}
                </span>
                <span v-if="subtitle" class="search-autocomplete__result__subtitle">
                    {{ subtitle }}
                </span>
            </span>
        </component>
    </li>
</template>
<script>
export default {
    props: {
        result: {
            type: Object,
            required: true,
        },
        term: {
            type: String,
            required: false,
            default: null,
        },
        isLoadingUserLocation: Boolean,
        highlight: Boolean,
    },

    watch: {
        highlight(newVal) {
            if (newVal) {
                this.$refs.element.scrollIntoView({
                    block: 'nearest',
                    inline: 'nearest',
                })
            }
        },
    },

    computed: {
        formattedTitle() {
            // return this.result._formatted? this.result._formatted.title : null;
            if (!this.term) {
                return null
            }

            const index = this.result.title.toLowerCase().indexOf(this.term.toLowerCase())
            const parts = []
            if (index > -1) {
                const termLength = this.term.length
                if (index > 0) {
                    parts.push(this.htmlEncode(this.result.title.substring(0, index)))
                }
                parts.push(
                    '<em>' +
                        this.htmlEncode(this.result.title.substring(index, index + termLength)) +
                        '</em>',
                )
                if (index + termLength < this.result.title.length) {
                    parts.push(this.htmlEncode(this.result.title.substring(index + termLength)))
                }
            }
            return parts.join('')
        },
        pretitle() {
            // switch(this.result.type) {
            //   case 'guide':
            //     return 'Guide';
            //     break;
            // }
            return null
        },
        subtitle() {
            let subtitle = null
            if (this.result.subtitle) {
                subtitle = this.result.subtitle
            }
            if (!subtitle) {
                switch (this.result.type) {
                    case 'guide':
                        subtitle = this.$t('SearchAutocomplete.guide_subtitle')
                        break
                }
            }
            if (Array.isArray(subtitle)) {
                return subtitle.join(', ', subtitle)
            }
            return subtitle
        },
        thumbnail() {
            if (this.result.group === 'history') {
                return null
            }
            return this.result.thumbnail
        },
        iconClass() {
            if (this.result.group === 'history') {
                return 'icon search sli sli-clock'
            }
            let iconClass = ''
            switch (this.result.type) {
                case 'search':
                case 'explore':
                    iconClass += 'icon search sli sli-search'
                    break
                case 'guide':
                    iconClass += 'icon icon--guide icon--guide--white'
                    break
                case 'place':
                    iconClass += 'icon category'
                    break
                case 'near-me':
                    iconClass +=
                        'icon search ' +
                        (this.isLoadingUserLocation
                            ? 'icon--loading-ring gray'
                            : 'sli sli-compass-arrow sli-outlined')
                    break
            }
            if (this.result.icon) {
                iconClass += ' ' + this.result.icon
            }
            return iconClass.trim()
        },
        iconColor() {
            if (this.result.group === 'history') {
                return 'transparent'
            }
            switch (this.result.type) {
                case 'guide':
                    return 'orange'
                    break
                case 'explore':
                    return 'transparent'
                    break
                case 'place':
                    return 'blue'
                    break
                default:
                    return 'transparent'
                    break
            }
        },
    },
    methods: {
        htmlEncode(str) {
            return str.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
                return '&#' + i.charCodeAt(0) + ';'
            })
        },
    },
}
</script>
