import EventEmitter from '../../../es6/src/utils/EventEmitter'

class Control extends EventEmitter {
    constructor(key, map, context = window.document) {
        super()
        this.key = key
        this.map = map
        this.context = context
    }
    hide() {
        this.map.hideControl(this.key)
    }
    show() {
        this.map.show(this.key)
    }
}

export class DrawControl extends Control {
    constructor(map, context = window.document) {
        super('draw', map, context)
        this.drawInitialized = false
    }
    onAdd() {
        const group = this.context.createElement('div')
        group.className = 'mapboxgl-ctrl-group mapboxgl-ctrl'

        const btn = this.context.createElement('button')
        btn.className = 'mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon'
        group.appendChild(btn)

        btn.addEventListener('click', () => {
            this.enableDraw()
        })

        this.group = group
        return group
    }
    onRemove() {
        this.group.parentNode.removeChild(this.group)
    }
    hide() {
        this.map.hideControl(this.key + '-native')
        this.map.showControl(this.key)
    }
    show() {
        this.map.hideControl(this.key)
        this.map.showControl(this.key + '-native')
    }
    async enableDraw() {
        this.map.hideControl(this.key)

        import('@mapbox/mapbox-gl-draw').then((module) => {
            import('@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css')

            const MapboxDraw = module.default

            this.drawControl = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    polygon: false,
                    trash: true,
                },
                defaultMode: 'draw_polygon',
            })

            this.map.addControl(this.key + '-native', this.drawControl)

            const shapes = this.map.getShapes()
            if (shapes && shapes.length) {
                let id = this.drawControl.set({
                    type: 'FeatureCollection',
                    features: shapes.map((shape) => {
                        return { ...shape.source.data, properties: {} }
                    }),
                })
                this.drawControl.changeMode('simple_select', { featureIds: id })
            }
            if (!this.drawInitialized) {
                this.drawInitialized = true
                let hasChanged = false

                this.map.on('draw.create', (e) => this.emit('create', e))
                this.map.on('draw.delete', (e) => {
                    this.emit('delete', e)
                    this.drawControl.changeMode('draw_polygon')
                })
                this.map.on('draw.update', (e) => {
                    this.emit('update', e)
                    hasChanged = true
                })
                this.map.on('draw.modechange', (e) => {
                    this.emit('modechange', e)
                })
                this.map.on('draw.selectionchange', (e) => {
                    this.emit('selectionchange', e)
                    // if(e.features.length === 0 && e.points.length === 0 && hasChanged) {
                    // 	this.$emit('draw', drawControl.getAll());
                    // }
                })
            }

            this.emit('enabled', this.drawControl)
        })
    }
    features() {
        if (!this.drawControl) {
            return []
        }
        return this.drawControl.getAll()
    }
}
