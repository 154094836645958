<script>
export default {
    props: {
        index: {
            type: Number,
            required: false,
            default: null,
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
        type: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
        configuration: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        data: {
            type: [Object, Array, String, Number],
            required: false,
            default: null,
        },
    },
}
</script>
