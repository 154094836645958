<template>
    <div>
        <h3>{{ $t('userPoll.alternatives_title') }}</h3>
        <div>
            <div v-if="canSearch" class="mb-4">
                <label class="text-smaller weight-600 mb-1 d-block">
                    {{ $t('userPoll.search_alternatives_label') }}
                </label>
                <input type="text" class="full-width" v-model="searchTerm" />
            </div>
            <ul>
                <li v-for="alternative in filteredAlternatives" :key="alternative.id">
                    <Alternative :alternative="alternative" @click="choose(alternative)">
                        <template v-slot:after>
                            <div class="place-box__button">
                                <i class="icon--round-right-arrow"></i>
                            </div>
                        </template>
                    </Alternative>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Nl2br from 'vue3-nl2br'
import Alternative from './Alternative.vue'
import { useLocaleStore } from '../../../../store/locale.js'

const normalizeTerm = function (term) {
    if (!term) {
        return ''
    }
    return term
        .toLowerCase()
        .normalize('NFD')
        .replace(/[^a-z\s]/g, '')
}

export default {
    emits: ['input'],
    components: {
        Nl2br,
        Alternative,
    },
    props: {
        userPoll: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            searchTerm: null,
        }
    },

    computed: {
        canSearch() {
            return this.userPoll.alternatives.length > 25
        },

        filteredAlternatives() {
            if (!this.searchTerm) {
                return this.sortAlternatives(this.userPoll.alternatives)
            }
            return this.sortAlternatives(
                this.userPoll.alternatives.filter((alternative) => {
                    return (
                        normalizeTerm(alternative.title).indexOf(normalizeTerm(this.searchTerm)) !==
                        -1
                    )
                }),
            )
        },
    },

    methods: {
        choose(alternative) {
            this.$emit('input', alternative)
        },

        sortAlternatives(alternatives) {
            if (this.userPoll.configuration.sort) {
                const localeStore = useLocaleStore()
                let sort = undefined
                switch (this.userPoll.configuration.sort) {
                    case 'a-z':
                        sort = (a, b) => a.title.localeCompare(b.title, localeStore.code)
                        break
                }

                return alternatives.sort(sort)
            }
            return alternatives
        },
    },
}
</script>
