import http from './../../utils/http'
import { translate } from './../locale/locale.js'
import { report } from './../community/report.js'
import { photoswipe } from './photoswipe.js'

export const userPhotoGallery = function (options, context = null) {
    if (context && typeof context === 'string') {
        context = window.document.querySelector(context)
    }
    var openPhotoswipeGallery = function (data) {
        const allItems = []
        const convertItems = function (data) {
            const offset = data.page.limit * (data.page.current - 1)
            data.data.forEach(function (item, index) {
                allItems[offset + index] = {
                    ...item,
                    thumbCropped: true,
                }
            })
        }
        const pagesLoading = {}
        const loadedPages = []
        const limit = data.page.limit
        const loadPageForIndex = function (index) {
            const page = Math.ceil((index + 1) / limit)
            if (pagesLoading[page]) {
                return pagesLoading[page]
            }
            if (loadedPages[page] === true) {
                return Promise.resolve(allItems)
            }
            pagesLoading[page] = http
                .get('/user/photo-gallery/', {
                    params: {
                        ...options,
                        first: null,
                        page: page,
                    },
                })
                .then(({ data }) => {
                    loadedPages[page] = true
                    convertItems(data)
                    pagesLoading[page] = null
                    return allItems
                })
            return pagesLoading[page]
        }
        convertItems(data)
        const photoswipeInstance = photoswipe(allItems)
        photoswipeInstance.on('uiRegister', function () {
            photoswipeInstance.pswp.ui.registerElement({
                name: 'edit-button',
                ariaLabel: translate('edit'),
                title: translate('edit'),
                order: 9,
                isButton: true,
                tagName: 'a',
                html: {
                    isCustomSVG: true,
                    inner: '<path id="pswp__icn-cog" d="M 22.905 14.497 L 22.068 14.198 C 21.823 14.114 21.626 13.927 21.53 13.686 C 21.427 13.452 21.427 13.186 21.53 12.95 L 21.962 12.14 C 22.484 11.047 21.627 9.798 20.419 9.894 C 20.226 9.909 20.037 9.958 19.863 10.042 L 19.052 10.427 C 18.817 10.526 18.55 10.526 18.315 10.427 C 18.077 10.326 17.892 10.132 17.803 9.89 L 17.504 9.046 C 17.093 7.902 15.596 7.631 14.809 8.559 C 14.687 8.704 14.592 8.869 14.528 9.046 L 14.196 9.91 C 14.107 10.152 13.922 10.346 13.684 10.447 C 13.449 10.546 13.182 10.546 12.947 10.447 L 12.136 10.042 C 11.042 9.521 9.793 10.378 9.888 11.585 C 9.903 11.778 9.953 11.966 10.037 12.14 L 10.422 12.95 C 10.525 13.186 10.525 13.452 10.422 13.686 C 10.325 13.927 10.129 14.114 9.884 14.198 L 9.047 14.497 C 7.902 14.907 7.63 16.404 8.56 17.19 C 8.704 17.311 8.869 17.406 9.047 17.47 L 9.884 17.77 C 10.129 17.853 10.325 18.039 10.422 18.281 C 10.525 18.515 10.525 18.782 10.422 19.018 L 10.037 19.827 C 9.481 20.903 10.299 22.178 11.509 22.121 C 11.728 22.11 11.941 22.054 12.136 21.958 L 12.947 21.579 C 13.181 21.473 13.45 21.473 13.684 21.579 C 13.923 21.678 14.109 21.874 14.196 22.118 L 14.494 22.954 C 14.906 24.098 16.403 24.369 17.19 23.44 C 17.312 23.296 17.407 23.131 17.471 22.954 L 17.77 22.118 C 17.857 21.874 18.042 21.678 18.281 21.579 C 18.516 21.473 18.785 21.473 19.019 21.579 L 19.829 21.958 C 20.897 22.531 22.186 21.735 22.149 20.526 C 22.142 20.282 22.078 20.042 21.962 19.827 L 21.576 19.018 C 21.473 18.782 21.473 18.515 21.576 18.281 C 21.673 18.039 21.869 17.853 22.115 17.77 L 22.952 17.47 C 24.098 17.06 24.368 15.563 23.44 14.778 C 23.296 14.655 23.13 14.561 22.952 14.497 L 22.905 14.497 Z M 15.983 19.163 C 14.705 19.132 13.557 18.37 13.033 17.206 C 12.355 15.578 13.13 13.709 14.761 13.037 C 16.389 12.386 18.241 13.152 18.933 14.762 C 19.61 16.39 18.836 18.258 17.205 18.931 C 16.818 19.092 16.402 19.172 15.983 19.163 Z"></path>',
                    outlineID: 'pswp__icn-cog',
                },
                onInit: (el, pswp) => {
                    el.setAttribute('target', '_blank')
                    pswp.on('change', () => {
                        const currSlide = pswp.currSlide
                        el.setAttribute('href', currSlide.data.url)
                        el.style.display = currSlide.data.canEdit ? 'block' : 'none'
                    })
                },
            })
            photoswipeInstance.pswp.ui.registerElement({
                name: 'report-button',
                ariaLabel: translate('report'),
                title: translate('report'),
                order: 9,
                isButton: true,
                html: {
                    isCustomSVG: true,
                    inner: '<path id="pswp__icn-alert" d="M 23.845 21.962 L 17.182 8.744 C 16.952 8.287 16.497 8 16.002 8 C 15.507 8 15.053 8.287 14.823 8.744 L 8.159 21.962 C 7.933 22.399 7.949 22.93 8.199 23.352 C 8.446 23.76 8.877 24.005 9.339 24 L 22.666 24 C 23.668 24 24.311 22.887 23.845 21.962 Z M 15.336 13.565 C 15.336 13.03 15.891 12.695 16.336 12.963 C 16.541 13.087 16.669 13.317 16.669 13.565 L 16.669 17.739 C 16.669 18.275 16.114 18.609 15.669 18.342 C 15.463 18.217 15.336 17.987 15.336 17.739 L 15.336 13.565 Z M 16.036 21.565 C 15.491 21.562 15.044 21.111 15.023 20.543 C 15.004 19.963 15.447 19.482 16.002 19.478 C 16.547 19.482 16.994 19.932 17.015 20.501 C 17.034 21.079 16.591 21.562 16.036 21.565 Z"></path>',
                    outlineID: 'pswp__icn-alert',
                },
                onClick() {
                    report('user-photo', photoswipeInstance.pswp.currSlide.data.id)
                    photoswipeInstance.pswp.close()
                },
                onInit: (el, pswp) => {
                    pswp.on('change', () => {
                        const currSlide = pswp.currSlide
                        el.style.display = currSlide.data.canReport ? 'block' : 'none'
                    })
                },
            })
        })

        photoswipeInstance.addFilter('thumbEl', (thumbEl, data) => {
            const el = (context || window.document).querySelector(
                '.user-photo[data-id="' + data.id + '"] img',
            )
            if (el) {
                return el
            }
            return thumbEl
        })
        photoswipeInstance.on('contentLoad', ({ content }) => {
            if (allItems[content.index] === undefined) {
                loadPageForIndex(content.index).then(() => {
                    photoswipeInstance.pswp.options.dataSource = allItems
                    photoswipeInstance.pswp.refreshSlideContent(content.index)
                })
            }
        })
        photoswipeInstance.addFilter('numItems', () => {
            return data.page.total
        })

        photoswipeInstance.init()
        photoswipeInstance.loadAndOpen(
            (data.firstIndex || 0) + data.page.limit * (data.page.current - 1),
        )
    }
    http.get(`/user/photo-gallery/`, {
        params: options,
    }).then(({ data }) => {
        if (data.error) {
            return alert(data.error)
        }
        if (data.data && data.data.length) {
            openPhotoswipeGallery(data)
        }
    })
}
