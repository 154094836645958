import { trackEvent } from '../tracking/tracking.js'
import { translate } from '../locale/locale.js'
import { popup } from '../popup/popup.js'

export const share = (url = location.href, title = '', options = {}) => {
    if (!title) {
        const ogTitle = document.querySelector("meta[property='og:title']")
        title = ogTitle ? ogTitle.getAttribute('content') : document.title
    }

    if (url.substring(0, 1) === '/') {
        url = `${window.location.protocol}//${window.location.host}${url}`
    }

    const { copy = true, email = true, facebook = true, emailSubject, emailBody } = options

    const shareOptions = {
        title,
        url,
    }

    if (navigator.share) {
        return navigator
            .share(shareOptions)
            .then(() => trackEvent('Native', { category: 'Share', label: url }))
            .catch((error) => trackEvent('Native Error', { category: 'Share', label: error }))
    }

    const wrapper = document.createElement('div')
    wrapper.classList.add('pretty-form')

    const addButton = (type, options) => {
        const buttonWrapper = document.createElement('p')
        buttonWrapper.classList.add('form-group')
        const button = document.createElement('a')
        button.dataset.type = type
        button.classList.add('flat-button', 'block')
        button.style.display = 'flex'
        button.style.justifyContent = 'center'
        button.style.alignItems = 'center'
        if (options.color) {
            button.classList.add(options.color)
        }
        button.innerHTML = `<i class="sli sli-${options.icon} mr-1"></i> ${translate(
            options.label,
        )}`

        if (options.title) {
            button.title = translate('share_x', title)
        }

        if (options.role) {
            button.setAttribute('role', options.role)
        }

        if (options.href) {
            button.href = options.href
        }

        if (options.target) {
            button.target = options.target
        }

        if (options.onClick) {
            button.addEventListener('click', () => options.onClick(button))
        }

        buttonWrapper.appendChild(button)
        wrapper.appendChild(buttonWrapper)
    }

    if (copy && navigator.clipboard) {
        addButton('Copy', {
            icon: 'copy',
            label: 'share_copy',
            title: true,
            color: 'white',
            onClick: (button) => {
                trackEvent('Copy link', { category: 'Share', label: url })
                navigator.clipboard
                    .writeText(url)
                    .then(() => {
                        button.innerHTML = `<i class="icon--check-rounded mr-1"></i> ${translate(
                            'share_copy_success',
                        )}`
                        setTimeout(() => {
                            button.innerHTML = `<i class="sli sli-copy mr-1"></i> ${translate(
                                'share_copy',
                            )}`
                        }, 3000)
                    })
                    .catch(() => {
                        button.innerHTML = `<i class="sli sli-copy mr-1"></i> ${translate(
                            'share_copy_failure',
                        )}`
                    })
            },
        })
    }

    if (email) {
        addButton('Email', {
            icon: 'email sli-outlined sli-white',
            label: 'share_email',
            title: true,
            color: 'orange',
            href: `mailto:?subject=${encodeURIComponent(
                emailSubject || title,
            )}&body=${encodeURIComponent(emailBody || url)}`,
            onClick: () => trackEvent('Email', { category: 'Share', label: url }),
        })
    }

    if (facebook) {
        addButton('Facebook', {
            icon: 'facebook sli-white',
            label: 'facebook',
            title: true,
            color: 'facebook',
            role: 'button',
            onClick: () => {
                if (typeof window.FB === 'undefined') {
                    return
                }

                window.FB.ui(
                    {
                        method: 'share',
                        href: url,
                    },
                    () => trackEvent('Facebook', { category: 'Share', label: url }),
                )
            },
        })
    }

    // if (twitter) {
    //     addButton('Twitter', {
    //         icon: 'twitter',
    //         label: 'share_twitter',
    //         color: 'blue',
    //         href: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    //             twitterMessage || title,
    //         )}&url=${encodeURIComponent(url)}`,
    //         target: '_blank',
    //         onClick: () => trackEvent('Twitter', { category: 'Share', label: url }),
    //     })
    // }

    trackEvent('Dialog', { category: 'Share', label: url })

    return popup({
        content: wrapper,
        width: 400,
    })
}
