<template>
    <div
        class="place-explorer__mobile-filter fixed-below-header"
        :class="{
            'place-explorer__mobile-filter--stuck': isStuck,
        }"
    >
        <div class="place-explorer__mobile-filter-bar d-flex items-center justify-between">
            <div class="flex-grow d-flex overflow-ellipsis" @click="openAutocomplete">
                <label for="q" class="cursor-pointer flex-grow flex-shrink overflow-ellipsis mr-1">
                    {{ filter.term }}
                </label>
                <label
                    for="autocomplete-location"
                    class="cursor-pointer text-brownish-grey overflow-ellipsis"
                >
                    {{ filter.location }}
                </label>
            </div>
            <span
                role="button"
                @click="$emit('open-filter')"
                class="not-flex-shrink place-explorer__mobile-filter-bar-button"
                :aria-label="$t('explore.filter.open_label')"
            >
                <i
                    class="icon icon--x18"
                    :class="{
                        'icon--filter icon--filter-gray': !isLoading,
                        'icon--loading-ring icon--loading-ring--blue': isLoading,
                    }"
                ></i>
            </span>
        </div>
    </div>
</template>

<script>
import { useSearchAutocompleteStore } from '../../../store/searchAutocomplete.js'

export default {
    components: {},
    props: {
        filter: Object,
        bodyOffset: Number,
        isLoading: Boolean,
    },
    data() {
        return {
            isStuck: false,
        }
    },
    setup() {
        const searchAutocompleteStore = useSearchAutocompleteStore()

        return {
            openAutocomplete: searchAutocompleteStore.open,
        }
    },
    computed: {},
    mounted() {
        this.bindEvents()
        this.onWindowScroll()
    },
    unmounted() {
        this.unbindEvents()
    },
    methods: {
        bindEvents() {
            window.addEventListener('scroll', this.onWindowScroll)
        },
        unbindEvents() {
            window.removeEventListener('scroll', this.onWindowScroll)
        },
        onWindowScroll(e) {
            const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
            this.isStuck = scrollTop > this.bodyOffset * 0.8
        },
    },
}
</script>
