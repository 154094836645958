type EventHandler<U extends ListenerHandlerArgumentsType> = (...args: U) => void

type OnNumberOfSlides = (numberOfSlides: number) => void

type OnIsOverflowing = (isOverflowing: boolean) => void

type EventsEmitted = {
    numberOfSlides?: OnNumberOfSlides

    isOverflowing?: OnIsOverflowing
}
type EventKey = keyof EventsEmitted

type ListenerHandlerArgumentsType = unknown[]

type ListenerArray = {
    [key in EventKey]?: EventHandler<ListenerHandlerArgumentsType>[]
}

export enum StepSize {
    FULL = 'full',
    SLIDE = 'slide',
}

type ControlsOption = true | false | 'hover'

interface GalleryOptions {
    key?: string
    element: HTMLElement | string
    slideSelector?: string
    loop?: boolean
    controls?: ControlsOption
    on?: EventsEmitted
    stepSize?: StepSize
}

const defaultOptions: GalleryOptions = {
    key: 'gallery',
    element: '.gallery',
    slideSelector: ':scope > *',
    loop: false,
    controls: false,
    on: {},
    stepSize: StepSize.SLIDE,
}

type DebounceCallback = (...args: unknown[]) => void

const debounce = function (callback: DebounceCallback, timeout: number): DebounceCallback {
    let activeDebounce = null
    return function (...args) {
        if (activeDebounce) {
            window.clearTimeout(activeDebounce)
        }
        activeDebounce = window.setTimeout(() => {
            callback(...args)
            activeDebounce = null
        }, timeout)
    }
}

const findOverflowElement = function (element: Element): Element {
    let overflowParent = null
    let parent = element.parentNode
    while (parent && parent instanceof HTMLElement) {
        const overflow = window.getComputedStyle(parent).overflow
        if (overflow === 'auto' || overflow === 'hidden') {
            overflowParent = parent
            break
        }
        parent = parent.parentNode
    }
    return overflowParent || window.document.querySelector('body')
}

class Gallery {
    element: HTMLElement

    key: string

    overflowElement: HTMLElement

    #resizeObserver: ResizeObserver

    #mutationObserver: MutationObserver

    #handleResizeEvent: DebounceCallback

    slides: Element[]

    options: GalleryOptions

    numberOfSlides: number

    isOverflowing: boolean = false

    hasPrevious: boolean = false
    hasNext: boolean = false

    #listeners: ListenerArray = {}

    #queuedEmits: [EventKey, ListenerHandlerArgumentsType][] = []

    isInitialized: boolean = false

    hasControls: boolean = false

    constructor(options: GalleryOptions) {
        this.options = { ...defaultOptions, ...options }
        if (typeof this.options.element === 'string') {
            const element = window.document.querySelector(this.options.element)
            this.element = <HTMLElement>element
        } else {
            this.element = this.options.element
        }
        this.key = this.options.key

        this.overflowElement = <HTMLElement>findOverflowElement(this.element)
        this.overflowElement.setAttribute(`data-${this.key}`, 'true')
        if (this.options.on) {
            for (const key in this.options.on) {
                this.on(<EventKey>key, this.options.on[key])
            }
        }

        this.refreshSlides()
        this.#handleOverflow()
        this.#initResizeObserver()
        this.#initMutationObserver()

        this.element.addEventListener('load', () => this.#handleOverflow())

        this.isInitialized = true
        this.#callQueuedEmits()
    }

    refreshSlides(): Element[] {
        const selector: string = this.options.slideSelector
        this.slides = Array.from(this.element.querySelectorAll(selector))
        return this.slides
    }

    step(step: number = 1): boolean {
        const galleryBounds = this.overflowElement.getBoundingClientRect()
        const inner = this.element
        const images = [...this.slides]
        // Reverse images if we're going previous
        if (step === -1) images.reverse()
        // Find the first image that isn't fully visible in the direction we're heading
        const firstOverflowing = images.find((image) => {
            const bounds = image.getBoundingClientRect()
            return (
                (step === 1 &&
                    Math.round(bounds.x + bounds.width) >
                        Math.round(galleryBounds.x + galleryBounds.width) + 10) ||
                (step === -1 && bounds.x < galleryBounds.x - 10)
            )
        })

        if (!firstOverflowing && !this.options.loop) return false

        const overflowingBounds = !firstOverflowing
            ? null
            : firstOverflowing.getBoundingClientRect()
        const innerBounds = inner.getBoundingClientRect()

        const currentOffset = innerBounds.x - galleryBounds.x
        let newOffset = currentOffset

        const minLeft = -1 * (inner.scrollWidth - galleryBounds.width) // Right edge of inner wrapper
        const maxLeft = 0 // Left edge of inner wrapper

        if (step === 1) {
            if (!firstOverflowing) {
                newOffset = maxLeft
            } else {
                switch (this.options.stepSize) {
                    case StepSize.FULL:
                        newOffset = currentOffset - (overflowingBounds.x - galleryBounds.x)
                        break
                    case StepSize.SLIDE:
                    default:
                        newOffset =
                            currentOffset -
                            (overflowingBounds.x +
                                overflowingBounds.width -
                                (galleryBounds.x + galleryBounds.width))
                        break
                }
                newOffset = Math.floor(newOffset)
            }
        } else if (step === -1) {
            if (!firstOverflowing) {
                newOffset = minLeft
            } else {
                switch (this.options.stepSize) {
                    case StepSize.FULL:
                        newOffset =
                            currentOffset +
                            (galleryBounds.width -
                                overflowingBounds.width +
                                galleryBounds.x -
                                overflowingBounds.x)
                        break
                    case StepSize.SLIDE:
                    default:
                        newOffset = Math.ceil(
                            currentOffset - (overflowingBounds.x - galleryBounds.x),
                        )
                        break
                }
                newOffset = Math.ceil(newOffset)
            }
        }
        this.overflowElement.style.setProperty(
            `--${this.key}-offset`,
            `${Math.max(minLeft, Math.min(maxLeft, newOffset))}px`,
        )
        this.overflowElement.style.setProperty(
            `--${this.key}-offset-percent`,
            `${Math.max(minLeft, Math.min(maxLeft, newOffset)) / minLeft}`,
        )
        this.overflowElement.setAttribute(
            `data-${this.key}-prev`,
            newOffset >= maxLeft ? 'false' : 'true',
        )
        this.overflowElement.setAttribute(
            `data-${this.key}-next`,
            newOffset <= minLeft ? 'false' : 'true',
        )
    }

    #initResizeObserver() {
        this.#handleResizeEvent = debounce((entries: ResizeObserverEntry[]) => {
            entries.forEach(() => {
                this.#handleOverflow()
            })
        }, 300)
        this.#resizeObserver = new ResizeObserver((entries) => {
            this.#handleResizeEvent(entries)
        })

        this.#resizeObserver.observe(this.element)
    }

    #initMutationObserver() {
        this.#mutationObserver = new MutationObserver((entries) => {
            this.#handleResizeEvent(entries)
        })

        this.#mutationObserver.observe(this.element, { childList: true })
    }

    on<T extends EventKey, U extends ListenerHandlerArgumentsType>(
        event: T,
        handler: EventHandler<U>,
    ): this {
        if (!(event in this.#listeners)) {
            this.#listeners[event] = []
        }
        this.#listeners[event].push(handler)
        return this
    }

    off<T extends EventKey, U extends ListenerHandlerArgumentsType>(
        event: T,
        handler: EventHandler<U>,
    ): this {
        if (!(event in this.#listeners)) {
            return this
        }
        const index = this.#listeners[event].indexOf(handler)
        if (index > -1) {
            this.#listeners[event].splice(index, 1)
        }
        return this
    }

    emit(event: EventKey, ...args: ListenerHandlerArgumentsType): this {
        if (!this.isInitialized) {
            this.#queuedEmits.push([event, args])
            return this
        }
        if (!(event in this.#listeners)) {
            return this
        }
        this.#listeners[event].forEach((handler) => {
            handler.apply(this, args)
        })
        return this
    }

    #callQueuedEmits(): this {
        if (!this.isInitialized) {
            return this
        }
        this.#queuedEmits.forEach((queued) => {
            this.emit(queued[0], ...queued[1])
        })
        return this
    }

    #handleOverflow() {
        const galleryBounds = this.overflowElement.getBoundingClientRect()
        const images = this.slides
        let isOverflowing = false
        for (let i = 0; i < images.length; i++) {
            const bounds = images[i].getBoundingClientRect()
            if (
                Math.round(bounds.x) < Math.round(galleryBounds.x) ||
                Math.round(bounds.x + bounds.width) -
                    Math.round(galleryBounds.x + galleryBounds.width) >
                    10
            ) {
                isOverflowing = true
                break
            }
        }
        const innerWidth = this.element.scrollWidth
        const numberOfSlides = Math.ceil(innerWidth / galleryBounds.width)
        if (numberOfSlides !== this.numberOfSlides) {
            this.numberOfSlides = numberOfSlides
            this.emit('numberOfSlides', numberOfSlides)
        }
        const innerBounds = this.element.getBoundingClientRect()
        const currentOffset = innerBounds.x - galleryBounds.x
        const minLeft = -1 * (this.element.scrollWidth - galleryBounds.width) // Right edge of inner wrapper
        const maxLeft = 0 // Left edge of inner wrapper
        const controlsValue = this.options.controls

        this.overflowElement.style.setProperty(`--${this.key}-slides`, `${numberOfSlides}`)
        this.overflowElement.setAttribute(
            `data-${this.key}-has-slides`,
            isOverflowing ? 'true' : 'false',
        )
        this.overflowElement.setAttribute(`data-${this.key}-controls`, controlsValue.toString())
        if (this.isOverflowing !== isOverflowing) {
            this.isOverflowing = isOverflowing
            this.emit('isOverflowing', this.isOverflowing)
        }
        this.isOverflowing = isOverflowing
        this.overflowElement.setAttribute(
            `data-${this.key}-prev`,
            currentOffset >= maxLeft ? 'false' : 'true',
        )
        this.overflowElement.setAttribute(
            `data-${this.key}-next`,
            currentOffset <= minLeft ? 'false' : 'true',
        )

        this.#addControlsIfNeeded()
    }

    #addControlsIfNeeded() {
        if (this.hasControls || !this.isOverflowing || !this.options.controls) {
            return
        }
        const html = `<div class="${this.key}__controls">
                            <span aria-label="Previous" role="button" class="${this.key}__controls__prev">‹</span>
                            <span aria-label="Next" role="button" class="${this.key}__controls__next">›</span>
                        </div>`
        this.hasControls = true
        const w = document.createElement('div')
        w.innerHTML = html
        const controls = w.firstElementChild
        this.overflowElement.appendChild(controls)
        // console.log(gallery, gallery?.overflowElement);
        controls.querySelector(`.${this.key}__controls__prev`).addEventListener('click', () => {
            this.step(-1)
        })
        controls.querySelector(`.${this.key}__controls__next`).addEventListener('click', () => {
            this.step(1)
        })
    }
}

export default Gallery
