import { nextTick } from 'vue'

const beforeMount = function (el, binding) {
    el.autoExpand = function () {
        // Reset field height
        this.style.height = 'auto'

        // Get the computed styles for the element
        let computed = window.getComputedStyle(this)

        // Set a minimum height equal to its original height
        this.style.minHeight = computed.getPropertyValue('height')

        let lineHeight = parseFloat(computed.getPropertyValue('line-height'))
        if (isNaN(lineHeight)) {
            lineHeight = parseFloat(computed.getPropertyValue('font-size')) * 1.2
        }
        // Calculate the height
        let height =
            parseFloat(computed.getPropertyValue('border-top-width') || 0) +
            Math.max(
                this.scrollHeight,
                lineHeight +
                    parseFloat(computed.getPropertyValue('padding-top') || 0) +
                    parseFloat(computed.getPropertyValue('padding-bottom') || 0),
            ) +
            parseFloat(computed.getPropertyValue('border-bottom-width') || 0)

        this.style.height = height + 'px'
        return height
    }
    window.addEventListener('resize', el.autoExpand.bind(el))
    el.addEventListener('input', el.autoExpand)
    el.unbindAutoHeight = function () {
        el.removeEventListener('input', el.autoExpand)
        window.removeEventListener('resize', el.autoExpand)
    }

    let options = {
        init: true,
    }

    if (binding.value) {
        options = { ...options, ...binding.value }
    }
    el.autoExpandOptions = options
}

const updated = function (el) {
    if (el.autoExpandOptions && el.autoExpandOptions.init) {
        el.autoExpand()
    }
}

const mounted = function (el) {
    if (el.autoExpandOptions && el.autoExpandOptions.init) {
        nextTick(() => {
            el.autoExpand()
        })
    }
}

const unmounted = function (el) {
    if (el.unbindAutoHeight) {
        el.unbindAutoHeight()
    }
}

const directive = {
    beforeMount,
    updated,
    mounted,
    unmounted,
}

export default directive
