<template>
    <input
        type="range"
        :min="min"
        :max="max"
        :step="step"
        v-model="currentValue"
        @change="change"
    />
</template>
<script>
export default {
    emits: ['update:modelValue'],
    props: {
        values: Array,
        steps: Array,
        modelValue: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    computed: {
        currentValue: {
            get() {
                if (!this.modelValue) {
                    return this.min
                }
                return this.getClosestStep(this.modelValue)
            },
            set(newVal) {
                this.$emit('update:modelValue', this.actualValues[parseInt(newVal)])
            },
        },
        step() {
            return 1
        },
        min() {
            return 0
        },
        max() {
            return this.actualValues.length - 1
        },
        actualValues() {
            const values = []
            for (let i = 0; i < this.steps.length; i++) {
                const from = this.values[i]
                const to = this.values[i + 1]
                const step = this.steps[i]
                const numberOfSteps = Math.ceil((to - from) / this.steps[i])
                for (let j = 0; j < numberOfSteps; j++) {
                    values.push(from + j * step)
                }
            }
            values.push(this.values[this.values.length - 1])
            return values
        },
    },
    watch: {
        values() {
            this.validateProps()
        },
        steps() {
            this.validateProps()
        },
    },
    mounted() {
        this.validateProps()
    },
    methods: {
        getClosestStep(value) {
            let closest = null
            for (let i = 0; i < this.actualValues.length; i++) {
                if (closest === null || this.actualValues[i] <= value) {
                    closest = i
                } else if (this.actualValues[i] > value) {
                    return closest
                }
            }
            return closest !== null ? closest : this.min
        },
        validateProps() {
            if (this.steps.length !== this.values.length - 1) {
                throw Error('Steps must have exactly one less value than Values.')
            }
        },
        change(event) {
            this.$emit('change', this.actualValues[parseInt(this.currentValue)])
        },
    },
}
</script>
