<template>
    <div
        class="place-explorer place-explorer--loaded tw-group"
        :class="{
            ['place-explorer--' + view]: true,
            'place-explorer--loading': isLoading,
        }"
        :style="containerStyle"
        ref="container"
    >
        <ExploreMobileFilter
            v-if="isMobile && result"
            :body-offset="bodyOffset"
            :filter="filter"
            :is-loading="isLoading"
            @open-filter="openFilter"
        />
        <ExploreDesktopFilter
            v-else-if="result"
            :filter="filter"
            :params="params"
            :can-have-radius="canHaveRadius"
            :is-loading="isLoading"
            @open-filter="openFilter"
            @input="updateParamsDelayed"
        />
        <ExploreFilterModal
            v-if="isFilterOpen"
            :filter="filter"
            :params="params"
            :is-open="isFilterOpen"
            :is-mobile="isMobile"
            :group="filterGroup"
            :can-have-radius="canHaveRadius"
            @close="closeFilter"
            @input="updateParams"
        />
        <ExploreBodyWrapper
            :place-page="result"
            :sponsored-places="sponsoredPlaces"
            :page-header-height="pageHeaderHeight"
            :window-height="windowHeight"
            :is-mobile="isMobile"
            :is-loading="isLoading || isMapLoading"
            :view="view"
            :html="bodyHtml"
            :highlighted-place="highlightedPlace"
            ref="body"
            @input="updateParams"
            @click="bodyClick"
            @dragdown="setView('map', 'dragdown')"
            @dragup="setView('hybrid', 'dragup')"
            @base-offset-change="bodyOffset = $event"
            @step-page="stepPage"
            @tab-change="scrollToTop"
            @highlight-place="highlightedPlace = $event"
            @go-to-user-position="goToUserPosition"
            @set-location="setLocation"
        >
            <slot />
        </ExploreBodyWrapper>
        <ExploreMap
            v-if="result"
            :place-page="result"
            :sponsored-places="sponsoredPlaces"
            :style="mapStyle"
            :padding="mapPadding"
            :is-loading="isLoading"
            :is-ready="!!windowHeight"
            :is-mobile="isMobile"
            :params="params"
            :with-list="isMobile && view === 'map'"
            :highlighted-place="highlightedPlace"
            :is-expanded="view === 'map'"
            :with-controls="!isMobile || view === 'map'"
            :body-offset="bodyOffset"
            :page-header-height="pageHeaderHeight"
            :require-activation="isMobile || !withMap"
            ref="map"
            @expand="setView('map', 'expand')"
            @contract="setView('hybrid', 'contract')"
            @click="mapClick"
            @marker-click="mapMarkerClick"
            @marker-mouseenter="mapMarkerMouseEnter"
            @marker-mouseleave="mapMarkerMouseLeave"
            @active-place-change="highlightedPlace = $event"
            @input="setLocation"
            @next="stepPage(1)"
            @prev="stepPage(-1)"
            @loading="isMapLoading = $event"
        />
    </div>
</template>
<script>
import ExploreMap from './ExploreMap.vue'
import ExploreMobileFilter from './ExploreMobileFilter.vue'
import http from '@utils/http'
import { debounce } from 'lodash-es'
import axios from 'axios'

const CancelToken = axios.CancelToken
let cancelRequest = null
import viewport from '../../../es6/src/utils/Viewport'
import ExploreBodyWrapper from './ExploreBodyWrapper.vue'
import ExploreDesktopFilter from './ExploreDesktopFilter.vue'
import ExploreFilterModal from './ExploreFilterModal.vue'
import { toRaw } from 'vue'
import { useExploreStore } from '../../../store/explore.js'
import { useSearchAutocompleteStore } from '../../../store/searchAutocomplete.js'
import { headerHeight } from '../../../es6/src/modules/header/header.js'
import { pushState, replaceState } from '../../../es6/src/utils/history.js'

let scrollArea = window
let scrollPositions = window.history?.state?.scrollPositions || []

const EMPTY_LOCATION_PARAMS = {
    'close-to': null,
    lat: null,
    ll: null,
    lng: null,
    city: null,
    district: null,
    gid: null,
    radius: null,
}

export default {
    components: {
        ExploreFilterModal,
        ExploreDesktopFilter,
        ExploreBodyWrapper,
        ExploreMobileFilter,
        ExploreMap,
    },
    props: {
        renderedData: {
            type: Object,
            required: false,
            default: null,
        },
        withMap: Boolean,
    },
    setup() {
        const exploreStore = useExploreStore()
        const searchAutocompleteStore = useSearchAutocompleteStore()

        return { exploreStore, searchAutocompleteStore }
    },
    data() {
        return {
            result: this.renderedData ? this.renderedData : null,
            hasHeightSet: false,
            view: 'hybrid',

            baseUrl: null,

            bodyOffset: 0,
            bodyHtml: null,

            windowHeight: viewport.height,
            windowWidth: viewport.width,
            pageHeaderHeight: 0,

            params: {},
            filter: {},

            isLoading: false,
            isMapLoading: false,

            isFilterOpen: false,
            filterGroup: null,

            pageIndex: window?.history?.state?.index || 0,

            highlightedPlace: null,

            isAtUserLocation: false,
        }
    },
    computed: {
        autocompleteLocationTerm() {
            return this.searchAutocompleteStore.locationTerm
        },
        autocompleteLocationData() {
            return this.searchAutocompleteStore.locationData
        },
        autocompleteSelected() {
            return this.searchAutocompleteStore.selected
        },
        containerStyle() {
            if (this.isMobile) {
                return {}
            }
            return {
                height: `${this.windowHeight - this.pageHeaderHeight}px`,
            }
        },
        mapStyle() {
            if (!this.isMobile) {
                return {}
            }
            return {
                top: `${this.pageHeaderHeight}px`,
            }
        },
        mapPadding() {
            let padding = {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            }
            if (this.isMobile) {
                // Create space for overlays
                padding.bottom = this.windowHeight - this.bodyOffset - this.pageHeaderHeight
            }
            return padding
        },
        isMobile() {
            return this.windowWidth && this.windowWidth < 992
        },
        locationParams() {
            return Object.keys(EMPTY_LOCATION_PARAMS).reduce((a, i) => {
                a[i] = this.params[i]
                return a
            }, {})
        },
        canHaveRadius() {
            return !!(this.result?.geo?.lat && this.result?.geo?.lng)
        },
        sponsoredPlaces() {
            return this.result?.sponsored_places || []
        },
    },
    watch: {
        result: {
            handler(newVal) {
                if (!newVal) {
                    return
                }
                this.params = { ...newVal.params }
                this.baseUrl = newVal.base_url
                if (newVal && 'filter' in newVal) {
                    this.filter = { ...this.filter, ...newVal.filter }
                    this.searchAutocompleteStore.setTerm(this.filter.term)
                    this.searchAutocompleteStore.setLocationTerm(this.filter.location)
                    const filteredData = this.filter.locationData
                    for (const key in filteredData) {
                        if (filteredData[key] === null) {
                            delete filteredData[key]
                        }
                    }
                    this.searchAutocompleteStore.setLocationData(filteredData)
                }
                document.querySelector('title').innerText = newVal.title
                document.querySelector('meta[name=robots]').content =
                    'follow,' + (newVal.should_be_indexed ? 'index' : 'noindex')
                document.querySelector('meta[name=description]').content = newVal.description
            },
            immediate: true,
        },
        isMobile: {
            handler(newVal) {
                this.updateScrollArea()
            },
            immediate: true,
        },
        autocompleteLocationTerm(newVal) {
            this.filter.location = newVal
        },
        autocompleteLocationData(newVal) {
            if (newVal && newVal !== this.filter.locationData) {
                this.setLocation(newVal)
            }
        },
        autocompleteSelected(newVal) {
            if (newVal.type !== 'explore') {
                return
            }
            // The autocomplete has a new selected result so we request its url
            // and only apply the location params
            this.getResult(newVal.url, {
                ...this.locationParams,
                withCategoryFilter: true,
            })
        },
        view(newVal) {
            if (newVal === 'map') {
                this.scrollToTop()
            }
            if (!this.isMobile) {
                this.$nextTick(() => {
                    this.$refs.map.resize()
                })
            }
        },
    },
    mounted() {
        this.setActive()
        // If we don't have a rendered result, make a request for it
        // This could happen if a GET request reaches the scraping limit
        if (!this.result) {
            this.getResult(window.location.href, {
                withCategoryFilter: true,
            })
        } else {
            this.$nextTick(function () {
                this.restoreScrollPosition()
            })
            replaceState(
                {
                    result: toRaw(this.result),
                    view: this.$refs.body.$el.innerHTML,
                    index: this.pageIndex,
                },
                this.result.title,
                this.result.url,
            )
        }

        this.onViewportChange(viewport)
        this.bindEvents()

        this.$watch(
            () => this.$refs.body,
            () => this.updateScrollArea(),
            { immediate: true },
        )
    },
    destroyed() {
        this.unbindEvents()
        this.setInactive()
    },
    methods: {
        bindEvents() {
            window.addEventListener('popstate', this.onPopState)
            window.addEventListener('beforeunload', this.onBeforeUnload)
            viewport.on('change', this.onViewportChange)
        },

        unbindEvents() {
            window.removeEventListener('popstate', this.onPopState)
            //window.removeEventListener('beforeunload', this.onBeforeUnload)
            viewport.off('change', this.onViewportChange)
        },

        onBeforeUnload() {
            this.saveScrollPosition()
            replaceState(
                {
                    ...window.history.state,
                    scrollPositions: scrollPositions,
                },
                window.document.title,
                window.location.href,
            )
        },

        onPopState(e) {
            if (!e.state?.result) {
                return
            }
            this.saveScrollPosition()
            this.bodyHtml = e.state.view
            this.result = { ...e.state.result }

            if (e.state.index !== undefined) {
                this.pageIndex = e.state.index
                this.$nextTick(function () {
                    this.restoreScrollPosition()
                })
            }
        },

        setView(view, source = null) {
            this.view = view
        },

        debounceGetResult: debounce(function (url, params) {
            return this.getResult(url, params)
        }, 300),

        async makeRequest(url, params) {
            if (cancelRequest) {
                cancelRequest()
            }
            const { data } = await http.post(url, params, {
                cancelToken: new CancelToken((c) => (cancelRequest = c)),
            })
            return data.data
        },

        async getResult(url, params) {
            const isFirstResult = !this.result
            this.isLoading = true
            if (!isFirstResult) {
                this.saveScrollPosition()
                this.pageIndex++
            }
            this.scrollTo(0, 0, 'auto')
            let data = null
            try {
                data = await this.makeRequest(url, params)
            } catch (e) {
                if (!axios.isCancel(e)) {
                    console.error(e)
                    this.hasError = true
                }
                return
            }
            this.bodyHtml = data.view
            this.result = { ...data.result }
            this.isLoading = false

            const historyMethod = isFirstResult ? replaceState : pushState

            historyMethod(
                {
                    ...data,
                    index: this.pageIndex,
                },
                data.result.title,
                data.result.url,
            )
        },
        onViewportChange(viewport) {
            this.windowHeight = viewport.height
            this.windowWidth = viewport.width
            this.pageHeaderHeight = headerHeight(false, true)
        },
        saveScrollPosition() {
            if (!this.isMobile) {
                scrollPositions[this.pageIndex] = scrollArea.scrollTop
            }
        },
        restoreScrollPosition() {
            if (!this.isMobile) {
                this.scrollTo(scrollPositions[this.pageIndex] || 0, 0, 'instant')
            }
        },
        bodyClick() {
            if (this.isMobile && this.view === 'map') {
                this.view = 'hybrid'
            }
        },
        stepPage(step = 1) {
            return this.updateParams(
                'page',
                Math.min(
                    this.result.last_page,
                    Math.max(1, (this.result.current_page || 1) + step),
                ),
                true,
            )
        },
        setLocation(data, immediate = false) {
            const params = {
                ...EMPTY_LOCATION_PARAMS,
                ...data,
            }
            if (params.gid) {
                params.lat = undefined
                params.lng = undefined
            }
            return this.updateParams(params, immediate)
        },
        updateParams(key, value = null, immediate = true) {
            let withCategoryFilter = false
            if (typeof key === 'object') {
                withCategoryFilter = key.category !== this.params.category
                this.params = {
                    ...this.params,
                    ...key,
                }
                immediate = !!value
            } else {
                withCategoryFilter = key === 'category' && value !== this.params.category
                this.params[key] = value
            }
            if (immediate) {
                this.getResult(this.baseUrl, {
                    ...this.params,
                    withCategoryFilter: withCategoryFilter ? true : undefined,
                })
            } else {
                this.debounceGetResult(this.baseUrl, {
                    ...this.params,
                    withCategoryFilter: withCategoryFilter ? true : undefined,
                })
            }
        },

        updateParamsDelayed(key, value = null) {
            if (typeof key === 'object') {
                value = false
            }
            return this.updateParams(key, value, false)
        },

        mapClick(e) {
            if (this.isMobile) {
                this.setView('map')
            }
        },

        mapMarkerClick(marker) {
            if (this.isMobile) {
                this.setView('map')
            } else {
                // this.highlightedPlace = this.result.data.find((p) => p.id === marker.id);
            }
        },

        mapMarkerMouseEnter(marker) {
            if (!this.isMobile) {
                const elm = scrollArea.querySelector(`.place-card[data-id="${marker.id}"]`)
                if (elm) {
                    elm.classList.add('place-card--highlight')
                }
            }
        },

        mapMarkerMouseLeave(marker) {
            if (!this.isMobile && marker.id !== this.highlightedPlace?.id) {
                const elm = scrollArea.querySelector(`.place-card[data-id="${marker.id}"]`)
                if (elm) {
                    elm.classList.remove('place-card--highlight')
                }
            }
        },

        scrollToTop() {
            return this.scrollTo(0, 0)
        },

        scrollTo(top = 0, left = 0, behavior = 'smooth') {
            scrollArea.scrollTo({
                top,
                left,
                behavior,
            })
        },

        openFilter(group = null) {
            this.filterGroup = group
            this.isFilterOpen = true
        },

        closeFilter() {
            this.isFilterOpen = false
            this.filterGroup = null
        },

        goToUserPosition() {
            return this.$refs.map?.goToUserPosition()
        },

        updateScrollArea() {
            if (this.isMobile || !this.$refs.body?.$el) {
                scrollArea = window
            } else {
                scrollArea = this.$refs.body?.$el
            }
        },

        setActive() {
            this.exploreStore.active()
        },

        setInactive() {
            this.exploreStore.inactive()
        },
    },
}
</script>
