export const overlayText = (overlayEl, height = null, stateKey = null) => {
    if (!overlayEl) return

    let overlayHeight = overlayEl.offsetHeight
    if (height === null) {
        height = overlayEl.offsetHeight
        overlayHeight = overlayEl.scrollHeight
    } else if (typeof height === 'string' && height.match(/[a-z]+$/)) {
        overlayEl.style.maxHeight = height
        height = overlayEl.offsetHeight
        overlayHeight = overlayEl.scrollHeight
    } else {
        height = parseInt(height)
    }

    let expandedDueToState = false
    if (stateKey !== null) {
        expandedDueToState = window.sessionStorage?.getItem(stateKey) === 'true'
    }

    if (!expandedDueToState && overlayHeight > height + 40) {
        overlayEl.classList.add('text-fade-overlay')
        overlayEl.style.maxHeight = height + 'px'
        overlayEl.style.setProperty('--text-fade-height', height + 'px')
        overlayEl.setAttribute('aria-expanded', 'false')

        // const arrowEl = h(
        //     '<a class="fade-overlay-arrow"><i class="icon icon--chevron"></i></a>'
        // );

        // overlayEl.append(arrowEl);

        let clickEvent = function (e) {
            e.preventDefault()
            this.classList.remove('text-fade-overlay')
            overlayEl.style.maxHeight = 'none'
            overlayEl.setAttribute('aria-expanded', 'true')
            // arrowEl.parentNode.removeChild(arrowEl);
            overlayEl.removeEventListener('click', clickEvent)
            if (stateKey !== null) {
                window.sessionStorage?.setItem(stateKey, 'true')
            }
        }

        overlayEl.addEventListener('click', clickEvent)
    } else {
        overlayEl.style.maxHeight = 'none'
    }
}

export const overlayScroll = (overlayEl, fadeSize = '20%') => {
    overlayEl.setAttribute('data-has-scroll-overlay', 'true')
    const fadeSizes = fadeSize.split(' ')
    while (fadeSizes.length < 4) {
        fadeSizes.push(fadeSizes[Math.min(fadeSizes.length - 1, fadeSizes.length % 2)])
    }
    ;['x-start', 'x-end', 'y-start', 'y-end'].forEach((direction, i) => {
        overlayEl.style.setProperty(`--scroll-fade-size-${direction}`, fadeSizes[i])
    })
    const onScroll = (e) => {
        if (e.target.scrollWidth <= e.target.offsetWidth) {
            overlayEl.removeAttribute('data-scroll-x-at-start')
            overlayEl.removeAttribute('data-scroll-x-at-end')
            overlayEl.removeAttribute('data-scroll-x')
            overlayEl.style.setProperty('--scroll-x-progress', 0)
        } else {
            overlayEl.setAttribute('data-scroll-x', 'true')
            if (e.target.scrollLeft === 0) {
                overlayEl.setAttribute('data-scroll-x-at-start', 'true')
            } else {
                overlayEl.removeAttribute('data-scroll-x-at-start')
            }
            if (e.target.scrollLeft + e.target.offsetWidth >= e.target.scrollWidth) {
                overlayEl.setAttribute('data-scroll-x-at-end', 'true')
            } else {
                overlayEl.removeAttribute('data-scroll-x-at-end')
            }
            overlayEl.style.setProperty(
                '--scroll-x-progress',
                (e.target.scrollLeft / (e.target.scrollWidth - e.target.offsetWidth)).toFixed(2),
            )
        }

        if (e.target.scrollHeight <= e.target.offsetHeight) {
            overlayEl.removeAttribute('data-scroll-y-at-start')
            overlayEl.removeAttribute('data-scroll-y-at-end')
            overlayEl.removeAttribute('data-scroll-y')
            overlayEl.style.setProperty('--scroll-y-progress', 0)
        } else {
            overlayEl.setAttribute('data-scroll-y', 'true')
            if (e.target.scrollTop === 0) {
                overlayEl.setAttribute('data-scroll-y-at-start', 'true')
            } else {
                overlayEl.removeAttribute('data-scroll-y-at-start')
            }
            if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
                overlayEl.setAttribute('data-scroll-y-at-end', 'true')
            } else {
                overlayEl.removeAttribute('data-scroll-y-at-end')
            }
            overlayEl.style.setProperty(
                '--scroll-y-progress',
                (e.target.scrollTop / (e.target.scrollHeight - e.target.offsetHeight)).toFixed(2),
            )
        }
    }
    onScroll({ target: overlayEl })
    overlayEl.addEventListener('scroll', onScroll)

    const resizeObserver = new ResizeObserver(() => {
        window.requestAnimationFrame(() => onScroll({ target: overlayEl }))
    })
    resizeObserver.observe(overlayEl)

    const mutationObserver = new MutationObserver(() => {
        window.requestAnimationFrame(() => onScroll({ target: overlayEl }))
    })
    mutationObserver.observe(overlayEl, {
        childList: true,
        subtree: true,
        attributes: true,
        characterData: true,
        attributeFilter: ['class'],
    })
}
