<template>
    <li class="place-explorer__pagination pagination mb-4" v-if="hasPrev || hasNext">
        <button
            rel="prev"
            class="pagination__button"
            :class="hasPrev ? null : 'pagination__button--disabled'"
            :disabled="!hasPrev"
            @click="hasPrev ? $emit('prev') : null"
        >
            <i class="icon icon--chevron-right white rotate-180"></i>
        </button>
        <span class="place-list__paging--label" v-if="hasPaging">
            {{
                $t('placeList.paging_label', {
                    from: placePage.paging.offset + 1,
                    to: placePage.paging.offset + placePage.paging.count,
                    total: placePage.paging.total,
                })
            }}
        </span>
        <span
            class="place-list__paging--label"
            v-if="!hasPaging && placePage !== null && currentIndex !== null"
        >
            {{ currentIndex + 1 }} / {{ placePage.length }}
        </span>
        <button
            rel="next"
            class="pagination__button"
            :class="hasNext ? null : 'pagination__button--disabled'"
            :disabled="!hasNext"
            @click="hasNext ? $emit('next') : null"
        >
            <i class="icon icon--chevron-right white"></i>
        </button>
    </li>
</template>
<script>
export default {
    props: {
        placePage: {
            type: Object,
            required: true,
        },
        currentIndex: {
            type: Number,
            default: null,
        },
    },

    computed: {
        hasPaging() {
            return this.placePage.paging
        },
        from() {
            return this.placePage.paging.offset + 1
        },
        to() {
            return this.placePage.paging.offset + this.placePage.paging.count
        },
        total() {
            return this.placePage.paging.total
        },
        hasNext() {
            if (!this.hasPaging) {
                return this.currentIndex < this.placePage.length - 1
            }

            return (
                this.placePage.paging.offset + this.placePage.paging.count <
                this.placePage.paging.total
            )
        },
        hasPrev() {
            if (!this.hasPaging) {
                return this.currentIndex > 0
            }

            return this.placePage.paging.offset > 0
        },
    },
}
</script>
