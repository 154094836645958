import { trackEvent } from '../tracking/tracking.js'
import { useUserStore } from '../../../../store/user.js'
import { popup } from '../popup/popup.js'
import { requireOne } from '../../utils/require.js'
import http from '../../utils/http.js'
import Eventbus from '../eventbus/Eventbus.js'
import { useNavigationStore } from '../../../../store/navigation.js'
import { setCookie } from '@utils/cookie.js'

export function auth(callback = null, options = {}) {
    const userStore = useUserStore()

    if (!userStore.isLoggedIn) {
        const navigationStore = useNavigationStore()
        navigationStore.isMobileUserMenuOpen = false

        authentication.callback = callback
        authentication.popup(options)
    } else if (callback instanceof Function) {
        callback({
            user: userStore.user,
            type: null,
            wasAsync: false,
        })
    }
}

Eventbus.on('auth:success', (data) => {
    const userStore = useUserStore()
    userStore.setUser(data.data.user)
    trackEvent(data.data.type, {
        category: 'auth',
        label: authentication.showOptions ? authentication.showOptions.variation : undefined,
    })

    if (authentication.callback) {
        authentication.callback({
            ...data.data,
            wasAsync: data.wasAsync,
        })
    } else if (data.wasAsync) {
        window.location.reload()
    }
})

Eventbus.on('auth:failure', (data) => {
    trackEvent('failure', {
        category: 'auth',
        label: data.error ? data.error.message : undefined,
    })
})

Eventbus.on('auth:show', (options) => {
    trackEvent('show', {
        category: 'auth',
        label: options.variation,
    })
})

export const authentication = {
    authPopup: null,
    callback: null,
    showOptions: null,
    pendingUserCode: null,
    isAsync: false,

    popup(options = {}) {
        this.authPopup = popup({
            variation: 'auth',
            content: document.querySelector('#auth-popup'),
            height: 600,
            width: 1000,
            afterClose: () => {
                Eventbus.emit('auth:popup:close')
            },
        })
        this.showOptions = options
        Eventbus.emit('auth:show', options)
    },

    closePopup() {
        if (this.authPopup) {
            this.authPopup.close()
        }
    },

    successCallback: null,
    failureCallback: null,

    authData(data = {}, flow = 'popup') {
        const defaultData = {
            callback: new Date().getTime(),
            redirect: window.location.pathname + window.location.search,
            flow: flow,
            pending: this.pendingUserCode,
        }

        return {
            ...defaultData,
            ...data,
        }
    },

    authApp(provider, flow = 'popup', data = {}) {
        const url = `/auth/app/${provider}/`
        return this.auth(url, flow, data)
    },

    connectApp(provider, flow = 'popup', data = {}) {
        const url = `/auth/app/${provider}/connect/`
        return this.auth(url, flow, data)
    },

    disconnectApp(provider) {
        const url = `/auth/app/${provider}/disconnect/`
        return this.auth(url, 'json', {})
    },

    authEmail(type = 'login', data = {}, flow = 'json') {
        const url = `/auth/email/${type}/`
        return this.auth(url, flow, data)
    },

    auth(url, flow = 'popup', data = {}) {
        data = this.authData(data, flow)

        if (flow === 'popup') {
            return this.authFlowPopup(url, data)
        } else if (flow === 'json') {
            return this.authFlowJson(url, data)
        } else {
            return this.authFlowRedirect(url, data)
        }
    },

    authFlowPopup(url, data) {
        openWindow(this.urlWithQuery(url, data), {
            height: 600,
            width: 600,
        })
        const messageReceiver = function (event) {
            const eventData = event.data || {}
            if (
                eventData.data &&
                eventData.data.data &&
                eventData.data.data.auth_callback &&
                eventData.data.data.auth_callback == data.callback
            ) {
                window.removeEventListener('message', messageReceiver, true)
                authentication.handleAuthResponse(event.data, true)
            }
        }
        window.addEventListener('message', messageReceiver, true)
    },

    authFlowJson(url, data) {
        return http.post(url, data).then((response) => {
            this.handleAuthResponse(response.data, true)
            return response
        })
    },

    authFlowRedirect(url, data) {
        return (window.location.href = this.urlWithQuery(url, data))
    },

    handleAuthResponse(response, wasAsync = false) {
        if (response.error) {
            Eventbus.emit('auth:failure', { ...response, wasAsync: wasAsync })
        } else {
            Eventbus.emit('auth:success', { ...response, wasAsync: wasAsync })
        }
    },

    urlWithQuery(url, data) {
        return url + (url.indexOf('?') === -1 ? '?' : '&') + this.dataAsQuery(data)
    },

    dataAsQuery(data) {
        const array = []
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                array.push(key + '=' + window.encodeURIComponent(data[key]))
            }
        }
        return array.filter((d) => !!d).join('&')
    },

    captcha: {
        key: import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY,

        exists() {
            return !!this.key
        },

        isInitialized: false,
        isInitializing: false,
        queue: [],

        init() {
            if (this.isInitialized) {
                return Promise.resolve()
            }
            return new Promise((resolve, reject) => {
                if (!this.isInitializing) {
                    this.isInitializing = true
                    window.onCaptchaLoad = () => {
                        this.isInitialized = true
                        this.isInitializing = false
                        this.queue.forEach((item) => item.resolve())
                        window.onCaptchaLoad = null
                    }
                    requireOne(
                        'https://www.google.com/recaptcha/api.js?onload=onCaptchaLoad',
                        () => {
                            // Wait for onLoad
                        },
                        () => {
                            this.queue.forEach((item) => item.reject())
                        },
                    )
                }
                this.queue.push({ resolve, reject })
            })
        },

        create(element) {
            let resolver = () => {}
            let rejector = () => {}
            let wasResolved = false
            let wasRejected = false
            let timeout = null

            const callback = function () {
                wasResolved = true
                if (timeout) {
                    window.clearTimeout(timeout)
                }
                resolver(...arguments)
            }
            const errorCallback = function () {
                wasRejected = true
                if (timeout) {
                    window.clearTimeout(timeout)
                }
                rejector(...arguments)
            }
            let timeoutHandler = () => {
                if (!wasRejected && !wasResolved) {
                    errorCallback('Timeout')
                }
            }

            const options = {
                sitekey: this.key,
                size: 'invisible',
                callback: callback,
                'expired-callback': errorCallback,
                'error-callback': errorCallback,
            }

            let captchaId = null

            const result = {
                id: captchaId,
                execute() {
                    return new Promise((resolve, reject) => {
                        resolver = resolve
                        rejector = reject

                        window.grecaptcha.execute(captchaId)
                        timeout = window.setTimeout(timeoutHandler, 60 * 2 * 1000)
                    })
                },
                reset() {
                    wasRejected = false
                    wasResolved = false
                    if (timeout) {
                        window.clearTimeout(timeout)
                    }
                    window.grecaptcha.reset(captchaId)
                },
            }

            this.init().then(() => {
                captchaId = window.grecaptcha.render(element, options)
                result.id = captchaId
            })

            return result
        },
    },
}

export function openWindow(url, options = {}, id = null) {
    const defaultOptions = {
        toolbar: 'no',
        directories: 'no',
        menubar: 'no',
        scrollbars: 'yes',
        resizable: 'no',
        status: 'no',
        titlebar: 'no',
        location: 'no',
        width: 600,
        height: 600,
    }

    options = {
        ...defaultOptions,
        ...options,
    }

    if (!('left' in options)) {
        options.left = (screen.width - options.width) / 2
    }

    if (!('top' in options)) {
        options.top = (screen.height - options.height) / 2
    }

    const optionArray = Object.entries(options).map(([key, value]) => `${key}=${value}`)

    id = id || new Date().getTime()

    return window.open(url, id, optionArray.join(','))
}

export const initAuth = () => {
    setCookie('auth_campaign', window.globalVars.auth.signupCampaign, 30)
    authentication.pendingUserCode = window.globalVars.auth.pendingUserCode

    let authResponse = window.globalVars.auth.authResponse

    if (!authResponse && 'sessionStorage' in window) {
        authResponse = window.sessionStorage.getItem('auth_response')
        if (authResponse) {
            authResponse = JSON.parse(authResponse)
            window.sessionStorage.removeItem('auth_response')
        }
    }

    let shouldShow = true

    if (!window.globalVars.auth.shouldShow) {
        shouldShow =
            authResponse &&
            (authResponse.data.success !== true ||
                ['forgot-password', 'signup-pending'].indexOf(authResponse.data.type) > -1)
    }

    if (authResponse && ['signup', 'login'].indexOf(authResponse.data.type) > -1) {
        authentication.handleAuthResponse(authResponse, false)
    }

    if (shouldShow) {
        auth(null, {
            authResponse: authResponse,
            user: window.globalVars.auth.authUser,
        })
    }
}
