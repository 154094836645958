<template>
    <form @submit.prevent="submitForm" class="box rounded shadow pretty-form">
        <h2 class="d-flex ai-center">
            {{ $t('user.settings.social.title') }}

            <div class="d-flex ml-auto">
                <a
                    href="/user/settings/"
                    class="flat-button flat-button--compact flat-button--gray"
                >
                    <span>{{ translate('confirm_cancel') }}</span>
                </a>
                <button
                    type="submit"
                    class="flat-button flat-button--compact flat-button--blue"
                    :class="{
                        'is-loading': isSubmitting,
                    }"
                    :title="translate('save')"
                >
                    <span>{{ translate('save') }}</span>
                </button>
            </div>
        </h2>

        <div class="content medium">
            <div class="form-group">
                <div class="items form-group">
                    <div
                        class="item form-group clearfix d-flex"
                        v-for="(item, index) in selectedItems"
                        :key="index"
                    >
                        <div class="input-group">
                            <select v-model="item.type" required :disabled="item.disabled">
                                <option value="">
                                    {{ $t('user.settings.social.choose_link_type') }}
                                </option>
                                <option
                                    v-for="option in linkTypeOptions"
                                    :disabled="selectedItems.some((i) => i.type === option.type)"
                                    :value="option.type"
                                >
                                    {{ option.label }}
                                </option>
                            </select>
                            <input
                                type="text"
                                class="full-width"
                                v-model="item.link"
                                :disabled="item.disabled"
                                required
                            />
                        </div>
                        <a
                            class="js-remove-item"
                            style="line-height: 49px; padding: 0 10px"
                            @click="removeItem(index)"
                        >
                            <span class="icon--close-x-small blue"></span>
                        </a>
                    </div>
                </div>
                <button class="tw-text-blue-600" @click="addItem">
                    + {{ $t('common.add_link') }}
                </button>
            </div>

            <div class="form-group text-right">
                <a href="/user/settings/" class="flat-button flat-button--gray">
                    <span>{{ translate('confirm_cancel') }}</span>
                </a>
                <button
                    type="submit"
                    class="flat-button flat-button--blue submit-button"
                    :class="{
                        'is-loading': isSubmitting,
                    }"
                    :title="translate('save')"
                >
                    <span>{{ translate('save') }}</span>
                </button>
            </div>
        </div>
    </form>
</template>

<script setup>
import { ref, computed } from 'vue'
import { translate } from '../../../es6/src/modules/locale/locale.js'
import { useUserStore } from '../../../store/user.js'
import http from '../../Shared/util/http.js'

const userStore = useUserStore()
const user = userStore.user

const linkTypeOptions = [
    {
        type: 'instagram',
        label: 'Instagram',
    },
    {
        type: 'facebook',
        label: 'Facebook',
    },
    {
        type: 'twitter',
        label: 'Twitter',
    },
]

if (user.rankTotal >= 100) {
    linkTypeOptions.push(
        {
            type: 'blog',
            label: 'Blog',
        },
        {
            type: 'website',
            label: 'Website',
        },
    )
}

const props = defineProps({
    userLinks: {
        type: Array,
        required: true,
    },
})

const selectedItems = ref([...props.userLinks])

function addItem() {
    // Check if selectedItem already contains all types
    if (selectedItems.value.length >= linkTypeOptions.length) {
        return
    }

    selectedItems.value.push({
        type: '',
        link: '',
        disabled: false,
    })
}

function removeItem(index) {
    selectedItems.value.splice(index, 1)
}

const isSubmitting = ref(false)
async function submitForm() {
    let links = selectedItems.value

    // Convert links to an object
    links = links.reduce((acc, item) => {
        if (item.type && item.link) {
            acc[item.type] = item.link
        }
        return acc
    }, {})

    isSubmitting.value = true

    await http.put('/user/settings/', {
        links: links,
        form: 'social-settings',
    })

    isSubmitting.value = false

    window.location.href = '/user/settings/'
}
</script>
