<template>
    <div>
        <header class="navigation-header">
            <a v-if="placeList.id" role="button" @click="cancel" class="d-flex ai-center">
                <i class="icon icon--chevron rotate-90"></i>
                <span>{{ $t('placeList.cancel') }}</span>
            </a>
            <span v-else></span>
            <span v-if="!placeList.id">{{ $t('placeList.list_create') }}</span>
            <a v-if="canSave" role="button" @click="save">
                {{ $t('placeList.save') }}
            </a>
            <span v-else></span>
        </header>
        <div class="place-list__content overlay-scroll-touch">
            <form class="place-list__edit-form pretty-form" @submit.prevent="save">
                <h2 class="text-xl bold mb-2" v-if="placeList.id">
                    {{ $t('placeList.list_edit') }}
                </h2>
                <template v-if="placeList.type === 'user-place-list'">
                    <div class="form-group">
                        <label class="input-label">
                            {{ $t('placeList.edit.name_label') }}
                        </label>
                        <input
                            type="text"
                            v-model="editedPlaceList.name"
                            class="full-width required"
                            @input="change"
                            required
                        />
                        <p
                            v-if="errors['placeList.name'] && errors['placeList.name'][0]"
                            class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600"
                        >
                            {{ errors['placeList.name'][0] }}
                        </p>
                    </div>
                    <div class="form-group">
                        <label class="input-label">
                            {{ $t('placeList.edit.description_label') }}
                        </label>
                        <textarea
                            v-model="editedPlaceList.description"
                            class="full-width"
                            @input="change"
                        ></textarea>

                        <p
                            v-if="
                                errors['placeList.description'] &&
                                errors['placeList.description'][0]
                            "
                            class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600"
                        >
                            {{ errors['placeList.description'][0] }}
                        </p>

                        <div class="form-group inline">
                            <label class="input-label text-sm form-group inline">
                                {{ $t('placeList.edit.public_label') }}

                                <i
                                    :data-tooltip="$t('placeList.tooltip.public_switch')"
                                    class="icon--question-mark ml-1 icon--x18"
                                ></i>
                            </label>
                            <label class="switch">
                                <input
                                    v-model="editedPlaceList.public"
                                    type="checkbox"
                                    name="public"
                                />
                                <span class="label"></span>
                            </label>
                        </div>
                    </div>
                    <h2 class="text-xl bold mt-4 mb-2">
                        {{ $t('placeList.list_users') }}
                    </h2>
                    <div class="form-group">
                        <ol class="place-list__owner-list item-list">
                            <li
                                v-for="item in editedPlaceList.owners"
                                class="d-flex jc-space-between ai-center item item--no-border py-1"
                            >
                                <strong class="d-flex ai-center">
                                    <span class="user artwork x40 circle mr-2">
                                        <img :src="item.thumbnail" :alt="item.name" />
                                    </span>
                                    <span>{{ item.name }}</span>
                                </strong>
                                <strong>
                                    <i
                                        v-if="item.isRemoved"
                                        role="button"
                                        @click="restoreUser(item)"
                                        class="icon--undo"
                                    ></i>
                                    <a
                                        v-else-if="!userIsCreator(item) && !userIsMe(item)"
                                        role="button"
                                        @click="removeUser(item)"
                                    >
                                        {{ $t('placeList.remove_owner') }}
                                    </a>
                                    <a
                                        v-else-if="!userIsCreator(item)"
                                        role="button"
                                        @click="leaveList(item)"
                                    >
                                        {{ $t('placeList.remove_owner_self') }}
                                    </a>
                                    <span v-else class="subtle">
                                        <span v-if="dateAddedString" class="d-hidden-lg-down">
                                            {{
                                                $t('placeList.creator_create_date', {
                                                    dateAdded: dateAddedString,
                                                })
                                            }}
                                        </span>
                                        <span class="d-hidden-lg-up">
                                            {{ $t('placeList.creator') }}
                                        </span>
                                    </span>
                                </strong>
                            </li>
                            <li class="item item--no-border py-1">
                                <strong
                                    v-show="!showUserSearch"
                                    role="button"
                                    class="d-flex ai-center"
                                    @click="inviteUser"
                                >
                                    <i
                                        class="icon--plus-circle dark-slate-blue mr-2"
                                        style="width: 40px; height: 40px"
                                    ></i>
                                    <span>
                                        {{ $t('placeList.invite_user') }}
                                    </span>
                                </strong>
                                <BaseAutocomplete
                                    v-show="showUserSearch"
                                    :async="true"
                                    :items="userSearchResult"
                                    :clear-on-select="true"
                                    :placeholder="$t('placeList.user_search_placeholder')"
                                    ref="userSearch"
                                    @change="searchUser"
                                    @select="addSearchUser"
                                    @blur="blurSearchUser"
                                >
                                    <template v-slot:item="{ item, index }">
                                        <span class="d-flex ai-center">
                                            <span class="user artwork x40 circle mr-2">
                                                <img :src="item.thumbnail" alt="item.name" />
                                            </span>
                                            <span>{{ item.name }}</span>
                                        </span>
                                    </template>
                                </BaseAutocomplete>
                            </li>
                        </ol>
                    </div>
                </template>
                <h2 class="text-xl bold mt-4 mb-2">
                    {{ $t('placeList.list_add_remove') }}
                </h2>
                <BaseAutocomplete
                    :async="true"
                    :items="placeSearchResult"
                    :clear-on-select="true"
                    :placeholder="$t('placeList.place_search_placeholder')"
                    :required="listIsEmpty"
                    @change="searchPlace"
                    @select="addSearchPlace"
                >
                    <template v-slot:item="{ item, index }">
                        <div class="tw-flex tw-items-center">
                            <div
                                class="icon category blue circle small mr-2"
                                :class="[item.icon]"
                            ></div>
                            <div>
                                <strong>{{ item.name }}</strong>
                                <br />
                                <small>
                                    {{
                                        [item.subtitle, item.cityName].filter((s) => !!s).join(', ')
                                    }}
                                </small>
                            </div>
                        </div>
                    </template>
                </BaseAutocomplete>
            </form>
            <SortableList
                v-if="!isLoadingPlaces && editedPlacePage && placeList.type === 'user-place-list'"
                tag="ol"
                item-tag="li"
                v-model:model-value="editedPlacePage.content"
                item-key="id"
                class="place-list__edit-list item-list"
                handle=".draggable-handle"
                :delay="0"
                :touch-delay="150"
                @change="updatePlaceOrder"
            >
                <template #item="{ item: place, isDragging }">
                    <div
                        :key="place.id"
                        class="item"
                        :class="{
                            'item--removed': place.isRemoved,
                            'item--expanded': place.id === expandedPlaceId,
                            'item--dragging': isDragging,
                        }"
                    >
                        <div class="d-flex jc-space-between ai-center disable-select text-md">
                            <span class="d-flex ai-center draggable-handle cursor-move">
                                <i class="icon--move-handle black mr-2"></i>
                                <strong>{{ place.name }}</strong>
                                <span v-if="place.closed" class="subtle ml-1">
                                    ({{ $t('placeList.place_closed') }})
                                </span>
                            </span>
                            <span class="mr-1 ml-auto full">
                                <i
                                    @click="togglePlace(place.id)"
                                    role="button"
                                    class="sli sli-pencil-write sli-outlined"
                                    :class="{ 'sli-blue': place.notes }"
                                ></i>
                            </span>
                            <span>
                                <i
                                    v-if="!place.isRemoved"
                                    role="button"
                                    @click="removePlace(place)"
                                    class="icon--remove-bin"
                                ></i>
                                <i
                                    v-else
                                    role="button"
                                    @click="restorePlace(place)"
                                    class="icon--undo"
                                ></i>
                            </span>
                        </div>

                        <div class="pretty-form mt-2" v-if="expandedPlaceId.includes(place.id)">
                            <label>
                                <textarea
                                    :value="place.notes"
                                    @input="editPlace($event.target.value, place, 'notes')"
                                    class="small w-full"
                                    :placeholder="$t('placeList.notes_input_placeholder')"
                                ></textarea>
                            </label>
                            <!--						<button class="flat-button flat-button&#45;&#45;blue flat-button&#45;&#45;small flat-button&#45;&#45;full">-->
                            <!--							Spara-->
                            <!--						</button>-->
                        </div>
                    </div>
                </template>
            </SortableList>
            <ol
                v-else-if="!isLoadingPlaces && editedPlacePage"
                class="place-list__edit-list item-list"
            >
                <li
                    v-for="(place, index) in editedPlacePage.content"
                    :key="place.id"
                    class="item d-flex jc-space-between ai-center"
                    :class="place.isRemoved ? 'item--removed' : null"
                >
                    <span class="d-flex ai-center">
                        <strong>{{ place.name }}</strong>
                        <span v-if="place.closed" class="subtle ml-1">
                            ({{ $t('placeList.place_closed') }})
                        </span>
                    </span>
                    <span>
                        <i
                            v-if="!place.isRemoved"
                            role="button"
                            @click="removePlace(place)"
                            class="icon--remove-bin"
                        ></i>
                        <i v-else role="button" @click="restorePlace(place)" class="icon--undo"></i>
                    </span>
                </li>
                <PlaceListPaging
                    v-if="editedPlacePage && !isLoadingPlaces"
                    :place-page="editedPlacePage"
                    @next="nextPage"
                    @prev="prevPage"
                />
            </ol>
            <ol v-if="isLoadingPlaces">
                <PlaceListItemSkeleton />
                <PlaceListItemSkeleton />
                <PlaceListItemSkeleton />
                <PlaceListItemSkeleton />
                <PlaceListItemSkeleton />
            </ol>
            <div class="place-list__edit-form text-center">
                <a
                    v-if="canSave"
                    role="button"
                    class="flat-button flat-button--blue flat-button--full mt-3"
                    @click="save"
                >
                    {{ $t('placeList.save') }}
                </a>
            </div>
            <div
                v-if="placeList.id && !isLoadingPlaces && placeList.isCreator"
                class="text-center form-group mt-4"
            >
                <span role="button" class="text-small subtle" @click="deleteList">
                    {{ $t('placeList.delete_list') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import cloneDeep from 'lodash-es/cloneDeep'
import isEqual from 'lodash-es/isEqual'
import xor from 'lodash-es/xor'
import debounce from 'lodash-es/debounce'
import confirm from '@utils/confirm.js'
import PlaceListPaging from './PlaceListPaging.vue'
import PlaceListItemSkeleton from './PlaceListItemSkeleton.vue'
import BaseAutocomplete from '../../../Shared/components/BaseAutocomplete.vue'
import SortableList from '../../../Shared/components/SortableList.vue'
import { localeDate, translate } from '../../../../es6/src/modules/locale/locale.js'
import { useUserStore } from '../../../../store/user.js'
import { useCityStore } from '../../../../store/city.js'

let abortController = null

export default {
    components: {
        SortableList,
        PlaceListItemSkeleton,
        BaseAutocomplete,
        PlaceListPaging,
    },
    props: {
        placeList: {
            type: Object,
            required: true,
        },
        placePage: {
            type: Object,
            required: false,
            default: null,
        },
        isLoadingPlaces: {
            type: Boolean,
            required: false,
            default: false,
        },
        isMobile: {
            type: Boolean,
            required: false,
            default: false,
        },
        errors: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },

    setup() {
        const userStore = useUserStore()
        const cityStore = useCityStore()

        return { userStore, cityStore }
    },

    data() {
        return {
            editedPlaceList: null,
            editedPlacePage: null,
            originalPlaceList: {},
            originalPlacePage: {},

            removedPlaces: [],
            addedPlaces: [],
            placeOrderChanged: false,

            addedOwners: [],
            removedOwners: [],

            placeSearchResult: [],
            userSearchResult: [],

            showUserSearch: false,

            removeAllClosedPlacesQuestion: false,

            expandedPlaceId: [],
        }
    },

    watch: {
        isLoadingPlaces() {
            // Places finished loading, save it
            if (!this.isLoadingPlaces) {
                this.editedPlacePage = cloneDeep(this.placePage)
                this.originalPlacePage = cloneDeep(this.placePage)
            }
        },
    },

    methods: {
        togglePlace(key) {
            this.expandedPlaceId = xor(this.expandedPlaceId, [key])
        },
        editPlace(value, place, key) {
            place.edited = true
            place[key] = value
        },
        change(event) {
            this.$emit('list-change', this.editedPlaceList)
        },
        getPlaceIndexById(id) {
            return this.editedPlacePage.content.findIndex((p) => p.id === id)
        },
        getOwnerIndexById(id) {
            return this.editedPlaceList.owners.findIndex((u) => u.username === id)
        },
        removePlace(place) {
            const index = this.getPlaceIndexById(place.id)
            if (index !== -1) {
                // The place has been added without beeing saved, remove it completely
                if (this.editedPlacePage.content[index].isAdded) {
                    this.editedPlacePage.content.splice(index, 1)
                    const addedIndex = this.addedPlaces.findIndex((id) => id === place.id)
                    if (addedIndex !== -1) {
                        this.addedPlaces.splice(addedIndex, 1)
                    }
                } else {
                    this.removedPlaces.push(place.id)
                    // Set isRemoved to true
                    this.editedPlacePage.content[index].isRemoved = true
                }
                this.$emit('place-change', this.editedPlacePage)
            }
        },
        restorePlace(place) {
            const index = this.getPlaceIndexById(place.id)
            if (index !== -1) {
                this.editedPlacePage.content[index].isRemoved = false
                this.$emit('place-change', this.editedPlacePage)
            }
            const removedIndex = this.removedPlaces.findIndex((id) => place.id)
            if (removedIndex !== -1) {
                this.removedPlaces.splice(removedIndex, 1)
            }
        },
        addSearchPlace({ item }) {
            const index = this.getPlaceIndexById(item.uuid)
            if (index === -1) {
                // We need to convert search resource to list resource
                this.editedPlacePage.content.unshift({
                    ...item,
                    latitude: item._geo?.lat || null,
                    longitude: item._geo?.lng || null,
                    id: item.uuid,
                    isAdded: true,
                    isNew: true,
                })
                this.addedPlaces.push(item.uuid)
            } else if (this.editedPlacePage.content[index].isRemoved) {
                const removedIndex = this.removedPlaces.findIndex((id) => item.id)
                if (removedIndex !== -1) {
                    this.removedPlaces.splice(removedIndex, 1)
                }
                this.editedPlacePage.content[index].isRemoved = false
            }
            this.$emit('place-change', this.editedPlacePage)
        },
        updatePlaceOrder(data) {
            this.placeOrderChanged = !isEqual(
                this.editedPlacePage.content,
                this.originalPlacePage.content,
            )
            this.$emit('place-change', this.editedPlacePage)
        },
        searchPlace: debounce(function (term) {
            if (term.length < 2) return

            if (abortController) {
                abortController.abort()
            }
            if ('AbortController' in window) {
                abortController = new AbortController()
            }

            axios
                .get(
                    '/' +
                        (this.cityStore.currentCity ? this.cityStore.currentCity.code + '/' : '') +
                        'search/place/json/',
                    {
                        params: {
                            q: term,
                            'all-cities': true,
                            'all-countries': true,
                        },
                        signal: abortController?.signal,
                    },
                )
                .then((result) => {
                    this.placeSearchResult = result.data.map((p) => {
                        return {
                            ...p,
                            name: p.title,
                        }
                    })
                })
        }, 300),
        searchUser: debounce(function (term) {
            axios
                .get('/search/user/json/', {
                    params: {
                        q: term,
                    },
                })
                .then((result) => {
                    this.userSearchResult = result.data.map((u) => {
                        return {
                            ...u,
                            name: u.title,
                            username: u.code,
                        }
                    })
                })
        }, 200),
        addSearchUser({ item }) {
            const index = this.getOwnerIndexById(item.username)
            if (index === -1) {
                // We need to convert search resource to list resource
                this.editedPlaceList.owners.push({
                    ...item,
                    isAdded: true,
                })
                this.addedOwners.push(item.username)
            } else if (this.editedPlaceList.owners[index].isRemoved) {
                const removedIndex = this.removedOwners.findIndex((username) => item.username)
                if (removedIndex !== -1) {
                    this.removedOwners.splice(removedIndex, 1)
                }
                this.editedPlaceList.owners[index].isRemoved = false
            }
            this.$emit('list-change', this.editedPlaceList)
            this.showUserSearch = false
        },
        removeUser(user) {
            const index = this.getOwnerIndexById(user.username)
            if (index !== -1) {
                // The user has been added without beeing saved, remove it completely
                if (this.editedPlaceList.owners[index].isAdded) {
                    this.editedPlaceList.owners.splice(index, 1)
                    const addedIndex = this.addedOwners.findIndex((username) => user.username)
                    if (addedIndex !== -1) {
                        this.addedOwners.splice(addedIndex, 1)
                    }
                } else {
                    this.removedOwners.push(user.username)
                    // Set isRemoved to true
                    this.editedPlaceList.owners[index].isRemoved = true
                }
                this.$emit('list-change', this.editedPlaceList)
            }
        },
        restoreUser(user) {
            const index = this.getOwnerIndexById(user.username)
            if (index !== -1) {
                this.editedPlaceList.owners[index].isRemoved = false
                this.$emit('list-change', this.editedPlaceList)
            }
            const removedIndex = this.removedOwners.findIndex((username) => user.username)
            if (removedIndex !== -1) {
                this.removedOwners.splice(removedIndex, 1)
            }
        },
        async leaveList(user) {
            if (
                await confirm(
                    this.$t('placeList.remove_owner_self'),
                    this.$t('placeList.remove_owner_self_confirm'),
                )
            ) {
                this.removeUser(user)
            }
        },
        async deleteList(user) {
            if (
                await confirm(
                    this.$t('placeList.delete_list'),
                    this.$t('placeList.delete_list_confirm'),
                )
            ) {
                this.$emit('delete')
            }
        },
        blurSearchUser(e) {
            if (e.target && !e.target.value) {
                this.showUserSearch = false
            }
        },
        leaveForm() {
            return new Promise(async (resolve, reject) => {
                if (this.canSave) {
                    if (await confirm(null, translate('dirty_form_warning'))) {
                        resolve()
                    } else {
                        reject()
                    }
                } else {
                    resolve()
                }
            })
        },
        cancel() {
            this.leaveForm()
                .then(() => {
                    this.editedPlaceList = this.originalPlaceList
                    this.$emit('list-change', this.editedPlaceList)
                    this.editedPlacePage = this.originalPlacePage
                    this.$emit('place-change', this.editedPlacePage)
                    this.$emit('cancel')
                })
                .catch(() => {})
        },
        save() {
            this.$emit('save', {
                placeList: this.editedPlaceList,
                placePage: this.editedPlacePage,
                removedPlaces: this.removedPlaces,
                addedPlaces: this.addedPlaces,
                placeOrderChanged: this.placeOrderChanged,
                removedOwners: this.removedOwners,
                addedOwners: this.addedOwners,
            })
        },
        isRemoved(place) {
            return this.removedPlaces.indexOf(place.id) !== -1
        },
        isAdded(place) {
            return this.addedPlaces.indexOf(place.id) !== -1
        },
        userIsCreator(user) {
            return user.username === this.placeList.creator.username
        },
        inviteUser() {
            this.showUserSearch = true
            this.$nextTick(() => {
                this.$refs.userSearch.focus()
            })
        },
        nextPage() {
            this.leaveForm()
                .then(() => {
                    this.removedPlaces = []
                    this.addedPlaces = []
                    this.placeOrderChanged = false
                    this.$emit('update-options', {
                        offset:
                            this.editedPlacePage.paging.offset + this.editedPlacePage.paging.limit,
                    })
                })
                .catch(() => {})
        },
        prevPage() {
            this.leaveForm()
                .then(() => {
                    this.removedPlaces = []
                    this.addedPlaces = []
                    this.placeOrderChanged = false
                    this.$emit('update-options', {
                        offset: Math.max(
                            0,
                            this.editedPlacePage.paging.offset - this.editedPlacePage.paging.limit,
                        ),
                    })
                })
                .catch(() => {})
        },
    },

    computed: {
        userIsMe() {
            return (user) => this.userStore.user.username === user.username
        },
        canSave() {
            return (
                !isEqual(this.editedPlaceList, this.originalPlaceList) ||
                !isEqual(this.editedPlacePage, this.originalPlacePage) ||
                !!this.removedPlaces.length ||
                !!this.addedPlaces.length ||
                this.placeOrderChanged
            )
        },
        dateAddedString() {
            return this.placeList.dateAdded
                ? localeDate(this.placeList.dateAdded, {
                      type: 'format',
                      format: 'll',
                  })
                : null
        },
        listIsEmpty() {
            if (this.isLoadingPlaces) {
                return false
            } else {
                return (
                    this.editedPlacePage.paging.offset === 0 &&
                    this.editedPlacePage.content.length === 0
                )
            }
        },
    },

    created() {
        this.editedPlaceList = cloneDeep(this.placeList)
        this.originalPlaceList = cloneDeep(this.placeList)
        this.editedPlacePage = cloneDeep(this.placePage)
        this.originalPlacePage = cloneDeep(this.placePage)
    },

    destroyed() {},
}
</script>
<style scoped>
.item-list .item {
    transition: transform 0.175s ease-in-out;
}
.item-list .item.item--dragging {
    transform: scale(1.02);
    background: #eee;
}
</style>
