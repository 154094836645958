import { authentication } from '@modules/authentication/authentication.js'

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('form[data-recaptcha]').forEach((contactForm) => {
        initForm(contactForm)
    })
})

function initForm(contactForm) {
    let captcha = null
    let hasSolvedCaptcha = false
    const captchaDiv = document.createElement('div')

    contactForm.appendChild(captchaDiv)
    captcha = authentication.captcha.create(captchaDiv)

    contactForm.addEventListener('submit', function (e) {
        this.querySelector('[type="submit"]').disabled = true
        if (captcha && !hasSolvedCaptcha) {
            e.preventDefault()
            captcha
                .execute()
                .then((response) => {
                    const captchaInput = document.createElement('input')
                    captchaInput.setAttribute('type', 'hidden')
                    captchaInput.setAttribute('name', 'captcha')
                    captchaInput.value = response
                    contactForm.appendChild(captchaInput)
                    hasSolvedCaptcha = true
                    contactForm.submit()
                    // const button = contactForm.querySelector('[type="submit"]')
                    // button.click()
                })
                .catch((response) => {
                    this.querySelector('[type="submit"]').disabled = false
                    captcha.reset()
                    return response
                })
        }
    })
}
