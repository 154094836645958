<script setup>
import { nextTick, ref, shallowRef } from 'vue'
import UserTrophy from './UserTrophy.vue'
import { getRibbon } from './utils.js'
import { trackEvent } from '../../../es6/src/modules/tracking/tracking.js'
import { popup } from '../../../es6/src/modules/popup/popup.js'

const props = defineProps(UserTrophy.props)

/**
 * TODO: Show popup when user has received a new trophy
 * TODO: Most of this below can be extracted to a usePopup hook
 */
const popupInstance = shallowRef(null)
const popupEl = ref(null)
const isActive = ref(false)

const close = () => {
    isActive.value = false
    popupInstance.value.close()
    popupInstance.value = null
}

const open = () => {
    trackEvent('Trophy popup', {
        category: 'User',
        label: props.trophy.name,
        nonInteraction: true,
    })

    isActive.value = true

    nextTick(async () => {
        popupInstance.value = popup({
            content: popupEl.value,
            cssClass: 'user-trophy-message',
            ribbon: getRibbon(props.trophy),
            separateBody: true,
            width: 350,
            queue: true,
            beforeClose() {
                isActive.value = false
            },
        })
    })
}
</script>

<template>
    <div>
        <slot :open="open" />

        <div ref="popupEl">
            <UserTrophy v-if="isActive" v-bind="props" />
        </div>
    </div>
</template>
