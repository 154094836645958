<template>
    <div class="box tw-flex tw-flex-grow tw-flex-col tw-items-center tw-justify-start">
        <div class="pretty-form tw-w-full tw-max-w-md tw-px-4 tw-py-6">
            <img class="tw-mx-auto tw-mb-4" src="/media/img/dostoyevsky.svg" alt="Review" />
            <h1 class="tw-mb-6 tw-text-center tw-text-3xl tw-font-bold">
                {{ $t('review.index.title') }}
            </h1>
            <div class="form-group">
                <input
                    :placeholder="$t('review.index.search_placeholder')"
                    class="input full-width"
                    type="text"
                    v-model="searchTerm"
                    required
                    v-autofocus="true"
                />
            </div>
            <div>
                <div
                    v-if="isLoadingUserLocation || isLoadingSuggestedPlaces"
                    class="tw-flex tw-flex-col tw-items-center"
                >
                    <div class="is-loading is-loading--large mt-4"></div>
                    <div v-if="isLoadingUserLocation" class="tw-font-semibold">
                        {{ $t('review.index.finding_location') }}
                    </div>
                    <div v-if="isLoadingSuggestedPlaces" class="tw-font-semibold">
                        {{ $t('review.index.finding_nearby_businesses') }}
                    </div>
                </div>
                <div
                    v-if="suggestedPlaces.length || placeSearchResult.length || isSearchingPlaces"
                    class="tw-mt-8"
                >
                    <h2 v-if="!searchTerm" class="tw-text-center tw-text-xl tw-font-bold">
                        {{ $t('review.index.nearby_businesses') }}
                    </h2>
                    <div
                        v-if="isSearchingPlaces && !placeSearchResult.length"
                        class="tw-flex tw-justify-center"
                    >
                        <div class="is-loading is-loading--large mt-4"></div>
                    </div>
                    <a
                        class="place-box box shadow rounded border mb-3 pointer cursor-pointer"
                        v-for="(place, index) in searchTerm ? placeSearchResult : suggestedPlaces"
                        :key="place.uuid"
                        :data-index="index"
                        :href="place.url + '#review'"
                    >
                        <div
                            class="place-box__image artwork medium rounded"
                            :class="{
                                'icon category blue small circle': !place.thumbnail,
                                [place.icon]: !place.thumbnail,
                            }"
                        >
                            <img v-if="place.thumbnail" :alt="place.name" :src="place.thumbnail" />
                        </div>
                        <div class="place-box__description">
                            <h2>{{ place.title }}</h2>
                            <h3>
                                {{ [place.subtitle, place.cityName].filter((s) => !!s).join(', ') }}
                            </h3>
                        </div>
                        <div class="place-box__button">
                            <i class="icon--round-right-arrow"></i>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import debounce from 'lodash-es/debounce'
import { computed, onMounted, ref, watch } from 'vue'
import http from '@utils/http'
import { useUserStore } from '../../../store/user.js'
import { useCityStore } from '../../../store/city.js'
import { requestUserGeolocation } from '@utils/geo'

let abortController = null

const selectPlace = (place) => {
    console.log(place)
}

const userStore = useUserStore()
const cityStore = useCityStore()

const placeSearchResult = ref([])

const userLocation = computed(() => {
    return userStore.location || null
})

const isLoadingUserLocation = ref(false)

onMounted(async () => {
    isLoadingUserLocation.value = true
    try {
        await requestUserGeolocation()
    } catch (e) {
        // userLocation.value = null
    } finally {
        isLoadingUserLocation.value = false
    }
})

const isSearchingPlaces = ref(false)

const searchPlace = debounce(function (term) {
    isSearchingPlaces.value = true
    if (term.length < 2) {
        isSearchingPlaces.value = false
        return
    }

    if (abortController) {
        abortController.abort()
    }
    if ('AbortController' in window) {
        abortController = new AbortController()
    }

    const uriPartial = cityStore.currentCityCode ? cityStore.currentCityCode + '/' : ''

    http.get(`/${uriPartial}search/place/json/`, {
        params: {
            q: term,
            'all-cities': true,
            'all-countries': true,
            ...(userLocation.value || {}),
        },
        signal: abortController?.signal,
    })
        .then((result) => {
            isSearchingPlaces.value = false
            placeSearchResult.value = result.data.map((p) => {
                return {
                    ...p,
                    name: p.title,
                }
            })
        })
        .catch(() => {
            isSearchingPlaces.value = false
        })
}, 300)

const searchTerm = ref(null)

watch(
    () => searchTerm.value,
    (newVal) => {
        if (!newVal) {
            placeSearchResult.value = []
            return
        }
        // Adding loading state here as well to prevent waiting for debounce
        isSearchingPlaces.value = true
        searchPlace(newVal)
    },
)

const isLoadingSuggestedPlaces = ref(false)
const suggestedPlaces = ref([])

watch(
    () => userLocation.value,
    (newVal) => {
        if (!newVal) {
            suggestedPlaces.value = []
            return
        }
        isLoadingSuggestedPlaces.value = true
        const uriPartial = cityStore.currentCityCode ? cityStore.currentCityCode + '/' : ''

        // TODO: Test this
        http.get(`/${uriPartial}search/place/json/`, {
            params: {
                'all-cities': true,
                'all-countries': true,
                distance: 100,
                ...newVal,
            },
        })
            .then((result) => {
                isLoadingSuggestedPlaces.value = false
                suggestedPlaces.value = result.data.map((p) => {
                    return {
                        ...p,
                        name: p.title,
                    }
                })
            })
            .catch(() => {
                isLoadingSuggestedPlaces.value = false
            })
    },
    { immediate: true },
)
</script>
