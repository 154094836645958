const EventBus = {
    listeners: {},

    on(event, listener) {
        if (!(event in this.listeners)) {
            this.listeners[event] = []
        }
        this.listeners[event].push(listener)
        return this
    },

    emit(event, data = null) {
        if (event in this.listeners) {
            this.listeners[event].forEach((listener) => {
                listener.call(this, data)
            })
        }
        if ('*' in this.listeners) {
            this.listeners['*'].forEach((listener) => {
                listener.call(this, event, data)
            })
        }
        return this
    },

    off(event, listener) {
        if (event in this.listeners) {
            const index = this.listeners[event].indexOf(listener)
            if (index > -1) {
                this.listeners[event].splice(index, 1)
            }
        }
        return this
    },

    one(event, listener) {
        if (!(event in this.listeners)) {
            this.listeners[event] = []
        }
        const newListener = (...args) => {
            listener.apply(this, args)
            this.off(event, newListener)
        }
        this.listeners[event].push(newListener)
        return this
    },
}

export default EventBus
