<template>
    <div>
        <h3 class="text-center">{{ $t('userPoll.voted_title') }}</h3>

        <p v-if="userPoll.endDate" class="text-center">
            {{ $t('userPoll.voted_description', { endDate: endDate }) }}
        </p>

        <div v-if="!vote.verified" class="text-center">
            <p>
                {{ $t('userPoll.vote_not_verified_description') }}
            </p>
            <p>
                <a :href="userProfileLink">
                    {{ $t('userPoll.verify_account') }}
                </a>
            </p>
        </div>

        <Alternative :alternative="vote.alternative" />

        <ShareButton :user-poll="userPoll" />

        <div class="text-center subtle text-smaller">
            <a role="button" class="" @click="$emit('cancel')">
                {{ $t('userPoll.vote_cancel') }}
            </a>
        </div>
    </div>
</template>

<script>
import Alternative from './Alternative.vue'
import ShareButton from './ShareButton.vue'
import { useLocaleStore } from '../../../../store/locale.js'
import { useUserStore } from '@/store/user.js'

export default {
    components: {
        Alternative,
        ShareButton,
    },
    props: {
        userPoll: {
            type: Object,
            required: true,
        },
        vote: {
            type: Object,
            required: true,
        },
    },

    setup() {
        const localeStore = useLocaleStore()
        const userStore = useUserStore()

        return { localeStore, userStore }
    },

    data() {
        return {}
    },

    computed: {
        userProfileLink() {
            return userStore.user.permalink
        },
        endDate() {
            return new Date(this.userPoll.endDate).toLocaleDateString(this.localeStore.code)
        },
    },

    methods: {},
}
</script>
<style lang="scss"></style>
