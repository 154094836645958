<template>
    <slot :open="toggleMobileUserMenu" />

    <teleport to="body">
        <!-- Overlay outside of the menu to close by clicking outside -->
        <div
            @click="hideMobileUserMenu"
            v-if="navigationStore.isMobileUserMenuOpen"
            :class="$style.closer"
        ></div>

        <!-- Mobile user menu -->
        <div
            id="mobile-user-menu"
            ref="mobileUserMenuEl"
            :class="{ 'mobile-user-menu': navigationStore.isMobileUserMenuOpen }"
        >
            <DraggableList :items="navigationItems" v-model:active="activeIndex">
                <template #default="{ item, index }">
                    <li :class="$style.draggableItem">
                        <div
                            class="overlay-scroll tw-flex tw-justify-between tw-px-5 tw-py-2.5 tw-text-lg tw-font-semibold"
                        >
                            <!-- Close button, left -->
                            <button
                                v-if="index === 0"
                                type="button"
                                aria-controls="mobile-user-menu"
                                :aria-label="$t('common.close')"
                                @click="hideMobileUserMenu"
                            >
                                <i class="icon--close-x dark-slate-blue x20"></i>
                            </button>

                            <!-- Go to next slide -->
                            <button
                                v-if="index !== 0 && index + 1 >= navigationItems.length - 1"
                                @click="activeIndex = index - 1"
                                type="button"
                                aria-controls="mobile-user-menu"
                                class="tw-flex tw-items-center tw-gap-2"
                            >
                                <img
                                    class="tw-inline tw-rotate-180"
                                    src="/media/img/icon/user-menu/arrow.svg"
                                    alt=""
                                    height="16"
                                    width="10"
                                />
                                <span>{{ navigationItems[index - 1].title }}</span>
                            </button>

                            <!-- Go to previous slide -->
                            <button
                                v-if="navigationItems.length - 1 >= index + 1"
                                @click="activeIndex = index + 1"
                                type="button"
                                aria-controls="mobile-user-menu"
                                class="tw-flex tw-items-center tw-gap-2"
                            >
                                <span>{{ navigationItems[index + 1].title }}</span>
                                <img
                                    class="tw-inline"
                                    src="/media/img/icon/user-menu/arrow.svg"
                                    alt=""
                                    height="16"
                                    width="10"
                                />
                            </button>

                            <!-- Close button, right -->
                            <button
                                v-if="
                                    navigationItems.length !== 1 &&
                                    index === navigationItems.length - 1
                                "
                                type="button"
                                aria-controls="mobile-user-menu"
                                :aria-label="$t('common.close')"
                                @click="hideMobileUserMenu"
                            >
                                <i class="icon--close-x dark-slate-blue x20"></i>
                            </button>
                        </div>

                        <component :is="item.component" v-bind="item.attrs"></component>
                    </li>
                </template>
            </DraggableList>
        </div>
    </teleport>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { useUserStore } from '../../../store/user.js'
import { useRoutesStore } from '../../../store/routes.js'
import { useCityStore } from '../../../store/city.js'
import DraggableList from '../../Shared/components/DraggableList.vue'
import LoggedInNavigation from './LoggedInNavigation.vue'
import LoggedOutNavigation from './LoggedOutNavigation.vue'
import NotificationsNavigation from './NotificationsNavigation.vue'
import { $t } from '../../helpers.js'
import { useNavigationStore } from '../../../store/navigation.js'

const routesStore = useRoutesStore()
const userStore = useUserStore()
const cityStore = useCityStore()
const navigationStore = useNavigationStore()

const mobileUserMenuEl = ref(null)
const navigationItems = []
const activeIndex = ref(0)
const routes = routesStore.routes

if (userStore.isLoggedIn) {
    navigationItems.push({
        title: $t('mobileUserMenu.user_profile'),
        component: LoggedInNavigation,
    })

    navigationItems.push({
        title: $t('common.notifications_short'),
        component: NotificationsNavigation,
        attrs: {
            class: 'tw-p-5',
        },
    })
} else {
    navigationItems.push({
        title: $t('authForm.log_in'),
        component: LoggedOutNavigation,
    })
}

function showMobileUserMenu() {
    document.querySelector('html').classList.add('mobile-user-menu')

    mobileUserMenuResizeEvent()

    navigationStore.isMobileUserMenuOpen = true
    if (userStore.isLoggedIn) {
        userStore.loadMobileNotifications()
    }
}

const mobileUserMenuResizeEvent = () => {
    // let scrollTop = $('html').scrollTop()
    // if (!scrollTop) {
    //     scrollTop = $('body').scrollTop()
    // }
    // Set the height of the mobile user menu to the scroll top
    mobileUserMenuEl.value.style.top = `${window.scrollY}px`
    mobileUserMenuEl.value.style.hright = `${window.innerHeight}px`
    //
    // thatsup.getHeader().css({"top":scrollTop,"position":"absolute"});
}

onMounted(() => {
    mobileUserMenuResizeEvent()
})

onUnmounted(() => {
    window.removeEventListener('resize', mobileUserMenuResizeEvent)
    window.removeEventListener('scroll', mobileUserMenuResizeEvent)
})

window.addEventListener('resize', mobileUserMenuResizeEvent)
window.addEventListener('scroll', mobileUserMenuResizeEvent)

function hideMobileUserMenu() {
    document.documentElement.classList.remove('mobile-user-menu')
    navigationStore.isMobileUserMenuOpen = false
}

watch(
    () => navigationStore.isMobileUserMenuOpen,
    (isOpen) => {
        if (isOpen) {
            showMobileUserMenu()
        } else {
            hideMobileUserMenu()
        }
    },
)

function toggleMobileUserMenu() {
    navigationStore.isMobileUserMenuOpen = !navigationStore.isMobileUserMenuOpen
}
</script>

<style module>
.draggableItem {
    display: flex;
    flex-direction: column;

    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;

    max-height: 100vh;
    overflow: hidden;
}

.closer {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.draggableItem >>> #mobile-notifications .user-action-wrapper {
    border-color: #e0e0e0;
}
</style>
