import { createApp } from 'vue/dist/vue.esm-bundler.js'
import helpers from './helpers.js'
import { createPinia } from 'pinia'

// Directives
import autofocus from './Shared/directives/autofocus.js'
//import tooltip from './Shared/directives/tooltip.js'

// Import components
import AuthForm from './components/auth-form/AuthForm.vue'
import PlaceWizard from './components/place-wizard/PlaceWizard.vue'
import PlaceList from './components/place-list/PlaceList.vue'
import PopupTrigger from './components/popup/PopupTrigger.vue'
import UserPoll from './components/user-poll/UserPoll.vue'
import PlaceRateBox from './components/place-review/PlaceRateBox.vue'
import SearchAutocomplete from './components/search-autocomplete/SearchAutocomplete.vue'
import Explore from './components/explore/Explore.vue'
import SearchAutocompleteLabel from './components/search-autocomplete/SearchAutocompleteLabel.vue'
import ElementsPage from './components/front-page/ElementsPage.vue'
import PlaceMap from './components/place-map/PlaceMap.vue'
import DrawerModal from './components/drawer-modal/DrawerModal.vue'
import DrawerModalNav from './components/drawer-modal/DrawerModalNav.vue'
import DrawerModalNavItem from './components/drawer-modal/DrawerModalNavItem.vue'
import ArticleSuggestion from './components/article-suggestion/ArticleSuggestion.vue'
import UserSocialSettings from './components/user-settings/UserSocialSettings.vue'
import MobileUserMenu from './components/user-navigation/MobileUserMenu.vue'
import GlobalUserTrophyPopup from './components/user-trophy/GlobalUserTrophyPopup.vue'
import PlaceImageButton from './components/place-image/PlaceImageButton.vue'

// Misc
// import { dispatchStoreEvents } from './store/init'
// import UserMessageFloater from './components/user-message-floater/UserMessageFloater.vue'
import WatchesPlaceListSavedStatus from './mixins/WatchesPlaceListSavedStatus'
import PlaceReviewIndex from './components/place-review/PlaceReviewIndex.vue'
import PlaceReviewReactions from './components/place-review/PlaceReviewReactions.vue'
import UserTrophy from './components/user-trophy/UserTrophy.vue'
import UserTrophyPopup from './components/user-trophy/UserTrophyPopup.vue'
import PlaceReviewForm from './components/place-review/PlaceReviewForm.vue'
import NotificationsNavigation from './components/user-navigation/NotificationsNavigation.vue'
import UserNotificationsPopup from './components/user-navigation/user-notifiations/UserNotificationsPopup.vue'
import '../../styles/tailwind.css'
import '../../styles/less/style.less'

const pinia = createPinia()

const bootstrapApp = (app) => {
    app.config.compilerOptions.whitespace = 'preserve'

    app.mixin(helpers)
    // app.use(store)
    app.use(pinia)

    app.directive('autofocus', autofocus)
    //app.directive('tooltip', tooltip)

    app.component('search-autocomplete-label', SearchAutocompleteLabel)
    app.component('search-autocomplete', SearchAutocomplete)
    app.component('auth-form', AuthForm)
    app.component('place-wizard', PlaceWizard)
    app.component('place-list', PlaceList)
    app.component('popup-trigger', PopupTrigger)
    app.component('user-poll', UserPoll)
    app.component('place-rate-box', PlaceRateBox)
    app.component('place-review-form', PlaceReviewForm)
    app.component('place-review-index', PlaceReviewIndex)
    app.component('place-explorer', Explore)
    app.component('elements-page', ElementsPage)
    app.component('place-map', PlaceMap)
    // app.component('usermessagefloater', UserMessageFloater)
    app.component('place-review-reactions', PlaceReviewReactions)
    app.component('drawer-modal', DrawerModal)
    app.component('drawer-modal-nav', DrawerModalNav)
    app.component('drawer-modal-nav-item', DrawerModalNavItem)
    app.component('user-trophy', UserTrophy)
    app.component('user-trophy-popup', UserTrophyPopup)
    app.component('user-social-settings', UserSocialSettings)
    app.component('article-suggestion', ArticleSuggestion)
    app.component('mobile-user-menu', MobileUserMenu)
    app.component('notifications-navigation', NotificationsNavigation)
    app.component('user-notifications-popup', UserNotificationsPopup)
    app.component('global-user-trophy-popup', GlobalUserTrophyPopup)
    app.component('place-image-button', PlaceImageButton)

    return app
}

const app = createApp({
    mixins: [WatchesPlaceListSavedStatus],
})

function mountApp() {
    app.mount('#app')
}

/**
 * Initializes a Vue instance on any HTML element. Mainly to be used
 * when fetching HTML from server that contains Vue components.
 *
 * @param elm
 * @returns {Promise<void>|Promise<unknown>}
 */
export function initDynamicVue(elm) {
    if (elm.tagName === 'HTML') {
        return Promise.resolve()
    }

    return new Promise((resolve) => {
        if (elm.vueApp) {
            elm.vueApp.unmount(elm)
            return resolve(null)
        }

        const availableComponents = Object.keys(app._context.components)
        if (!elm.querySelector(availableComponents.join(','))) {
            // No vue components found in the element
            return resolve(null)
        }

        const dynamicApp = createApp({
            mixins: [WatchesPlaceListSavedStatus],
        })

        dynamicApp.mixin({
            mounted() {
                if (this === this.$root) {
                    resolve(app)
                }
            },
        })

        elm.vueApp = bootstrapApp(dynamicApp)
        elm.vueApp.mount(elm)
    })
}

export function initVue() {
    bootstrapApp(app)
    mountApp()
}
