<template>
    <form
        v-show="searchAutocompleteStore.isOpen"
        class="search-autocomplete"
        action="/search/"
        method="get"
        :class="{
            'search-autocomplete--open': searchAutocompleteStore.isOpen,
            'search-autocomplete--loading': isLoading,
        }"
    >
        <div class="search-autocomplete__close-area" @click="close"></div>
        <div class="search-autocomplete__wrapper">
            <div class="search-autocomplete__inputs">
                <AutocompleteInput
                    ref="locationInput"
                    :model-value="!hasSetLocation ? null : currentLocationTerm"
                    @update:modelValue="currentLocationTerm = $event"
                    name="location"
                    icon="sli sli-location-pin"
                    id="autocomplete-location"
                    :is-open="searchAutocompleteStore.isOpen"
                    :label="$t('SearchAutocomplete.location_label')"
                    :highlight="highlightedLocationResult"
                    :sources="locationSources"
                    :placeholder-results="placeholderLocationResults"
                    :limit="9"
                    :placeholder="$t('SearchAutocomplete.location_placeholder')"
                    :display-value="locationDisplayValue"
                    :active="currentFocus === 'location'"
                    :disabled="isLoading"
                    @focus="focus('location')"
                    @blur="blur('location')"
                    @results="locationResults = $event"
                    @highlight="highlightedLocationResult = $event"
                    @select:result="selectLocation"
                    @cancel="close"
                    required
                    auto-highlight
                    select-on-focus
                    clearable
                >
                    <template v-slot:after>
                        <i
                            class="icon--close-x icon--x16 search-autocomplete__close-button"
                            role="button"
                            @click="close"
                            :title="$t('SearchAutocomplete.close')"
                        ></i>
                    </template>
                </AutocompleteInput>
                <AutocompleteInput
                    ref="termInput"
                    v-model="currentTerm"
                    name="q"
                    url="/search/autocomplete/"
                    id="q"
                    :is-open="searchAutocompleteStore.isOpen"
                    :label="$t('SearchAutocomplete.term_label')"
                    :highlight="highlightedResult"
                    :limit="9"
                    :sources="sources"
                    :placeholder-results="placeholderResults"
                    :after="textSearchItem"
                    :auto-highlight="
                        (result) =>
                            !!currentTerm && (result.type === 'explore' || result.type === 'search')
                    "
                    :placeholder="
                        isSmall
                            ? $t('SearchAutocomplete.term_placeholder_small')
                            : $t('SearchAutocomplete.term_placeholder')
                    "
                    :active="currentFocus === 'term'"
                    :disabled="isLoading"
                    @focus="focus('term')"
                    @results="results = $event"
                    @highlight="highlightedResult = $event"
                    @select:result="select"
                    @select:none="performSearch"
                    @cancel="close"
                    @blur="blur('term')"
                    @icon:click="close"
                    select-on-focus
                    clearable
                />
                <div>
                    <button
                        class="search-autocomplete__submit-button"
                        type="button"
                        aria-label="Search"
                        :disabled="isLoading"
                    ></button>
                </div>
            </div>
            <div class="search-autocomplete__results overlay-scroll-touch">
                <template v-if="isLoading">
                    <div v-if="isLoading" class="is-loading is-loading--large"></div>
                </template>
                <template v-else>
                    <ResultList
                        :active="currentFocus === 'location'"
                        :results="locationResults"
                        :is-loading="isLoadingLocationResults"
                        :term="currentLocationTerm"
                        :highlight="highlightedLocationResult"
                        @highlight="highlightedLocationResult = $event"
                        @select:result="selectLocation"
                        :is-loading-user-location="isLoadingUserLocation"
                    />
                    <ResultList
                        :active="currentFocus === 'term'"
                        :results="results"
                        :is-loading="isLoadingResults"
                        :term="currentTerm"
                        :highlight="highlightedResult"
                        :group-by="!currentTerm ? 'group' : null"
                        @highlight="highlightedResult = $event"
                        @select:result="select"
                    />
                </template>
            </div>
        </div>
    </form>
</template>
<script>
import ResultList from './ResultList.vue'
import AutocompleteInput from './AutocompleteInput.vue'
import geoSearch from './util/geoSearch'
import http from '@utils/http'
import { debounce } from 'lodash-es'
import { trackEvent } from '../../../es6/src/modules/tracking/tracking.js'
import { useExploreStore } from '../../../store/explore.js'
import { useSearchAutocompleteStore } from '../../../store/searchAutocomplete.js'
import { useUserStore } from '../../../store/user.js'
import { useCityStore } from '../../../store/city.js'
import { useLocaleStore } from '../../../store/locale.js'
import { localStorage } from '../../../es6/src/utils/localStorage.js'
import { getUserPosition, requestUserGeolocation } from '../../../es6/src/utils/geo.js'

const TERM_HISTORY_KEY = 'search_autocomplete_history'
const LOCATION_HISTORY_KEY = 'search_autocomplete_location_history'

export default {
    components: { AutocompleteInput, ResultList },
    props: {
        term: {
            type: String,
            required: false,
            default: null,
        },
        locationTerm: {
            type: String,
            required: false,
            default: null,
        },
    },

    setup() {
        const exploreStore = useExploreStore()
        const searchAutocompleteStore = useSearchAutocompleteStore()
        const userStore = useUserStore()
        const cityStore = useCityStore()
        const localeStore = useLocaleStore()

        const open = searchAutocompleteStore.open
        const close = searchAutocompleteStore.close
        const toggle = searchAutocompleteStore.toggle
        const setTerm = searchAutocompleteStore.setTerm
        const setSelected = searchAutocompleteStore.setSelected
        const setLocationTerm = searchAutocompleteStore.setLocationTerm
        const setLocationData = searchAutocompleteStore.setLocationData

        return {
            exploreStore,
            userStore,
            searchAutocompleteStore,
            cityStore,
            localeStore,

            open,
            close,
            toggle,
            setTerm,
            setSelected,
            setLocationTerm,
            setLocationData,
        }
    },

    data() {
        return {
            isLoading: false,

            currentTerm: this.term || this.searchAutocompleteStore?.term,
            currentLocationTerm: this.locationTerm || this.searchAutocompleteStore?.locationTerm,

            currentFocus: null,

            isLoadingResults: false,
            isLoadingLocationResults: false,

            termHasFocus: false,
            locationHasFocus: false,

            results: [],
            highlightedResult: null,

            locationResults: [],
            highlightedLocationResult: null,

            history: null,
            recommendations: null,
            locationRecommendations: null,

            selected: null,

            savedUserPosition: null,

            isLoadingUserLocation: false,

            isSmall: true,

            defaultLocation: null,

            hasSetLocation: false,
        }
    },

    mounted() {
        this.bindEvents()
        if (this.history === null) {
            this.loadHistory()
        }
        this.updateDefaultLocation()
    },
    beforeUnmount() {
        this.unbindEvents()
    },

    computed: {
        isExploreActive() {
            return this.exploreStore.isActive
        },

        locationData: {
            get() {
                return this.searchAutocompleteStore.locationData
            },
            set(value) {
                this.hasSetLocation = true
                this.setLocationData(value)
            },
        },

        placeholderResults() {
            const history = [...(this.getHistory(TERM_HISTORY_KEY) || [])].splice(0, 3)
            let recommendations = [...(this.recommendations || [])]
            recommendations = recommendations
                .filter((recommendation) => {
                    return !history.find((h) => h.id === recommendation.id)
                })
                .splice(0, 6 - history.length) // Min 3, max 6
            return [
                ...history.map((result) => ({
                    ...result,
                    group: 'history',
                })),
                ...recommendations.map((result) => ({
                    ...result,
                    group: 'recommendations',
                })),
            ]
        },
        nearMeResult() {
            return {
                id: 'near-me',
                type: 'near-me',
                title: this.$t('SearchAutocomplete.near_me_label'),
                hitwords: this.$t('SearchAutocomplete.near_me_hitwords'),
                data: this.savedUserPosition,
            }
        },
        placeholderLocationResults() {
            let results = []

            let nearMeResults = this.defaultLocation
                ? [this.defaultLocation, this.nearMeResult]
                : [this.nearMeResult]

            let historyResults = [...(this.getHistory(LOCATION_HISTORY_KEY) || [])]
                .filter((history) => {
                    return !nearMeResults.find((result) => {
                        return history.id === result.id
                    })
                })
                .splice(0, 3)
                .map((result) => {
                    return {
                        ...result,
                        group: 'history',
                    }
                })

            results = results.concat(historyResults)
            results = results.concat(nearMeResults)
            if (this.locationRecommendations) {
                results = results.concat(this.locationRecommendations)
            }

            return results
        },
        sources() {
            return [
                {
                    url: '/search/autocomplete/',
                    params: {
                        ...(this.defaultLocation?.data || {}),
                        ...this.locationData,
                        lat: this.locationData?.lat || this.savedUserPosition?.lat || undefined,
                        lng: this.locationData?.lng || this.savedUserPosition?.lng || undefined,
                    },
                },
                {
                    url: '/search/autocomplete/fallback/',
                    params: {
                        ...(this.defaultLocation?.data || {}),
                        ...this.locationData,
                    },
                    condition(term, limit, currentResults) {
                        return currentResults.length < 9
                    },
                },
                // {
                //     url: '/search/autocomplete/',
                //     params: {
                //         type: 'place',
                //         country: window.thatsup?.city?.country?.code,
                //         ...(this.savedUserPosition || {}),
                //         not_city: ({
                //             ...(this.defaultLocation?.data || {}),
                //             ...this.locationData
                //         }).city
                //     },
                //     condition(term, limit, currentResults) {
                //         // If no place hits
                //         return term && term.length >= 2 && currentResults.length < 3 && currentResults.reduce((carry, item) => carry += item.type === 'place'? 1 : 0, 0) < 1
                //     }
                // }
            ]
        },
        locationSources() {
            return [this.localLocations, '/search/autocomplete/locations/', this.geoSearch]
        },

        locationDisplayValue() {
            if (this.currentFocus === 'location') {
                return null
            }
            if (!this.hasSetLocation && this.currentLocationTerm) {
                return this.currentLocationTerm
            }
            if (!this.currentLocationTerm && this.defaultLocation) {
                return this.defaultLocation.title
            }
            return null
        },

        isLocationSameAsDefault() {
            const filtered = { ...(this.locationData || {}) }
            const defaultLocation = { ...(this.defaultLocation?.data || {}) }
            for (const key in filtered) {
                if (filtered[key] === null) {
                    delete filtered[key]
                }
            }
            for (const key in defaultLocation) {
                if (defaultLocation[key] === null) {
                    delete defaultLocation[key]
                }
            }
            return JSON.stringify(filtered) === JSON.stringify(defaultLocation)
        },
    },

    requestIndex: 0,
    locationRequestIndex: 0,

    watch: {
        'searchAutocompleteStore.isOpen'(newVal) {
            if (newVal) {
                if (this.recommendations === null) {
                    this.loadRecommendations()
                }
                if (this.locationRecommendations === null) {
                    this.loadLocationRecommendations()
                }
                this.$refs.termInput.focus()
                this.checkIfSmall()
                document.querySelector('html').classList.add('search-autocomplete-open')
                if (this.userStore.hasAcceptedGeolocation && !this.savedUserPosition) {
                    this.tryToGetUserPosition()
                }
            } else {
                this.currentFocus = null
                document.querySelector('html').classList.remove('search-autocomplete-open')
            }
        },
        'searchAutocompleteStore.term'(newVal) {
            this.currentTerm = newVal
        },
        'searchAutocompleteStore.locationTerm'(newVal) {
            this.currentLocationTerm = newVal
        },
        city(newVal) {
            this.updateDefaultLocation(newVal)
        },
        savedUserPosition() {
            this.loadLocationRecommendations()
        },
        currentLocationTerm(newVal) {
            this.hasSetLocation = true
        },
    },

    methods: {
        bindEvents() {
            if ('onpageshow' in window) {
                window.addEventListener('pageshow', this.onPageShow)
            }
        },
        unbindEvents() {
            if ('onpageshow' in window) {
                window.removeEventListener('pageshow', this.onPageShow)
            }
        },

        onPageShow() {
            // Always reset loading
            if (this.isLoading) {
                this.isLoading = false
                this.currentFocus = 'term'
            }
        },

        focus(field) {
            this.currentFocus = field
            document.querySelector('html').classList.add('search-autocomplete-input-focus')
            if (field === 'location' && !this.savedUserPosition) {
                this.tryToGetUserPosition()
            }
        },

        blur(field) {
            document.querySelector('html').classList.remove('search-autocomplete-input-focus')
        },

        updateDefaultLocation() {
            this.defaultLocation = this.getDefaultLocation()
        },

        tryToGetUserPosition() {
            if (this.isLoadingUserLocation) return
            this.isLoadingUserLocation = true

            requestUserGeolocation()
                .then((data) => {
                    this.isLoadingUserLocation = false
                    trackEvent('autocomplete_geolocation_yes')
                    this.savedUserPosition = {
                        lat: data.lat,
                        lng: data.lng,
                    }
                })
                .catch((d) => {
                    this.isLoadingUserLocation = false
                    trackEvent('autocomplete_geolocation_no')
                })
        },

        getDefaultLocation() {
            const city = this.cityStore.currentCity

            if (!city) {
                return null
            }

            return {
                id: 'city-' + city.code,
                title: city.name,
                data: {
                    city: city.code,
                },
                hitwords: [city.name.toLowerCase(), city.code.toLowerCase()],
            }
        },

        async selectLocation(location) {
            if (location.type === 'near-me' && !location.data) {
                this.isLoadingUserLocation = true
                try {
                    const userLocation = await this.getUserLocation()
                    location.data = {
                        ...userLocation,
                    }
                } catch (e) {
                    this.isLoadingUserLocation = false
                    return
                }
                this.isLoadingUserLocation = false
            } else if (location.data.lat && location.data.lng) {
                console.log(location.data)
                this.cityStore.findAndSetCity(location.data)
            }

            this.addToHistory(location, LOCATION_HISTORY_KEY)

            this.locationData = location.data
            if (location !== this.defaultLocation) {
                this.currentLocationTerm = location.title
                this.setLocationTerm(this.currentLocationTerm)
            }

            if (this.isExploreActive) {
                this.close()
                return
            }

            if (this.selected) {
                return this.goToResult(this.selected)
            }
            this.focus('term')
        },

        getUrlForResult(result) {
            const type = result.type
            let url = result.url

            const location = {
                ...(this.locationData || this.defaultLocation?.data || {}),
            }

            if (type === 'explore') {
                if (location.city) {
                    url = `/${location.city}${url}`
                }

                if (location.district) {
                    url = url.replace('/explore/', '/explore/' + location.district + '/')
                }

                if (location.gid || location['close-to']) {
                    location.lat = undefined
                    location.lng = undefined
                }

                const queryString = ['close-to', 'lat', 'lng', 'gid', 'radius', 'll']
                    .filter(
                        (key) =>
                            key in location &&
                            location[key] !== null &&
                            location[key] !== undefined,
                    )
                    .map((key) => {
                        let value = location[key]
                        if (Array.isArray(value)) {
                            return value
                                .map((v, k) => {
                                    return `${key}[]=${window.encodeURIComponent(v)}`
                                })
                                .join('&')
                        }
                        return `${key}=${window.encodeURIComponent(location[key])}`
                    })
                    .join('&')

                if (queryString) {
                    url += (url.indexOf('?') !== -1 ? '&' : '?') + queryString
                }
            } else if (type === 'search') {
                url = '/search/?q=' + window.encodeURIComponent(this.currentTerm)

                if (location && location.city) {
                    url = `/${location.city}${url}`
                }
            }

            return url
        },

        localLocations(term, limit, currentResults, filterFunction) {
            // Cities from window.thatsup
            let results = this.getCitiesFromApp()

            // Near me result
            results.push(this.nearMeResult)

            // Filter with default filter function
            return results.filter(filterFunction)
        },

        getCitiesFromApp() {
            return (this.cityStore.cities || []).map((city) => {
                return {
                    id: 'city-' + city.code,
                    title: city.name,
                    type: 'city',
                    hitwords: [city.name.toLowerCase(), city.code.toLowerCase()],
                    data: {
                        city: city.code,
                        country: city.country?.code?.toLowerCase(),
                    },
                }
            })
        },

        textSearchItem(term, results = []) {
            if (!term) {
                return null
            }
            return {
                title: results?.length
                    ? this.$t('SearchAutocomplete.term_catch_all_label_more', {
                          term,
                      })
                    : this.$t('SearchAutocomplete.term_catch_all_label', {
                          term,
                      }),
                id: 'search-' + term,
                type: 'search',
            }
        },

        addLocalityToTerm(term) {
            const city =
                (this.locationData ? this.locationData.city : null) ||
                this.cityStore.currentCity?.name
            if (city) {
                const localityRegExp = new RegExp(city, 'gi')
                term = term.replace(localityRegExp, '')
                term += ' ' + city
            }

            return term
        },

        async geoSearch(term, limit, currentResults) {
            // Only perform geoSearch if we have no other results
            // This is to keep costs down
            if (!term || currentResults.length >= 3) {
                return Promise.resolve([])
            }

            return await geoSearch(
                {
                    address:
                        '"' + this.addLocalityToTerm(term.trim()) + '" OR "' + term.trim() + '"',
                    componentRestrictions: {
                        country:
                            this.locationData?.country || this.cityStore.currentCity.country.code,
                    },
                    language: this.localeStore.code,
                },
                limit,
                currentResults,
            )
        },

        getHistory(key = TERM_HISTORY_KEY) {
            let persistedHistory = localStorage.get(key)
            if (persistedHistory) {
                try {
                    persistedHistory = JSON.parse(persistedHistory)
                } catch (e) {}
            }
            if (!persistedHistory || !Array.isArray(persistedHistory)) {
                persistedHistory = []
            }
            return persistedHistory
        },

        loadHistory() {
            this.history = this.getHistory()
        },

        getUserLocation() {
            return new Promise((resolve, reject) => {
                if (this.savedUserPosition) {
                    return resolve(this.savedUserPosition)
                }
                getUserPosition(
                    (pos) => {
                        if (pos.lat && pos.lng) {
                            this.savedUserPosition = {
                                lat: pos.lat,
                                lng: pos.lng,
                            }
                            return resolve(this.savedUserPosition)
                        } else {
                            reject()
                        }
                    },
                    (error) => {
                        reject(error)
                    },
                )
            })
        },

        performSearch(term) {
            if (!term) {
                return
            }
            return this.select(this.textSearchItem(term))
        },

        select(result) {
            this.selected = result

            let historyTitle = result.title
            // Search results should have the current term as title
            if (result.type === 'search' && result.group !== 'history') {
                historyTitle = this.currentTerm
            }

            this.addToHistory(
                {
                    ...result,
                    title: historyTitle,
                },
                TERM_HISTORY_KEY,
            )

            trackEvent(result.type || 'select', {
                category: 'autocomplete',
                label: this.currentTerm,
            })

            // Set the term to the title (if it's not the catch-all item)
            // Also, history items already have a converted search title from above
            if (result.type !== 'search' || result.group === 'history') {
                this.currentTerm = result.title
            }
            this.setTerm(this.currentTerm)
            this.setSelected(result)

            // Switch to location input if we haven't selected one
            // and the selected item is of type "explore"
            // if(result.type === 'explore' && !this.hasSelectedLocation) {
            //  this.currentFocus = 'location';
            // if(result.type === 'explore' && !this.hasSelectedLocation) {
            // this.locationData = this.defaultLocation;
            // }
            if (
                !this.isLoadingUserLocation ||
                !this.locationData?.lat ||
                !this.locationData?.lng ||
                this.locationData?.lat !== this.nearMeResult?.data?.lat ||
                this.locationData?.lng !== this.nearMeResult?.data?.lng
            ) {
                this.goToResult(result)
            }
        },

        addToHistory(result, key = TERM_HISTORY_KEY) {
            let persistedHistory = this.getHistory(key)
            const existingIndex = persistedHistory.findIndex((p) => p.id === result.id)

            if (existingIndex > -1) {
                persistedHistory.splice(existingIndex, 1)
            }
            persistedHistory = [
                {
                    ...result,
                },
                ...persistedHistory.splice(0, 9),
            ]

            localStorage.set(key, persistedHistory)
        },

        goToResult(result) {
            if (this.isLoading) {
                return
            }
            if (result.type === 'explore' && this.isExploreActive) {
                this.isLoading = false
                this.close()
                return
            }
            this.isLoading = true

            this.currentFocus = null
            this.$refs.termInput.blur()
            this.$refs.locationInput.blur()

            const url = this.getUrlForResult(result)
            window.location.href = url
        },

        checkIfSmall() {
            this.isSmall = window.innerWidth < 500
        },

        loadRecommendations() {
            http.get('/search/autocomplete/recommendations/', {
                params: {
                    ...(this.defaultLocation?.data || {}),
                    ...(this.savedUserPosition || {}),
                },
            }).then(({ data }) => {
                this.recommendations = data.data
            })
        },

        loadLocationRecommendations: debounce(function () {
            http.get('/search/autocomplete/locations/recommendations/', {
                params: {
                    ...(this.defaultLocation?.data || {}),
                    ...(this.savedUserPosition || {}),
                },
            }).then(({ data }) => {
                this.locationRecommendations = data.data
            })
        }, 200),
    },
}
</script>
