import { on } from '../../utils/events.js'

export default function tooltip(selector: Element | HTMLElement | NodeListOf<Element> | string) {
    if (typeof selector === 'string') {
        on(
            'mouseenter',
            window.document,
            selector,
            (e) => {
                if (!e.matchingTarget.tooltip) {
                    e.matchingTarget.tooltip = new Tooltip(e.matchingTarget)
                }
            },
            true,
        )
        return
    }
    if (selector instanceof NodeList) {
        selector.forEach((elm) => {
            new Tooltip(elm)
        })
        return
    }
    return new Tooltip(selector)
}

interface EventBound {
    element: HTMLElement
    event: keyof HTMLElementEventMap
    fn: EventListenerOrEventListenerObject
}

export class Tooltip {
    element: HTMLElement

    tooltipElement: null | HTMLElement = null

    eventsBound: EventBound[] = []

    #removeTimeout: number | null = null

    #mutationObserver: MutationObserver | null

    #hasPreventedClick: boolean = false

    constructor(element: Element | HTMLElement | string) {
        if (typeof element === 'string') {
            this.element = window.document.querySelector(element)
        } else if (element instanceof Element) {
            this.element = <HTMLElement>element
        } else {
            this.element = element
        }
        if (!this.element) {
            throw new Error('Invalid element.')
        }

        this.#bindEvents()
    }

    show() {
        if (!this.tooltipElement) {
            this.#initTooltipElement()
        }
        this.refresh()
        this.tooltipElement.style.display = 'block'
    }

    hide() {
        if (!this.tooltipElement) return
        this.tooltipElement.style.display = 'none'
    }

    #initTooltipElement() {
        const element = document.createElement('div')
        element.classList.add('tooltip')
        element.style.display = 'none'
        element.innerHTML = '<div class="tooltip__inner"></div>'
        document.querySelector('body').appendChild(element)
        this.tooltipElement = element
        this.#updateContent()
    }

    remove() {
        if (!this.tooltipElement) return
        this.tooltipElement.parentNode.removeChild(this.tooltipElement)
        this.tooltipElement = null
    }

    destroy() {
        this.remove()
        this.#unbindEvents()
    }

    refresh() {
        if (!this.tooltipElement) return

        const rect = this.element.getBoundingClientRect()
        this.tooltipElement.style.visibility = 'hidden'
        this.tooltipElement.style.opacity = '0'

        window.requestAnimationFrame(() => {
            const tooltipRect = this.tooltipElement.getBoundingClientRect()

            let overflowParent = null
            let parent = this.element.parentNode
            while (parent && parent instanceof HTMLElement) {
                if (['auto', 'hidden'].indexOf(window.getComputedStyle(parent).overflow) !== -1) {
                    overflowParent = parent
                    break
                }
                parent = parent.parentNode
            }
            let minX = 0,
                maxX = window.innerWidth,
                minY = 0
            if (overflowParent) {
                const bounds = overflowParent.getBoundingClientRect()
                minX = bounds.x
                maxX = bounds.x + bounds.width
                minY = bounds.y
            }

            const scrollTop =
                window.scrollY || document.body.scrollTop || document.documentElement.scrollTop

            const position = {
                top: scrollTop + rect.y - tooltipRect.height,
                left: rect.x + (Math.round(rect.width / 2) - Math.round(tooltipRect.width / 2)),
            }

            let offset = 0

            let atTop = true

            if (position.top < minY) {
                position.top = rect.y + rect.height
                atTop = false
            }
            if (position.left < minX) {
                offset -= minX - position.left
                position.left += minX - position.left
            } else if (position.left + tooltipRect.width > maxX) {
                offset += position.left + tooltipRect.width - maxX
                position.left = maxX - tooltipRect.width
            }
            this.tooltipElement.classList.toggle('tooltip--top', atTop)
            this.tooltipElement.classList.toggle('tooltip--bottom', !atTop)
            this.tooltipElement.style.setProperty('--tooltip-translate-x', position.left + 'px')
            this.tooltipElement.style.setProperty('--tooltip-translate-y', position.top + 'px')
            this.tooltipElement.style.setProperty('--tooltip-offset', offset + 'px')

            this.tooltipElement.style.visibility = 'visible'
            this.tooltipElement.style.opacity = ''
        })
    }

    #getContent(): string | null {
        return this.element.dataset.tooltip
    }

    #updateContent() {
        const value = this.#getContent()

        if (!this.tooltipElement) return

        const inner = <HTMLElement>this.tooltipElement.firstElementChild

        if (!value) {
            inner.innerText = ''
            this.element.removeAttribute('aria-label')
            this.tooltipElement.setAttribute('tooltip-no-content', '')
        } else {
            inner.innerText = value
            if (!this.element.hasAttribute('aria-label')) {
                this.element.setAttribute('aria-label', value)
            }
            this.tooltipElement.removeAttribute('tooltip-no-content')
        }
    }

    #bindEvent(element, event, fn) {
        const boundFn = fn.bind(this)
        element.addEventListener(event, boundFn)
        this.eventsBound.push({
            element,
            event,
            fn: boundFn,
        })
    }

    #bindEvents() {
        this.#bindEvent(this.element, 'mouseenter', this.#onMouseEnter)
        this.#bindEvent(this.element, 'mouseleave', this.#onMouseLeave)
        this.#bindEvent(this.element, 'click', this.#onClick)

        this.#mutationObserver = new MutationObserver(() => {
            this.#updateContent()
            this.refresh()
        })
        this.#mutationObserver.observe(this.element, {
            attributes: true,
            childList: false,
            subtree: false,
            attributeFilter: ['data-tooltip'],
        })
    }

    #unbindEvents() {
        this.eventsBound.forEach((e) => {
            e.element.removeEventListener(e.event, e.fn)
        })
        this.#mutationObserver.disconnect()
    }

    #onMouseEnter() {
        if (this.#removeTimeout !== null) {
            window.clearTimeout(this.#removeTimeout)
        }
        this.show()
    }

    #onMouseLeave() {
        this.hide()
        this.#removeTimeout = window.setTimeout(() => {
            this.remove()
        }, 1000)
    }

    #onClick(e) {
        // Prevent first click on touch devices
        if (
            this.element.closest('a[href]') &&
            !this.#hasPreventedClick &&
            'ontouchstart' in window
        ) {
            e.preventDefault()
            this.#hasPreventedClick = true
        }
    }
}
