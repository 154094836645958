import { translate } from '@modules/locale/locale.js'
import { popup } from '@modules/popup/popup.js'

export default function confirm(title = null, message = null, yesLabel = null, cancelLabel = null) {
    let customHtml
    let htmlAfter = false
    let buttons

    if (title !== null && typeof title === 'object') {
        yesLabel = title.yesLabel || null
        cancelLabel = title.cancelLabel || null
        customHtml = title.html || null
        message = title.message || null
        buttons = title.buttons || null
        title = title.title || null // This always has to be last
    }

    title = title || translate('confirm_title')
    const yes = yesLabel || translate('confirm_yes')
    const cancel = cancelLabel || translate('confirm_cancel')

    let templateHtml = `<div class="text-center">
							<h2 class="text-lg text-strong">${title}</h2>
							${message ? `<p class="leading-relaxed mb-0">${message}</p>` : ''}
						</div>`

    if (customHtml && htmlAfter) {
        templateHtml = `<div>${templateHtml + customHtml}</div>`
    } else if (customHtml && !htmlAfter) {
        templateHtml = `<div>${customHtml + templateHtml}</div>`
    }

    const templateTag = document.createElement('template')
    templateTag.innerHTML = templateHtml.trim()
    templateHtml = templateTag.content.firstChild

    return new Promise((resolve) => {
        popup({
            cssClass: 'popup-confirm',
            content: templateHtml,
            width: 325,
            afterClose: () => resolve(false),
            buttons: buttons || [
                {
                    label: yes,
                    class: 'text-blue',
                    onclick: (button, popup) => {
                        resolve(true)
                        popup.close()
                    },
                },
                {
                    label: cancel,
                    onclick: (button, popup) => {
                        resolve(false)
                        popup.close()
                    },
                },
            ],
        })
    })
}

export function confirmOk(title = null, message = null) {
    return confirm({
        title,
        message,
        buttons: [
            {
                label: 'Ok!', // TODO: Translate this
                onclick(button, popup) {
                    popup.close()
                },
            },
        ],
    })
}
