let bindings = {}

const on = function (eventName, element, selector, handler = null, deep = false) {
    if (arguments.length === 3) {
        handler = selector
        selector = element
        element = document
    }

    const listener = function (e) {
        // loop parent nodes from the target to the delegation node
        for (var target = e.target; target && target !== this; target = target.parentNode) {
            if (target.matches(selector)) {
                e.matchingTarget = target
                handler.call(target, e)
                break
            }
        }
    }

    element.addEventListener(eventName, listener, deep)

    const result = {
        remove() {
            element.removeEventListener(eventName, listener)
        },
        selector,
        eventName,
        element,
        handler,
        listener,
    }

    if (!(element in bindings)) {
        bindings[element] = []
    }
    bindings[element].push(result)

    return result
}

const off = function (eventName, element, selector, handler) {
    if (!(element in bindings)) {
        return false
    }
    for (let i = 0; i < bindings[element].length; i++) {
        const binding = bindings[element][i]
        if (
            binding.eventName === eventName &&
            binding.selector === selector &&
            binding.handler === handler
        ) {
            binding.remove()
        }
    }
    return true
}

export { on, off }
