<template>
    <aside class="place-explorer__map tw-overflow-hidden">
        <ThatsupMap
            v-if="isReady && mapIsActivated && !placePage.has_no_location"
            class="place-explorer__map__map"
            :markers="markers"
            :polygons="polygons"
            :auto-zoom="shouldAutoZoom"
            :padding="actualPadding"
            :zoom="userPosition ? 14 : null"
            :user-position="userPosition"
            :user-radius="userRadius"
            :active-marker="
                highlightedPlace ? highlightedPlace.id : activePlace ? activePlace.id : null
            "
            @marker-click="mapMarkerClick"
            @map-click="mapClick"
            @user-position-change="updateUserPosition"
            @load="mapLoad"
            @moveend="mapMoveEnd"
            v-bind="listeners"
            ref="map"
        />
        <div
            v-if="!placePage.has_no_location"
            class="tw-absolute tw-z-10 tw-h-full tw-w-full tw-transition-all tw-duration-1000"
            :class="
                !mapIsActivated
                    ? 'tw-opacity-1 tw-pointer-events-auto'
                    : 'tw-pointer-events-none tw-opacity-0'
            "
            @click="mapIsActivated = true"
        >
            <div
                class="bg-dark-slate-blue tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-opacity-80"
            ></div>
            <span
                v-if="placePage.data.length"
                class="tw-absolute tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-font-bold tw-text-white tw-transition-all tw-duration-300"
                :class="mapIsActivated ? 'tw-scale-110' : 'tw-scale-100'"
                :style="{
                    top: pageHeaderHeight + 'px',
                    bottom: actualPadding.bottom + 'px',
                }"
            >
                <svg
                    class="tw-inline-block tw-h-8 tw-w-8 tw-flex-shrink-0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-0.75 -0.75 24
24"
                >
                    <path
                        d="M6.328125 21.795V14.0625a4.921875 4.921875 0 1 1 9.84375
0v7.734375"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    ></path>
                    <path
                        d="m13.359375 14.76375 0 1.40625 -4.21875 0 0 -1.40625"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    ></path>
                    <path
                        d="M21.796875 5.881875a13.3265625 13.3265625 0 0 0 -21.09375 0"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    ></path>
                    <path
                        d="M18.984375 18.916875a9.140625
9.140625 0 1 0 -15.46875 0"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    ></path>
                </svg>
                {{ $t('explore.map.show_map') }}
            </span>
        </div>
        <ExploreMapControls
            v-if="withControls"
            :style="controlsStyle"
            :place-page="placePage"
            :filter-on-viewport="filterOnViewport"
            :is-loading-user-location="isLoadingUserLocation"
            :is-loading-filter-on-viewport="isLoadingFilterOnViewport"
            :user-location-has-error="userLocationHasError"
            :is-at-user-location="isAtUserLocation"
            :is-watching-user-location="isWatchingUserLocation"
            :is-at-viewport="isAtViewport"
            :is-expanded="isExpanded"
            :is-mobile="isMobile"
            @expand="$emit('expand')"
            @contract="$emit('contract')"
            @next="$emit('next')"
            @prev="$emit('prev')"
            @filter-on-viewport="filterOnMapBounds"
            @go-to-user-position="goToUserPosition"
        />
        <ExploreMapPlaceList
            v-if="withList"
            :place-page="placePage"
            :sponsored-places="sponsoredNotInResult"
            :active-place="activePlace"
            :style="listStyle"
            @active-place-change="listChange"
        />
    </aside>
</template>

<script>
import ThatsupMap from '../../Shared/components/ThatsupMap.vue'
import ExploreMapPlaceList from './ExploreMapPlaceList.vue'
import ExploreMapControls from './ExploreMapControls.vue'
import { DrawControl } from '../../Shared/util/mapControls'
import mapMarkers from '../../../es6/src/modules/map/markers.js'
import { requestUserPosition } from '../../../es6/src/utils/geo.js'
import { useUserStore } from '@store/user.js'

const COORDINATE_PRECISION = 6

let drawControl = null

export default {
    components: { ExploreMapControls, ExploreMapPlaceList, ThatsupMap },

    props: {
        placePage: {
            type: Object,
            required: false,
            default: null,
        },
        sponsoredPlaces: {
            type: Array,
            required: false,
            default: () => [],
        },
        padding: {
            type: Object,
            required: false,
            default: null,
        },
        isReady: Boolean,
        isLoading: Boolean,
        isExpanded: Boolean,
        isMobile: Boolean,
        params: {
            type: Object,
            required: false,
            default: null,
        },
        withList: Boolean,
        withControls: Boolean,
        highlightedPlace: {
            type: Object,
            required: false,
            default: null,
        },
        bodyOffset: Number,
        pageHeaderHeight: Number,
        requireActivation: Boolean,
    },

    data() {
        return {
            activePlaceId: null,
            filterOnViewport: false,
            viewport: null,
            isLoadingUserLocation: false,
            isLoadingFilterOnViewport: false,

            userLocationHasError: false,

            currentUserLocation: null,

            isAtUserLocation: false,
            isWatchingUserLocation: false,

            mapIsActivated: !this.requireActivation,
        }
    },

    computed: {
        shouldAutoZoom() {
            return !this.params.ll && !this.userPosition
        },
        listeners() {
            return Object.keys(this.$attrs).reduce((acc, key) => {
                if (typeof this.$attrs[key] !== 'function') return acc
                acc['on' + key[0].toUpperCase() + key.substring(1)] = this.$attrs[key]
                return acc
            }, {})
        },
        actualPadding() {
            if (this.filterOnViewport) {
                return { top: 0, left: 0, right: 0, bottom: 0 }
            }
            return this.padding
        },
        controlsStyle() {
            return {
                bottom: (this.actualPadding?.bottom || 0) + 'px',
                opacity: this.isMobile && this.activePlace ? 0 : 1,
            }
        },
        listStyle() {
            return {
                paddingBottom: (this.actualPadding?.bottom || 0) + 'px',
            }
        },
        sponsoredNotInResult() {
            return this.sponsoredPlaces.filter((p) => {
                return !this.placePage.data.some((p2) => p2.id === p.id)
            })
        },
        markers() {
            if (!this.placePage) {
                return []
            }
            const markers = [
                ...this.placePage.data,
                ...this.sponsoredNotInResult.map((p) => {
                    return {
                        ...p,
                        label: 'S',
                        markerIcon: mapMarkers.PLACE_SPONSORED,
                    }
                }),
            ]
                .filter((p) => !p.isRemoved)
                .map((place, i) => {
                    if (!place.latitude || !place.longitude) {
                        return false
                    }
                    return {
                        lat: place.latitude,
                        lng: place.longitude,
                        id: place.id,
                        icon: place.markerIcon || mapMarkers.PLACE,
                        label: place.label || (this.placePage.from + i).toString(),
                        data: {
                            placeId: place.id,
                        },
                        quicklook: {
                            type: 'place',
                            id: place.id,
                        },
                    }
                })
                .filter((p) => !!p)

            // Move sponsored results to the top
            // markers.sort((a,b) => {
            //     if(a.icon !== b.icon && a.icon === mapMarkers.PLACE_SPONSORED) return -1;
            //     if(a.icon !== b.icon && b.icon === mapMarkers.PLACE_SPONSORED) return 1;
            //     return 0;
            // })
            return markers
        },
        polygons() {
            return this.placePage.geo?.polygons || []
        },
        polygonCenter() {
            return this.polygons.map((coords) => {
                const sum = coords.reduce(
                    (carry, latLng) => {
                        return [carry[0] + latLng[0], carry[1] + latLng[1]]
                    },
                    [0, 0],
                )
                return [sum[0] / coords.length, sum[1] / coords.length]
            })
        },
        userPosition() {
            if (this.placePage.geo.lat && this.placePage.geo.lng) {
                return {
                    lat: this.placePage.geo.lat,
                    lng: this.placePage.geo.lng,
                }
            }
            return null
        },
        userRadius() {
            return this.params?.radius || this.placePage.geo?.radius
        },
        activePlace() {
            if (!this.activePlaceId) {
                return null
            }
            return (
                [...this.placePage.data, ...this.sponsoredNotInResult].find((place) => {
                    return place.id === this.activePlaceId
                }) || null
            )
        },
        isAtViewport() {
            if (!this.viewport || !this.params.ll) {
                return false
            }
            let ll = this.params.ll
            if (Array.isArray(ll)) {
                ll = ll.join('|')
            }
            return this.viewport.map((c) => c.join(',')).join('|') === ll
        },
    },

    watch: {
        params(newVal) {},
        isExpanded(newVal) {
            if (!this.mapIsActivated) {
                this.mapIsActivated = true
            }
        },
        placePage() {
            if (this.isLoadingFilterOnViewport) {
                this.isLoadingFilterOnViewport = false
            }
            if (drawControl) {
                drawControl.hide()
            }
        },
        userPosition(newVal) {
            if (!this.currentUserLocation || !newVal) {
                this.isAtUserLocation = false
            } else {
                this.isAtUserLocation =
                    this.compareCoords(this.currentUserLocation.lat, this.userPosition.lat) &&
                    this.compareCoords(this.currentUserLocation.lng, this.userPosition.lng)
            }
        },
        activePlace(newVal) {
            this.$emit('active-place-change', newVal)
        },
        filterOnViewport(newVal) {
            if (newVal) {
                this.filterOnMapBounds()
            }
        },
        viewport(newVal) {
            if (newVal && this.filterOnViewport) {
                return this.filterOnMapBounds()
            }
        },
    },

    methods: {
        mapLoad(map) {
            window.exploreMap = map

            const userStore = useUserStore()

            if (userStore?.user?.admin) {
                drawControl = new DrawControl(map)
                map.addControl(drawControl)
                let lastChanged = null
                drawControl.on('enabled', () => {
                    map.removeShapes()
                })
                drawControl.on('create', () => this.onMapDraw(drawControl.features()))
                drawControl.on('update', (e) => {
                    lastChanged = e.features[0].id
                })
                drawControl.on('modechange', (e) => {
                    if (e.mode === 'simple_select') {
                        this.onMapDraw(drawControl.features())
                    }
                })
                drawControl.on('selectionchange', (e) => {
                    if (e.features.length === 0 && e.points.length === 0 && lastChanged) {
                        this.onMapDraw(drawControl.features())
                    }
                })
            }
        },
        resize() {
            if (this.$refs.map) {
                this.$refs.map.resize()
            }
        },
        mapMarkerClick(marker, e) {
            this.activePlaceId = marker.id
            e.preventDefault()
        },
        mapClick(map, e) {
            if (!e.originalEvent?.defaultPrevented) {
                this.activePlaceId = null
            }
        },
        listChange(place) {
            if (this.activePlaceId !== place.id) {
                this.activePlaceId = place.id
                this.$nextTick(() => {
                    if (this.activePlace) {
                        this.$refs.map.panTo(this.activePlace.latitude, this.activePlace.longitude)
                    }
                })
            }
        },
        updateUserPosition(value) {
            this.filterOnViewport = false
            this.$emit(
                'input',
                {
                    lat: value?.lat,
                    lng: value?.lng,
                    radius: this.placePage.geo?.radius,
                },
                true,
            )
        },

        mapMoveEnd() {
            this.updateViewportPolygon()
        },

        filterOnMapBounds() {
            if (!this.viewport) {
                return
            }
            this.isLoadingFilterOnViewport = true
            this.$emit(
                'input',
                {
                    ll: this.viewport.map((coords) => coords.join(',')),
                },
                true,
            )
        },

        updateViewportPolygon() {
            const map = this.$refs.map.map
            if (!map?.getBounds) {
                return
            }
            const bounds = map.getBounds()
            const nw = bounds.getNorthWest()
            const ne = bounds.getNorthEast()
            const se = bounds.getSouthEast()
            const sw = bounds.getSouthWest()
            this.viewport = [
                [this.parseCoord(nw.lat), this.parseCoord(nw.lng)],
                [this.parseCoord(ne.lat), this.parseCoord(ne.lng)],
                [this.parseCoord(se.lat), this.parseCoord(se.lng)],
                [this.parseCoord(sw.lat), this.parseCoord(sw.lng)],
                [this.parseCoord(nw.lat), this.parseCoord(nw.lng)],
            ]
        },

        goToUserPosition() {
            if (this.isLoadingUserLocation) {
                return
            }
            this.filterOnViewport = false
            this.isLoadingUserLocation = true
            this.$emit('loading', true)
            requestUserPosition()
                .then((position) => {
                    this.isAtUserLocation = true
                    this.isLoadingUserLocation = false
                    this.userLocationHasError = false
                    this.currentUserLocation = position
                    this.$emit(
                        'input',
                        {
                            ...this.currentUserLocation,
                            radius: this.placePage.geo?.radius,
                        },
                        true,
                    )
                    this.$emit('loading', false)
                })
                .catch(() => {
                    this.$emit('loading', false)
                    this.isLoadingUserLocation = false
                    this.userLocationHasError = true
                })
        },
        compareCoords(a, b) {
            return this.parseCoord(a) === this.parseCoord(b)
        },
        parseCoord(coord) {
            return parseFloat(parseFloat(coord).toFixed(COORDINATE_PRECISION))
        },
        onMapDraw({ features }) {
            if (!features || !features.length) {
                return
            }
            const coordinates = features[0].geometry.coordinates[0]
            this.$emit(
                'input',
                {
                    ll: coordinates.map((coords) =>
                        coords.reverse().map(this.parseCoord).join(','),
                    ),
                },
                true,
            )
        },
    },

    created() {
        if (this.placePage.has_no_location) {
            this.goToUserPosition()
        }
    },

    destroyed() {},
}
</script>
