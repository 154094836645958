import { headerHeight } from '@modules/header/header.js'

export function handleMainHeaderCompact() {
    if (document.querySelector('.site-search')) {
        const classList = document.querySelector('.main-header').classList

        const callback = (e) => {
            if (e[0].isIntersecting) {
                classList.add('main-header--compact')
            } else {
                classList.remove('main-header--compact')
            }
        }

        let observer = new IntersectionObserver(callback, {
            root: null,
            rootMargin: `-${headerHeight(true)}px`,
        })

        observer.observe(document.querySelector('.site-search'))
    }
}
