import { defineStore } from 'pinia'
import { HAS_ACCEPTED_GEOLOCATION_KEY } from '@/es6/src/constants.js'
import http from '@utils/http.js'
import { numberFormat, translate } from '@modules/locale/locale.js'
import { identify, trackEvent } from '@modules/tracking/tracking.js'
import { confirmOk } from '@utils/confirm.js'
import { popup } from '@modules/popup/popup.js'
import { auth, authentication } from '@modules/authentication/authentication.js'
import Eventbus from '@modules/eventbus/Eventbus.js'

const hasAcceptedGeolocation = window.sessionStorage?.getItem(HAS_ACCEPTED_GEOLOCATION_KEY)

export const useUserStore = defineStore('user', {
    state: () => ({
        user: window.globalVars.user || null,
        token: window.globalVars.token,
        trophiesToShow: [],
        locale: {
            code: window.globalVars.locale,
            fullCode: window.globalVars.fullCode,
        },
        // TODO: Implement location and verify that it works
        location: null,
        hasAcceptedGeolocation: hasAcceptedGeolocation === null ? null : !!hasAcceptedGeolocation,
        permissions: window.globalVars?.userPermissions || [],

        notificationsAreLoading: false,
        notificationsHaveReachedEnd: false,
        notificationPage: 1, // TODO: Implement this
        notificationCount: 0,
        userMessageCount: 0,
        notificationHTML: null,

        userUpdateIntervalIterator: 0,
    }),
    getters: {
        isLoggedIn: (state) => !!state.user,
        hasPermission: (state) => {
            if (!state.permissions) {
                return false
            }

            return (permission, godmodeEnabled = true) => {
                if (godmodeEnabled && state.permissions.indexOf('godmode') !== -1) {
                    return true
                } else if (
                    typeof permission === 'string' &&
                    state.permissions.indexOf(permission) !== -1
                ) {
                    return true
                } else if (Array.isArray(permission)) {
                    for (let i = 0; i < permission.length; i++) {
                        if (state.permissions.indexOf(permission[i]) === -1) {
                            return false
                        }
                    }
                    return true
                }
                return false
            }
        },
    },
    actions: {
        auth: auth,
        refreshUserProgress(force = false) {
            return this.updateUser(force)
        },
        updateUser: function (force) {
            let url = '/user/me/json/'

            http.get(url, {
                params: {
                    force: !!force,
                },
            }).then(({ data }) => {
                if ('sessionStorage' in window) {
                    window.sessionStorage.setItem('last_user_update', new Date().toISOString())
                }
                if (this.isLoggedIn && !data.loggedIn) {
                    const oldUser = { ...this.user }
                    this.setUser(null)
                    auth(
                        (response) => {
                            if (!response.user || oldUser.username !== response.user.username) {
                                window.location.reload()
                            } else {
                                this.updateUser(true)
                                window.setTimeout(function () {
                                    authentication.closePopup()
                                }, 2000)
                            }
                        },
                        {
                            variation: 'logged-out',
                        },
                    )
                }

                this.token = data.token

                if (data.loggedIn) {
                    this.setUser(data.user)
                    this.setNoticationCount(data.notifications.count, data.messages.count)
                    if (data.message) {
                        confirmOk('', data.message)
                    }

                    if (data.trophies.length) {
                        this.trophiesToShow = data.trophies
                    }
                }
            })
        },

        updateUserInterval() {
            let lastCheck = null
            if (this.userUpdateIntervalIterator > 0 && 'sessionStorage' in window) {
                const sessionCheck = window.sessionStorage.getItem('last_user_update')
                if (sessionCheck) {
                    try {
                        lastCheck = Date.parse(sessionCheck)
                    } catch (e) {
                        lastCheck = null
                    }
                }
            }
            const now = new Date()
            let nextTimeout = 60 * 1000 * (this.isLoggedIn ? 5 : 10)
            if (lastCheck && now.getTime() - lastCheck < nextTimeout) {
                nextTimeout -= now.getTime() - lastCheck
            } else {
                this.updateUser(this.userUpdateIntervalIterator === 0)
            }
            this.userUpdateIntervalIterator += 1
            window.setTimeout(this.updateUserInterval, nextTimeout)
        },

        setUser(user) {
            let userChanges = this.checkUserChanges(user)
            const userChange =
                (!this.user && user) ||
                (this.user && !user) ||
                (this.user && user && this.user.username !== user.username)
            if (userChange) {
                Eventbus.emit('user:change', {
                    user: user,
                    from: this.user,
                })
            } else {
                Eventbus.emit('user:update', user)
            }

            this.user = user

            if (!this.user) {
                return
            }
            if ('sessionStorage' in window) {
                window.sessionStorage.setItem('user', JSON.stringify(this.user))
            }
            if (userChanges) {
                let navs = document.querySelector('#header')
                let links = navs.querySelectorAll('*[data-link]')
                links.forEach(function ($elm) {
                    let property = $elm.dataset.link

                    if (user[property] === undefined) {
                        return
                    }
                    let value = user[property]
                    let type = $elm.dataset.type || 'text'
                    let locale = $elm.dataset.locale
                    let format = $elm.dataset.format

                    if (format) {
                        if (format === 'percent') {
                            value = Math.round(value * 100) + '%'
                        } else if (format === 'number') {
                            value = numberFormat(value, 0)
                        }
                    }
                    if (locale) {
                        value = translate(locale, value)
                    }

                    if (type === 'text') {
                        $elm.textContent = value
                    } else if (type === 'width') {
                        $elm.style.width = value + 'px'
                    } else if (type === 'class') {
                        const s = $elm.getAttribute('class').replace(/\blevel-[0-9]+\b/, '')

                        $elm.setAttribute('class', s.trim())

                        $elm.classList.add('level-' + value)
                    } else if (type === 'href') {
                        $elm.href = value
                    } else if (type === 'src') {
                        $elm.src = value
                    }
                })
            }
            if (userChange) {
                identify(this.user)
            }
        },

        setNoticationCount(count, messageCount) {
            if (count) {
                this.notificationCount = count
                window.requestAnimationFrame(function () {
                    document.querySelectorAll('.notification-count').forEach((el) => {
                        el.textContent = count
                        el.classList.add('has-notification')
                    })
                })
            } else {
                this.notificationCount = 0
                window.requestAnimationFrame(function () {
                    document.querySelectorAll('.notification-count').forEach((el) => {
                        el.textContent = '0'
                        el.classList.remove('has-notification')
                    })
                })
            }
            if (messageCount) {
                this.userMessageCount = messageCount
                window.requestAnimationFrame(function () {
                    document.querySelectorAll('.user-message-count').forEach((el) => {
                        el.textContent = messageCount
                        el.classList.add('has-notification')
                    })
                })
            } else {
                this.userMessageCount = 0
                window.requestAnimationFrame(function () {
                    document.querySelectorAll('.user-message-count').forEach((el) => {
                        el.textContent = '0'
                        el.classList.remove('has-notification')
                    })
                })
            }
        },
        checkUserChanges(user) {
            let savedUser = this.user

            if (!savedUser) {
                if ('sessionStorage' in window) {
                    try {
                        savedUser = JSON.parse(window.sessionStorage.getItem('user'))
                    } catch (e) {
                        savedUser = false
                    }
                } else {
                    savedUser = false
                }
            }
            if (!savedUser || !user) {
                return false
            }

            let didChange = false
            for (const key in user) {
                if (!(key in user)) {
                    continue
                }
                if (user[key] !== savedUser[key]) {
                    didChange = true
                    break
                }
            }

            if (user.username === savedUser.username) {
                if (user.rankLevel !== savedUser.rankLevel) {
                    if (user.rankLevel > savedUser.rankLevel) {
                        this.showRankLevelPopup()
                    }
                    Eventbus.emit('user:rank-level:change', {
                        rankLevel: user.rankLevel,
                        from: savedUser.rankLevel,
                    })
                }
                if (user.userXP !== savedUser.userXP) {
                    if (user.userXP > savedUser.userXP) {
                        this.showXPProgress(user.userXP - savedUser.userXP)
                    }
                    Eventbus.emit('user:xp:change', {
                        xp: user.userXP,
                        from: savedUser.userXP,
                    })
                }
            }
            return didChange
        },
        showRankLevelPopup: function () {
            http.get('/user/rank/message/').then(({ data }) => {
                if (!data.success) {
                    return
                }

                trackEvent('Rank level popup', {
                    category: 'User',
                    label: this.user.username,
                    value: this.user.rankLevel,
                    nonInteraction: true,
                })

                const container = document.createElement('div')

                let pretitle = document.createElement('h2')
                pretitle.textContent = data.pretitle
                container.appendChild(pretitle)

                let title = document.createElement('h1')
                title.className = 'user-rank-color-' + data.rankLevel + '-f'
                title.textContent = data.title
                container.appendChild(title)

                let iconContainer = document.createElement('div')
                iconContainer.className = 'icon-container'
                container.appendChild(iconContainer)

                let icon = document.createElement('i')
                icon.className = 'icon--rank-level icon--rank-level--shadow level-' + data.rankLevel
                iconContainer.appendChild(icon)

                let message = document.createElement('p')
                message.textContent = data.message
                container.appendChild(message)

                return popup({
                    content: container,
                    cssClass: 'user-rank-message',
                    width: 400,
                    queue: true,
                })
            })
        },
        showXPProgress(xp) {
            window.requestAnimationFrame(() => {
                document
                    .querySelectorAll('#header .coin-container')
                    .forEach((coinContainerElement) => {
                        const xpElement = coinContainerElement.querySelector(
                            '#header .coin-container .back span',
                        )

                        xpElement.textContent = xp
                        coinContainerElement.classList.remove('show')
                        window.requestAnimationFrame(() => {
                            coinContainerElement.classList.add('show')
                        })
                    })
            })
        },
        loadMobileNotifications() {
            if (!this.isLoggedIn) {
                return Promise.reject('User is not logged in.')
            }
            if (this.notificationsAreLoading) {
                return Promise.reject('Notifications are loading.')
            }

            const url = '/user/notifications/popup/'

            this.notificationsAreLoading = true

            return http
                .get(url, { params: { page: this.notificationPage } })
                .then(({ data }) => {
                    // loadingContainer.innerHTML = data
                    this.notificationPage = this.notificationPage + 1
                    this.notificationsHaveReachedEnd = !data

                    if (this.notificationHTML !== null) {
                        this.notificationHTML += data
                    } else {
                        this.notificationHTML = data
                    }

                    this.updateUser(true)
                })
                .finally(() => {
                    this.notificationsAreLoading = false
                })
        },
    },
})
