<template>
    <li>
        <component
            :is="component"
            :href="href"
            v-bind="$attrs"
            class="tw-md:px-8 tw-flex tw-w-full tw-items-center tw-gap-4 tw-px-4 tw-py-4 tw-text-left hover:tw-bg-black/5 active:tw-opacity-80"
            :class="{
                'tw-bg-black/10': highlighted,
            }"
        >
            <slot name="icon" />

            <div
                class="color-body tw-flex tw-min-w-0 tw-items-center tw-justify-start tw-gap-2 tw-font-semibold"
            >
                <slot>{{ label }}</slot>
            </div>
        </component>
    </li>
</template>

<script setup>
import { computed } from 'vue'

defineOptions({
    inheritAttrs: false,
})

const props = defineProps({
    as: {
        type: String,
        default: null,
    },
    href: {
        type: String,
        default: null,
    },
    icon: {
        type: String,
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    highlighted: {
        type: Boolean,
        default: false,
    },
})

const component = computed(() => {
    return props.as || (props.href ? 'a' : 'button')
})
</script>
