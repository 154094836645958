import trackers from './trackers.js'

export function log() {
    //console._log.apply(window,arguments);
}

export function identify(user) {
    for (const channel in trackers) {
        if (trackers[channel].indentify) {
            log('Identifying ' + channel, user)
            trackers[channel].indentify(user)
        }
    }
}

export function track(events) {
    if (!events) {
        return false
    }
    for (const channel in events) {
        if (trackers[channel]) {
            log('Tracking ' + channel, events[channel])
            trackers[channel].track(events[channel])
        }
    }
}

export function trackEvent(name, properties = {}) {
    track({
        gtag: {
            name,
            properties,
        },
    })
}

// Simple accessor for all interaction events
export function interaction(name, properties) {
    const baseEvent = {
        name: name,
        properties: properties,
    }

    track({
        fbq: baseEvent,
    })
}

export function pageView(url) {
    for (const channel in trackers) {
        if (trackers[channel].pageView) {
            log('Page view ' + channel, url)
            trackers[channel].pageView(url)
        }
    }
}
