<template>
    <form class="auth-form pretty-form" method="POST" action="/test" @submit.prevent="next">
        <EmailLookup
            v-if="!type"
            :model-value="user"
            :invalid-fields="invalidFields"
            :email-suggestion="emailSuggestion"
            @update:model-value="change"
        />
        <EmailLogin
            v-else-if="type === 'login'"
            :model-value="user"
            :invalid-fields="invalidFields"
            @update:model-value="change"
        />
        <EmailSignup
            v-else-if="type === 'signup'"
            :model-value="user"
            :invalid-fields="invalidFields"
            @update:model-value="change"
        />
        <EmailForgotPasswordSend
            v-else-if="type === 'forgot-send'"
            v-model="user"
            :invalid-fields="invalidFields"
            @update:model-value="change"
        />
        <EmailForgotPasswordRecover
            v-else-if="type === 'forgot-recover'"
            v-model="user"
            :invalid-fields="invalidFields"
            :forgot-password-code="forgotPasswordCode"
            @update:model-value="change"
        />
        <fieldset v-else-if="type === 'forgot-send-success'" class="form-group text-center mb-4">
            <em>{{ $t('authForm.forgot_password_sent', { ...user }) }}</em>
        </fieldset>
        <div class="form-group">
            <button
                type="submit"
                class="auth-button auth-button--blue auth-button--full"
                :class="{ 'auth-button--loading': isLoading }"
                :disabled="isLoading"
            >
                <span>{{ $t(buttonLabel) }}</span>
            </button>
        </div>
        <p v-if="type === 'login'" class="explanation text-center">
            {{ $t('authForm.forgot_password_question') }}
            <a role="button" @click="type = 'forgot-send'">
                {{ $t('authForm.restore_password') }}
            </a>
        </p>
        <Captcha ref="captcha" v-if="needsCaptcha" />
    </form>
</template>
<script>
import http from '@utils/http'

import EmailLookup from './EmailLookup.vue'
import EmailLogin from './EmailLogin.vue'
import EmailSignup from './EmailSignup.vue'
import EmailForgotPasswordSend from './EmailForgotPasswordSend.vue'
import EmailForgotPasswordRecover from './EmailForgotPasswordRecover.vue'
import Captcha from '../../../Shared/components/Captcha.vue'
import { useUserStore } from '../../../../store/user.js'
import Eventbus from '../../../../es6/src/modules/eventbus/Eventbus.js'
import { authentication } from '../../../../es6/src/modules/authentication/authentication.js'

export default {
    emits: ['update:modelValue'],
    components: {
        EmailLookup,
        EmailLogin,
        EmailSignup,
        EmailForgotPasswordSend,
        EmailForgotPasswordRecover,
        Captcha,
    },
    props: {
        error: {
            type: Object,
            required: false,
            default: null,
        },
        modelValue: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        forgotPasswordCode: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            type: null,
            user: {
                email: '',
                firstName: '',
                lastName: '',
                password: '',
                captcha: null,
            },
            isLoading: false,
            emailSuggestion: null,
        }
    },
    watch: {
        modelValue: {
            deep: true,
            handler(newVal) {
                this.updateUser(newVal)
            },
        },
    },
    computed: {
        invalidFields() {
            return this.error && this.error.invalid ? this.error.invalid : []
        },
        buttonLabel() {
            switch (this.type) {
                case 'forgot-send':
                    return 'authForm.send_instructions'
                    break
                case 'forgot-send-success':
                    return 'authForm.log_in'
                    break
                default:
                    return 'authForm.continue'
                    break
            }
        },
        needsCaptcha() {
            return (
                authentication.captcha.exists() &&
                this.type === 'signup' &&
                this.user.captcha === null
            )
        },
    },
    methods: {
        checkForEmailSuggestion(input) {
            let misspellings = {
                '.con': '.com',
                '.vom': '.com',
                '.von': '.com',
                gamil: 'gmail',
                gail: 'gmail',
                gnail: 'gmail',
                gmial: 'gmail',
                hotnail: 'hotmail',
                hormail: 'hotmail',
                ocloud: 'icloud',
                'icloud.se': 'icloud.com',
                'mai.': 'mail.',
            }
            let match = input
            for (let key in misspellings) {
                let re = new RegExp(
                    '(.*)' + key.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&') + '(.*)',
                )
                if (input.match(re)) {
                    match = match.replace(re, '$1' + misspellings[key] + '$2')
                }
            }
            if (match !== input) {
                return match
            }
            return null
        },
        lookup() {
            this.isLoading = true
            return http.post('/auth/email/lookup/', this.user).then((data) => {
                this.isLoading = false
                return data
            })
        },
        forgotPasswordSend() {
            this.isLoading = true
            return http.post('/auth/forgot-password/send/', this.user).then((data) => {
                this.isLoading = false
                return data
            })
        },
        forgotPasswordRecover() {
            this.isLoading = true
            return http
                .post('/auth/forgot-password/recover/', {
                    ...this.user,
                    code: this.forgotPasswordCode,
                })
                .then((data) => {
                    this.isLoading = false
                    return data
                })
        },
        login() {
            this.isLoading = true
            return authentication.authEmail('login', this.user).then((data) => {
                this.isLoading = false
                return data
            })
        },
        signup() {
            this.isLoading = true
            return authentication.authEmail('signup', this.user).then((data) => {
                this.isLoading = false
                if (!data.data.success) {
                    this.captchaReset()
                }
                return data
            })
        },
        executeCaptcha() {
            return this.$refs.captcha.execute()
        },
        captchaReset() {
            if (this.$refs.captcha) {
                this.$refs.captcha.reset()
            }
            this.user.captcha = null
        },
        captchaError(response) {
            if (this.$refs.captcha) {
                this.$refs.captcha.reset()
            }
            this.$emit('error', {
                data: {
                    user: this.user,
                },
                error: {
                    message: 'Invalid captcha',
                },
            })
        },
        next() {
            if (this.isLoading) {
                return
            }
            if (this.needsCaptcha) {
                this.isLoading = true
                return this.executeCaptcha()
                    .then((response) => {
                        this.isLoading = false
                        this.user.captcha = response
                        this.next()
                    })
                    .catch((response) => {
                        this.isLoading = false
                        this.captchaError(response)
                    })
            }
            if (!this.type) {
                if (!this.emailSuggestion) {
                    const emailSuggestion = this.checkForEmailSuggestion(this.user.email)
                    if (emailSuggestion) {
                        this.emailSuggestion = emailSuggestion
                        return
                    }
                } else {
                    this.emailSuggestion = null
                }
                this.lookup().then(({ data }) => {
                    if (data.error) {
                        this.$emit('error', data)
                    } else if (data.data.exists) {
                        this.type = 'login'
                    } else {
                        this.type = 'signup'
                    }
                })
            } else if (this.type === 'login') {
                this.login()
            } else if (this.type === 'forgot-send') {
                this.forgotPasswordSend().then(({ data }) => {
                    if (data.error) {
                        this.$emit('error', data)
                    } else {
                        this.type = 'forgot-send-success'
                    }
                })
            } else if (this.type === 'forgot-send-success') {
                const userStore = useUserStore()
                userStore.updateUser(true)
                this.type = 'login'
            } else if (this.type === 'forgot-recover') {
                this.forgotPasswordRecover().then(({ data }) => {
                    if (data.error) {
                        this.$emit('error', data)
                    } else {
                        Eventbus.emit('auth:success', {
                            ...data,
                            wasAsync: true,
                        })
                    }
                })
            } else {
                this.signup()
            }
            this.$emit('step', this.type)
        },
        prev() {
            if (!!this.type) {
                this.type = null
                this.$emit('step', this.type)
            } else {
                this.$emit('cancel')
            }
        },
        change(data) {
            this.$emit('update:modelValue', data)
        },
        updateUser(data) {
            this.user = { ...this.user, ...data }
        },
    },
    mounted() {
        this.updateUser(this.modelValue)
        if (this.forgotPasswordCode) {
            this.type = 'forgot-recover'
        }
    },
}
</script>

<style>
.autocomplete-hidden-field--hidden {
    display: none;
}
.autocomplete-hidden-field {
    height: 1px !important;
    outline: none !important;
    padding: 0 !important;
    position: absolute;
    width: 1px !important;
}
</style>
