import { trackEvent } from '../tracking/tracking.js'
import confirm from '@utils/confirm.js'
import { report } from '../community/report.js'
import { share } from '../share/share.js'
import Trophy from '../trophy/trophy.js'
import {
    archivedReviews,
    // editReview,
    generateReviewSummary,
    reportReview,
    translateReview,
} from '../reviews/reviews.js'
import { popup } from '../popup/popup.js'
import { get } from 'lodash-es'
import clickOutside from '../../utils/clickOutside.js'
import http from '../../utils/http.js'
import { checkVisibility } from '../../utils/html.js'
import { useUserStore } from '../../../../store/user.js'
import { PopupBubble } from '../popup/popupBubble.ts'
import { placeList } from '../place-list/placeList.js'
import { slideTo } from '../../utils/animation.js'
import { auth, authentication } from '../authentication/authentication.js'
import Eventbus from '../eventbus/Eventbus.js'
import { setNavWidth } from '../header/header.js'
import { translate } from '../locale/locale.js'
import { approveComment, removeComment } from '@modules/blog/blog.js'

export const dispatchElementHooks = function (e, elm) {
    let actions = elm.dataset.action

    if (actions) {
        actions = actions.split(',')
        dispatchHooks(actions, e, elm)
    }
}

export const dispatchHooks = function (addedHooks, event, element) {
    for (let i = 0; i < addedHooks.length; i++) {
        let hookPath = addedHooks[i]

        const hook = get(hooks, hookPath, null)

        if (hook) {
            hook(event, element)
        }
    }
}

export const add = function (key, hook) {
    hooks[key] = hook
}

export const hooks = {
    popup: function (e, elm) {
        e.preventDefault()

        const options = JSON.parse(elm.dataset.options || '{}')

        return popup(options)
    },

    event(e, elm) {
        if (!elm.dataset.event) {
            return
        }

        Eventbus.emit(elm.dataset.event)
    },

    slideTo: function (e, elm) {
        if (!elm.dataset.to) {
            return
        }

        slideTo(elm.dataset.to)
    },

    dropdown: function (e, elm) {
        e.preventDefault()
        const options = JSON.parse(elm.dataset.options || '{}')
        const target = elm.dataset.target || options.dropdown
        let dropdown = target
            ? document.querySelector(target)
            : elm.parentNode.querySelector('.popup')
        if (!dropdown) {
            return
        }

        dropdown.style.display = 'block'

        clickOutside(dropdown, function () {
            dropdown.style.display = 'none'
        })
    },

    'track-event': function (e, elm) {
        const options = elm.dataset.track ? JSON.parse(elm.dataset.track) : null

        if (!options || !Array.isArray(options) || options.length !== 2) {
            return
        }

        return trackEvent(...options)
    },

    'user-follow': function (e, elm) {
        const usernameToFollow = elm.dataset.username

        const updateFollowerCount = (username, count) => {
            document
                .querySelectorAll(`*[data-hook-link='follower-count'][data-user='${username}']`)
                .forEach((element) => (element.textContent = count))

            document
                .querySelectorAll(
                    `*[data-hook-link='following-count'][data-user='${userStore.user.username}']`,
                )
                .forEach((element) => (element.textContent = Number(element.textContent) + 1))
        }

        const follow = function () {
            elm.classList.add('d-none')
            document
                .querySelector(
                    "*[data-action='user-unfollow'][data-username='" + usernameToFollow + "']",
                )
                .classList.remove('d-none')

            return http
                .post('/user/follow/', {
                    user: usernameToFollow,
                })
                .then(({ data }) => {
                    updateFollowerCount(usernameToFollow, data.followerCount)
                })
                .catch(function () {
                    document
                        .querySelector(
                            "*[data-action='user-unfollow'][data-username='" +
                                usernameToFollow +
                                "'']",
                        )
                        .classList.add('d-none')

                    elm.classList.remove('d-none')
                })
        }

        const userStore = useUserStore()

        if (userStore.isLoggedIn) {
            return follow()
        }

        auth(() => {
            follow().then(() => {
                window.location.reload()
            })
        })
    },
    'user-unfollow': async function (e, elm) {
        let userToFollow = JSON.parse(elm.dataset.user)
        const usernameToFollow = elm.dataset.username

        let unfollow = function () {
            document
                .querySelector(
                    "*[data-action='user-unfollow'][data-username='" + usernameToFollow + "']",
                )
                .classList.add('d-none')

            document
                .querySelector(
                    "*[data-action='user-follow'][data-username='" + usernameToFollow + "']",
                )
                .classList.remove('d-none')

            return http
                .post('/user/unfollow/', {
                    user: usernameToFollow,
                })
                .then(({ data }) => {
                    document
                        .querySelectorAll(
                            `*[data-hook-link='following-count'][data-user='${userStore.user.username}']`,
                        )
                        .forEach((el) => {
                            el.textContent = Number(el.textContent) - 1
                        })

                    document
                        .querySelectorAll(
                            `*[data-hook-link='follower-count'][data-user='${usernameToFollow}']`,
                        )
                        .forEach((el) => {
                            el.textContent = data.followerCount
                        })
                })
                .catch(function () {
                    document
                        .querySelector(
                            "*[data-action='user-unfollow'][data-username='" +
                                usernameToFollow +
                                "']",
                        )
                        .classList.remove('d-none')

                    document
                        .querySelector(
                            "*[data-action='user-follow'][data-username='" +
                                usernameToFollow +
                                "']",
                        )
                        .classList.add('d-none')
                })
        }

        const userStore = useUserStore()

        if (!userStore.isLoggedIn) {
            auth(() => {
                unfollow().then(function () {
                    window.location.reload()
                })
            })
        } else {
            const title = `Sluta följa ${userToFollow.name}?`
            const message = `Du kommer inte längre att få några uppdateringar från ${userToFollow.name}`

            if (
                await confirm({
                    title,
                    message,
                    html: `<div class="artwork artwork--shadow circle large ml-auto mr-auto mb-4">
								<img src="${userToFollow.thumbnail}" alt="${userToFollow.name}">
							</div>`,
                })
            ) {
                unfollow()
            }
        }
    },
    prevent: function (e) {
        e.preventDefault()
    },
    stop: function (e) {
        e.stopPropagation()
    },
    report: function (e, elm) {
        const type = elm.getAttribute('data-type')
        const id = elm.getAttribute('data-id')
        const data = elm.getAttribute('data-data')
        const reportReason = elm.getAttribute('data-reason')

        return report(type, id, data, null, reportReason)
    },
    share: function (e, elm) {
        const title = elm.dataset.title
        const url = elm.dataset.url
        const options = JSON.parse(elm.dataset.options || '{}')

        return share(url, title, options)
    },
    toClipboard(e, elm) {
        e.preventDefault()

        const text = elm.dataset.url

        navigator.clipboard.writeText(text).then(() => {
            let temp = elm.innerHTML

            elm.childNodes.forEach((node) => {
                if (node.textContent.trim() && node.nodeName === '#text') {
                    node.textContent = translate('share_copy_success')
                }
            })

            setTimeout(() => {
                elm.innerHTML = temp
            }, 2000)
        })
    },
    'trophy-popup': function (e, elm) {
        const trophy = elm.dataset.trophy
        const user = JSON.parse(elm.dataset.user)

        Trophy.showPopup(trophy, false, user)
    },
    quicklook: function (e, elm) {
        let targetElement = e.target || elm
        let nonClosing = targetElement

        const shouldClose = function (e) {
            let relatedTarget = e.relatedTarget
            if (relatedTarget) {
                if (nonClosing.contains(relatedTarget) || nonClosing === relatedTarget) {
                    return false
                }
            }
            return true
        }

        targetElement.removeEventListener('mouseleave', undefined)
        targetElement.addEventListener('mouseleave', function (e) {
            if (this.hoverPopupTimeout !== null) {
                window.clearTimeout(this.hoverPopupTimeout)
            }
            if (this.hoverPopup) {
                if (shouldClose(e)) {
                    window.setTimeout(function () {
                        if (!targetElement.hoverPopup.container.classList.contains('hover')) {
                            targetElement.hoverPopup.close()
                        }
                    }, 200)
                }
            }
        })

        let popup = targetElement.hoverPopup
        let wait = 'wait' in elm.dataset ? elm.dataset.wait : 50

        if (popup) {
            // let rect = targetElement.getBoundingClientRect()
            // let left = rect.left + targetElement.offsetWidth / 2
            // targetElement.style.left = left + 'px'
            targetElement.hoverPopupTimeout = window.setTimeout(function () {
                popup.open({
                    event: e,
                })
                nonClosing = nonClosing && popup.container ? popup.container : nonClosing
            }, wait)
        } else {
            const quicklook = elm.dataset.quicklook
                ? JSON.parse(elm.dataset.quicklook)
                : {
                      type: elm.dataset.type,
                      id: elm.dataset.id,
                  }
            if (!quicklook) {
                return
            }
            targetElement.title = ''
            targetElement.hoverPopupTimeout = window.setTimeout(function () {
                let popup = new PopupBubble({
                    event: e,
                    url: `/${quicklook.type}/quicklook/?id=${quicklook.id}`,
                    maxWidth: '95%',
                    className: 'no-padding center',
                    destroy: false,
                    out(e) {
                        if (shouldClose(e)) {
                            popup.close()
                        }
                    },
                    nub: true,
                })
                targetElement.hoverPopup = popup
                nonClosing = nonClosing && popup.container ? popup.container : nonClosing
            }, wait)
        }
    },
    'review.edit': function (e, elm) {
        console.log('here')
        const form = document.querySelector('.review-form')
        console.log(form, checkVisibility(form))
        if (checkVisibility(form) === false) {
            return
        }
        e.preventDefault()
        // editReview(elm.dataset.id)
        Eventbus.emit('review.edit', { placeId: elm.dataset.place, id: elm.dataset.id })
    },
    'review.translate': function (e, elm) {
        translateReview(elm.dataset.id, elm.dataset.original === 'true', elm.dataset.container)
    },
    'review.summary.create': function (e, elm) {
        const force = elm.dataset.force === 'true'
        elm.classList.add('background--loading')

        generateReviewSummary(elm.dataset.id, force).then(() => window.location.reload())
    },
    'review.remove': async function (e, elm) {
        if (!(await confirm(translate('confirm_title'), translate('review_delete_confirm')))) {
            return
        }
        const placeId = elm.dataset.place
        const id = parseInt(elm.dataset.id, 10)
        Eventbus.emit('review.remove', { placeId, id })
    },
    'review.archived': function (e, elm) {
        archivedReviews(elm.dataset.id)
    },
    'review.report': function (e, elm) {
        reportReview(elm.dataset.id)
    },
    'save-place': function (e, elm) {
        const userStore = useUserStore()
        e.preventDefault()

        if (!userStore.isLoggedIn) {
            return auth(
                () => {
                    window.location.reload()
                },
                {
                    variation: 'lists',
                },
            )
        }

        if (elm.dataset.place) {
            if (!elm.dataset.list) {
                placeList.userPopup(elm.dataset.place)
            } else {
                placeList.addPlace(elm.dataset.place, elm.dataset.list)
            }
        } else if (elm.dataset.places) {
            const places = JSON.parse(elm.dataset.places)
            if (places.length === 1) {
                return placeList.userPopup(places[0].id)
            }
            return placeList.userPopupMulti(places)
        }
    },
    'interact-place': function (e, elm) {
        e.preventDefault()

        auth(
            ({ user, wasAsync }) => {
                const wasActive = elm.classList.contains('active')
                elm.classList.toggle('active')
                placeList
                    .updatePlace(
                        elm.dataset.place,
                        user.username + ':' + elm.dataset.interaction,
                        wasActive ? 'remove' : 'add',
                    )
                    .then((data) => {
                        if (!data.success) {
                            elm.classList.toggle('active')
                        }
                        if (wasAsync) {
                            window.location.reload()
                        }
                    })
            },
            {
                variation: 'lists',
            },
        )
    },
    emit: function (e, elm) {
        let events = elm.dataset.event || []

        if (!Array.isArray(events)) {
            events = [events]
        }

        events.forEach((event) => {
            Eventbus.emit(event)
        })
    },
    'navigation.expand': function (e, elm) {
        elm.parentElement.parentElement.classList.toggle('expanded')
        setNavWidth()
    },
    'user.photo.delete': async function (e, elm) {
        if (
            !(await confirm(
                translate('confirm_title'),
                translate('user_place_image_delete_confirm'),
            ))
        ) {
            return
        }

        http.post('/place/photo/delete/', {
            id: elm.dataset.hash,
        })
            .then(({ data }) => {
                const userStore = useUserStore()
                if (data.success) {
                    location.href = userStore.user.permalink + 'photos/'
                }
            })
            .catch(function (error) {
                console.error('Error:', error)
            })
    },
    'user.photo.edit': function () {
        document.querySelector('.user-photo form').style.display = 'block'
        document.querySelector('.user-photo figure figcaption').style.display = 'none'
    },
    'auth.connectApp': function (e, elm) {
        const app = elm.dataset.app
        authentication.connectApp(app)
    },
    'auth.disconnectApp': function (e, elm) {
        const app = elm.dataset.app
        authentication.disconnectApp(app)
    },
    'blog.comment.delete': function (e, elm) {
        removeComment(elm.dataset.id)
    },
    'blog.comment.approve': function (e, elm) {
        approveComment(elm.dataset.id)
    },
}
