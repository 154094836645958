<template>
    <div
        @mouseover="handleMouseOver"
        @mouseout="handleMouseLeave"
        @touchstart="handleMouseOver"
        @touchend="handleMouseLeave"
        @touchmove="handleTouchMove"
        ref="container"
    >
        <place-review-reactions-hover
            v-if="
                reviewsStore.availableReactions &&
                reviewsStore.availableReactions.length > 1 &&
                isHovered
            "
            class="reaction-list"
            :available-reactions="reviewsStore.availableReactions"
            :active-index="activeTouchIndex"
            @react="react"
        />
        <button
            type="button"
            class="tw-m-0 tw-flex tw-touch-none tw-select-none tw-items-center tw-gap-0.5 tw-rounded-lg tw-border tw-border-solid tw-border-blue-600 tw-px-3 tw-py-1"
            :class="{ 'tw-bg-blue-600': userHasReacted }"
            @click="handleClick"
        >
            <i v-if="!userHasReacted" class="sli sli-like sli-blue sli-x15"></i>
            <span
                v-else
                class="tw-inline-block tw-scale-105 tw-transform"
                :class="[isNewReaction === true ? $style.newReactionAnimation : '']"
            >
                {{ actualReaction.emoji }}
            </span>
            <span v-if="!userHasReacted" class="tw-font-semibold tw-text-blue-600">
                {{ defaultReactionTitle }}
            </span>
            <span v-else class="tw-font-semibold tw-text-white">
                {{ actualReaction.title }}
            </span>
        </button>
    </div>
</template>

<script setup>
import PlaceReviewReactionsHover from './PlaceReviewReactionsHover.vue'
import { ref, onMounted, computed } from 'vue'
import http from '../../../Shared/util/http.js'
import { useReviewsStore } from '../../../../store/reviews.js'

const props = defineProps({
    reactions: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    userHasReacted: {
        type: Boolean,
        required: true,
    },
    actualReaction: {
        type: [Object, null],
        required: true,
    },
})

const emit = defineEmits(['react'])
const reviewsStore = useReviewsStore()

const defaultReactionTitle = computed(() => {
    if (!reviewsStore.availableReactions || !reviewsStore.availableReactions.length) {
        return ''
    }
    return reviewsStore.availableReactions[0].title
})

/* Hover animation delay*/
const isHovered = ref(false)
let hoverTimeout = null
let leaveTimeout = null

const closeHover = () => {
    isHovered.value = false
    clearTimeout(hoverTimeout)
    clearTimeout(leaveTimeout)
}

const handleMouseOver = (e) => {
    clearTimeout(leaveTimeout)
    hoverTimeout = setTimeout(() => {
        if (hoverTimeout) {
            isHovered.value = true
        }
    }, 300)
}
const handleMouseLeave = (e) => {
    if (activeTouchIndex.value !== null) {
        react(reviewsStore.availableReactions[activeTouchIndex.value])
        activeTouchIndex.value = null
        closeHover()
        return
    }
    clearTimeout(hoverTimeout)
    hoverTimeout = null
    leaveTimeout = setTimeout(() => {
        if (leaveTimeout) {
            activeTouchIndex.value = null
            isHovered.value = false
        }
    }, 300)
}

const handleClick = (e) => {
    closeHover()
    if (props.userHasReacted) {
        react(null)
    } else {
        if (!reviewsStore.availableReactions || !reviewsStore.availableReactions.length) {
            return
        }
        react(reviewsStore.availableReactions[0])
    }
}

const container = ref(null)

const findClosestReactionByPosition = function (reactionElements, x, y) {
    if (!container.value) return null

    let activeItem = null
    const actives = reactionElements
        .map((element, index) => {
            return {
                element,
                index,
                rect: element.getBoundingClientRect(),
            }
        })
        .filter(({ rect }) => {
            return x >= rect.left && x <= rect.left + rect.width
        })
    if (actives.length) {
        if (actives.length > 1) {
            actives.sort((a, b) => {
                const aDistance = Math.abs(y - (a.rect.top + a.rect.height / 2))
                const bDistance = Math.abs(y - (b.rect.top + b.rect.height / 2))
                return aDistance - bDistance
            })
        }
        activeItem = actives.shift()
    }
    return activeItem
}
const activeTouchIndex = ref(null)
const handleTouchMove = (e) => {
    if (reviewsStore.availableReactions.length <= 1) return
    if (isHovered.value) {
        e.preventDefault()
        const closest = findClosestReactionByPosition(
            [...container.value.querySelectorAll('.reaction-list button')],
            e.touches[0].clientX,
            e.touches[0].clientY,
        )
        if (closest) {
            activeTouchIndex.value = closest.index
        } else {
            activeTouchIndex.value = null
        }
    }
}

/* Reaction function */
const isNewReaction = ref(false)

const react = (reaction) => {
    emit('react', reaction)
    isHovered.value = false
    clearTimeout(hoverTimeout)
    clearTimeout(leaveTimeout)
    isNewReaction.value = true
}

/* On mount animation */
onMounted(() => {
    isNewReaction.value = true
    setTimeout(() => {
        isNewReaction.value = false
    }, 1000)
})
</script>

<style lang="scss" module>
@keyframes newReactionAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.8);
    }

    70% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}
.newReactionAnimation {
    animation: newReactionAnimation 0.7s ease;
}
</style>
