<template>
    <slot :open="open" />

    <div
        v-if="isOpen"
        id="notification-list"
        class="popup shadow bubble right drop-in"
        style="display: block"
    >
        <div class="content overlay-scroll">
            <notifications-navigation></notifications-navigation>
        </div>
        <div class="notification-footer">
            <a href="/user/notifications/">{{ $t('notifications.show_all') }}&nbsp;&raquo;</a>
        </div>
    </div>
</template>

<script setup>
import { translate } from '@modules/locale/locale.js'
import { ref } from 'vue'
import { useUserStore } from '@store/user.js'

const userStore = useUserStore()
const isOpen = ref(false)

const open = () => {
    isOpen.value = !isOpen.value
    if (!userStore.notificationHTML) {
        userStore.loadMobileNotifications()
    }
}
</script>
