<template>
    <div class="user-poll my-2 pb-2">
        <div
            v-if="isLoading"
            class="text-center"
            key="loader"
            style="display: flex; flex-direction: column; height: 100%; align-items: center"
        >
            <div class="is-loading is-loading--large m-4"></div>
        </div>
        <div class="user-poll__content pretty-form" v-if="!isLoading && userPoll">
            <About class="mb-6" :user-poll="userPoll" />
            <div ref="contentContainer" class="user-poll__box">
                <template v-if="!userPoll.hasStarted">
                    <NotStarted :user-poll="userPoll" />
                </template>
                <template v-else-if="userPoll.hasEnded">
                    <HasEnded :user-poll="userPoll" :vote="vote" />
                </template>
                <template v-else-if="!vote">
                    <transition :name="slideName">
                        <Alternatives :user-poll="userPoll" @input="choose" />
                    </transition>
                </template>
                <template v-else-if="!voteConfirmed">
                    <transition :name="slideName">
                        <ConfirmVote
                            :user-poll="userPoll"
                            :vote="vote"
                            @cancel="cancelVote"
                            @confirm="confirmVote"
                        />
                    </transition>
                </template>
                <template v-else>
                    <transition :name="slideName">
                        <Voted :user-poll="userPoll" :vote="vote" @cancel="cancelVote" />
                    </transition>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import http from '@utils/http'
import confirm from '@utils/confirm.js'

import NotStarted from './components/NotStarted.vue'
import HasEnded from './components/HasEnded.vue'
import Alternatives from './components/Alternatives.vue'
import ConfirmVote from './components/ConfirmVote.vue'
import Voted from './components/Voted.vue'
import About from './components/About.vue'
import { localStorage } from '../../../es6/src/utils/localStorage.js'
import { slideTo } from '../../../es6/src/utils/animation.js'
import { currentHeaderHeight } from '../../../es6/src/modules/header/header.js'
import { auth, authentication } from '../../../es6/src/modules/authentication/authentication.js'
import { useUserStore } from '@/store/user.js'

const normalizeTerm = function (term) {
    if (!term) {
        return ''
    }
    return term
        .toLowerCase()
        .normalize('NFD')
        .replace(/[^a-z\s]/g, '')
}

export default {
    components: {
        About,
        NotStarted,
        HasEnded,
        Alternatives,
        ConfirmVote,
        Voted,
    },
    data() {
        return {
            isLoading: false,
            userPoll: null,
            searchTerm: null,
            vote: null,
            voteConfirmed: false,
            voteVerified: false,
            slideName: 'none',
        }
    },
    props: {
        id: {
            type: String,
            required: true,
            default: null,
        },
    },

    setup() {
        const userStore = useUserStore()

        return { userStore }
    },

    created() {
        if (this.id) {
            this.fetchUserPoll()
        }
    },

    computed: {
        canSearch() {
            return this.userPoll && this.userPoll.alternatives.length > 25
        },

        filteredAlternatives() {
            if (!this.userPoll) {
                return []
            }
            if (!this.searchTerm) {
                return this.userPoll.alternatives
            }
            return this.userPoll.alternatives.filter((alternative) => {
                return (
                    normalizeTerm(alternative.title).indexOf(normalizeTerm(this.searchTerm)) !== -1
                )
            })
        },

        timeUntilStart() {
            return new Date(this.userPoll.startDate).getTime() - new Date().getTime()
        },

        timeUntilEnd() {
            return new Date(this.userPoll.endDate).getTime() - new Date().getTime()
        },
    },

    methods: {
        scrollToTop() {
            if (this.$refs.contentContainer) {
                if (
                    this.$refs.contentContainer.getBoundingClientRect().y < -currentHeaderHeight()
                ) {
                    slideTo(this.$refs.contentContainer)
                }
            }
        },

        choose(alternative) {
            this.scrollToTop()
            this.vote = {
                alternative,
            }
            this.voteConfirmed = false
        },

        confirmVote() {
            if (!this.vote) {
                return false
            }

            localStorage.set('poll_vote_pending_' + this.userPoll.id, this.vote.alternative.id)

            auth(() => {
                authentication.closePopup()
                return http
                    .post(`/poll/${this.id}/vote/`, {
                        alternative: this.vote.alternative.id,
                    })
                    .then(({ data }) => {
                        this.vote = data.data
                        this.voteConfirmed = true
                        localStorage.remove('poll_vote_pending_' + this.userPoll.id)
                        this.scrollToTop()
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 410) {
                            this.userPoll.hasEnded = true
                        } else {
                            this.vote = null
                        }
                    })
            })
        },

        fetchUserPoll() {
            this.isLoading = true
            return http.get(`/poll/${this.id}/`).then(({ data }) => {
                this.userPoll = data.data
                if (this.userPoll.userVote) {
                    this.vote = this.userPoll.userVote
                    this.voteConfirmed = true
                } else {
                    const pendingVote = localStorage.get('poll_vote_pending_' + this.userPoll.id)
                    if (pendingVote) {
                        const alternative = this.userPoll.alternatives.find((a) => {
                            return a.id === pendingVote
                        })
                        if (alternative) {
                            this.vote = {
                                alternative,
                            }
                            if (userStore.isLoggedIn) {
                                this.confirmVote()
                            }
                        }
                    }
                }
                if (!this.userPoll.hasStarted) {
                    this.addReloadTimeout()
                }
                this.isLoading = false
            })
        },

        addReloadTimeout() {
            const offset = this.timeUntilStart + 10000
            if (offset > 0 && offset < 1000 * 60 * 60 * 24) {
                window.setTimeout(this.fetchUserPoll, offset)
            }
        },

        async cancelVote() {
            if (!this.voteConfirmed) {
                this.vote = null

                this.scrollToTop()
            } else {
                if (
                    await confirm(
                        this.$t('userPoll.vote_delete_confirm', {
                            alternative: this.vote.alternative.title,
                        }),
                    )
                ) {
                    http.delete(`/poll/${this.id}/vote/`).catch((error) => {
                        if (error.response && error.response.status === 410) {
                            this.userPoll.hasEnded = true
                        }
                    })
                    this.vote = null
                    this.voteConfirmed = false

                    this.scrollToTop()
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: all 0.35s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
//.fade-enter {
//  transform: scale(0);
//}
//.fade-leave-to {
//  transform: scale(0);
//}
.fade-leave,
.fade-leave-to {
    position: absolute;
}
//.user-poll__content {
//  position: relative;
//  > div:nth-child(1) {
//	&.fade-enter-active, &.fade-leave-active {
//	  position: absolute;
//	  top: 0;
//	  width: 100%;
//	}
//  }
//}
</style>
<style lang="scss">
.user-poll {
    p {
        font-weight: 600;
    }

    .user-poll__box {
        max-width: 400px;
        margin: 0 auto;
    }
}
</style>
