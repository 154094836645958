<template>
    <article class="box rounded shadow tw-mx-auto tw-max-w-lg">
        <h1>{{ $t('articleSuggestion.title') }}</h1>
        <div class="content medium" v-if="submitSuccess">
            <h2 class="tw-font-bold">{{ $t('articleSuggestion.thank_you_title') }}</h2>
            <p>{{ $t('articleSuggestion.thank_you_description') }}</p>
            <div class="button-container tw-flex tw-flex-wrap tw-justify-center tw-gap-2">
                <a
                    href="/"
                    class="flat-button flat-button--blue flat-button--small flat-button--full"
                >
                    {{ $t('articleSuggestion.to_home') }}
                </a>
                <button
                    class="flat-button flat-button--white flat-button--small flat-button--full"
                    @click="writeNew"
                >
                    {{ $t('articleSuggestion.write_new') }}
                </button>
            </div>
        </div>
        <form v-else @submit.prevent="submitSuggestion" class="content medium pretty-form">
            <div class="form-group">
                <label class="input-label">{{ $t('articleSuggestion.name_label') }}</label>
                <input
                    type="text"
                    class="tw-w-full"
                    v-model="suggestion.name"
                    :placeholder="$t('articleSuggestion.name_placeholder')"
                    required
                />
                <p v-if="errors.name" class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600">
                    {{ errors.name.join(', ') }}
                </p>
            </div>
            <div class="form-group">
                <label class="input-label">{{ $t('articleSuggestion.description_label') }}</label>
                <textarea
                    class="tw-w-full"
                    v-model="suggestion.description"
                    v-auto-height
                    rows="8"
                    :placeholder="$t('articleSuggestion.description_placeholder')"
                    required
                ></textarea>
                <p
                    v-if="errors.description"
                    class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600"
                >
                    {{ errors.description.join(', ') }}
                </p>
            </div>
            <div class="form-group">
                <label class="input-label">
                    {{ $t('articleSuggestion.dates_label') }}
                    <small class="tw-text-xs tw-text-gray-600">
                        {{ $t('articleSuggestion.dates_description') }}
                    </small>
                </label>
                <DatePicker
                    class="box rounded shadow tw-mx-auto tw-max-w-lg tw-bg-white"
                    v-model="suggestion.dates"
                    multi
                />
                <div v-if="suggestion.dates.length" class="tw-mt-2 tw-text-xs tw-font-semibold">
                    {{
                        suggestion.dates.length <= 3
                            ? suggestion.dates.join(', ')
                            : $t('articleSuggestion.date_and_x_more_dates', {
                                  date: suggestion.dates[0],
                                  count: suggestion.dates.length - 1,
                              })
                    }}
                </div>
                <p v-if="errors.dates" class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600">
                    {{ errors.dates.join(', ') }}
                </p>
            </div>

            <div class="form-group">
                <label class="input-label">{{ $t('articleSuggestion.location_label') }}</label>
                <div
                    v-if="suggestion.place"
                    class="box shadow rounded border mb-3 tw-flex tw-gap-4 tw-p-4"
                >
                    <div
                        class="tw-h-16 tw-w-16 tw-flex-shrink-0 tw-rounded-full"
                        :class="{
                            'icon category blue circle': !suggestion.place.thumbnail,
                            [suggestion.place.icon]: !suggestion.place.thumbnail,
                        }"
                    >
                        <img v-if="suggestion.place.thumbnail" :src="suggestion.place.thumbnail" />
                    </div>
                    <div class="place-box__description">
                        <h2 class="tw-mb-0 tw-text-lg tw-font-bold">
                            {{ suggestion.place.title }}
                        </h2>
                        <h3>
                            {{
                                [suggestion.place.subtitle, suggestion.place.cityName]
                                    .filter((s) => !!s)
                                    .join(', ')
                            }}
                        </h3>
                    </div>
                    <div class="tw-flex-shrin-0 tw-flex tw-flex-col tw-items-end tw-justify-center">
                        <button
                            class="tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-400 tw-p-2"
                            type="button"
                            @click="suggestion.place = null"
                        >
                            <i
                                class="icon--close-x-small icon--close-x-small--white"
                                aria-hidden="true"
                            ></i>
                        </button>
                    </div>
                </div>
                <PlaceAutocomplete
                    v-else
                    :placeholder="$t('articleSuggestion.location_placeholder')"
                    :value="suggestion.location"
                    @input="suggestion.location = $event"
                    @select="addSearchPlace"
                    required
                />
                <p v-if="errors.location" class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600">
                    {{ errors.location.join(', ') }}
                </p>
                <p v-if="errors.place" class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600">
                    {{ errors.place.join(', ') }}
                </p>
            </div>
            <div class="form-group">
                <label class="input-label">
                    {{ $t('articleSuggestion.artwork_label') }}
                    <small class="tw-text-xs tw-text-gray-600">
                        {{ $t('articleSuggestion.optional_label') }}
                    </small>
                </label>
                <FileUpload
                    @add="addFile"
                    v-if="!suggestion.artwork || suggestion.artwork.error"
                    accept="image/*"
                    multiple
                >
                    <template #input-button="{ dragging }">
                        <button
                            class="flat-button flat-button--white flat-button--small flat-button--full"
                            :class="{ white: !dragging, blue: dragging }"
                        >
                            <i
                                class="sli sli-upload sli-outlined tw-ml-2 tw-h-6 tw-w-6"
                                aria-hidden="true"
                            ></i>
                            {{ $t('articleSuggestion.artwork_upload_button') }}
                        </button>
                    </template>
                </FileUpload>
                <div v-else>
                    <div
                        class="tw-relative tw-h-32 tw-w-32"
                        :class="{ 'tw-overflow-hidden': suggestion.artwork.loading }"
                    >
                        <template v-if="suggestion.artwork.loading">
                            <img
                                v-if="suggestion.artwork.thumbnail"
                                :src="suggestion.artwork.thumbnail"
                                class="tw-h-32 tw-w-32 tw-rounded tw-object-cover tw-blur-sm"
                            />
                            <div
                                class="is-loading is-loading--large tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-white/30"
                            ></div>
                        </template>
                        <template v-else>
                            <img
                                v-if="suggestion.artwork.url"
                                :src="suggestion.artwork.url"
                                class="tw-h-32 tw-w-32 tw-rounded tw-object-cover"
                            />
                            <button
                                class="tw-absolute -tw-right-2 -tw-top-2 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-400 tw-p-2"
                                type="button"
                                @click="suggestion.artwork = null"
                            >
                                <i
                                    class="icon--close-x-small icon--close-x-small--white"
                                    aria-hidden="true"
                                ></i>
                            </button>
                        </template>
                    </div>
                </div>
                <p
                    v-if="suggestion.artwork && suggestion.artwork.error"
                    class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600"
                >
                    {{ suggestion.artwork.error }}
                </p>
            </div>
            <template
                v-if="
                    suggestion.artwork && !suggestion.artwork.loading && !suggestion.artwork.error
                "
            >
                <div class="form-group">
                    <label class="input-label">
                        {{ $t('articleSuggestion.artwork_byline_label') }}
                        <small class="tw-text-xs tw-text-gray-600">
                            {{ $t('articleSuggestion.artwork_byline_description') }}
                        </small>
                    </label>
                    <input
                        type="text"
                        name="artworkByline"
                        class="tw-w-full"
                        v-model="suggestion.artwork.byline"
                    />
                    <p
                        v-if="errors.artworkByline"
                        class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600"
                    >
                        {{ errors.artworkByline.join(', ') }}
                    </p>

                    <div class="checkbox">
                        <input
                            id="copyrightWaiver"
                            type="checkbox"
                            v-model="suggestion.artwork.copyrightWaiver"
                            required
                        />
                        <label for="copyrightWaiver">
                            {{ $t('articleSuggestion.artwork_copyright_waiver') }}
                        </label>
                    </div>
                    <p
                        v-if="errors.artworkByline"
                        class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600"
                    >
                        {{ errors.artworkByline.join(', ') }}
                    </p>
                </div>
            </template>
            <div class="form-group">
                <label class="input-label">
                    {{ $t('articleSuggestion.category_label') }}
                    <small class="tw-text-xs tw-text-gray-600">
                        {{ $t('articleSuggestion.optional_label') }}
                    </small>
                </label>
                <select
                    v-model="suggestion.category"
                    class="tw-w-full"
                    :class="{ 'tw-text-gray-600': suggestion.category === null }"
                >
                    <option :value="null" selected>
                        {{ $t('articleSuggestion.category_placeholder') }}
                    </option>
                    <option v-for="category in categories" :value="category.id" :key="category.id">
                        {{ category.name }}
                    </option>
                </select>
                <p v-if="errors.category" class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600">
                    {{ errors.category.join(', ') }}
                </p>
            </div>
            <div class="form-group">
                <label class="input-label">
                    {{ $t('articleSuggestion.website_label') }}
                    <small class="tw-text-xs tw-text-gray-600">
                        {{ $t('articleSuggestion.optional_label') }}
                    </small>
                </label>
                <input
                    type="url"
                    class="tw-w-full"
                    v-model="suggestion.website"
                    :placeholder="$t('articleSuggestion.website_placeholder')"
                />
                <p v-if="errors.website" class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600">
                    {{ errors.website.join(', ') }}
                </p>
            </div>
            <div class="form-group">
                <label class="input-label">
                    {{ $t('articleSuggestion.ticket_url_label') }}
                    <small class="tw-text-xs tw-text-gray-600">
                        {{ $t('articleSuggestion.optional_label') }}
                    </small>
                </label>
                <input
                    type="url"
                    class="tw-w-full"
                    v-model="suggestion.ticketUrl"
                    :placeholder="$t('articleSuggestion.ticket_url_placeholder')"
                />
                <p v-if="errors.ticketUrl" class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600">
                    {{ errors.ticketUrl.join(', ') }}
                </p>
            </div>
            <div class="form-group">
                <label class="input-label">
                    {{ $t('articleSuggestion.ticket_price_label') }}
                    <small class="tw-text-xs tw-text-gray-600">
                        {{ $t('articleSuggestion.optional_label') }}
                    </small>
                </label>
                <input
                    type="text"
                    class="tw-w-full"
                    v-model="suggestion.ticketPrice"
                    :placeholder="$t('articleSuggestion.ticket_price_placeholder')"
                />
                <p
                    v-if="errors.ticketPrice"
                    class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600"
                >
                    {{ errors.ticketPrice.join(', ') }}
                </p>
            </div>
            <div class="form-group">
                <label class="input-label">
                    {{ $t('articleSuggestion.facebook_url_label') }}
                    <small class="tw-text-xs tw-text-gray-600">
                        {{ $t('articleSuggestion.optional_label') }}
                    </small>
                </label>
                <input
                    type="text"
                    class="tw-w-full"
                    v-model="suggestion.facebookUrl"
                    :placeholder="$t('articleSuggestion.facebook_url_placeholder')"
                />
                <p
                    v-if="errors.facebookUrl"
                    class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600"
                >
                    {{ errors.facebookUrl.join(', ') }}
                </p>
            </div>
            <div class="form-group">
                <div class="checkbox">
                    <input id="isOrganizer" type="checkbox" v-model="suggestion.isOrganizer" />
                    <label for="isOrganizer">
                        {{ $t('articleSuggestion.is_organizer_label') }}
                    </label>
                </div>
            </div>
            <div class="form-group">
                <label class="input-label">{{ $t('articleSuggestion.email_label') }}</label>
                <input
                    type="email"
                    class="tw-w-full"
                    v-model="suggestion.email"
                    :placeholder="$t('articleSuggestion.email_placeholder')"
                    required
                    autocomplete="email"
                />
                <p v-if="errors.email" class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600">
                    {{ errors.email.join(', ') }}
                </p>
            </div>
            <div v-if="suggestion.isOrganizer" class="form-group">
                <label class="input-label">
                    {{ $t('articleSuggestion.organizer_name_label') }}
                    <small class="tw-text-xs tw-text-gray-600">
                        {{ $t('articleSuggestion.optional_label') }}
                    </small>
                </label>
                <input
                    type="text"
                    class="tw-w-full"
                    v-model="suggestion.organizerName"
                    :placeholder="$t('articleSuggestion.organizer_name_placeholder')"
                    autocomplete="name"
                />
                <p
                    v-if="errors.organizerName"
                    class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600"
                >
                    {{ errors.organizerName.join(', ') }}
                </p>
            </div>
            <div v-if="suggestion.isOrganizer" class="form-group">
                <label class="input-label">
                    {{ $t('articleSuggestion.organizer_phone_label') }}
                    <small class="tw-text-xs tw-text-gray-600">
                        {{ $t('articleSuggestion.optional_label') }}
                    </small>
                </label>
                <input
                    type="tel"
                    class="tw-w-full"
                    v-model="suggestion.organizerPhone"
                    :placeholder="$t('articleSuggestion.organizer_phone_placeholder')"
                    autocomplete="tel"
                />
                <p
                    v-if="errors.organizerPhone"
                    class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600"
                >
                    {{ errors.organizerPhone.join(', ') }}
                </p>
            </div>
            <div class="form-group">
                <label class="input-label">
                    {{ $t('articleSuggestion.other_information_label') }}
                    <small class="tw-text-xs tw-text-gray-600">
                        {{ $t('articleSuggestion.optional_label') }}
                    </small>
                </label>
                <textarea
                    class="tw-w-full"
                    v-model="suggestion.otherInformation"
                    v-auto-height
                    :placeholder="$t('articleSuggestion.other_information_placeholder')"
                ></textarea>
                <p
                    v-if="errors.otherInformation"
                    class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600"
                >
                    {{ errors.otherInformation.join(', ') }}
                </p>
            </div>
            <div class="form-group">
                <button
                    class="flat-button flat-button--blue flat-button--full"
                    :class="{ 'is-loading': isLoading }"
                    type="submit"
                >
                    {{ $t('articleSuggestion.submit_button') }}
                </button>
            </div>
            <p v-if="errorMessage" class="tw-mt-2 tw-text-sm tw-font-bold tw-text-red-600">
                {{ errorMessage }}
            </p>
        </form>
    </article>
</template>

<script setup>
import { ref } from 'vue'
import FileUpload from '../../Shared/components/FileUpload.vue'
import DatePicker from '../../Shared/components/DatePicker.vue'
import PlaceAutocomplete from '../../Shared/components/PlaceAutocomplete.vue'
import http from '../../Shared/util/http.js'
import { useCityStore } from '../../../store/city.js'
import { useUserStore } from '../../../store/user.js'
import vAutoHeight from '../../directives/autoHeight.js'

const cityStore = useCityStore()
const userStore = useUserStore()

const EMPTY_SUGGESTION = {
    city: cityStore.currentCity?.code,
    name: '',
    description: '',

    dates: [],

    location: '',
    place: null,

    artwork: null,
    artworkByline: '',

    category: null,

    website: '',
    ticketUrl: '',
    ticketPrice: '',
    facebookUrl: '',

    email: userStore.user?.email || '',
    isOrganizer: false,
    organizerName: '',
    organizerPhone: '',

    otherInformation: '',
}

const suggestion = ref({ ...EMPTY_SUGGESTION })

const errors = ref({})
const errorMessage = ref(null)
const isLoading = ref(false)
const submitSuccess = ref(false)

const writeNew = () => {
    suggestion.value = {
        ...EMPTY_SUGGESTION,
        email: suggestion.value.email || '',
        isOrganizer: suggestion.value.isOrganizer || false,
        organizerName: suggestion.value.organizerName || '',
        organizerPhone: suggestion.value.organizerPhone || '',
    }
    submitSuccess.value = false
    errors.value = {}
    errorMessage.value = null
}

const addFile = (file) => {
    suggestion.value.artwork = file
}

const submitSuggestion = () => {
    isLoading.value = true
    http.post('/api/article-suggestion', suggestion.value)
        .then(({ data }) => {
            submitSuccess.value = true
        })
        .catch((error) => {
            submitSuccess.value = false
            errors.value = error.response.data?.errors || {}
            errorMessage.value = error.response.data?.message || error.message
        })
        .finally(() => {
            isLoading.value = false
        })
}

const addSearchPlace = ({ item }) => {
    suggestion.value.place = item
    suggestion.value.location = item.title
}
const categories = ref([])
const loadCategories = () => {
    http.get('/api/categories', { params: { type: 'article' } })
        .then(({ data }) => {
            categories.value = data.data
        })
        .catch((error) => {
            throw error
        })
}

loadCategories()
</script>
