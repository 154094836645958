<template>
    <div
        class="place-explorer__desktop-filter d-flex justify-between items-center overflow-hidden pretty-form"
    >
        <div
            class="place-explorer__desktop-filter__categories d-flex items-center flex-grow"
            :class="{
                'overflow-hidden': visibleSuggestionLimit !== suggestionLimit,
            }"
            ref="categories"
        >
            <div
                style="
                    display: flex;
                    border: 1px solid #d2d2d2;
                    border-radius: 9999px;
                    padding: 8px 7px;
                    align-items: center;
                    font-weight: 600;
                "
            >
                <label
                    for="autocomplete-location"
                    class="d-flex items-center jc-space-between px-2"
                    style="cursor: text; width: 185px"
                    @click="openAutocomplete"
                >
                    <span class="pr-1 text-nowrap ellipsis">
                        {{ filter.location }}
                    </span>
                    <!--              <i class="sli sli-gps flex-shrink-0" :class="{'sli-gps-blue': isAtUserLocation}"></i>-->
                </label>

                <label style="height: 19px; width: 1px; background-color: #ccc"></label>

                <label
                    for="q"
                    class="d-flex items-center jc-space-between px-2"
                    style="cursor: text; width: 220px"
                    @click="openAutocomplete"
                >
                    <span class="pr-1 ellipsis">{{ filter.term }}</span>
                    <i class="sli sli-search"></i>
                </label>
            </div>

            <div v-if="canHaveRadius" class="d-flex mx-1 items-center weight-600">
                <NonLinearRangeInput
                    class="flex-grow"
                    :values="[100, 500, 1000, 3000, 5000, 10000]"
                    :steps="[50, 100, 250, 500, 1000]"
                    :model-value="params.radius || filter.radius"
                    @update:model-value="setRadius(parseFloat($event))"
                />
                <span class="ml-1 text-nowrap">
                    {{
                        $t('explore.filter.distance_number', {
                            number: formattedRadius,
                        })
                    }}
                </span>
            </div>
            <span v-if="filter.groups && filter.groups.length" class="separator"></span>
            <template v-for="group in filter.groups" :key="group.key">
                <ExploreControl
                    v-if="
                        !group.dependencies?.length ||
                        group.dependencies.some((dep) => searchedProperties.includes(dep))
                    "
                    :active="!!groupParams(group.key).length"
                    :aria-label="group.label"
                    @click="$emit('open-filter', group)"
                >
                    {{ group.label }}
                </ExploreControl>
            </template>
            <span v-if="selected.length" class="separator"></span>
            <ExploreControl
                v-for="property in selected"
                :key="property.key"
                :aria-label="property.label"
                @click="toggleProp(property)"
                small
                active
            >
                <i class="icon icon--close-x-small icon--close-x-small--blue"></i>
                <span>{{ property.label }}</span>
            </ExploreControl>
        </div>
        <i v-if="isLoading" class="icon icon--loading-ring icon--loading-ring--blue icon--x20"></i>
    </div>
</template>

<script>
import viewport from '../../../es6/src/utils/Viewport'
import throttle from 'lodash-es/throttle'
import flatten from 'lodash-es/flatten'
import NonLinearRangeInput from '../../Shared/components/NonLinearRangeInput.vue'
import ExploreControl from './ExploreControl.vue'
import { useSearchAutocompleteStore } from '../../../store/searchAutocomplete.js'
import { numberFormat } from '../../../es6/src/modules/locale/locale.js'

export default {
    components: { ExploreControl, NonLinearRangeInput },
    props: {
        filter: Object,
        params: Object,
        bodyOffset: Number,
        canHaveRadius: Boolean,
        isLoading: Boolean,
        isAtUserLocation: Boolean,
    },
    data() {
        return {
            isStuck: false,
            suggestionLimit: 0,
            suggestionWidthBasis: null,
            visibleSuggestionLimit: 0,
        }
    },
    setup() {
        const searchAutocompleteStore = useSearchAutocompleteStore()

        return {
            openAutocomplete: searchAutocompleteStore.open,
        }
    },
    computed: {
        searchedProperties() {
            const groups = [...this.filter.groups, { key: 'category' }]

            return flatten(groups.map((group) => this.groupParams(group.key)))
        },
        selected() {
            if (!this.filter.groups) {
                return []
            }
            return this.filter.groups
                .map((group) => {
                    if (!(group.key in this.params)) {
                        return []
                    }
                    return group.values
                        .filter((property) => {
                            return this.params[group.key].indexOf(property.value) !== -1
                        })
                        .map((property) => {
                            return {
                                ...property,
                                group: group.key,
                            }
                        })
                })
                .flat()
        },

        selectedKeys() {
            return this.selected.map((property) => {
                return `${property.group}_${property.key}`
            })
        },

        suggestions() {
            return []
            if (!this.filter.groups) {
                return []
            }

            const properties = this.filter.groups
                .map((group) => {
                    return group.values
                        .filter((property) => {
                            return this.selectedKeys.indexOf(`${group.key}_${property.key}`) === -1
                        })
                        .map((property) => {
                            return {
                                ...property,
                                group: group.key,
                            }
                        })
                })
                .flat()

            return properties
                .sort((a, b) => {
                    return b.popularity - a.popularity
                })
                .slice(0, this.suggestionLimit)
        },

        formattedRadius() {
            return numberFormat(this.params.radius || this.filter.radius, 0)
        },
    },
    watch: {
        // selected() {
        //   this.calculateSuggestionLimit();
        // }
    },
    mounted() {
        this.bindEvents()
        this.onWindowScroll()
        this.onViewportChange(viewport)
    },
    unmounted() {
        this.unbindEvents()
    },
    methods: {
        groupParams(key) {
            if (!this.params) {
                return []
            }
            return !(key in this.params) ? [] : this.params[key]
        },
        groupHasParam(group, key) {
            if (!this.params) {
                return false
            }
            return group in this.params && this.params[group].indexOf(key) !== -1
        },
        bindEvents() {
            window.addEventListener('scroll', this.onWindowScroll)
            viewport.on('change', this.onViewportChange)
        },
        unbindEvents() {
            window.removeEventListener('scroll', this.onWindowScroll)
            viewport.off('change', this.onViewportChange)
        },
        onWindowScroll(e) {
            const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
            this.isStuck = scrollTop > this.bodyOffset * 0.8
        },
        onViewportChange(viewport) {
            // this.calculateSuggestionLimit();
        },
        calculateSuggestionLimit: throttle(function () {
            const width = viewport.width
            const elm = this.$refs.categories
            const previousBasis = this.suggestionWidthBasis
            this.suggestionWidthBasis = width
            const direction = previousBasis && previousBasis > width ? -1 : 1
            this.calculateSuggestionLimitTick(elm, direction)
        }, 1000),
        calculateSuggestionLimitTick(elm, direction) {
            if (this.isMobile) {
                return
            }
            this.$nextTick(() => {
                window.requestAnimationFrame(() => {
                    if (direction === -1) {
                        if (this.suggestionLimit > 0 && elm.scrollWidth > elm.offsetWidth) {
                            this.suggestionLimit = this.suggestionLimit - 1
                            this.calculateSuggestionLimitTick(elm, -1)
                        } else {
                            this.visibleSuggestionLimit = this.suggestionLimit
                        }
                    } else {
                        if (this.suggestionLimit < 20) {
                            this.suggestionLimit = this.suggestionLimit + 1
                            this.calculateSuggestionLimitTick(
                                elm,
                                elm.scrollWidth <= elm.offsetWidth ? 1 : -1,
                            )
                        } else {
                            this.visibleSuggestionLimit = this.suggestionLimit
                        }
                    }
                })
            })
        },
        setRadius(value) {
            this.$emit('input', {
                ...this.params,
                radius: value,
            })
        },
        toggleProp(property) {
            let newParams = {
                ...this.params,
            }
            if (this.groupHasParam(property.group, property.key)) {
                newParams[property.group].splice(newParams[property.group].indexOf(property.key), 1)
            } else {
                if (!(property.group in newParams)) {
                    newParams[property.group] = []
                }
                newParams[property.group].push(property.key)
            }
            this.$emit('input', newParams)
        },
    },
}
</script>
