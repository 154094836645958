<template>
    <BaseAutocomplete
        :async="true"
        :items="placeSearchResult"
        :placeholder="$t('placeAutocomplete.placeholder')"
        :initial-query="initialQuery"
        @change="searchPlace"
    >
        <template v-slot:item="{ item, index }">
            <div class="tw-flex tw-items-center">
                <div class="icon category blue circle small mr-2" :class="[item.icon]"></div>
                <div>
                    <strong>{{ item.name }}</strong>
                    <br />
                    <small>
                        {{ [item.subtitle, item.cityName].filter((s) => !!s).join(', ') }}
                    </small>
                </div>
            </div>
        </template>
    </BaseAutocomplete>
</template>

<script setup>
import { ref } from 'vue'
import { debounce } from 'lodash-es'
import http from '../util/http.js'
import BaseAutocomplete from './BaseAutocomplete.vue'
import { useCityStore } from '../../../store/city.js'

const cityStore = useCityStore()

const props = defineProps({
    initialQuery: {
        type: String,
        required: false,
        default: '',
    },
    params: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    debounce: {
        type: Number,
        required: false,
        default: 300,
    },
})

const placeSearchResult = ref([])
const isSearchingPlaces = ref(false)
let abortController = null
const searchPlace = debounce(function (term) {
    isSearchingPlaces.value = true
    if (term.length < 2) {
        isSearchingPlaces.value = false
        return
    }

    if (abortController) {
        abortController.abort()
    }
    if ('AbortController' in window) {
        abortController = new AbortController()
    }

    const uriPartial = cityStore.currentCityCode ? cityStore.currentCity?.code + '/' : ''
    http.get(`/${uriPartial}search/place/json/`, {
        params: {
            q: term,
            'all-cities': true,
            'all-countries': false,
            ...props.params,
        },
        signal: abortController?.signal,
    })
        .then((result) => {
            isSearchingPlaces.value = false
            placeSearchResult.value = result.data.map((p) => {
                return {
                    ...p,
                    name: p.title,
                }
            })
        })
        .catch(() => {
            isSearchingPlaces.value = false
        })
}, props.debounce)
</script>
