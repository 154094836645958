<template>
    <div class="loaded">
        <template v-for="(element, i) in elements" :key="element.id">
            <div
                v-if="!('data' in element.props) || element.props.data !== null"
                :ref="(el) => observe(element, el)"
                :class="$style.element"
            >
                <component :is="element.component" v-bind="element.props" />
            </div>
        </template>
    </div>
</template>

<script setup>
import ArticleCarousel from './components/elements/ArticleCarousel.vue'
import ArticleGrid from './components/elements/ArticleGrid.vue'
import TeaserCarousel from './components/elements/TeaserCarousel.vue'
import TeaserHeroList from './components/elements/TeaserHeroList.vue'
import PlaceListCarousel from './components/elements/PlaceListCarousel.vue'
import PlaceCarousel from './components/elements/PlaceCarousel.vue'
import UserActionCarousel from './components/elements/UserActionCarousel.vue'
import GuidesHero from './components/elements/GuidesHero.vue'
import BlogCarousel from './components/elements/BlogCarousel.vue'

import http from '@utils/http'
import { computed, onBeforeMount, ref, watch, unref } from 'vue'
import Hero from './components/elements/Hero.vue'
import HeroList from './components/elements/HeroList.vue'
import Advert from './components/elements/Advert.vue'
import TextElement from './components/elements/TextElement.vue'

const props = defineProps({
    data: {
        type: Object,
        required: false,
        default: () => ({}),
    },
})

const flipIndex = ref(0)

const COMPONENT_MAP = {
    'article-carousel': ArticleCarousel,
    'article-list': ArticleCarousel,
    'article-grid': ArticleGrid,
    'teaser-carousel': TeaserCarousel,
    'teaser-list': TeaserCarousel,
    'teaser-hero-list': TeaserHeroList,
    'place-list-carousel': PlaceListCarousel,
    'place-carousel': PlaceCarousel,
    hero: Hero,
    'hero-list': HeroList,
    'user-action-carousel': UserActionCarousel,
    'guides-hero': GuidesHero,
    'blog-carousel': BlogCarousel,
    'text-element': TextElement,
    advert: Advert,
}

const elements = computed(() => {
    return (props.data?.elements || [])
        .map((element) => {
            return {
                component: COMPONENT_MAP[element.type],
                props: {
                    ...element,
                    ...(lazyElements.value[element.id] || {}),
                },
            }
        })
        .filter((element) => {
            return !!element.component
        })
})

const lazyElements = ref({})

function loadDataIfNotLoaded(element) {
    if ('data' in element.props || element.props.id in lazyElements.value) {
        return
    }
    http.get(`/elements/${element.props.id}/`)
        .then(({ data }) => {
            const newElement = data.data
            lazyElements.value[element.props.id] = newElement
            if (newElement.data === null) {
                throw new Error('Null data')
            }
        })
        .catch(() => {
            lazyElements.value[element.props.id] = { data: null }
        })
}

const observed = ref([])

function observe(element, el) {
    if (observed.value.includes(element.props.id) || 'data' in element.props) {
        return
    }
    observed.value.push(element.props.id)
    const observer = new window.IntersectionObserver(
        (entries, observer) => {
            if (entries.find((entry) => entry.isIntersecting)) {
                loadDataIfNotLoaded(element)
                observer.disconnect()
            }
        },
        {
            root: null,
            rootMargin: '200px',
            threshold: [0, 1],
        },
    )
    observer.observe(el)
}
</script>

<style module>
.element {
    margin: var(--element-spacing);
}
</style>
