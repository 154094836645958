<template>
    <div>
        <div class="rate-box">
            <div v-if="review.loading" class="tw-flex tw-justify-center">
                <i class="icon icon--loading-ring icon--loading-ring--blue icon--x24"></i>
            </div>
            <RatingStars
                v-else
                v-model="review.rating"
                :size="35"
                :rating-text="ratingText"
                :disabled="review && !!review.bodyText"
                @update:model-value="setRating"
            />
            <!-- Rating but no review -->
            <button
                v-if="review && review.rating && !review.bodyText"
                class="flat-button flat-button--blue flat-button--small flat-button--full tw-mt-4"
                @click="addReview(rating)"
            >
                {{ $t('PlaceRateBox.add_review') }}
                <i class="icon icon--point">+7</i>
            </button>
            <!-- Rating and review -->
            <button
                v-else-if="review && review.rating && !!review.bodyText"
                class="flat-button flat-button--blue flat-button--small flat-button--full tw-mt-4"
                @click="goToReviews()"
            >
                {{ $t('PlaceRateBox.view_review') }}
            </button>
            <!-- Rating but no images -->
            <PlaceImageButton v-if="review && review.rating" :place="place">
                <button
                    class="flat-button flat-button--white flat-button--small flat-button--full tw-mt-2"
                >
                    {{ $t('PlaceRateBox.add_photos') }}
                    <i
                        v-if="review && review.rating && !review.images.length"
                        class="icon icon--point"
                    >
                        +5
                    </i>
                </button>
            </PlaceImageButton>

            <div v-if="canRemove" class="tw-mt-4 tw-text-center">
                <a
                    class="tw-font-semibold tw-text-dark-blue-600"
                    role="button"
                    @click="removeRating"
                >
                    {{ $t('PlaceRateBox.delete_rating') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import RatingStars from '../../Shared/components/RatingStars.vue'
import PlaceImageButton from '../place-image/PlaceImageButton.vue'
import http from '@utils/http'
import EventBus from '../../Shared/EventBus'
import confirm from '@utils/confirm.js'
import { onMounted, ref, watch } from 'vue'
import { trackEvent } from '../../../es6/src/modules/tracking/tracking.js'
import { translate } from '../../../es6/src/modules/locale/locale.js'
import { useReviewsStore } from '../../../store/reviews.js'
import { slideTo } from '../../../es6/src/utils/animation.js'
import { useUserStore } from '../../../store/user.js'
import { auth } from '../../../es6/src/modules/authentication/authentication.js'
import Eventbus from '../../../es6/src/modules/eventbus/Eventbus.js'

export default {
    components: {
        RatingStars,
        PlaceImageButton,
    },

    props: {
        type: {
            type: String,
            default: 'rating',
        },
        place: {
            type: String,
            required: true,
        },
        initialRating: {
            type: Number,
            required: false,
            default: 0,
        },
        hasPhotos: {
            type: Boolean,
        },
    },

    setup(props) {
        const uploadPhotoButton = ref(null)
        const reviewStore = useReviewsStore()
        const userStore = useUserStore()

        onMounted(() => {
            document.addEventListener('DOMContentLoaded', () => {
                //thatsup.place.uploadPhotoButton(uploadPhotoButton.value, props.place)
            })
        })

        return { uploadPhotoButton, reviewStore, userStore }
    },

    data() {
        // console.log('review in rate box',  this.reviewStore.getReviewForUser(this.place));
        return {
            show: !this.review,
            recentlyCreated: false,
            review: this.reviewStore.getReviewForUser(this.place),
            ratingText: '',
            endpoint: 'rating',
            version: 2,
        }
    },

    computed: {
        canRemove() {
            return this.review && this.review.id && !this.review.bodyText
        },
    },

    created() {
        Eventbus.on('place-review:saved', this.handleReviewSavedEvent)
        Eventbus.on('place-review:deleted', this.handleReviewDeletedEvent)

        this.ratingText = this.initialRating
            ? `${this.$t('PlaceRateBox.my_rating')}: ${this.currentReview.rating}`
            : ''
    },

    unmounted() {
        Eventbus.off('place-review:saved', this.handleReviewSavedEvent)
        Eventbus.off('place-review:deleted', this.handleReviewDeletedEvent)
    },

    methods: {
        addReview() {
            this.goToReviews(true)
        },
        goToReviews(edit = false) {
            if (edit && this.review) {
                this.review.isEditing = true
            }
            slideTo('#reviews')
        },
        async removeRating() {
            if (!(await confirm(translate('confirm_title'), translate('review_delete_confirm')))) {
                return
            }
            return this.reviewStore.deleteReview(this.place, this.review)
        },

        setRating(rating) {
            auth(async ({ wasAsync }) => {
                console.log('storing rating', this.review, rating)
                await this.reviewStore.storeRating(this.place, this.review)
                console.log('rating', rating, this.place)
                if (wasAsync) {
                    window.location.reload()
                }
            })
            return
            auth((authData) => {
                this.recentlyCreated = true

                const didExist = !!this.currentReview

                this.ratingText = this.$t('PlaceRateBox.rating_saved')

                http.post(`/place/${this.endpoint}/`, {
                    rating: rating,
                    place: this.place,
                })
                    .then(({ data }) => {
                        const review = data.data
                        this.currentReview = review

                        trackEvent('rated', {
                            category: 'place',
                            label: review.place.name,
                        })

                        if (didExist) {
                            Eventbus.emit('place-review:updated', review)
                        } else {
                            Eventbus.emit('place-review:created', review)
                        }

                        Eventbus.emit('place-review:saved', review)

                        if (authData.wasAsync) {
                            window.thatsup.reloadWindow()
                        } else {
                            const userStore = useUserStore()
                            userStore.updateUser(true)
                        }

                        this.ratingText = this.$t('PlaceRateBox.rating_saved')
                    })
                    .catch(() => {
                        this.recentlyCreated = false
                        this.rating = 0
                    })
            })

            EventBus.$on('thatsup:auth:popup:close', (e) => {
                if (!this.recentlyCreated) {
                    this.rating = null
                }
            })
        },

        handleReviewSavedEvent(data) {
            if (data.place.id === this.place) {
                this.currentReview = data
                if (!this.currentReview.isRating) {
                    this.show = false
                }
                this.rating = data.rating
            }
        },

        handleReviewDeletedEvent(data) {
            if (this.currentReview && data.id === this.currentReview.id) {
                this.currentReview = null
                this.rating = 0
            }
        },
    },
}
</script>

<style scoped>
.cta-button {
    display: flex;
    align-items: center;
}

.cta-button svg {
    flex: 0 0 22px;
    margin-right: 9px;
    max-width: 22px;
}
</style>
