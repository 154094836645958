<template>
    <component
        :is="tag"
        ref="wrapper"
        :class="[$style.wrapper, draggingIndex ? $style.wrapperDragging : null]"
    >
        <template v-for="(item, index) in currentItems" :key="item[itemKey]">
            <component
                :is="itemTag"
                :class="[$style.itemWrapper]"
                :style="
                    index === closestIndex
                        ? {
                              '--sortable-offset-x':
                                  index === closestIndex ? draggingOffset.x + 'px' : '0',
                              '--sortable-offset-y':
                                  index === closestIndex ? draggingOffset.y + 'px' : '0',
                          }
                        : null
                "
            >
                <div
                    :class="[
                        $style.innerWrapper,
                        index === closestIndex ? $style.innerWrapperDragging : '',
                    ]"
                >
                    <slot
                        name="item"
                        :item="item"
                        :index="index"
                        :is-dragging="index === closestIndex"
                    />
                </div>
            </component>
        </template>
    </component>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

const emit = defineEmits(['update:modelValue', 'change'])

const props = defineProps({
    modelValue: {
        type: Array,
        required: true,
    },
    itemKey: {
        type: String,
        required: false,
        default: 'id',
    },
    tag: {
        type: String,
        required: false,
        default: 'div',
    },
    itemTag: {
        type: String,
        required: false,
        default: 'div',
    },
    handle: {
        type: String,
        required: false,
        default: '.handle',
    },
    delay: {
        type: Number,
        required: false,
        default: 0,
    },
    touchDelay: {
        type: Number,
        required: false,
        default: 0,
    },
})
const wrapper = ref(null)

const closestIndex = ref(null)
const draggingIndex = ref(null)

const items = computed(() => {
    return props.modelValue
})

const currentItems = computed(() => {
    if (draggingIndex.value === null || draggingIndex.value === closestIndex.value)
        return items.value

    const orderedItems = [...items.value]
    const currentItem = orderedItems.splice(draggingIndex.value, 1)
    return [...orderedItems.splice(0, closestIndex.value), currentItem[0], ...orderedItems]
})

watch(
    () => currentItems.value,
    (newVal) => {
        emit('change', newVal)
    },
)

const scrollArea = ref(null)

function bindWrapperEvents(wrapper) {
    wrapper.addEventListener('touchstart', wrapperTouchStart, {
        passive: false,
    })
    wrapper.addEventListener('mousedown', wrapperTouchStart)
}

function unbindWrapperEvents(wrapper) {
    wrapper.removeEventListener('touchstart', wrapperTouchStart)
    wrapper.removeEventListener('mousedown', wrapperTouchStart)
}

function normalizePosition(e) {
    if (e.constructor.name === 'TouchEvent') {
        return { x: e.touches[0].clientX, y: e.touches[0].clientY }
    } else {
        return { x: e.clientX, y: e.clientY }
    }
}

// const closestIndicator = window.document.createElement('div')
// closestIndicator.style.position = 'fixed'
// closestIndicator.style.width = '100%'
// closestIndicator.style.height = '2px'
// closestIndicator.style.backgroundColor = 'red'
// closestIndicator.style.pointerEvents = 'none'
// closestIndicator.style.zIndex = '9999'
// window.document.body.appendChild(closestIndicator)

function itemFromPosition(position) {
    let closest = Infinity
    let closestIndex = null
    for (let i = 0; i < wrapper.value.children.length; i++) {
        const child = wrapper.value.children[i]
        const rect = child.getBoundingClientRect()
        const elementY = rect.y + rect.height / 2 // + (window.scrollY || window.pageYOffset);
        const distance = Math.abs(position.y - elementY)

        if (closestIndex === null || distance < closest) {
            closestIndex = i
            closest = distance
        } else if (distance >= closest) {
            return closestIndex
        }
    }

    return closestIndex
}

const startPosition = ref(null)
const currentPosition = ref(null)

const nodeStartPosition = ref(null)
const nodeCurrentPosition = ref(null)

const draggingNode = ref(null)
const mutationObserver = ref(null)
const draggingOffset = computed(() => {
    if (startPosition.value && currentPosition.value) {
        return {
            x:
                currentPosition.value.x -
                nodeCurrentPosition.value.x -
                (startPosition.value.x - nodeStartPosition.value.x), //currentPosition.value.x - startPosition.value.x + (nodeStartPosition.value.x - nodeCurrentPosition.value.x),
            y:
                currentPosition.value.y -
                nodeCurrentPosition.value.y -
                (startPosition.value.y - nodeStartPosition.value.y), // currentPosition.value.y - startPosition.value.y + (nodeStartPosition.value.y - nodeCurrentPosition.value.y)// - scrollOffset.value,
        }
    }
    return { x: 0, y: 0 }
})

const scrollOffset = ref(0)
const didMove = ref(false)

function onUp() {
    document.removeEventListener('touchmove', onMove)
    document.removeEventListener('mousemove', onMove)
    document.removeEventListener('touchend', onUp)
    document.removeEventListener('mouseup', onUp)
    window.removeEventListener('scroll', refreshNodePosition, { passive: true, capture: true })

    // Do the thing
    if (didMove.value) {
        emit('update:modelValue', currentItems.value)
    }
    draggingIndex.value = null
    closestIndex.value = null
    scrollOffset.value = 0
    didMove.value = false
    scrollArea.value = null
    currentPosition.value = null
    startPosition.value = null
    nodeStartPosition.value = null
    nodeCurrentPosition.value = null
    mutationObserver.value.disconnect()
    removeScrollInterval()
}

function onMove(e) {
    if (!didMove.value) {
        didMove.value = true
    }
    e.preventDefault()
    const position = normalizePosition(e)

    currentPosition.value = position

    closestIndex.value = itemFromPosition(position)

    // closestIndicator.style.top = wrapper.value.children[closestIndex.value].getBoundingClientRect().top + 'px'

    let rect = null
    if (scrollArea.value) {
        rect = scrollArea.value.getBoundingClientRect()
    } else {
        rect = {
            y: 0,
            height: window.innerHeight,
        }
    }

    if (position.y <= rect.y + 10) {
        scrollOffset.value = Math.max(-50, position.y - (rect.y + 10))
    } else if (position.y >= rect.y + rect.height - 10) {
        scrollOffset.value = Math.min(50, position.y - (rect.y + rect.height - 10))
    } else {
        scrollOffset.value = 0
    }
}

function refreshNodePosition() {
    if (!draggingNode.value) return
    const rect = draggingNode.value.getBoundingClientRect()
    nodeCurrentPosition.value = {
        x: rect.left,
        y: rect.top,
    }
}

function wrapperTouchStart(e) {
    if (
        (e.constructor.name === 'TouchEvent' && e.touches.length > 1) ||
        (e.constructor.name !== 'TouchEvent' && e.button !== 0)
    ) {
        return
    }
    const handle = findClosestHandle(e.target)

    if (!handle) return

    startPosition.value = normalizePosition(e)

    const node = findClosestItem(handle)
    const index = [].indexOf.call(wrapper.value.children, node)

    draggingNode.value = node

    nodeStartPosition.value = {
        x: node.getBoundingClientRect().left,
        y: node.getBoundingClientRect().top,
    }
    nodeCurrentPosition.value = nodeStartPosition.value

    let aborted = false
    const abort = function () {
        aborted = true
        document.removeEventListener('touchmove', abort)
        document.removeEventListener('mousemove', abort)
        startPosition.value = null
        currentPosition.value = null
    }

    const delay = e.type === 'touchstart' ? props.touchDelay : props.delay
    if (delay) {
        document.addEventListener('touchmove', abort)
        document.addEventListener('mousemove', abort)
    }
    window.setTimeout(() => {
        if (aborted) return
        e.preventDefault()

        draggingIndex.value = index
        closestIndex.value = index

        scrollArea.value = findClosestScrollArea(wrapper.value)

        document.addEventListener('touchmove', onMove, { passive: false })
        document.addEventListener('mousemove', onMove)
        document.addEventListener('touchend', onUp)
        document.addEventListener('mouseup', onUp)
        window.addEventListener('scroll', refreshNodePosition, { passive: true, capture: true })

        mutationObserver.value = new MutationObserver(refreshNodePosition)
        mutationObserver.value.observe(wrapper.value, {
            childList: true,
            subtree: true,
        })
    }, delay || 0)
}

watch(
    () => scrollOffset.value,
    (newVal, oldVal) => {
        if (newVal) addScrollInterval()
        else removeScrollInterval()
    },
)

let scrollInterval = null
function addScrollInterval() {
    if (!scrollInterval) {
        scrollInterval = window.setInterval(() => {
            if (!scrollArea.value) {
                window.scrollBy(0, scrollOffset.value)
            } else {
                scrollArea.value.scrollTop += scrollOffset.value
            }
        }, 24)
    }
}

function removeScrollInterval() {
    if (scrollInterval) {
        window.clearInterval(scrollInterval)
        scrollInterval = null
    }
}

function findClosestHandle(target) {
    let elm = target
    while (elm) {
        if (props.handle && elm.matches(props.handle)) return elm
        if (elm === wrapper.value) return null
        elm = elm.parentNode
        if (!props.handle && elm === wrapper) return elm
    }
    return null
}

function findClosestItem(target) {
    let elm = target
    do {
        const parent = elm.parentNode
        if (parent === wrapper.value) return elm
        elm = parent
    } while (elm)
    return null
}

function findClosestScrollArea(target) {
    let elm = target

    while (elm && elm !== window.document.body) {
        if (elm.scrollHeight > elm.clientHeight) {
            return elm
        }
        elm = elm.parentNode
    }
    return null
}

watch(
    () => wrapper.value,
    (newVal, oldVal) => {
        if (newVal) {
            bindWrapperEvents(newVal)
        }
        if (oldVal) {
            unbindWrapperEvents(oldVal)
        }
    },
)
</script>

<style module>
.wrapper {
    --sortable-offset-x: 0;
    --sortable-offset-y: 0;
}
.wrapperDragging {
    user-select: none;
}
.innerWrapper {
    transition: transform 150ms cubic-bezier(0.17, 0.67, 0.21, 1.24);
    transform: translate(var(--sortable-offset-x), var(--sortable-offset-y));
    will-change: transform;
}
.innerWrapperDragging {
    transition: none;
    user-select: none;
}
</style>
