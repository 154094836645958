import { appendScriptsToBody, extractScriptsFromElement } from './asyncHtml.js'
import Eventbus from '@modules/eventbus/Eventbus.js'

export const h = function (htmlString) {
    let div = document.createElement('div')
    div.innerHTML = htmlString.trim()

    return div.firstChild
}

export const putContent = (element, content) => {
    return new Promise((resolve, reject) => {
        if (typeof element === 'string') {
            element = document.querySelector(element)
        }
        if (!element) {
            reject('Element not found')
            return
        }
        const tempElement = document.createElement('div')
        if (typeof content === 'string') {
            tempElement.innerHTML = content
        } else {
            tempElement.appendChild(content)
        }
        if (tempElement.children.length > 1) {
            reject('Content must have a single root element')
            return
        }
        extractScriptsFromElement(tempElement)
            .then((scripts) => {
                element.innerHTML = tempElement.innerHTML
                return scripts
            })
            .then((scripts) => {
                // On content needs to go before the scripts because Vue might replace elements
                onContent(element.firstElementChild)
                appendScriptsToBody(scripts)
                resolve(element.firstElementChild)
            })
            .catch((e) => {
                reject(e)
            })
    })
}

export const onContent = (selector = null) => {
    const element =
        !selector || typeof selector === 'string'
            ? document.querySelector(selector || 'html')
            : selector

    const parentLinks = element.querySelectorAll('.submenu li a.parent')

    parentLinks.forEach((link) => {
        link.addEventListener('click', (event) => {
            event.preventDefault()
            const siblingUl = link.nextElementSibling

            if (siblingUl && siblingUl.tagName === 'UL') {
                siblingUl.style.display = 'block'

                const hideUl = (e) => {
                    if (!siblingUl.contains(e.target) && !link.contains(e.target)) {
                        siblingUl.style.display = 'none'
                        document.removeEventListener('click', hideUl)
                    }
                }

                document.addEventListener('click', hideUl)
            }
        })
    })

    Eventbus.emit('content', element)
}

export const checkVisibility = (element) => {
    const styles = window.getComputedStyle(element)
    return styles.display !== 'none' && styles.visibility !== 'hidden' && styles.opacity !== '0'
}
