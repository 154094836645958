import http from '../../utils/http.js'
import { h } from '../../utils/html.js'
import { headerHeight } from '../header/header.js'

type popupOptions = {
    origin?: HTMLElement
    content?: HTMLElement
    event?: Event
    className?: string
    url?: string
    over?: () => void
    out?: () => void
    destroy?: boolean
    fixed?: boolean
    nub?: boolean
    left?: number
    top?: number
    width?: number
    height?: number
    maxWidth?: number
    padding?: number
    align?: string
}

export class PopupBubble {
    isLoaded: boolean
    nub: HTMLElement
    container: HTMLElement
    currentOrigin: HTMLElement | null

    constructor(private options: popupOptions) {
        this.container = document.createElement('div')
        this.container.classList.add('popup', 'bubble', 'drop-in')
        this.container.classList.add(options.fixed ? 'fixed' : 'absolute')
        this.isLoaded = false

        if (options.nub) {
            this.container.classList.add('w-nub')
            const nub = document.createElement('span')
            nub.classList.add('nub')
            this.container.append(nub)
            this.nub = nub
        }

        if (options.origin) {
            const originOffset = options.origin.getBoundingClientRect()
            options.left = originOffset.left
            options.top = originOffset.top + options.origin.clientHeight
        }

        if (options.padding !== undefined) {
            this.container.style.padding = `${options.padding}px`
        }

        if (options.className !== null) {
            const classes = options.className.split(' ')
            classes.forEach((className) => {
                this.container.classList.add(className)
            })
        }

        if (options.content) {
            this.container.append(options.content)
            document.body.append(this.container)
            this.load(options.event)
        }

        if (options.url) {
            http.get(options.url).then(({ data }) => {
                this.container.append(h(data))
                document.body.append(this.container)
                this.load(options.event)
            })
        }

        options.over = options.over || function () {}
        options.out = options.out || function () {}

        Object.assign(this.container.style, {
            left: `${options.left}px`,
            top: `${options.top}px`,
            width: `${options.width}px`,
            height: `${options.height}px`,
            maxWidth: `${options.maxWidth}px`,
        })

        this.container.addEventListener('mouseover', (e) => {
            this.container.classList.add('hover')
            options.over.apply(this, [e])
        })

        this.container.addEventListener('mouseleave', (e) => {
            this.container.classList.remove('hover')
            if (
                (options.origin && e.relatedTarget === options.origin) ||
                (this.currentOrigin && e.relatedTarget === this.currentOrigin)
            ) {
                return
            }
            options.out.apply(this, [e])
        })

        return this
    }

    setPositionFromEvent(e) {
        if (!e.target.offsetParent) {
            this.close()
            return
        }

        const origin = e.target
        this.currentOrigin = origin
        const originRect = origin.getBoundingClientRect()
        let height = this.container.offsetHeight
        let width = this.container.offsetWidth
        if (!width) {
            // if the width is 0, it's likely because the element is hidden
            const previousDisplay = this.container.style.display
            const previousVisibility = this.container.style.visibility
            this.container.style.display = 'block'
            this.container.style.visibility = 'hidden'
            width = this.container.offsetWidth
            height = this.container.offsetHeight
            this.container.style.display = previousDisplay
            this.container.style.visibility = previousVisibility
        }
        // Calculate the top position of the container relative to the origin element
        let top = originRect.top + window.scrollY - height - 10

        // Calculate the left position of the container relative to the origin element
        const xOffset =
            this.options.align === 'cursor'
                ? e.pageX - originRect.left
                : Math.floor(originRect.width / 2)
        let left = originRect.left + xOffset
        let nub = 20

        // Adjust the left position if the container has the 'center' class
        if (this.container.classList.contains('center')) {
            left -= Math.floor(width / 2)
            nub = Math.floor(width / 2)
        }

        // Adjust the left position if the container exceeds the window width
        if (left + width > window.innerWidth) {
            const newLeft = Math.max(0, window.innerWidth - width)
            nub += left - newLeft
            left = newLeft
        }

        // Determine the position of the container based on its relation to the header
        if (originRect.top - height < headerHeight(true)) {
            this.container.classList.remove('bottom', 'drop-up')
            this.container.classList.add('drop-in')
            top = originRect.top + originRect.height
        } else {
            this.container.classList.remove('drop-in')
            this.container.classList.add('bottom', 'drop-up')
        }

        // Set the left and top positions of the container
        this.container.style.left = `${left}px`
        this.container.style.top = `${top}px`

        // Adjust the position of the nub element if it exists
        if (this.nub) {
            this.nub.style.left = `${nub}px`
        }
    }

    load(event): void {
        if (event) {
            this.setPositionFromEvent(event)
        }
    }

    close() {
        if (this.options.destroy !== false) {
            this.container.remove()
        } else {
            this.container.style.display = 'none'
        }
    }

    open(options: popupOptions = {}) {
        this.load(options.event)
        this.container.style.display = 'block'
    }

    left(leftVal) {
        const rect = this.container.getBoundingClientRect()
        if (leftVal == null) {
            return rect.left
        } else {
            this.options.left = leftVal
            this.container.style.left = `${leftVal}px`
        }
    }

    top(topVal) {
        const rect = this.container.getBoundingClientRect()
        if (topVal == null) {
            return rect.top
        } else {
            this.options.top = topVal
            this.container.style.top = `${topVal}px`
        }
    }
}
