<script setup>
import { ref, onMounted, computed } from 'vue'
import http from '@utils/http'
import { numberFormat, translate } from '../../../es6/src/modules/locale/locale.js'
import { useUserStore } from '../../../store/user.js'

const props = defineProps({
    trophy: {
        type: Object,
        required: true,
    },
    username: {
        type: String,
        default: null,
    },
})

const userStore = useUserStore()
const selectedTrophy = ref(props.trophy)
const trophies = ref([])

onMounted(() => {
    fetchTrophyData(props.trophy, props.username)
})

const fetchTrophyData = (trophy, username) => {
    http.get('/trophy/family/', {
        params: {
            trophy: trophy.code,
            user: username,
        },
    })
        .then((res) => {
            trophies.value = res.data

            if (trophies.value.length) {
                selectedTrophy.value = trophies.value.find((t) => t.code === props.trophy.code)
            }
        })
        .catch((error) => {
            console.error('Error fetching trophy data:', error)
        })
}

function getLink(trophy) {
    if (trophy.code === 'REGISTERED') {
        return '/about/points/'
    }

    return trophy.code ? `/trophy/${trophy.code}/` : false
}

function getNumber(trophy) {
    return trophy.family ? trophy.familyLevel : trophy.possessionCount
}

function getTrophyImage(trophy) {
    return !trophy.image ? trophy.placeholder : trophy.image
}

const progressPercentage = computed(() => {
    const progress = selectedTrophy.value?.progress || { total: 0, possession: false }

    return progress.possession ? 100 : progress.total * 100
})
</script>

<template>
    <div>
        <div class="user-trophy glow">
            <h1 class="user-trophy-name" v-if="!selectedTrophy.multiple">
                {{ selectedTrophy.name }}
            </h1>

            <div
                class="user-trophy-image-container"
                :class="{
                    multi: selectedTrophy.multiple,
                    'trophy-levels': selectedTrophy.family,
                }"
                :data-number="getNumber(selectedTrophy)"
            >
                <img
                    :src="getTrophyImage(selectedTrophy)"
                    class="user-trophy-image"
                    :alt="selectedTrophy.name"
                />
            </div>

            <h1 class="user-trophy-name" v-if="selectedTrophy.multiple">
                {{ selectedTrophy.name }}
            </h1>

            <p class="user-trophy-description">
                {{ selectedTrophy.description }}
            </p>
        </div>

        <a
            :href="getLink(selectedTrophy)"
            class="user-trophy-details-link flat-button flat-button--small flat-button--white mt-1"
            :class="{ disabled: !selectedTrophy.code }"
        >
            <template v-if="selectedTrophy.code === 'REGISTERED'">
                {{ translate('what_is_this') }}
            </template>

            <template v-else>
                {{ translate('read_more') }}
            </template>
        </a>

        <div
            class="user-trophies"
            v-if="selectedTrophy.family"
            :class="{ loading: !trophies.length }"
        >
            <div
                class="user-trophy small scale"
                :class="{ focus: selectedTrophy.name === t.name }"
                v-for="t in trophies"
            >
                <div
                    class="user-trophy-image-container trophy-levels"
                    :data-number="t.familyLevel"
                    @click="selectedTrophy = t"
                >
                    <img :src="getTrophyImage(t)" :alt="t.name" class="user-trophy-image" />
                </div>
            </div>
        </div>

        <div
            class="user-trophy-progress-container"
            v-if="userStore.user && selectedTrophy.multiple"
        >
            <div class="message">
                <span class="artwork x-small circle">
                    <img :src="userStore.user.thumbnail" :alt="userStore.user.username" />
                </span>
                <span class="label">
                    {{
                        translate(
                            'user_trophy_your_multi_count',
                            numberFormat(selectedTrophy.possessionCount, 0),
                        )
                    }}
                </span>
            </div>
        </div>

        <div
            class="user-trophy-progress-container"
            v-if="userStore.user && !selectedTrophy.multiple"
        >
            <div class="message">
                <span class="artwork x-small circle">
                    <img :src="userStore.user.thumbnail" :alt="userStore.user.username" />
                </span>
                <span class="label">{{ translate('user_trophy_your_progress') }}</span>
            </div>

            <div class="user-trophy-progress" :title="progressPercentage">
                <img
                    class="trophy"
                    :src="
                        selectedTrophy.progress?.possession
                            ? selectedTrophy.image
                            : selectedTrophy.placeholder
                    "
                    :alt="selectedTrophy.name"
                />

                <div class="user-progress-slider">
                    <span class="progress-bar">
                        <span class="progress" :style="{ width: `${progressPercentage}%` }"></span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
