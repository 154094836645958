import { uglifyName } from '../../utils/string'
import { log as trackingLog } from './tracking.js'

export default {
    gtag: {
        identity: null,
        indentify(user) {
            if (!window.gtag) {
                trackingLog('Could not identify gtag')
                return
            }
            if (this.identity !== null && user.username === this.identity) {
                return
            }
            if (!user) {
                this.identity = null
                window.gtag('set', { user_id: null })
            } else {
                this.identity = user.username
                window.gtag('set', { user_id: user.username })
            }
        },
        convertProperties(properties) {
            if (!properties) {
                return {}
            }
            const map = {
                category: 'event_category',
                label: 'event_label',
                nonInteraction: 'non_interaction',
            }

            for (const key in properties) {
                if (key in map) {
                    properties[map[key]] = properties[key]
                    delete properties[key]
                }
            }
            return properties
        },
        track(event) {
            if (!window.gtag || !event) {
                return
            }
            const properties = this.convertProperties(event.properties)
            window.gtag('event', event.name, {
                hitType: 'event',
                ...properties,
            })
        },
        pageView(url) {
            if (!window.gtag) {
                return
            }
            window.gtag('event', 'page_view', { page_path: url })
        },
    },
    fbq: {
        identity: null,
        indentify() {
            return
        },
        track(event) {
            if (!window.fbq) {
                return
            }
            if (!(window.location?.search || '').match(/[&?]lng=/)) {
                window.fbq('trackCustom', uglifyName(event.name), event.properties)
            }
        },
        pageView() {
            if (!window.fbq) {
                return
            }
            if (!(window.location?.search || '').match(/[&?]lng=/)) {
                window.fbq('track', 'PageView')
            }
        },
    },
    // "ga" : {
    // 	identity : null,
    // 	indentify : function(user) {
    // 		if(!window.ga) {
    // 			thatsup.tracking.log("Could not identify ga");
    // 			return;
    // 		}
    // 		if(this.identity !== null && user.username === this.identity) {
    // 			return;
    // 		}
    // 		this.identity = user.username;
    // 		thatsup.tracking.log("Identifying ga", user.username);
    // 		window.ga('set', '&uid', user.username);
    // 	},
    // 	track : function(event) {
    // 		if(!window.ga) {
    // 			return;
    // 		}
    // 		var properties = event.properties || {};
    // 		window.ga(
    // 			'send',
    // 			{
    // 				'hitType': 'event',
    // 				'eventCategory': properties.category,
    // 				'eventAction' : properties.action,
    // 				'eventLabel' : properties.label,
    // 				'eventValue' : properties.value,
    // 				'nonInteraction' : !!properties.nonInteraction
    // 			}
    // 		);
    // 	},
    // 	pageView : function(url) {
    // 		if(!window.ga) {
    // 			return;
    // 		}
    // 		window.ga('send', 'pageview', url);
    // 		gtag('event', 'page_view', { page_path : url });
    // 	}
    // },
}
