import { handleMainHeaderCompact } from './mainHeader.js'
import {
    handleMobileNavToggle,
    handleMobileNavClose,
    handleMobileNavCloserTouch,
    handleMobileNavSubMenuToggle,
} from './mobileNav.js'

document.addEventListener('DOMContentLoaded', () => {
    handleMainHeaderCompact()

    handleMobileNavToggle()
    handleMobileNavClose()
    handleMobileNavCloserTouch()
    handleMobileNavSubMenuToggle()
})
