import { translate } from '../locale/locale.js'
import http from '../../utils/http.js'
import { popup } from '../popup/popup.js'
import { useUserStore } from '../../../../store/user.js'
import { auth } from '../authentication/authentication.js'
import Eventbus from '../eventbus/Eventbus.js'

const userPopupMulti = (places) => {
    const fragment = document.createDocumentFragment()
    const container = document.createElement('div')
    fragment.appendChild(container)
    container.classList.add('item-list')

    places.forEach(function (place) {
        const item = document.createElement('div')
        item.className = 'item cursor-pointer weight-600 d-flex ai-center jc-space-between'
        item.innerText = place.name

        const chevron = document.createElement('i')
        chevron.className = 'icon--chevron-right icon--chevron-right--gray'
        item.appendChild(chevron)

        item.addEventListener('click', function () {
            userPopup(place.id)
        })

        container.appendChild(item)
    })

    popup({
        cssClass: 'popup--compact',
        content: fragment,
        width: 400,
        maxHeight: 500,
        title: translate('choose_lists_popup_title'),
    })
}

const userPopup = (placeId) => {
    const userStore = useUserStore()

    if (!userStore.isLoggedIn) {
        return
    }

    if (placeId && typeof placeId === 'object') {
        placeId = placeId.id
    }
    const fragment = document.createDocumentFragment()
    const container = document.createElement('div')
    fragment.appendChild(container)
    container.classList.add('item-list')
    container.classList.add('background--loading')
    const createItem = function (placeList) {
        const item = document.createElement('label')
        item.classList.add('item')
        item.classList.add('place-list-checkbox')
        const input = document.createElement('input')
        input.type = 'checkbox'
        input.checked = !!placeList.containsPlace
        input.addEventListener('change', function () {
            item.classList.toggle('changed')
            if (this.checked) {
                addPlace(placeId, placeList).catch(() => {
                    input.checked = false
                })
            } else {
                removePlace(placeId, placeList).catch(() => {
                    input.checked = false
                })
            }
        })
        item.appendChild(input)
        const label = document.createElement('span')
        label.classList.add('label')
        label.innerText = placeList.name
        item.appendChild(label)
        return item
    }
    http.get('/user/lists/', { params: { place: placeId } }).then(({ data }) => {
        data.forEach((placeList) => {
            container.appendChild(createItem(placeList))
        })
        const createListItem = document.createElement('div')
        createListItem.className = 'item pretty-form'
        const createLink = document.createElement('a')
        createLink.role = 'button'
        createLink.classList.add('d-flex')
        createLink.innerHTML = '<i class="sli sli-plus sli-blue va-middle mr-2"></i>'

        const createLabel = document.createElement('span')
        createLabel.innerText = translate('create_new_list')
        createLabel.classList.add('fw-6')
        createLink.appendChild(createLabel)
        createListItem.appendChild(createLink)

        const createForm = document.createElement('div')
        createForm.className = 'd-flex ai-center'
        createForm.style.display = 'none'
        const createInput = document.createElement('input')
        createInput.type = 'text'
        createInput.placeholder = translate('create_list_name')
        createInput.className = 'size-medium full-width'
        createForm.appendChild(createInput)
        const createButton = document.createElement('a')
        createButton.className = 'p-2'
        const createIcon = document.createElement('i')
        createIcon.className = 'icon--plus-circle blue va-middle'
        createButton.appendChild(createIcon)
        createForm.appendChild(createButton)

        createListItem.appendChild(createForm)
        const formLoading = function () {
            createForm.disabled = true
            createInput.disabled = true
            createIcon.className = 'icon--loading-ring x21 grey va-middle'
        }
        const formNotLoading = function () {
            createForm.disabled = false
            createInput.disabled = false
            createIcon.className = 'icon--plus-circle blue va-middle'
        }
        const submitForm = function () {
            if (!createInput.value) {
                return
            }
            formLoading()
            createList({
                name: createInput.value,
                places: [placeId],
            })
                .then((data) => {
                    formNotLoading()
                    createForm.style.display = 'none'
                    createLink.style.display = 'block'
                    createInput.value = ''
                    container.insertBefore(
                        createItem({
                            name: data.name,
                            id: data.id,
                            containsPlace: true,
                        }),
                        createListItem,
                    )
                })
                .catch(() => {
                    formNotLoading()
                })
        }
        createLink.addEventListener('click', function () {
            this.style.display = 'none'
            createForm.style.display = ''
            createInput.focus()
        })
        createInput.addEventListener('blur', function () {
            if (!this.value) {
                createForm.style.display = 'none'
                createLink.style.display = 'block'
            }
        })
        createInput.addEventListener('keydown', function (e) {
            if (e.which === 13) {
                submitForm()
            }
        })
        createButton.addEventListener('click', function () {
            if (this.disabled) {
                return
            }
            submitForm()
        })
        container.appendChild(createListItem)
        container.classList.remove('background--loading')
    })

    popup({
        content: fragment,
        cssClass: 'popup--compact',
        width: 400,
        maxHeight: 500,
        title: translate('select_list'),
    })
}

const createList = (data) => {
    return new Promise((resolve, reject) => {
        http.post('/user/list/create/', data)
            .then(({ data }) => {
                if (data.success === true) {
                    Eventbus.emit('place-list:created', data)
                    Eventbus.emit('place-list:saved', data)
                    resolve(data)
                } else {
                    reject(data)
                }
            })
            .catch((e) => {
                reject(e)
            })
    })
}

const addPlace = (place, placeList) => {
    return updatePlace(place, placeList, 'add')
}

const removePlace = (place, placeList) => {
    return updatePlace(place, placeList, 'remove')
}

const updatePlace = (place, placeList, action) => {
    const placeId = typeof place === 'string' ? place : place.id
    const placeListId = typeof placeList === 'string' ? placeList : placeList.id

    return new Promise((resolve, reject) => {
        if (!placeId || !placeListId) {
            return reject()
        }

        http.post('/user/list/update/', {
            place: placeId,
            list: placeListId,
            action,
        })
            .then(({ data }) => {
                if (data.success === true) {
                    Eventbus.emit('place-list:updated', data)
                    Eventbus.emit('place-list:saved', data)
                    resolve(data)
                } else {
                    reject(data)
                }
            })
            .catch((e) => {
                reject(e)
            })
    })
}

const save = (place, list = null) => {
    const userStore = useUserStore()

    if (!userStore.isLoggedIn) {
        return auth(
            () => {
                window.location.reload()
            },
            {
                variation: 'lists',
            },
        )
    }

    if (typeof place === 'string') {
        if (!list) {
            userPopup(place)
        } else {
            addPlace(place, list)
        }
    } else if (Array.isArray(place)) {
        if (place.length === 1) {
            return userPopup(place[0].id)
        }
        return userPopupMulti(place)
    }
}

export const placeList = {
    userPopupMulti,
    userPopup,
    createList,
    addPlace,
    removePlace,
    updatePlace,
    save,
}
