<template>
    <div
        class="search-autocomplete__input-wrapper"
        :class="{
            [`search-autocomplete__input-wrapper--${name}`]: true,
            'search-autocomplete__input-wrapper--active': active,
            'search-autocomplete__input-wrapper--disabled': disabled,
            'search-autocomplete__input-wrapper--has-value': !!currentValue || !!displayValue,
        }"
    >
        <label v-if="label" :for="uuid" class="search-autocomplete__input-label">
            {{ label }}
        </label>
        <span v-if="icon" class="search-autocomplete__input-icon" @click="$emit('icon:click')">
            <i :class="icon"></i>
        </span>
        <input
            type="search"
            class="search-autocomplete__input"
            @input="currentValue = $event.target.value"
            :value="displayValue || currentValue"
            :id="uuid"
            :name="name"
            :placeholder="placeholder"
            @keydown.up.prevent="stepHighlightedResult(-1)"
            @keydown.down.prevent="stepHighlightedResult(1)"
            @keydown.enter.prevent="selectHighlightedResult"
            @keydown.esc="$emit('cancel')"
            autocomplete="off"
            @focus="onFocus"
            @blur="onBlur"
            @click="onClick"
            ref="input"
            :disabled="disabled"
            :required="required"
        />
        <span
            v-if="clearable && !disabled && active"
            class="search-autocomplete__input-clear"
            @click="clear"
        >
            <i class="icon--close-x-small icon--close-x-small--white"></i>
        </span>
        <slot name="after" />
    </div>
</template>
<script>
import throttle from 'lodash-es/throttle'
import debounce from 'lodash-es/debounce'
import http from '@utils/http'

export default {
    emits: [
        'update:modelValue',
        'results',
        'cancel',
        'icon:click',
        'focus',
        'blur',
        'loading',
        'highlight',
        'select:result',
        'select:none',
    ],
    props: {
        id: {
            type: String,
            required: false,
            default: null,
        },
        modelValue: {
            type: [String, Number],
            required: false,
            default: null,
        },
        displayValue: {
            type: [String, Number],
            required: false,
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        url: {
            type: String,
            required: false,
            default: null,
        },
        params: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        highlight: {
            type: Object,
            required: false,
        },
        limit: {
            type: Number,
            required: false,
            default: null,
        },
        placeholderResults: {
            type: [Array, Function, Object, String],
            required: false,
            default: null,
        },
        source: {
            type: [Array, Function, Object, String],
            required: false,
            default: null,
        },
        sources: {
            type: Array,
            required: false,
            default: null,
        },
        after: {
            type: [Array, Function, Object],
            required: false,
            default: null,
        },
        autoHighlight: {
            type: [Function, Boolean],
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: null,
        },
        icon: {
            type: String,
            required: false,
            default: null,
        },
        verbose: Boolean,
        active: Boolean,
        disabled: Boolean,
        clearable: Boolean,
        required: Boolean,
        selectOnFocus: Boolean,
        isOpen: Boolean,
    },
    data() {
        const key =
            this.id ||
            `search-autocomplete-${this.name}-${
                document.querySelectorAll('.search-autocomplete__input').length
            }`
        return {
            uuid: key,
            results: null,
            isLoading: false,
            highlightedResult: -1,
            didSelect: false,
            searchIndex: 0,
            finishedSearchIndex: 0,
        }
    },

    computed: {
        currentValue: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            },
        },
        filterTerm() {
            return this.currentValue ? this.currentValue.toLowerCase() : this.currentValue
        },
        allSources() {
            if (this.sources) {
                return this.sources
            }
            return [this.source]
        },
        highlightedIndex: {
            get() {
                return !this.allResults || !this.highlight
                    ? -1
                    : this.allResults.findIndex((r) => r.id === this.highlight.id)
            },
            set(index) {
                this.setHighlightedResultIndex(index)
            },
        },
        highlightedItem() {
            const index = this.highlightedIndex
            if (index === -1) return null
            return this.allResults[index]
        },
        afterResults() {
            let after = this.after
            if (typeof after === 'function') {
                after = this.after(this.currentValue, this.results)
            }
            if (!after) {
                return []
            } else if (Array.isArray(after)) {
                return after
            } else if (typeof after === 'object') {
                return [after]
            }
            return []
        },
        allResults() {
            if (!this.results) {
                return this.results
            }
            return [...this.results, ...this.afterResults]
        },
    },

    watch: {
        currentValue: {
            handler(newVal) {
                this.triggerSearch(false)
            },
            deep: true,
        },
        params: {
            handler(newVal) {
                this.triggerSearch(true)
            },
            deep: true,
        },
        placeholderResults: {
            handler(newVal) {
                if (!this.currentValue) {
                    this.triggerSearch(true)
                }
            },
            deep: true,
        },
        allSources: {
            handler(newVal, oldVal) {
                this.triggerSearch(true)
            },
            deep: true,
        },
        isLoading(newVal) {
            this.$emit('loading', newVal)
        },
        allResults(newVal) {
            this.$emit('results', newVal)
        },
        active(newVal) {
            if (newVal) {
                this.$refs.input.focus()
            }
        },
    },

    cancelSearch: null,
    requestIndex: null,

    methods: {
        triggerSearch(immediate = false) {
            if (!this.isOpen) {
                return
            }
            this.isLoading = true
            if (!immediate) {
                this.throttleSearch()
            } else {
                this.search()
            }
        },

        onFocus(e) {
            this.$emit('focus')
            if (this.results === null) {
                this.isLoading = true
                this.search()
            }
            if (this.selectOnFocus && this.currentValue && !this.didSelect) {
                this.highlightText()
            }
        },

        onBlur() {
            this.$emit('blur')
            this.didSelect = false
        },

        onClick() {
            if (this.selectOnFocus && !this.didSelect) {
                this.highlightText()
            }
        },

        onTab(e) {
            if (!e.shiftKey && this.highlight) {
                this.selectHighlightedResult()
            }
        },

        highlightText() {
            if (!this.currentValue) {
                return
            }
            this.didSelect = true
            try {
                this.$refs.input.setSelectionRange(0, this.currentValue.length)
            } catch (e) {
                console.error(e)
            }
        },

        clear() {
            this.currentValue = null
            this.focus()
        },

        focus() {
            this.$refs.input.focus()
        },

        blur() {
            this.$refs.input.blur()
        },

        stepHighlightedResult(step, stopAt = null) {
            const currentIndex = this.highlightedIndex
            let newIndex = currentIndex + step

            if (newIndex < -1) {
                newIndex = this.allResults.length - 1
            } else if (newIndex >= this.allResults.length) {
                newIndex = -1
            }
            if (newIndex === currentIndex || newIndex === stopAt) {
                return
            }
            const result = this.resultByIndex(newIndex)
            if (result && result.disabled) {
                return this.stepHighlightedResult(
                    step + Math.sign(step),
                    stopAt === null ? newIndex : stopAt,
                )
            }
            return this.setHighlightedResult(result)
        },

        setHighlightedResultIndex(index) {
            const result = this.resultByIndex(index)
            return this.setHighlightedResult(result)
        },

        setHighlightedResult(result) {
            this.$emit('highlight', result)
            return result
        },

        selectHighlightedResult() {
            return this.highlight ? this.select(this.highlight) : this.selectNone()
        },

        select(result) {
            this.$emit('select:result', result)
        },

        selectNone() {
            this.$emit('select:none', this.currentValue)
        },

        resultByIndex(index) {
            if (index < 0 || index >= this.allResults.length) {
                return null
            }
            return this.allResults[index]
        },

        filterFunction(item) {
            if (!this.filterTerm) {
                return true
            }
            if (!item.hitwords) {
                return false
            }
            for (let i = 0; i < item.hitwords.length; i++) {
                if (item.hitwords[i].toLowerCase().indexOf(this.filterTerm) !== -1) {
                    return true
                }
            }
            return false
        },

        getResultsFromArray(array, currentResults) {
            const filtered = array.filter(this.filterFunction)
            return !this.limit ? filtered : filtered.splice(0, this.limit - currentResults.length)
        },

        async getResultsFromCallable(callable, currentResults) {
            return callable(
                this.currentValue,
                this.limit ? this.limit - currentResults.length : null,
                currentResults,
                this.filterFunction,
            )
        },

        async getResultsFromObject(obj, currentResults) {
            const minLength = obj.minLength || 2
            if (
                !this.currentValue ||
                this.currentValue.length < minLength ||
                ('condition' in obj &&
                    !obj.condition(
                        this.currentValue,
                        this.limit ? this.limit - currentResults.length : null,
                        currentResults,
                    ))
            ) {
                return Promise.resolve([])
            }
            return await http
                .get(obj.url, {
                    params: {
                        [obj.param || 'q']: this.currentValue,
                        [obj.limitParam || 'limit']: this.limit
                            ? this.limit - currentResults.length
                            : undefined,
                        ...obj.params,
                    },
                })
                .then(({ data }) => {
                    const result = data.data || []
                    return !this.limit
                        ? result
                        : result.splice(0, this.limit - currentResults.length)
                })
        },

        getResultFromSource(source, currentResults) {
            if (!source) {
                return Promise.reject('Invalid source type.')
            } else if (typeof source === 'function') {
                return this.getResultsFromCallable(source, currentResults)
            } else if (Array.isArray(source)) {
                return this.getResultsFromArray(source, currentResults)
            } else if (typeof source === 'object') {
                return this.getResultsFromObject(source, currentResults)
            } else if (typeof source === 'string') {
                return this.getResultsFromObject(
                    {
                        url: source,
                    },
                    currentResults,
                )
            }
            return Promise.reject('Invalid source type.')
        },

        log(...args) {
            if (this.verbose) {
                console.log(...args)
            }
        },

        shouldAutoHighlight(result) {
            if (typeof this.autoHighlight === 'function') {
                return !!this.autoHighlight(result)
            }
            return !!this.autoHighlight
        },

        throttleSearch: debounce(function () {
            this.search()
        }, 200),

        async search() {
            const previouslyHighlightedId = this.highlightedItem?.id

            let currentResults = []
            const oldSearchIndex = this.searchIndex
            const newSearchIndex = oldSearchIndex + 1
            this.searchIndex = newSearchIndex
            if (!this.currentValue && this.placeholderResults) {
                this.log('Showing placeholder results [' + newSearchIndex + ']')
                currentResults = this.placeholderResults
                this.finishedSearchIndex = newSearchIndex
            } else {
                this.log('Started search [' + newSearchIndex + ']', this.currentValue)
                this.isLoading = true

                for (let i = 0; i < this.allSources.length; i++) {
                    const source = this.allSources[i]
                    const result = await this.getResultFromSource(source, currentResults)
                    // A subsequent search has already finished
                    if (this.finishedSearchIndex > newSearchIndex) {
                        this.log(
                            'Cancelling [' +
                                newSearchIndex +
                                '] (' +
                                i +
                                '/' +
                                this.allSources.length +
                                ') New index: [' +
                                this.finishedSearchIndex +
                                '].',
                        )
                        break
                    }
                    currentResults = currentResults.concat(result)
                    if (!this.results || this.results.length < currentResults.length) {
                        this.results = !this.limit
                            ? currentResults
                            : [...currentResults].splice(0, this.limit)
                        if (
                            this.highlightedIndex === -1 &&
                            currentResults.length &&
                            this.shouldAutoHighlight(currentResults[0])
                        ) {
                            this.highlightedIndex = 0
                        }
                    }

                    if (this.limit && currentResults.length >= this.limit) {
                        break
                    }
                }

                // A subsequent search has already finished
                if (this.finishedSearchIndex > newSearchIndex) {
                    this.log(
                        'Cancelling [' +
                            newSearchIndex +
                            '] (all results) New index: [' +
                            this.finishedSearchIndex +
                            '].',
                    )
                    return
                }
                this.finishedSearchIndex = newSearchIndex
                this.log('Finished [' + newSearchIndex + '].')
            }

            const results = !this.limit ? currentResults : [...currentResults].splice(0, this.limit)
            this.results = results

            const previouslyHighlightedIndex = !previouslyHighlightedId
                ? -1
                : this.results.findIndex((item) => item.id === previouslyHighlightedId)

            if (previouslyHighlightedId && previouslyHighlightedIndex !== -1) {
                this.highlightedIndex = previouslyHighlightedIndex
            } else if (
                (this.results.length && this.shouldAutoHighlight(this.results[0])) ||
                (!this.results.length &&
                    this.afterResults.length &&
                    this.shouldAutoHighlight(this.afterResults[0]))
            ) {
                this.highlightedIndex = 0
            } else {
                this.highlightedIndex = -1
            }

            this.isLoading = false
        },
    },
}
</script>
