<template>
    <div :class="$style.wrapper">
        <component :is="as" :href="href" :class="[$style.hero, { [$style.heroSmall]: small }]">
            <ImageWithSizes
                v-if="image?.src"
                :class="$style.image"
                :src="image.src"
                :srcset="image.srcset"
                :anchor="image.anchor"
                :alt="image.alt"
                loading="lazy"
            />
            <h2 :class="$style.title">{{ title }}</h2>
            <p :class="$style.description">{{ description }}</p>
        </component>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import ImageWithSizes from '../../../../Shared/components/ImageWithSizes.vue'

const props = defineProps({
    image: {
        type: Object,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    href: {
        type: String,
        required: false,
        default: null,
    },
    description: {
        type: String,
        required: false,
        default: null,
    },
    small: Boolean,
})

const as = computed(() => {
    if (props.href !== null) {
        return 'a'
    }

    return 'div'
})
</script>

<style module>
.hero {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: relative;
    color: #fff;
    padding: 14px;
    min-height: 365px;
    overflow: hidden;
    border-radius: 10px;
}

@media screen and (min-width: 768px) {
    .hero {
        padding: 30px 30px 32px;
    }
}

.hero::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.76) 0%,
        rgba(0, 0, 0, 0.2) 50%,
        rgba(0, 0, 0, 0) 100%
    );
    transition: transform 0.3s;
}

@media screen and (min-width: 768px) {
    .wrapper:nth-child(odd):last-child .hero {
        padding: 40px 40px 44px;
    }

    .wrapper:nth-child(odd):last-child .hero::after {
        background-image: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.4) 0%,
            rgba(0, 0, 0, 0.3) 50%,
            rgba(0, 0, 0, 0) 100%
        );
    }
}

.heroSmall {
    padding: 30px 30px 32px;
}

.image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.title,
.description {
    max-width: 570px;
}

.title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0;
}

@media screen and (min-width: 768px) {
    .title {
        font-size: 35px;
    }
}

.description {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 15px;
}
</style>
