<template>
    <div
        class="place-explorer__map__list-wrapper"
        :class="{
            'place-explorer__map__list-wrapper--visible': !!activePlace,
        }"
    >
        <DraggableList
            class="place-explorer__map__list"
            :items="places"
            :active="activePlace"
            @change="$emit('active-place-change', $event)"
        >
            <template #default="{ item, index }">
                <ExploreMapPlaceListPlace
                    :place="item"
                    :is-active="activePlace === item"
                    :index="sponsoredPlaces.includes(item) ? null : placePage.from + index"
                    @click="itemClick(item, index, $event)"
                />
            </template>
        </DraggableList>
    </div>
</template>
<script>
import DraggableList from '../../Shared/components/DraggableList.vue'
import ExploreMapPlaceListPlace from './ExploreMapPlaceListPlace.vue'
import http from '@utils/http'
import { trackEvent } from '../../../es6/src/modules/tracking/tracking.js'
export default {
    components: { ExploreMapPlaceListPlace, DraggableList },
    props: {
        placePage: {
            type: Object,
            required: true,
        },
        sponsoredPlaces: {
            type: Array,
            required: false,
            default: () => [],
        },
        activePlace: {
            type: Object,
            required: false,
            default: null,
        },
    },
    emits: ['active-place-change'],
    computed: {
        places() {
            return [...this.placePage.data, ...this.sponsoredPlaces]
        },
    },
    methods: {
        addUtmIfNeeded(link, params) {
            const url = new URL(link.href)
            const currentParams = url.searchParams
            for (const key in params) {
                if (params[key] && !currentParams.has(key)) {
                    currentParams.set(key, params[key])
                }
            }
            link.href = url
        },
        itemClick(item, index, e) {
            if (this.sponsoredPlaces.includes(item) && item.sponsor?.id) {
                const link = e.target?.closest('a')
                if (link) {
                    this.addUtmIfNeeded(link, {
                        utm_campaign: item.sponsor.name,
                        utm_medium: 'sponsored_explore',
                        utm_term: this.placePage?.title,
                        utm_id: item.sponsor.id,
                    })
                }
                http.post(`/api/explore/tr/${item.sponsor.id}/`, {
                    action: 'c', // Send click,
                    url: window.location.href,
                    position: index,
                })
                trackEvent('explore_sponsored_click', {
                    place: item.id,
                    sponsor_id: item.sponsor.id,
                    position: index,
                })
            }
        },
    },
}
</script>
