import { defineStore } from 'pinia'
import { useCityStore } from './city.js'

const LOCATION_DATA_KEY = 'search_autocomplete_location_data'
const LOCATION_TERM_KEY = 'search_autocomplete_location_term'

const sessionLocationData = window.sessionStorage?.getItem(LOCATION_DATA_KEY)
const sessionLocationTerm = window.sessionStorage?.getItem(LOCATION_TERM_KEY)

export const useSearchAutocompleteStore = defineStore('searchAutocomplete', {
    state: () => ({
        isOpen: false,
        locationData: sessionLocationData ? JSON.parse(sessionLocationData) : null,
        locationTerm: sessionLocationTerm,
        term: null,
        selected: null,
    }),
    actions: {
        init() {
            const cityStore = useCityStore()

            if (
                cityStore.currentCity &&
                this.locationData?.city &&
                this.locationData?.city !== cityStore.currentCity.code
            ) {
                this.clearStoredLocation()
            }
        },
        afterInit() {},
        open() {
            this.isOpen = true
        },
        close() {
            this.isOpen = false
        },
        toggle() {
            this.isOpen = !this.isOpen
        },
        setTerm(term) {
            this.term = term
        },
        setSelected(selected) {
            this.selected = selected
        },
        setLocationTerm(term) {
            this.locationTerm = term
            if ('sessionStorage' in window) {
                if (term) {
                    window.sessionStorage.setItem(LOCATION_TERM_KEY, term)
                } else {
                    window.sessionStorage.removeItem(LOCATION_TERM_KEY)
                }
            }
        },
        setLocationData(locationData) {
            this.locationData = locationData
            if ('sessionStorage' in window) {
                if (locationData) {
                    window.sessionStorage.setItem(LOCATION_DATA_KEY, JSON.stringify(locationData))
                } else {
                    window.sessionStorage.removeItem(LOCATION_DATA_KEY)
                }
            }
        },
        clearStoredLocation() {
            this.locationTerm = null
            this.locationData = null
        },
    },
    getters: {},
})
