export default {
    oldInput: null,
    mounted(el, binding) {
        if (binding.value !== false) {
            el.focus()
        }
        binding.dir.oldInput = el.name
    },
    updated(el, binding) {
        if (binding.value !== false && el.name !== binding.dir.oldInput) {
            el.focus()
        }
        binding.dir.oldInput = el.name
    },
}
