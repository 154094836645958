<template>
    <AuthButton v-bind="$attrs" :href="href" :provider="provider" />
</template>
<script>
import AuthButton from './AuthButton.vue'
export default {
    components: { AuthButton },
    props: {
        provider: {
            type: String,
            required: true,
        },
    },

    computed: {
        href() {
            const redirectUrl = location.pathname + location.search
            return (
                '/auth/app/' +
                this.provider +
                '/?redirect=' +
                window.encodeURIComponent(redirectUrl)
            )
        },
    },
}
</script>
