<template>
    <component
        class="draggable-list"
        :class="{
            'draggable-list--dragging': dragging,
        }"
        :is="as"
        :style="style"
        ref="list"
        @touchstart="touchstart"
    >
        <template v-for="(item, i) in items">
            <slot :item="item" :index="i" :is-active="i === currentIndex" />
        </template>
    </component>
</template>
<script>
import viewport from '../../../es6/src/utils/Viewport'

export default {
    props: {
        as: {
            type: [String, Object],
            required: false,
            default: 'ul',
        },
        wrapperAs: {
            type: [String, Object],
            required: false,
            default: 'div',
        },
        items: {
            type: Array,
            required: true,
        },
        active: {
            type: [Number, String, Object],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            startX: null,
            startY: null,
            move: null,
            baseOffset: 0,
            delta: 0,
            currentIndex: 0,
            dragging: false,
            endX: 0,
        }
    },
    computed: {
        style() {
            return {
                transform: `translateX(${this.offset}px)`,
            }
        },
        offset() {
            const diff = (this.move || 0) - (this.startX || 0)
            if (this.baseOffset + diff > 0) {
                // Bouncy at left edge
                return this.baseOffset + diff / 2
            } else if (this.endX + this.baseOffset + diff < 0) {
                // Bouncy at right edge
                return this.baseOffset + diff - (this.endX + this.baseOffset + diff) / 2
            }
            return this.baseOffset + diff
        },
        offsetPercent() {
            if (!this.endX) {
                return 0
            }
            const diff = (this.move || 0) - (this.startX || 0)
            return (-1 * (this.baseOffset + diff)) / this.endX
        },
    },
    watch: {
        active(newVal) {
            this.setIndexFromItem(newVal)
        },
        currentIndex(newVal) {
            this.$emit('change', newVal === null ? null : this.items[newVal])
        },
    },
    mounted() {
        if (this.active) {
            // Can't be an immediate watcher because the DOM is not ready
            this.setIndexFromItem(this.active)
        }
        viewport.on('widthchange', this.viewportWidthChange)
    },
    unmounted() {
        viewport.off('widthchange', this.viewportWidthChange)
    },
    methods: {
        viewportWidthChange() {
            this.setCurrentIndex(this.currentIndex)
        },

        setIndexFromItem(active) {
            if (active === null) {
                return
            }
            let index

            if (Number.isInteger(active)) {
                index = active
            } else {
                index = this.items.findIndex((item) => {
                    return item === active
                })
            }

            if (index !== -1) {
                this.setCurrentIndex(index)
            }
        },

        setCurrentIndex(index) {
            this.currentIndex = Math.max(0, Math.min(this.items.length - 1, index))
            this.updateEndX()
            const newOffset = this.findOffsetForIndex(this.currentIndex)
            if (newOffset !== null) {
                this.baseOffset = Math.max(-1 * this.endX, newOffset)
            }
            this.$emit('update:active', this.currentIndex)
        },

        updateEndX() {
            this.endX =
                Math.max(this.$refs.list.offsetWidth, this.$refs.list.scrollWidth) -
                this.$refs.list.offsetWidth
        },

        touchstart(e) {
            if (e.touches.length !== 1) return

            this.$emit('start', e)
            this.updateEndX()
            this.startX = e.touches[0].pageX
            this.startY = e.touches[0].pageY
            this.move = this.startX

            window.addEventListener('touchmove', this.touchmove, { passive: false })
            window.addEventListener('touchend', this.touchend, { passive: false })
        },

        touchmove(e) {
            if (this.startX === null || e.touches.length !== 1) return

            const previous = this.move
            const x = e.touches[0].pageX
            if (!this.dragging) {
                const y = e.touches[0].pageY
                if (
                    Math.abs(x - previous) <= 50 &&
                    Math.abs(y - this.startY) >= Math.abs(x - previous)
                ) {
                    this.touchend(e)
                    return
                }
                this.dragging = true
            }

            this.$emit('move', e)
            e.preventDefault()
            this.move = x
            this.delta = Math.sign(this.move - previous)
        },

        touchend(e) {
            const firstVisibleIndex = this.firstVisibleIndex()
            if (firstVisibleIndex === null) {
                // All items are off the screen
                this.setCurrentIndex(this.items.length - 1)
            } else if (this.delta === 1) {
                this.setCurrentIndex(firstVisibleIndex - 1)
            } else if (this.delta === -1) {
                this.setCurrentIndex(firstVisibleIndex)
            }
            this.startX = null
            this.move = null
            this.delta = 0
            this.dragging = false

            window.removeEventListener('touchmove', this.touchmove)
            window.removeEventListener('touchend', this.touchend)
        },

        firstVisibleIndex() {
            const list = this.$refs.list
            const baseListOffsetX = Math.round(list.getBoundingClientRect().x + -1 * this.offset)
            const firstVisibleChild = [].findIndex.call(list.children, (node) => {
                return node.getBoundingClientRect().x >= baseListOffsetX
            })
            return firstVisibleChild === -1 ? null : firstVisibleChild
        },

        findOffsetForIndex(index) {
            const list = this.$refs.list
            if (!list.children[index]) return null
            const childOffsetX = list.children[index].offsetLeft
            return -1 * childOffsetX
        },
    },
}
</script>
