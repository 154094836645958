<template>
    <ul :class="$style.wrapper">
        <li
            v-for="(item, i) in currentItems"
            :key="item.key"
            :class="[$style.item]"
            :style="{
                transform:
                    parseInt(i) === 0
                        ? transformBefore
                        : parseInt(i) === 1
                          ? 'translate(0, 0)'
                          : transformAfter,
            }"
        >
            <slot
                :item="item.value.value"
                :index="item.key"
                :was-active="i == 0"
                :is-active="i == 1"
                :will-be-active="i == 2"
            >
                {{ item.value }} {{ i }}.
            </slot>
        </li>
    </ul>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
const props = defineProps({
    items: {
        type: Array,
        required: true,
    },
    index: {
        type: Number,
        required: false,
        default: 0,
    },
    direction: {
        type: String,
        required: false,
        default: 'y',
    },
    reverse: Boolean,
})

const FlipValue = function (value) {
    this.value = value
}
FlipValue.prototype.toString = function () {
    return this.value
}

const allItems = computed(() => {
    const items = [...props.items]
    while (items.length < 3) {
        items.push([...items].shift())
    }
    // We make them unique by creating an object
    return items.map((v) => new FlipValue(v))
})

const currentItems = computed(() => {
    const items = [...allItems.value]

    if (items.length === 0) return {}

    const reOrdered = [...items.splice(activeIndex.value - 1, 3), ...items]
    return allItems.value.reduce((obj, item, index) => {
        const newIndex = reOrdered.indexOf(item)
        obj[newIndex] = { key: index, value: item }
        return obj
    }, {})
})

const transformBefore = computed(() => {
    return `translate(${[
        ['x', 'xy'].includes(props.direction) ? (props.reverse ? '-100%' : '100%') : 0,
        ['y', 'xy'].includes(props.direction) ? (props.reverse ? '-100%' : '100%') : 0,
    ].join(',')})`
})

const transformAfter = computed(() => {
    return `translate(${[
        ['x', 'xy'].includes(props.direction) ? (props.reverse ? '100%' : '-100%') : 0,
        ['y', 'xy'].includes(props.direction) ? (props.reverse ? '100%' : '-100%') : 0,
    ].join(',')})`
})

const activeIndex = ref(0)

watch(
    () => props.index,
    (newValue) => {
        const numberOfItems = Object.values(currentItems.value).length
        if (newValue < 0) {
            activeIndex.value = numberOfItems - ((newValue * -1) % numberOfItems)
        } else {
            activeIndex.value = newValue % numberOfItems
        }
    },
    {
        immediate: true,
    },
)
</script>

<style module>
.wrapper {
    position: relative;
    overflow: hidden;
}
.wrapper::after {
    content: ' ';
    display: block;
}
.item {
    position: absolute;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    opacity: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* transform: v-bind(transformBefore); */
    overflow: hidden;
}
@media (prefers-reduced-motion) {
    .item {
        transition: none;
    }
}
.item:first-child {
    /* transform: v-bind(transformAfter); */
    opacity: 1;
}
.item:nth-child(2) {
    /* transform: translate(0, 0); */
    opacity: 1;
    overflow: visible;
}
</style>
