<template>
    <li>
        <div class="place-explorer__map__list__place tw-flex tw-min-w-0 tw-gap-2">
            <div class="tw-h-20 tw-w-20 tw-flex-shrink-0 tw-overflow-hidden tw-rounded-md">
                <img
                    v-if="place.thumbnail"
                    width="80"
                    height="80"
                    :src="place.thumbnail"
                    :alt="place.name"
                    loading="lazy"
                    class="!tw-h-full !tw-w-full"
                />
                <span v-else class="!tw-h-full !tw-w-full" :class="place.icon" />
            </div>
            <div class="tw-flex-grow tw-overflow-hidden">
                <a
                    :href="place.permalink"
                    :title="place.name"
                    class="place-explorer__map__list__place__head"
                >
                    <div class="place-explorer__map__list__place__rating">
                        <i class="icon--review-star review-star--small"></i>
                        <span>{{ ratingText }}</span>
                    </div>
                    <h3 v-if="index">{{ index }}. {{ place.name }}</h3>
                    <h3 v-else>{{ place.name }}</h3>
                    <h4
                        v-if="place.title && place.title !== place.name"
                        class="tw-mb-0 tw-text-sm tw-font-semibold"
                    >
                        {{ place.title }}
                    </h4>
                    <address>{{ place.address }}</address>
                </a>
                <div class="place-explorer__map__list__place__links overflow-auto tw-flex tw-gap-1">
                    <template
                        v-if="
                            place.contact?.website ||
                            place.contact?.instagram ||
                            (place.contact?.buttons && place.contact?.buttons.length)
                        "
                    >
                        <a
                            v-if="place.contact?.website"
                            class="referral-page tctrack article-list__element__button !tw-px-4 tw-text-sm"
                            target="_blank"
                            rel="noopener"
                            :href="place.contact.website.value"
                            :data-track="getTrackingValue('Website referrals')"
                        >
                            <i class="sli sli-x18 sli-network-refresh mr-1"></i>
                            <span>{{ $t('explore.place.website_cta') }}</span>
                        </a>
                        <a
                            v-if="place.contact?.instagram"
                            rel="noopener"
                            class="website-link tctrack article-list__element__button !tw-px-2.5 tw-text-sm"
                            :title="place.contact.instagram.value"
                            :href="place.contact.instagram.value"
                            target="_blank"
                            :data-track="getTrackingValue('Instagram referrals')"
                        >
                            <i class="sli sli-x18 sli-instagram"></i>
                        </a>
                        <a
                            v-if="place.contact?.buttons"
                            v-for="button in place.contact?.buttons"
                            class="referral-page tctrack article-list__element__button !tw-px-4 tw-text-sm"
                            target="_blank"
                            rel="noopener"
                            :href="button.url"
                            :data-track="getTrackingValue('custom_referral')"
                        >
                            <i class="sli sli-x18 sli-network-refresh mr-1"></i>
                            {{ button.title }}
                        </a>
                    </template>
                    <a
                        v-else-if="place.permalink"
                        class="article-list__element__button !tw-px-4 tw-text-sm"
                        :title="place.name"
                        target="_blank"
                        :href="place.permalink"
                    >
                        <i class="sli sli-x18 sli-info mr-1"></i>
                        <span>{{ $t('explore.place.read_more_cta') }}</span>
                    </a>
                </div>
            </div>
        </div>
    </li>
</template>
<script>
import { numberFormat } from '../../../es6/src/modules/locale/locale.js'
import { $t } from '../../helpers.js'

export default {
    props: {
        place: Object,
        index: Number,
        isActive: Boolean,
    },
    computed: {
        ratingText() {
            return numberFormat(this.place.rating || 0, 1, true)
        },
    },
    methods: {
        $t,
        getTrackingValue(event, properties = {}) {
            return JSON.stringify(this.getTrackingProperties(event, properties))
        },
        getTrackingProperties(event, properties = {}) {
            return [
                event,
                {
                    category: 'Place',
                    label: this.place.name,
                    place: this.place.id,
                    ...properties,
                },
            ]
        },
    },
}
</script>
