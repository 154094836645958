<template>
    <div
        ref="rootEl"
        id="mobile-notifications"
        class="user-menu-notifications mobile-user-menu user-menu-nav tw-overflow-auto"
        @scroll="handleScroll"
        style="max-height: inherit"
    >
        <div v-html="userStore.notificationHTML"></div>

        <div v-if="userStore.notificationsAreLoading" class="tw-flex tw-justify-center tw-p-4">
            <div class="is-loading"></div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoutesStore } from '@store/routes.js'
import { useUserStore } from '@store/user.js'
import { useCityStore } from '@store/city.js'

const routesStore = useRoutesStore()
const userStore = useUserStore()
const cityStore = useCityStore()

const routes = routesStore.routes
const rootEl = ref(null)
const currentPage = ref(1)

watch(
    () => userStore.notificationPage,
    () => {
        function handleUserActionClick(e) {
            if (
                e.originalEvent &&
                e.originalEvent.target &&
                e.originalEvent.target.tagName === 'A'
            ) {
                return
            }

            const userActionWrapper = e.target.closest('.user-action-wrapper')
            if (userActionWrapper) {
                const mainLink = userActionWrapper.querySelector('a.permalink')
                if (mainLink) {
                    window.location.href = mainLink.href
                }
            }
        }

        rootEl.value?.addEventListener('click', handleUserActionClick)
    },
)

function handleScroll() {
    if (rootEl.value) {
        const { scrollTop, clientHeight, scrollHeight } = rootEl.value

        if (scrollTop + clientHeight >= scrollHeight - 50) {
            userStore.loadMobileNotifications()
        }
    }
}
</script>
