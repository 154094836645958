const rarityRibbonColor = (rarityLevel) => {
    const ribbonColors = {
        1: 'blue',
        2: 'yellow',
        3: 'orange',
        4: 'red',
        5: 'black',
    }

    return ribbonColors[rarityLevel] || ''
}
export const getRibbon = (trophy) => {
    if (!trophy.rarityLevel) {
        return null
    }

    return {
        label: trophy.rarityName,
        color: rarityRibbonColor(trophy.rarityLevel),
    }
}
