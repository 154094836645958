<template>
    <fieldset class="form-group">
        <input
            name="email"
            type="email"
            autocomplete="username"
            tabindex="-1"
            aria-hidden="true"
            :value="modelValue.email"
            :class="autocompleteHiddenFieldClasses"
            @input="change"
        />
        <input
            v-autofocus
            name="firstName"
            type="text"
            required="required"
            class="mb-1"
            minlength="2"
            :placeholder="$t('authForm.first_name')"
            :value="modelValue.firstName"
            :class="{ invalid: isInvalid(invalidFields, 'firstName') }"
            @input="change"
        />
        <input
            name="lastName"
            type="text"
            required="required"
            class="mb-1"
            minlength="2"
            :placeholder="$t('authForm.last_name')"
            :value="modelValue.lastName"
            :class="{ invalid: isInvalid(invalidFields, 'lastName') }"
            @input="change"
        />
        <input
            name="password"
            type="password"
            required="required"
            minlength="6"
            autocomplete="new-password"
            :placeholder="$t('authForm.password')"
            :value="modelValue.password"
            :class="{ invalid: isInvalid(invalidFields, 'password') }"
            @input="change"
        />
    </fieldset>
</template>
<script setup>
import { computed, ref } from 'vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
    modelValue: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    invalidFields: {
        type: Array,
        required: false,
        default: () => [],
    },
})

const autocompleteFieldsAreHidden = ref(false)

const autocompleteHiddenFieldClasses = computed(() => {
    return {
        'autocomplete-hidden-field': true,
        'autocomplete-hidden-field--hidden': autocompleteFieldsAreHidden.value,
    }
})

const isInvalid = (fields, field) => {
    return fields.indexOf(field) !== -1
}

const change = (event) => {
    emit('update:modelValue', {
        ...props.modelValue,
        [event.target.name]: event.target.value,
    })
}

const hideAutocompleteHiddenFields = () => {
    window.setTimeout(() => {
        autocompleteFieldsAreHidden.value = true
    }, 100)
}

hideAutocompleteHiddenFields()
</script>
