<template>
    <component
        v-bind="$attrs"
        :is="as"
        class="place-explorer__control-button"
        :class="{
            'place-explorer__control-button--active': active,
            'place-explorer__control-button--loading': loading,
            'place-explorer__control-button--radar': radar,
            'place-explorer__control-button--small': small,
            'place-explorer__control-button--circular': circular,
            'place-explorer__control-button--square': square,
        }"
    >
        <slot />
    </component>
</template>
<script>
export default {
    props: {
        active: Boolean,
        loading: Boolean,
        radar: Boolean,
        small: Boolean,
        circular: Boolean,
        square: Boolean,
        as: {
            type: [String, Object],
            required: false,
            default: 'button',
        },
    },
}
</script>
