<template>
    <aside class="place-list__map">
        <ThatsupMap
            v-bind="$attrs"
            :center="center"
            :markers="markers"
            :auto-zoom="!userPosition"
            :zoom="userPosition ? 14 : null"
            :user-position="userPosition"
            :active-marker="highlightedPlace"
            ref="map"
        />
    </aside>
</template>

<script>
import ThatsupMap from '../../../Shared/components/ThatsupMap.vue'
export default {
    components: { ThatsupMap },
    emits: ['click'],

    props: {
        placeList: {
            type: Object,
            required: true,
        },
        placePage: {
            type: Object,
            required: false,
            default: null,
        },
        userPosition: {
            type: Object,
            required: false,
            default: null,
        },
        highlightedPlace: {
            type: String,
            required: false,
            default: null,
        },
    },

    data() {
        return {
            center: null,
        }
    },

    computed: {
        markers() {
            if (!this.placePage) {
                return []
            }
            const self = this
            return this.placePage.content
                .filter((p) => !p.isRemoved)
                .map((place, i) => {
                    if (!place.latitude || !place.longitude) {
                        return false
                    }
                    return {
                        lat: place.latitude,
                        lng: place.longitude,
                        id: place.id,
                        label: (this.placePage.paging.offset + i + 1).toString(),
                        data: {
                            placeId: place.id,
                        },
                        quicklook: {
                            type: 'place',
                            id: place.id,
                        },
                    }
                })
                .filter((p) => !!p)
        },
    },

    methods: {
        centerPlace(placeId) {
            if (this.$refs.map && this.placePage) {
                const place = this.placePage.content.find((p) => p.id === placeId)
                if (place) {
                    this.$refs.map.panTo(place.latitude, place.longitude, {
                        zoom: 14,
                    })
                }
            }
        },
        resize() {
            if (this.$refs.map) {
                this.$refs.map.resize()
            }
        },
    },

    created() {},

    destroyed() {},
}
</script>
