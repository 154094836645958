import { localeDate } from '../locale/locale.js'
import { useLocaleStore } from '../../../../store/locale.js'

const momentInterval = () => {
    if (typeof window.moment !== 'undefined') {
        const localeStore = useLocaleStore()
        window.moment.locale(localeStore.code)

        document
            .querySelectorAll('.moment-live[datetime],.moment-live[data-date]')
            .forEach((el) => {
                const options = el.dataset.options ? JSON.parse(el.dataset.options) : {}
                const timeString = el.getAttribute('datetime') || el.dataset.date

                if (!timeString) {
                    return
                }

                const time =
                    typeof timeString === 'string' && timeString.match(/^\d+$/) !== null
                        ? parseInt(timeString)
                        : timeString

                if (!time) {
                    return
                }

                const datetime = new Date(time)
                el.innerText = localeDate(datetime, options)
            })

        window.setTimeout(momentInterval, 60 * 1000)
    }
}

export default momentInterval
