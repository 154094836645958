import { showBlind, hideBlind, slideTo } from '../../utils/animation'
import { translate } from '../locale/locale.js'
import http from '../../utils/http.js'
import { useLocaleStore } from '../../../../store/locale.js'
import { authentication } from '../authentication/authentication.js'
import confirm from '@utils/confirm.js'
import { useDirtyStore } from '../../../../store/dirty.js'

export const commentList = async function (listSelector) {
    const list = document.querySelector(listSelector)
    const formContainer = list?.querySelector('.comment-form-container')

    if (!formContainer) return

    const form = formContainer.querySelector('form')
    const commentList = list.querySelector('.comment-list')
    const submitButton = form.querySelector('.submit-button')

    // await window.thatsup.authentication.captcha.init();
    const captchaDiv = document.createElement('div')
    formContainer.appendChild(captchaDiv)
    const captcha = authentication.captcha.create(captchaDiv)

    form.setAttribute('action', '/blog/comment/')

    const subRoutines = {}

    if (window.localStorage) {
        const commentData = window.localStorage.getItem('blog_comment_form')
        const parsedCommentData = commentData ? JSON.parse(commentData) : {}

        for (const key in parsedCommentData) {
            const input = form.querySelector(`input[name=${key}]`)
            if (input) {
                input.value = parsedCommentData[key]
            }
        }
    }

    form.addEventListener('submit', function (event) {
        submitButton.classList.add('disabled')
        event.preventDefault()

        const name = this.querySelector('input[name=name]')?.value
        const email = this.querySelector('input[name=email]')?.value
        const url = this.querySelector('input[name=url]')?.value

        const commentData = {
            name: name,
            email: email,
            url: url,
        }
        if (localStorage) {
            localStorage.setItem('blog_comment_form', JSON.stringify(commentData))
        }
        captcha
            .execute()
            .then((response) => {
                const captchaInput = document.createElement('input')
                captchaInput.type = 'hidden'
                captchaInput.name = 'captcha'
                captchaInput.value = response
                form.appendChild(captchaInput)
                const postData = new FormData(this)

                http.post('/blog/comment/', postData)
                    .then(({ data }) => {
                        captchaInput.remove()
                        captcha.reset()
                        submitButton.classList.remove('disabled')

                        const replyToContainer = form.querySelector('.reply-to-container')
                        replyToContainer.style.display = 'none'
                        replyToContainer.querySelectorAll('input').forEach((input) => {
                            input.disabled = true
                            input.value = ''
                        })
                        const messageContainer = form.querySelector('.message-container')
                        messageContainer.innerHTML = ''
                        if (data == null) {
                            return
                        }
                        if (data.subroutine != null && subRoutines[data.subroutine] != null) {
                            subRoutines[data.subroutine](data)
                        }
                        if (data.success === false) {
                            // Handle failure case
                        } else if (data.success === true && data.id != null) {
                            messageContainer.innerHTML = ''
                            form.querySelector('textarea').value = ''
                            let appendTo = commentList
                            if (data.replyTo) {
                                const replyToComment = commentList.querySelector(
                                    `.comment-${data.replyTo} .children`,
                                )
                                appendTo = replyToComment
                                appendTo.style.display = 'block'
                            }

                            fetch(`/blog/comment/${data.id}/`)
                                .then((response) => response.text())
                                .then((html) => {
                                    const container = document.createElement('div')
                                    container.innerHTML = html
                                    container.style.display = 'none'
                                    appendTo.appendChild(container)
                                    showBlind(container, 500)
                                    slideTo(container)
                                })
                        }
                        if (data.message != null || (data.error && data.error.message)) {
                            messageContainer.style.display = 'block'
                            messageContainer.innerHTML = data.message || data.error.message
                            messageContainer.classList.toggle('error', !data.success)
                        } else {
                            messageContainer.style.display = 'none'
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error)
                        captcha.reset()
                        submitButton.classList.remove('disabled')
                        const messageContainer = form.querySelector('.message-container')
                        if (error.error && error.error.message != null) {
                            messageContainer.style.display = 'block'
                            messageContainer.innerHTML = error.error.message
                            messageContainer.classList.add('error')
                        } else {
                            messageContainer.style.display = 'none'
                        }
                    })
            })
            .catch((response) => {
                submitButton.classList.remove('disabled')
                const messageContainer = form.querySelector('.message-container')
                messageContainer.style.display = 'block'
                messageContainer.innerHTML = response
                messageContainer.classList.add('error')
                captcha.reset()
                return response
            })
    })
    submitButton.addEventListener('click', function (e) {
        if (this.classList.contains('disabled')) {
            e.preventDefault()
        }
    })

    commentList.addEventListener('click', function (e) {
        if (e.target.classList.contains('reply-button')) {
            e.preventDefault()
            const container = form.querySelector('.reply-to-container')
            container.style.display = 'block'
            container.querySelectorAll('input').forEach((input) => {
                input.disabled = false
                input.value = e.target.dataset.id
            })
            if (e.target.dataset.name) {
                container.querySelector('.name').textContent = e.target.dataset.name
            }
            form.querySelector('textarea').focus()
        }
    })

    form.querySelector('.remove-reply').addEventListener('click', function () {
        const container = form.querySelector('.reply-to-container')
        container.style.display = 'none'
        container.querySelectorAll('input').forEach((input) => {
            input.disabled = true
            input.value = ''
        })
    })
}

export const removeComment = async function (id) {
    if (!(await confirm(translate('confirm_title'), translate('comment_delete_confirm')))) {
        return
    }

    const reviewContainer = document.getElementById(`blog-comment-${id}`)
    reviewContainer.querySelector('.delete-button').remove()

    return http
        .post('/blog/comment/delete/', { comment: id })
        .then(({ data }) => {
            if (data.success === false || !reviewContainer) {
                return
            }
            hideBlind(reviewContainer, 500).then((element) => {
                element.remove()
            })
        })
        .catch((error) => {
            console.error('Error:', error)
        })
}

export const approveComment = function (id) {
    const reviewContainer = document.getElementById(`blog-comment-${id}`)
    reviewContainer.querySelector('.approve-button').classList.add('disabled')

    return http
        .post('/blog/comment/publish/', { comment: id, publish: true })
        .then(({ data }) => {
            if (data.success === false || !reviewContainer) {
                return
            }
            reviewContainer.classList.remove('unpublished')
        })
        .catch((error) => {
            console.error('Error:', error)
        })
}

export const htmlEditor = function (selector, options) {
    const elements = document.querySelectorAll(selector)
    const localeStore = useLocaleStore()
    const dirtyStore = useDirtyStore()

    if (elements.length === 0) {
        return
    }

    if (elements.length > 1) {
        elements.forEach((element) => {
            htmlEditor(element, options)
        })
        return
    }
    if (!options) {
        options = {}
    }

    if (options.setup && typeof options.setup === 'function') {
        const defaultSetup = defaults.setup
        const optionsSetup = options.setup
        options.setup = function (ed) {
            defaultSetup.call(this, ed)
            optionsSetup.call(this, ed)
        }
    }

    const defaults = {
        menubar: true,

        menu: {
            edit: {
                title: 'Edit',
                items: 'undo redo | cut copy paste pastetext | selectall',
            },
            insert: {
                title: 'Insert',
                items: 'imageuploader image media | link unlink',
            },
            thatsup: {
                title: 'Thatsup',
                items: 'thatsup_place thatsup_article',
            },
            format: {
                title: 'Format',
                items: 'blockformats | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | code',
            },
            table: {
                title: 'Table',
                items: 'inserttable tableprops deletetable | cell row column',
            },
            tools: { title: 'Tools', items: 'pastetext removeformat spellchecker code' },
        },

        statusbar: true,
        toolbar_items_size: window.innerWidth > 900 ? 'large' : 'small',
        theme: 'modern',
        plugins: [
            'link image wordcount anchor thatsup_place thatsup_article code lists media paste imageuploader table',
        ],
        toolbar:
            'bold italic underline | indentbutton | link unlink | imageuploader image | alignleft aligncenter alignright alignjustify | bullist numlist',

        table_responsive_width: true,

        reisize: true,
        relative_urls: false,
        schema: 'html5',
        block_formats: 'Paragraph=p;Header 2=h2;Blockquote=blockquote',

        force_br_newlines: true,
        force_p_newlines: false,

        extended_valid_elements: 'a[*],img[*],figure[class]',
        height: Math.min(window.innerHeight * 0.8, 400),
        content_css: '/media/external/blogeditor.css?v2',

        paste_as_text: true,

        branding: false,

        language: localeStore.code !== 'en' ? localeStore.fullCode : null,

        setup: function (ed) {
            ed.on('change', function () {
                dirtyStore.setIsDirty(true)
            })
            ed.on('dirty', function () {
                dirtyStore.setIsDirty(true)
            })
            ed.addButton('indentbutton', {
                icon: 'outdent',
                tooltip: 'Decrease indent',
                onclick: function () {
                    const node = window.tinymce.activeEditor.selection.getNode()
                    let targetNode = node
                    if (
                        node.nodeName === 'IMG' ||
                        node.nodeName === 'IFRAME' ||
                        node.classList.contains('mce-preview-object')
                    ) {
                        targetNode = node.parentNode
                    }
                    if (targetNode.nodeName === 'P') {
                        targetNode.classList.toggle('full-width')
                        this.active(targetNode.classList.contains('full-width'))
                        this.icon(this.active() ? 'indent' : 'outdent')

                        if (!targetNode.className) {
                            targetNode.removeAttribute('class')
                        }
                    }
                },
                onPostRender: function () {
                    // eslint-disable-next-line @typescript-eslint/no-this-alias
                    const _this = this
                    ed.on('NodeChange', function () {
                        const node = window.tinymce.activeEditor.selection.getNode()
                        let targetNode = node
                        if (
                            node.nodeName === 'IMG' ||
                            node.nodeName === 'IFRAME' ||
                            node.classList.contains('mce-preview-object')
                        ) {
                            targetNode = node.parentNode
                        }
                        if (targetNode.nodeName === 'P') {
                            _this.disabled(false)
                            _this.active(targetNode.classList.contains('full-width'))
                            _this.icon(_this.active() ? 'indent' : 'outdent')
                        } else {
                            _this.disabled(true)
                        }
                    })
                },
            })
        },

        images_upload_url: null,
        images_upload_credentials: true,
        images_reuse_filename: true,

        automatic_uploads: false,
        paste_data_images: false,

        file_picker_types: 'image media',
    }

    options = Object.assign(defaults, options)

    if (typeof selector === 'string') {
        options.selector = selector
    } else {
        options.target = selector
    }

    return window.tinymce.init(options)
}

export const blogSettings = () => {
    const form = document.getElementById('blog-form')

    if (!form) {
        return
    }

    const submitButton = form?.querySelector('.submit-button')
    const publishedCheckbox = document.getElementById('published')
    const scheduledCheckbox = document.getElementById('scheduled')
    const dirtyStore = useDirtyStore()

    form.addEventListener('submit', (e) => {
        dirtyStore.setIsDirty(false)
        if (submitButton?.classList.contains('disabled')) {
            e.preventDefault()
            return false
        }
    })

    form.querySelectorAll('input, select, textarea').forEach((input) => {
        input.addEventListener('change', () => {
            dirtyStore.setIsDirty(true)
        })

        input.addEventListener('input', () => {
            dirtyStore.setIsDirty(true)
        })
    })

    publishedCheckbox?.addEventListener('change', function () {
        document.getElementById('publishing-options').classList.toggle('hidden', !this.checked)
    })

    scheduledCheckbox?.addEventListener('change', function () {
        document.getElementById('scheduling-options').classList.toggle('hidden', !this.checked)
    })

    htmlEditor('#about', {
        images_upload_url: form.dataset.uploadUrl,
    })
}
