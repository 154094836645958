import { languageContent } from '../es6/src/modules/locale/locale.js'

import sv from './lang/sv.js'
import en from './lang/en.js'

import { get, forEach } from 'lodash-es'

function _trans(key) {
    const lang = globalVars.locale.code

    let string

    if (lang === 'sv') {
        string = get(sv, key)
    }

    if (lang === 'en') {
        string = get(en, key)
    }

    if (string === undefined) {
        string = get(languageContent[lang], key)
    }

    return string
}

export const $t = (key, replace = null) => {
    let string = _trans(key)

    // Replace the strings keys with the replace objects corresponding values
    // Example: "I have {n} apples."
    // $t('my_apples', {n: 5});
    if (string !== undefined && replace && typeof replace === 'object') {
        forEach(replace, function (value, key) {
            string = string.replace(`{${key}}`, value)
        })
    }

    if (string === undefined) {
        string = key
    }
    return string
}
export function $te(key) {
    let string = _trans(key)
    return !!string
}

export default {
    computed: {
        $thatsup() {
            return window.thatsup
        },
    },
    methods: {
        $t,
        $te,
    },
}
