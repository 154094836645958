import http from '../../utils/http.js'
import { trackEvent } from './tracking.js'

interface SponsorData {
    id: string
    name: string
    index?: string
}

export class SponsoredPlaceTracker {
    public static initSponsoredContentClick(elements?: NodeList<Element>): void {
        elements.forEach((element) => {
            if (element.tagName === 'A') {
                element.addEventListener('click', this.linkClickHandler(element, element))
            }

            element.querySelectorAll('a').forEach((link: HTMLAnchorElement) => {
                link.addEventListener('click', this.linkClickHandler(element, link))
            })
        })
    }

    public static observeSponsoredContent(targets?: NodeList<Element>): void {
        const handleIntersect = (
            entries: IntersectionObserverEntry[],
            observer: IntersectionObserver,
        ) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    return
                }

                const element = entry.target
                const sponsorData = this.getSponsorData(element)

                this.sendHttpPost(`/api/explore/tr/${sponsorData.id}/`, {
                    action: 'i', // Send impression
                    url: window.location.href,
                    position: sponsorData.index || '',
                })

                this.trackEvent('sponsored_content_impression', {
                    sponsor_id: sponsorData.id,
                    sponsor_name: sponsorData.name,
                    position: sponsorData.index || '',
                })

                observer.unobserve(element)
            })
        }

        const options: IntersectionObserverInit = {
            root: null,
            rootMargin: '0px',
            threshold: 0.9,
        }

        const observer = new IntersectionObserver(handleIntersect, options)

        setTimeout(() => {
            targets.forEach((target) => observer.observe(target))
        }, 1000)
    }

    private static addUtmIfNeeded(link: HTMLAnchorElement, params: Record<string, string>): void {
        if (!link) return
        const url = new URL(link.href)
        const currentParams = url.searchParams
        for (const [key, value] of Object.entries(params)) {
            if (value && !currentParams.has(key)) {
                currentParams.set(key, value)
            }
        }
        link.href = url.toString()
    }

    private static getSponsorData(element: Element): SponsorData {
        return {
            id: element.getAttribute('data-sponsor-id') || '',
            name: element.getAttribute('data-sponsor-name') || '',
            index: element.getAttribute('data-sponsor-index'),
        }
    }

    private static trackEvent(eventName: string, data: Record<string, string>): void {
        trackEvent(eventName, data)
    }

    private static async sendHttpPost(
        endpoint: string,
        data: Record<string, string>,
    ): Promise<void> {
        return http.post(endpoint, data)
    }

    private static linkClickHandler(element: Element, link: HTMLAnchorElement) {
        return () => {
            const sponsorData = this.getSponsorData(element)

            this.addUtmIfNeeded(link, {
                utm_campaign: sponsorData.name,
                utm_medium: 'sponsored_content',
                utm_term: document.title,
                utm_id: sponsorData.id,
            })

            this.sendHttpPost(`/api/explore/tr/${sponsorData.id}/`, {
                action: 'c', // Send click
                url: window.location.href,
                position: sponsorData.index || '',
            })

            this.trackEvent('sponsored_content_click', {
                sponsor_id: sponsorData.id,
                sponsor_name: sponsorData.name,
                position: sponsorData.index || '',
            })
        }
    }
}
