import { defineStore } from 'pinia'
import { ref } from 'vue'
import { translate } from '../es6/src/modules/locale/locale.js'

export const useDirtyStore = defineStore('dirty', () => {
    const isDirty = ref([])
    const closeEditorWarning = ref(null)

    function setIsDirty(dirty, key = 'root') {
        if (isDirty.value.includes(key) && !dirty) {
            isDirty.value = isDirty.value.filter((k) => k !== key)
        } else if (!isDirty.value.includes(key) && dirty) {
            isDirty.value.push(key)
        }
        if (isDirty.value.length && !closeEditorWarning.value) {
            closeEditorWarning.value = function (e) {
                const warning =
                    isDirty.value.length > 0
                        ? translate('dirty_form_warning') ||
                          'It looks like you have been editing something -- if you leave before submitting your changes will be lost.'
                        : null
                if (e) {
                    e.returnText = warning
                }
                return warning
            }
            window.onbeforeunload = closeEditorWarning.value
        }
    }

    function checkDirty() {
        if (!isDirty.value.length) {
            return true
        }

        if (confirm(translate('dirty_form_warning'))) {
            isDirty.value = []
            return true
        }

        return false
    }

    return {
        isDirty,
        closeEditorWarning,
        setIsDirty,
        checkDirty,
    }
})
