import { staticMapUrl } from './map.js'

export default class StaticMap {
    constructor(el) {
        this.el = el

        if (!this.el) {
            return
        }

        const longitude = this.el.dataset.longitude
        const latitude = this.el.dataset.latitude
        let locations = this.el.dataset.locations

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        return
                    }

                    if (!this.el || !this.el.offsetParent) {
                        return
                    }

                    if (!locations) {
                        locations = [
                            {
                                lat: latitude,
                                lng: longitude,
                            },
                        ]
                    } else {
                        locations = JSON.parse(locations)
                    }

                    const url = staticMapUrl({
                        width: this.el.clientWidth,
                        height: this.el.clientHeight,
                        locations,
                    })

                    let img = this.el.querySelector('img')

                    if (!img) {
                        this.el.innerHTML = ''
                        img = document.createElement('img')
                        this.el.appendChild(img)
                    }

                    img.src = url
                    img.onload = () => {
                        this.el.classList.remove('is-loading')
                        img.width = this.el.clientWidth
                        img.height = this.el.clientHeight
                    }

                    observer.unobserve(entry.target)
                })
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            },
        )
        observer.observe(this.el)
    }
}
