import eventBus from '../Shared/EventBus'
import { useListsStore } from '../../store/lists.js'
import { watch } from 'vue'
import { useUserStore } from '../../store/user.js'

const INDICATOR_SELECTOR =
    '*[data-list-indicator],*[data-action*="interact-place"],*[data-action*="save-place"]'

/**
 * TODO: This is a mixin, which is not recommended with Vue3. It should be a composition function.
 */
export default {
    created() {
        const listsStore = useListsStore()
        const userStore = useUserStore()

        watch(
            () => listsStore.containsPlace,
            () => {
                this.updateIndicators()
            },
            { deep: true },
        )

        return { listsStore, userStore }
    },

    mounted() {
        eventBus.$on('thatsup:content', this.handleContent)
        eventBus.$on('thatsup:place-list:updated', this.onPlaceListUpdated)
        eventBus.$on('thatsup:place-list:created', this.onPlaceListCreated)
    },

    unmounted() {
        eventBus.$off('thatsup:content', this.handleContent)
        eventBus.$off('thatsup:place-list:updated', this.onPlaceListUpdated)
        eventBus.$off('thatsup:place-list:created', this.onPlaceListCreated)
    },

    methods: {
        async handleContent(element) {
            const userStore = useUserStore()

            if (!userStore.user) {
                return
            }
            const elements = element.querySelectorAll(INDICATOR_SELECTOR)
            this.updateIndicators(elements)
            const ids = [].map
                .call(elements, (elm) => this.getPlaceIdsFromIndicatorElement(elm))
                .flat()
            const listsStore = useListsStore()
            await listsStore.checkContainsPlaces(ids)
        },
        updateIndicators(elements = null) {
            elements = elements || document.querySelectorAll(INDICATOR_SELECTOR)
            elements.forEach((elm) => {
                const places = this.getPlaceIdsFromIndicatorElement(elm)
                if (!places.length) {
                    return
                }
                const type = elm.dataset.listIndicator || elm.dataset.interaction || null
                let isSaved = false
                const listsStore = useListsStore()
                for (let i = 0; i < places.length; i++) {
                    if (listsStore.isSaved(places[i], type)) {
                        isSaved = true
                        break
                    }
                }
                elm.classList.toggle('active', isSaved)
            })
        },
        getPlaceIdsFromIndicatorElement(elm) {
            if (!elm.dataset.places && !elm.dataset.place) {
                return []
            }
            let places = elm.dataset.places ? JSON.parse(elm.dataset.places) : [elm.dataset.place]
            return places.map((place) => {
                return typeof place === 'object' ? place.id : place
            })
        },
        onPlaceListUpdated(data) {
            const place = data.place
            const type = this.getPlaceTypeFromData(data)
            const listsStore = useListsStore()

            if (data.action === 'add') {
                listsStore.addContainsPlaces({
                    places: [place],
                    type,
                })
            } else if (data.action === 'remove') {
                listsStore.removeContainsPlaces({
                    places: [place],
                    type,
                })
            }
        },
        onPlaceListCreated(data) {
            const type = this.getPlaceTypeFromData(data)
            const listsStore = useListsStore()
            if (data.places) {
                listsStore.addContainsPlaces({
                    places: data.places,
                    type,
                })
            }
        },
        getPlaceTypeFromData(data) {
            return !data.type || data.type === 'user-place-list' ? 'saved' : data.id.split(':')[1]
        },
        handleStateUpdate() {
            document.querySelectorAll('*[data-action="save-place"]')
        },
    },
}
