<template>
    <div ref="container"></div>
</template>
<script>
import { authentication } from '../../../es6/src/modules/authentication/authentication.js'

export default {
    async mounted() {
        if (!authentication.captcha.exists()) {
            throw new Error('No captcha support')
        }
        this.captcha = authentication.captcha.create(this.$refs.container)
    },
    methods: {
        execute() {
            return this.captcha
                .execute()
                .then((response) => {
                    this.$emit('success', response)
                    return response
                })
                .catch((response) => {
                    this.$emit('error', response)
                    return response
                })
        },
        reset() {
            return this.captcha.reset()
        },
    },
}
</script>
