import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday.js'
import localeData from 'dayjs/plugin/localeData.js'
dayjs.extend(weekday)
dayjs.extend(localeData)

const locales = {
    en: () => import('dayjs/locale/en.js'),
    sv: () => import('dayjs/locale/sv.js'),
}

export async function setLocale(locale) {
    if (!locales[locale]) {
        locale = 'en'
    }
    await locales[locale]()

    dayjs.locale(locale)
}

export default dayjs
