<template>
    <div class="tw-relative tw-flex tw-items-center tw-gap-2">
        <PlaceReviewReactionsButton
            v-if="canReact"
            :reactions="reactions"
            :user-has-reacted="userHasReacted"
            :actual-reaction="actualReaction || {}"
            @react="react"
        />
        <popup-trigger
            v-if="total"
            popup-component="ReviewReactionsPopup"
            :popup-props="{ reviewId: review.id }"
            v-slot="{ open }"
        >
            <button
                type="button"
                class="cursor-pointer tw-align-center tw-flex tw-content-center tw-rounded-full tw-bg-gray-200 tw-px-2 tw-py-1"
                @click="open"
            >
                <span v-for="emoji in totalEmojis" :key="emoji" class="tw-not-italic">
                    {{ emoji }}
                </span>
                <span class="tw-pl-1 tw-font-semibold">{{ total }}</span>
            </button>
        </popup-trigger>
    </div>
</template>
<script setup>
import { ref, watch, computed } from 'vue'

import PlaceReviewReactionsButton from './components/PlaceReviewReactionsButton.vue'
import PopupTrigger from '../popup/PopupTrigger.vue'
import { showAuth } from '../../Shared/util/auth'
import http from '@utils/http'
import { useReviewsStore } from '../../../store/reviews.js'
import { useUserStore } from '../../../store/user.js'

const props = defineProps({
    review: {
        type: Object,
        required: true,
    },
    reactions: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    userReaction: {
        type: Object,
        required: false,
        default: null,
    },
})

const userStore = useUserStore()
const reviewsStore = useReviewsStore()

const canReact = computed(() => {
    return (
        !props.review.archived &&
        !props.review.pending &&
        userStore.user?.username !== props.review?.user
    )
})

/* Total reactions */
const total = computed(() => {
    let total = Object.values(props.reactions).reduce((sum, current) => sum + current, 0)
    const hasAddedReaction = !props.userReaction && !!newReaction.value
    const hasRemovedReaction = props.userReaction !== null && newReaction.value === null

    if (hasAddedReaction) {
        total += 1
    }
    if (hasRemovedReaction) {
        total -= 1
    }

    return total
})

/* Reactions */
const userHasReacted = computed(() => {
    return actualReaction.value !== null
})
const storeReaction = computed(() => {
    return props.review.id in reviewsStore.reactions
        ? reviewsStore.reactions[props.review.id]
        : false
})
watch(
    () => storeReaction.value,
    (newValue) => {
        newReaction.value = newValue
    },
)
const newReaction = ref(false)
const actualReaction = computed(() => {
    return newReaction.value !== false ? newReaction.value : props.userReaction
})

/* Update Reaction */
let localReactions = computed(() => {
    const reactions = {}
    for (const key in props.reactions) {
        reactions[key] = props.reactions[key] - (props.userReaction?.emoji === key ? 1 : 0)
    }
    if (actualReaction.value) {
        reactions[actualReaction.value.emoji] = (reactions[actualReaction.value.emoji] || 0) + 1
    }
    return reactions
})
const totalEmojis = computed(() => {
    const emojis = []
    for (const key in localReactions.value) {
        if (localReactions.value[key] > 0) {
            emojis.push(key)
        }
    }
    return emojis
})

const react = (reaction) => {
    if (reaction === null) {
        return deleteReaction()
    }
    const review = props.review.id
    const oldReaction = actualReaction.value ? { ...actualReaction.value } : null

    // Set reaction for instant feedback
    setNewReaction(reaction)

    http.post(`/api/review/${review}/react/`, {
        emoji: reaction.emoji,
    })
        .then(() => {
            onReactionSaved(reaction)
        })
        .catch((error) => {
            // Reset reaction
            setNewReaction(oldReaction)
        })
}

const deleteReaction = () => {
    const review = props.review.id
    const oldReaction = actualReaction.value ? { ...actualReaction.value } : null
    setNewReaction(null)
    http.delete(`/api/review/${review}/react/`)
        .then(({ data }) => {})
        .catch((error) => {
            setNewReaction(oldReaction)
        })
}

const setNewReaction = (newValue) => {
    newReaction.value = newValue
    reviewsStore.addReaction({
        reviewId: props.review.id,
        reaction: newValue,
    })
}

const onReactionSaved = (newValue) => {
    if (userStore.isLoggedIn) {
        // Refresh user XP etc
        userStore.refreshUserProgress(true)
    } else {
        // Check if signup already shown
        const REACT_SESSION_KEY = 'reaction_auth_query'
        if (window.sessionStorage?.getItem(REACT_SESSION_KEY)) {
            return
        }
        window.sessionStorage?.setItem(REACT_SESSION_KEY, 'true')

        // Show signup
        showAuth({ variation: 'reactions' })
            .then(() => {
                // Add reaction to new user
                return http.post(`/api/review/${props.review.id}/update-reaction-user/`)
            })
            .catch(() => {})
            .then(() => {
                window.location.reload()
            })
    }
}
</script>

<style lang="scss" module></style>
