<template>
    <div
        class="search-autocomplete__result-list"
        :class="{ 'search-autocomplete__result-list--active': active }"
    >
        <div
            v-show="active"
            class="search-autocomplete__result-wrapper"
            :class="{
                'search-autocomplete__result-wrapper--placeholders': !term,
            }"
            ref="wrapper"
        >
            <ul>
                <li
                    v-for="(group, i) in groupedResults"
                    :key="group.key"
                    class="search-autocomplete__result-group"
                >
                    <span v-if="group.key">
                        {{ $t(`SearchAutocomplete.groups.${group.key}`) }}
                    </span>
                    <ul>
                        <Result
                            v-for="(result, i) in group.results"
                            :key="result.type + '_' + (result.id || result.code)"
                            :result="result"
                            :term="term"
                            :highlight="shouldHighlight(result)"
                            @click="$emit('select:result', result)"
                            ref="items"
                            :is-loading-user-location="isLoadingUserLocation"
                        />
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import Result from './Result.vue'
export default {
    components: { Result },
    props: {
        results: {
            type: Array,
            required: false,
            default: () => [],
        },
        isLoading: Boolean,
        isLoadingUserLocation: Boolean,
        term: {
            type: String,
            required: false,
            default: null,
        },
        highlight: {
            type: Object,
            required: false,
            default: null,
        },
        groupBy: {
            type: [String, Function],
            required: false,
            default: null,
        },
        active: Boolean,
    },
    watch: {
        highlight(newVal) {
            if (newVal === null) {
                this.$refs.wrapper.scrollTop = 0
            }
        },
    },
    computed: {
        groupedResults() {
            if (!this.groupBy) {
                return [
                    {
                        results: this.results,
                    },
                ]
            }
            let groups = []
            const groupFunction =
                typeof this.groupBy === 'function' ? this.groupBy : (result) => result[this.groupBy]
            let prevGroup = null
            let currentResults = []
            for (let i = 0; i < this.results.length; i++) {
                const group = groupFunction(this.results[i])
                if (prevGroup && group !== prevGroup) {
                    groups.push({
                        key: prevGroup,
                        results: currentResults,
                    })
                    currentResults = []
                }

                currentResults.push(this.results[i])
                prevGroup = group
            }
            groups.push({
                key: prevGroup,
                results: currentResults,
            })

            return groups
        },
        touchOffset() {
            if (!this.touchStart || !this.touchMove) {
                return null
            }
            return Math.max(0, this.touchMove.y - this.touchStart.y)
        },
    },
    data() {
        return {
            touchStart: null,
            touchMove: null,
            touchDelta: 0,
        }
    },
    methods: {
        shouldHighlight(result) {
            return this.highlight && this.highlight === result
        },
        onTouchStart(e) {
            this.touchStart = { x: e.touches[0].pageX, y: e.touches[0].pageY }
        },
        onTouchMove(e) {
            if (this.touchStart) {
                const prevOffset = this.touchOffset
                const prevDelta = this.touchDelta
                const newPos = { x: e.touches[0].pageX, y: e.touches[0].pageY }
                const newOffset = Math.max(0, newPos.y - this.touchStart.y)
                const delta = Math.sign(prevOffset - newOffset)

                if (delta === -1 && this.$refs.wrapper.scrollTop === 0) {
                    e.preventDefault()
                    this.touchMove = newPos
                    this.touchDelta = delta
                } else if (
                    (!this.isDragging && delta !== -1) ||
                    (this.isDragging && Math.abs(newOffset) <= 0)
                ) {
                    this.isDragging = false
                    this.touchStart = newPos
                    this.touchMove = newPos
                }
            }
        },
        onTouchEnd(e) {
            this.isDragging = false
            if (this.touchDelta === -1) {
                this.$nextTick(() => {
                    this.$emit('close')
                })
            }
            this.touchStart = null
            this.touchMove = null
        },
    },
}
</script>
