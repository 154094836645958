import { translate } from '../modules/locale/locale.js'
import { useUserStore } from '../../../store/user.js'
import { HAS_ACCEPTED_GEOLOCATION_KEY } from '../constants.js'
import mapMarkers from '../modules/map/markers.js'
import { useLocaleStore } from '../../../store/locale.js'
import { popup } from '../modules/popup/popup.js'
import { useCityStore } from '../../../store/city.js'
import Eventbus from '../modules/eventbus/Eventbus.js'
import { googleMaps } from '../modules/map/googleMaps.js'

export const requestUserPosition = () => {
    return new Promise((resolve, reject) => {
        getUserPosition(
            (pos) => {
                if (pos.lat && pos.lng) {
                    return resolve(pos)
                } else {
                    reject()
                }
            },
            (error) => {
                reject(error)
            },
        )
    })
}

export const getUserPosition = (success, error, timeout = 10000, requireAccuracy = 1500) => {
    const newSuccess = (data) => {
        if (data.lat && data.lng) {
            // this.findAndSetCity(data);
        }

        success(data)
    }
    const newError = (data) => {
        error(data)
    }

    if (navigator.geolocation) {
        getUserGeolocation(
            (data) => {
                newSuccess({
                    lat: data.coords.latitude,
                    lng: data.coords.longitude,
                    accuracy: data.coords.accuracy,
                })
            },
            (data) => {
                const result = {
                    message: translate('could_not_find_your_location'),
                }
                if (data && data.coords) {
                    result.lat = data.coords.latitude
                    result.lng = data.coords.longitude
                }
                userPositionDialog(
                    (data) => {
                        newSuccess(data)
                    },
                    (data) => {
                        newError(data)
                    },
                    result,
                )
            },
            timeout,
            requireAccuracy,
        )
    } else {
        userPositionDialog(
            (data) => {
                newSuccess(data)
            },
            (data) => {
                newError(data)
            },
        )
    }
}

export const getUserGeolocation = (success, error, timeout, requireAccuracy) => {
    const userStore = useUserStore()

    if (!navigator.geolocation) {
        error({ code: 0, message: 'No access' })

        return null
    }
    requireAccuracy = requireAccuracy || false
    return navigator.geolocation.getCurrentPosition(
        function (data) {
            if ('sessionStorage' in window) {
                window.sessionStorage.setItem(HAS_ACCEPTED_GEOLOCATION_KEY, '1')
            }
            userStore.hasAcceptedGeolocation = true
            // TODO: Remove this legacy event emitter?
            if (data.coords) {
                Eventbus.emit('user:location', {
                    lat: data.coords.latitude,
                    lng: data.coords.longitude,
                })
            }
            if (!requireAccuracy || data.coords.accuracy <= requireAccuracy) {
                success(data)
            } else {
                error(data)
            }
        },
        function (data) {
            console.log('error', data)
            if ('sessionStorage' in window) {
                window.sessionStorage.setItem(HAS_ACCEPTED_GEOLOCATION_KEY, '0')
            }
            userStore.hasAcceptedGeolocation = false
            error(data)
        },
        {
            timeout: timeout,
        },
    )
}

export const watchUserGeolocation = function (success, error, timeout) {
    if (!window.navigator.geolocation) {
        error({ code: 0, message: 'No access' })
        return null
    }
    const id = window.navigator.geolocation.watchPosition(
        function (data) {
            success(data)
            if (data.coords) {
                Eventbus.emit('user:location', {
                    lat: data.coords.latitude,
                    lng: data.coords.longitude,
                })
            }
        },
        function (data) {
            console.error('error', data)
            error(data)
        },
        {
            timeout: timeout,
        },
    )
    return {
        clear() {
            if (id) {
                window.navigator.geolocation.clearWatch(id)
            }
        },
        id: id,
    }
}

export const userPositionDialog = async function (success, error, dataChain) {
    const cityStore = useCityStore()
    let container = document.getElementById('user-position-dialog')

    if (!container) {
        container = document.createElement('div')
        container.setAttribute('id', 'user-position-dialog')
        container.classList.add('box', 'rounded')
    }

    container.classList.add('is-loading')
    container.style.display = 'none'
    container.innerHTML = ''

    const oldsuccess = success || function () {}
    const olderror = error || function () {}

    let wasDismissed = true
    success = function () {
        const data = {
            lat: latInput.value ? parseFloat(latInput.value) : false,
            lng: lngInput.value ? parseFloat(lngInput.value) : false,
        }
        wasDismissed = false
        popupInstance.close()
        if (data.lat && data.lng) {
            Eventbus.emit('user:location', data)
            oldsuccess(data)
        } else {
            olderror(data)
        }
    }
    error = function () {
        const data = {
            lat: latInput.value,
            lng: lngInput.value,
        }
        wasDismissed = false
        popupInstance.close()
        olderror(data)
    }

    const mapContainer = document.createElement('div')
    mapContainer.classList.add('map')
    container.appendChild(mapContainer)

    const settingsContainer = document.createElement('div')
    settingsContainer.classList.add('content', 'small', 'actions', 'item-list')
    container.appendChild(settingsContainer)

    const messageContainer = document.createElement('div')
    messageContainer.classList.add('message')
    container.appendChild(messageContainer)
    messageContainer.style.display = 'none'

    const messageCloser = document.createElement('a')
    messageCloser.classList.add('closer')
    const closeIcon = document.createElement('i')
    closeIcon.classList.add('fa', 'fa-times')
    messageCloser.appendChild(closeIcon)
    messageCloser.addEventListener('click', () => {
        messageContainer.style.display = 'none'
    })
    messageContainer.appendChild(messageCloser)

    const descriptionContainer = document.createElement('p')
    descriptionContainer.classList.add('description')
    messageContainer.appendChild(descriptionContainer)

    const actionContainer = document.createElement('div')
    actionContainer.classList.add('item', 'text-center')
    settingsContainer.appendChild(actionContainer)

    const latInput = document.createElement('input')
    latInput.setAttribute('type', 'hidden')
    latInput.setAttribute('name', 'lat')
    actionContainer.appendChild(latInput)

    const lngInput = document.createElement('input')
    lngInput.setAttribute('type', 'hidden')
    lngInput.setAttribute('name', 'lng')
    actionContainer.appendChild(lngInput)

    const okButton = document.createElement('a')
    okButton.classList.add('flat-button', 'blue')
    okButton.textContent = translate('confirm_done')
    okButton.addEventListener('click', success)
    actionContainer.appendChild(okButton)

    const cancelButton = document.createElement('a')
    cancelButton.classList.add('flat-button', 'gray')
    cancelButton.textContent = translate('confirm_cancel')
    cancelButton.addEventListener('click', error)
    actionContainer.appendChild(cancelButton)

    let userMap = null
    const autocompleteInput = document.createElement('input')
    autocompleteInput.setAttribute('type', 'text')

    dataChain = dataChain || {}

    const userIcon = {
        icon: mapMarkers.USER_UNLABELED,
        draggable: true,
        dragend: function () {
            this.map.setCenter(this.getPosition())
            latInput.value = this.getLatitude()
            lngInput.value = this.getLongitude()

            autocompleteInput.value = ''
        },
        dragstart: function () {
            messageContainer.style.display = 'none'
        },
        lat: dataChain.lat || cityStore.currentCity.latitude,
        lng: dataChain.lng || cityStore.currentCity.longitude,
    }

    latInput.value = userIcon.lat
    lngInput.value = userIcon.lng

    let pacContainer = null

    const popupInstance = popup({
        content: container,
        width: '100%',
        height: '100%',
        beforeClose: function () {
            if (wasDismissed) {
                olderror({ lat: latInput.value, lng: lngInput.value })
            }
        },
        afterClose: function () {
            if (pacContainer) {
                pacContainer.remove()
            }
        },
    })

    const { interactiveMap } = await import('../modules/map/map.js')

    userMap = new interactiveMap({
        container: mapContainer,
        center: [userIcon.lat, userIcon.lng],
    })

    const marker = userMap.renderMarker(userIcon, true)

    let message = translate('drag_and_drop_marker_help')
    if (dataChain.message) {
        message = `${dataChain.message}<br/>${message}`
    }

    descriptionContainer.innerHTML = message
    messageContainer.style.display = 'block'

    container.appendChild(autocompleteInput)

    const createAutocomplete = async () => {
        const localeStore = useLocaleStore()

        const maps = await googleMaps()

        const autocomplete = new maps.places.Autocomplete(autocompleteInput, {
            language: localeStore.code,
        })

        const setPacContainer = (pc) => {
            pacContainer = pc
            if (popupInstance && popupInstance.clickOutsideEvent) {
                popupInstance.clickOutsideEvent.addElement(pc)
            }
        }

        let pacIterator = 0

        const findPacContainer = () => {
            const pc = document.querySelectorAll('body > .pac-container:last-child')
            if (pc.length) {
                setPacContainer(pc[0])
            } else if (pacIterator < 10) {
                window.setTimeout(findPacContainer, 500)
            }
            pacIterator++
        }

        findPacContainer()

        maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace()
            if (!place.geometry) {
                return
            }
            const lat = place.geometry.location.lat()
            const lng = place.geometry.location.lng()

            marker.setPosition(lat, lng)

            userMap.setCenter(lat, lng)

            latInput.value = lat
            lngInput.value = lng
        })

        mapContainer.appendChild(autocompleteInput)
    }

    createAutocomplete()
}

export const requestUserGeolocation = (timeout = 10000, requireAccuracy = false) => {
    return new Promise((resolve, reject) => {
        getUserGeolocation(
            (data) => {
                if (data && data.coords) {
                    const userStore = useUserStore()
                    userStore.hasAcceptedGeolocation = true
                    userStore.location = {
                        lat: data.coords.latitude,
                        lng: data.coords.longitude,
                    }
                    return resolve({
                        lat: data.coords.latitude,
                        lng: data.coords.longitude,
                    })
                } else {
                    reject()
                }
            },
            (error) => {
                reject(error)
            },
            timeout,
            requireAccuracy,
        )
    })
}
