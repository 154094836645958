<template>
    <div v-if="localUser">
        <span class="progress-bar">
            <span
                data-link="rankLevelProgress"
                data-type="width"
                data-format="percent"
                class="progress"
                :style="{ width: localUser.rankLevelProgress * 100 + '%' }"
            ></span>
        </span>

        <span class="block clearfix">
            <span class="float-right next-level" :title="localUser.nextRankTitle">
                <span data-link="nextRankXP" data-locale="user_xp_x" data-format="number">
                    {{ cityStore.formatNumber(localUser.nextRankXP) }}p
                </span>
                <i
                    data-link="nextRankLevel"
                    data-type="class"
                    class="icon--rank-level level-7 middle next-level-icon"
                ></i>
            </span>
            <span class="float-left current-level" :title="localUser.rankTitle">
                <i
                    data-link="rankLevel"
                    data-type="class"
                    class="icon--rank-level middle"
                    :class="{
                        ['level-' + localUser.rankLevel]: userStore.isLoggedIn,
                    }"
                ></i>
                <span data-link="rankXP" data-locale="user_xp_x" data-format="number">
                    {{ cityStore.formatNumber(localUser.rankXP) }}p
                </span>
            </span>
        </span>
    </div>
</template>

<script setup>
import { useUserStore } from '../../../../store/user.js'
import { useCityStore } from '../../../../store/city.js'
import { computed } from 'vue'

const props = defineProps({
    user: {
        type: Object,
        required: false,
    },
})

const userStore = useUserStore()
const cityStore = useCityStore()

const localUser = computed(() => {
    return props.user || userStore.user
})
</script>
