import { trackEvent } from '../tracking/tracking'
import { numberFormat, translate } from '../locale/locale.js'
import { popup } from '../popup/popup.js'
import http from '../../utils/http.js'

export default {
    rarityRibbonColor(rarityLevel) {
        const ribbonColors = {
            1: 'blue',
            2: 'yellow',
            3: 'orange',
            4: 'red',
            5: 'black',
        }

        return ribbonColors[rarityLevel] || ''
    },

    showPopup(trophy, received, user) {
        trackEvent('Trophy popup', {
            category: 'User',
            label: trophy.name,
            nonInteraction: true,
        })

        received = !!received

        const number = trophy.family ? trophy.familyLevel : trophy.possessionCount

        const innerPopup = document.createElement('div')
        let pretitle = null
        if (received) {
            pretitle = document.createElement('h2')
            pretitle.textContent = translate('user_trophy_received')
            innerPopup.appendChild(pretitle)
        }
        const container = document.createElement('div')
        container.classList.add('user-trophy', 'glow')
        innerPopup.appendChild(container)

        const name = document.createElement('h1')
        name.classList.add('user-trophy-name')
        name.textContent = trophy.name
        container.appendChild(name)

        const imageContainer = document.createElement('div')
        imageContainer.classList.add('user-trophy-image-container')
        if (trophy.multiple && number) {
            imageContainer.classList.add('multi')
        }
        if (trophy.family && number) {
            imageContainer.classList.add('trophy-levels')
        }
        imageContainer.setAttribute('data-number', number)
        container.appendChild(imageContainer)
        const image = document.createElement('img')
        image.src = trophy.image
        image.classList.add('user-trophy-image')
        imageContainer.appendChild(image)

        const description = document.createElement('p')
        description.textContent = trophy.description
        description.classList.add('user-trophy-description')
        container.appendChild(description)
        if (!trophy.description) {
            description.style.display = 'none'
        }

        const trophyUrl =
            trophy.code === 'REGISTERED' ? '/about/points/' : `/trophy/${trophy.code}/`
        const trophyText =
            trophy.code === 'REGISTERED' ? translate('what_is_this') : translate('read_more')
        const trophyDetailsLink = document.createElement('a')
        trophyDetailsLink.href = trophyUrl
        trophyDetailsLink.classList.add(
            'user-trophy-details-link',
            'flat-button',
            'flat-button--small',
            'flat-button--white',
            'mt-1',
        )
        trophyDetailsLink.textContent = trophyText
        innerPopup.appendChild(trophyDetailsLink)

        let ribbon = null
        if (trophy.rarityLevel) {
            ribbon = {
                label: trophy.rarityName,
                color: this.rarityRibbonColor(trophy.rarityLevel),
            }
        }

        let popupInstance = popup({
            content: innerPopup,
            cssClass: 'user-trophy-message',
            ribbon: ribbon,
            separateBody: true,
            width: 350,
            queue: true,
            afterOpen: function () {
                if (received) {
                    this.popupContainer.classList.add('received')
                }
            },
        })

        const getData = { trophy: trophy.code }
        if (user) {
            getData.user = user
        }

        let progressContainer = null

        const setProgress = function (trophy) {
            if (!progressContainer) {
                return
            }
            if (trophy.multiple) {
                Array.from(progressContainer.querySelectorAll('.user-trophy-progress')).forEach(
                    (element) => {
                        element.style.display = 'none'
                    },
                )

                const messageLabel = progressContainer.querySelector('.message .label')
                messageLabel.textContent = translate(
                    'user_trophy_your_multi_count',
                    numberFormat(trophy.possessionCount, 0),
                )
                progressContainer.style.display = 'block'
            } else {
                const progress = trophy.progress || { total: 0, possession: false }
                const trophyImage = progressContainer.querySelector('img.trophy')
                trophyImage.src = !progress.possession ? trophy.placeholder : trophy.image

                const userTrophyProgress = progressContainer.querySelector('.user-trophy-progress')
                userTrophyProgress.title = Math.round(progress.total * 100) + '%'
                userTrophyProgress.style.display = 'block'

                const messageLabel = progressContainer.querySelector('.message .label')
                messageLabel.textContent = translate('user_trophy_your_progress')
                progressContainer.style.display = 'block'

                progressContainer.querySelector('.progress').style.width =
                    progress.total * 100 + '%'
                // svg.style.strokeDashoffset = ((1 - progress.total) * 295.3) + "%";
            }
        }

        if (window.thatsup.loggedIn) {
            progressContainer = document.createElement('div')
            progressContainer.classList.add('user-trophy-progress-container')
            progressContainer.innerHTML = `
                <div class="user-trophy-progress">
                    <img class="trophy" src="" alt="">
                    <div class="user-progress-slider">
                        <span class="progress-bar">
                            <span class="progress"></span>
                        </span>
                    </div>
                </div>`
            progressContainer.style.display = 'none'
            progressContainer.insertAdjacentHTML(
                'afterbegin',
                `
                <div class="message">
                    <span class="artwork x-small circle"><img src="${window.thatsup.user.thumbnail}" alt=""></span>
                    <span class="label">Din utveckling.</span>
                </div>`,
            )
            innerPopup.appendChild(progressContainer)
        }

        if (trophy.family && progressContainer) {
            const userTrophies = document.createElement('div')
            userTrophies.classList.add('user-trophies', 'loading')
            progressContainer.insertAdjacentElement('beforebegin', userTrophies)

            if (window.thatsup.loggedIn) {
                progressContainer.style.display = 'block'
                progressContainer.classList.add('loading')
            }

            http.get('/trophy/family/?trophy=' + getData.trophy)
                .then(function ({ trophies }) {
                    if (trophies.length <= 1) {
                        userTrophies.remove()
                        return
                    }

                    trophies.forEach(function (familyTrophy) {
                        const trophyContainer = document.createElement('div')
                        trophyContainer.classList.add('user-trophy', 'small')
                        userTrophies.appendChild(trophyContainer)
                        const trophyImageContainer = document.createElement('div')
                        trophyImageContainer.classList.add('user-trophy-image-container')
                        trophyContainer.appendChild(trophyImageContainer)

                        if (!familyTrophy.image || !familyTrophy.possession) {
                            trophyImageContainer.classList.add('not-possessed')
                        }

                        trophyContainer.classList.add('scale')
                        trophyImageContainer.classList.add('trophy-levels')
                        trophyImageContainer.setAttribute('data-number', familyTrophy.familyLevel)

                        if (trophy.code === familyTrophy.code) {
                            trophyContainer.classList.add('focus')
                            setProgress(familyTrophy)
                        }

                        const tropyLevelImage = document.createElement('img')
                        tropyLevelImage.src =
                            !familyTrophy.image || !familyTrophy.possession
                                ? familyTrophy.placeholder
                                : familyTrophy.image
                        tropyLevelImage.classList.add('user-trophy-image')
                        trophyImageContainer.appendChild(tropyLevelImage)

                        trophyContainer.addEventListener('click', function () {
                            Array.from(this.parentNode.children).forEach((sibling) => {
                                sibling.classList.remove('focus')
                            })

                            description.textContent = familyTrophy.description
                            if (familyTrophy.description) {
                                description.style.display = 'block'
                            } else {
                                description.style.display = 'none'
                            }
                            name.textContent = familyTrophy.name
                            imageContainer.setAttribute('data-number', familyTrophy.familyLevel)
                            image.src =
                                !familyTrophy.image || !familyTrophy.possession
                                    ? familyTrophy.placeholder
                                    : familyTrophy.image

                            if (familyTrophy.code) {
                                trophyDetailsLink.disabled = false
                                trophyDetailsLink.href = '/trophy/' + familyTrophy.code + '/'
                            } else {
                                trophyDetailsLink.removeAttribute('href')
                                trophyDetailsLink.disabled = true
                            }

                            function transformjQueryElement(element) {
                                if ('jQuery' in window && element instanceof window.jQuery) {
                                    return element.get(0)
                                }

                                return element
                            }

                            const popupContainer = transformjQueryElement(
                                popupInstance.popupContainer,
                            )

                            if (ribbon) {
                                popupContainer.classList.remove('ribbon--' + ribbon.color)
                            }
                            if (familyTrophy.rarityLevel) {
                                ribbon = {
                                    label: familyTrophy.rarityName,
                                    color: this.rarityRibbonColor(familyTrophy.rarityLevel),
                                }
                                popupContainer.classList.add('w-ribbon')
                                popupContainer.classList.add('ribbon--' + ribbon.color)
                                popupContainer.setAttribute('data-ribbon', ribbon.label)
                            } else {
                                popupContainer.classList.remove('w-ribbon')
                                ribbon = null
                            }

                            if (trophy.code === familyTrophy.code && pretitle) {
                                pretitle.style.display = 'block'
                            } else if (pretitle) {
                                pretitle.style.display = 'none'
                            }
                            setProgress(familyTrophy)

                            this.classList.add('focus')
                        })
                    })
                    innerPopup.addEventListener('swipeleft', function () {
                        const focusTrophy = userTrophies.querySelector('.user-trophy.focus')
                        if (focusTrophy.nextElementSibling) {
                            focusTrophy.nextElementSibling.click()
                        }
                    })
                    innerPopup.addEventListener('swiperight', function () {
                        const focusTrophy = userTrophies.querySelector('.user-trophy.focus')
                        if (focusTrophy.previousElementSibling) {
                            focusTrophy.previousElementSibling.click()
                        }
                    })
                })
                .finally(function () {
                    userTrophies.classList.remove('loading')
                    progressContainer.classList.remove('loading')
                })
        } else if (window.thatsup.loggedIn && progressContainer) {
            // Get progress status for logged in users
            progressContainer.insertAdjacentElement('afterend', trophyDetailsLink)
            progressContainer.style.display = 'block'
            progressContainer.classList.add('loading')
            http.get('/trophy/family/', getData).then(({ trophies }) => {
                progressContainer.classList.remove('loading')
                if (trophies && trophies.length) {
                    setProgress(trophies[0])
                }
            })
        }

        return popupInstance
    },
}
