<template>
    <div :class="$style.wrapper">
        <component
            v-for="hero in items"
            :is="hero.component"
            :class="$style.hero"
            v-bind="hero.props"
        />
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>
<script setup>
import { computed } from 'vue'
import HeroElementSkeleton from './HeroElementSkeleton.vue'
import HeroElement from './HeroElement.vue'
import BaseElement from './BaseElement.vue'

const props = defineProps({
    ...BaseElement.props,
    data: {
        type: Array,
        required: false,
        default: null,
    },
})
const items = computed(() => {
    if (props.data === null) {
        const skeletons = []
        for (let i = 0; i < (props.configuration.limit || 3); i++) {
            skeletons.push({
                component: HeroElementSkeleton,
            })
        }
        return skeletons
    }
    return props.data.map((hero) => {
        return {
            component: HeroElement,
            props: hero,
        }
    })
})
</script>

<style module>
.wrapper {
    padding: var(--element-padding);
    display: flex;
    gap: 22px;
    flex-wrap: wrap;
}
.hero {
    max-width: 100%;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
}
@media screen and (min-width: 768px) {
    .hero {
        width: auto;
        min-width: calc(50% - 11px);
        flex-basis: calc(50% - 11px);
    }
}
</style>
