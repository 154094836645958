<template>
    <div class="base-filter">
        <ul class="base-filter__list">
            <BaseFilterItems
                v-for="f in filters"
                :key="f.key"
                v-model="filter[f.key]"
                @update:model-value="onChange({ key: f.key, value: $event })"
                :filter="f"
            />
        </ul>
    </div>
</template>

<script>
import BaseFilterItems from './BaseFilterItems.vue'

export default {
    components: { BaseFilterItems },
    emits: ['change'],
    props: {
        filters: {
            type: Array,
            required: false,
            default: () => [],
        },
        filter: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    computed: {},
    methods: {
        onChange(event) {
            this.$emit('change', event)
        },
    },
}
</script>

<style scoped>
.base-filter__list {
    overflow: auto;
    white-space: nowrap;
}
</style>
