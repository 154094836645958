<template>
    <CarouselElement v-if="items.length" :items="items" :title="title" :description="description" />
</template>
<script setup>
import CarouselElement from './CarouselElement.vue'
import CarouselPlaces from './CarouselPlaces.vue'
import { computed } from 'vue'
import CarouselImageSkeleton from './CarouselImageSkeleton.vue'
import { ref } from 'vue'
import BaseElement from './BaseElement.vue'

const props = defineProps({
    ...BaseElement.props,
    data: {
        type: Array,
        required: false,
        default: null,
    },
})

const allItemsExpanded = ref(false)

const items = computed(() => {
    if (props.data === null) {
        const skeletons = []
        for (let i = 0; i < (props.configuration.limit || 3); i++) {
            skeletons.push({
                component: CarouselImageSkeleton,
                props: { index: i },
            })
        }
        return skeletons
    }
    return props.data.map((category) => {
        return {
            component: CarouselPlaces,
            props: {
                ...category,
                expanded: allItemsExpanded.value,
                'onUpdate:expanded': (e) => {
                    allItemsExpanded.value = e
                },
            },
        }
    })
})
</script>
