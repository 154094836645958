<template>
    <div class="text-center">
        <h3>{{ $t('userPoll.poll_not_started_title') }}</h3>
        <p>{{ $t('userPoll.poll_not_started_date', { date: startDate }) }}</p>
    </div>
</template>

<script>
export default {
    props: {
        userPoll: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {}
    },

    computed: {
        startDate() {
            return window.moment(this.userPoll.startDate).calendar()
        },
    },

    methods: {},
}
</script>
<style scoped>
h2 {
    font-size: 20px;
    font-weight: 600;
}
</style>
