<template>
    <div class="place-list" ref="container" :class="{ 'place-list--edit': editMode || createMode }">
        <template v-if="placeList">
            <PlaceListBodyWrapper @width-change="mapResize">
                <PlaceListBody
                    v-if="(isLoading || !createMode) && !editMode"
                    :place-list="placeList"
                    :place-page="placePage"
                    :is-mobile="isMobile"
                    :options="options"
                    :is-loading="isLoading"
                    :is-loading-places="isLoadingPlaces"
                    :active-place="activePlace"
                    :highlighted-place="highlightedPlace"
                    :user-position="userPosition"
                    :is-empty="isEmpty"
                    @edit="editMode = true"
                    @follow="follow"
                    @unfollow="unfollow"
                    @update-options="updateOptions"
                    @request-user-location="requestUserLocation"
                    @highlight-place="highlightPlace"
                    @unhighlight-place="unhighlightPlace"
                    @map-center-place="mapCenterPlace"
                    @show-map="showMapOverlay"
                />
                <PlaceListEditor
                    v-if="createMode || (editMode && !isLoading && placeList.canEdit)"
                    :place-list="placeList"
                    :place-page="placePage"
                    :is-loading-places="isLoadingPlaces"
                    :is-mobile="isMobile"
                    :errors="errors"
                    @update-options="updateOptions"
                    @list-change="updatePlaceList"
                    @place-change="updatePlacePage"
                    @cancel="cancelEdit"
                    @save="save"
                    @delete="deleteList"
                />
            </PlaceListBodyWrapper>
            <PlaceListMap
                v-if="!isMobile"
                :place-list="placeList"
                :place-page="placePage"
                :user-position="userPosition"
                :highlighted-place="highlightedPlace"
                @user-position-change="updateOptions"
                @click="mapClick"
                @marker-click="mapMarkerClick"
                @marker-mouseenter="mapMarkerMouseEnter"
                @marker-mouseleave="mapMarkerMouseLeave"
                ref="map"
            />
            <template v-if="isMapPopupOpen">
                <div class="place-map-wrapper" v-if="isMapPopupOpen" ref="mapPopupEl">
                    <PlaceMapPopup
                        :title="placeList.name"
                        @close="hideMapOverlay"
                        :places="placePage?.content"
                    />
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import http from '@utils/http'
import PlaceListBodyWrapper from './components/PlaceListBodyWrapper.vue'
import PlaceListBody from './components/PlaceListBody.vue'
import PlaceListMap from './components/PlaceListMap.vue'
import PlaceListEditor from './components/PlaceListEditor.vue'
import confirm from '@utils/confirm.js'
import { headerHeight } from '../../../es6/src/modules/header/header.js'
import { useUserStore } from '../../../store/user.js'
import { getUserPosition } from '../../../es6/src/utils/geo.js'
import Eventbus from '../../../es6/src/modules/eventbus/Eventbus.js'
import { replaceState } from '../../../es6/src/utils/history.js'
import { useDirtyStore } from '../../../store/dirty.js'
import { mapStores } from 'pinia'
import { popup } from '@modules/popup/popup.js'
import { nextTick } from 'vue'
import { lazyLoadComponent } from '@/admin-v2/js/utils/lazyLoadComponent.js'
const PlaceMapPopup = lazyLoadComponent(import('@/vue/components/popup/PlaceMapPopup.vue'))

const DEFAULT_OPTIONS = {
    sort: null,
    offset: 0,
    filter: {},
    lat: undefined,
    lng: undefined,
}

let mapPopup = null

export default {
    name: 'PlaceList',
    components: {
        PlaceMapPopup,
        PlaceListBodyWrapper,
        PlaceListBody,
        PlaceListMap,
        PlaceListEditor,
    },
    data() {
        return {
            isMobile: true,
            placeList: null,
            placePage: null,
            options: DEFAULT_OPTIONS,
            isLoading: false,
            isLoadingPlaces: false,
            activePlace: null,
            highlightedPlace: null,

            isMapPopupOpen: false,
            mapPopupEl: null,

            editMode: false,
            isDirty: false,
            errors: {},

            savedLat: null,
            savedLng: null,
        }
    },
    props: {
        id: {
            type: String,
            required: false,
            default: null,
        },
    },

    setup() {
        const userStore = useUserStore()

        return {
            userStore,
        }
    },

    created() {
        if (this.id) {
            this.fetchPlaceList().then(() => {
                this.fetchPlaceListPlaces()
            })
        } else {
            this.placeList = {
                name: '',
                description: '',
                owners: [this.userStore.user],
                public: true,
                isCreator: true,
                canEdit: true,
                creator: this.userStore.user,
                id: null,
                type: 'user-place-list',
            }
            this.placePage = {
                content: [],
                paging: {
                    offset: 0,
                    count: 0,
                    limit: 100,
                    total: 0,
                },
            }
        }
    },

    destroyed() {
        window.removeEventListener('resize', this.onWindowResize)
    },

    watch: {
        options(newVal) {
            this.fetchPlaceListPlaces()
        },
        editMode() {
            if (this.editMode && (this.options.sort || Object.keys(this.options.filter).length)) {
                this.options = {
                    ...this.options,
                    sort: DEFAULT_OPTIONS.sort,
                    filter: DEFAULT_OPTIONS.filter,
                }
            }
        },
    },

    computed: {
        ...mapStores(useDirtyStore),
        userPosition() {
            if (this.options.lat && this.options.lng) {
                return {
                    lat: this.options.lat,
                    lng: this.options.lng,
                }
            } else {
                return null
            }
        },
        createMode() {
            return this.placeList && this.placeList.id === null
        },
        isEmpty() {
            return (
                !Object.keys(this.options.filter).length &&
                this.placePage &&
                this.placePage.paging.total === 0
            )
        },
    },

    methods: {
        refreshHeight() {
            if (this.$refs.container) {
                const body = document.querySelector('body')
                if (body) {
                    if (this.isMobile) {
                        body.classList.add('small-header--force')
                    } else {
                        body.classList.remove('small-header--force')
                    }
                }
                this.$refs.container.style.height =
                    window.innerHeight - headerHeight(true, true) + 'px'
            }
        },

        mapResize() {
            if (this.$refs.map) {
                this.$refs.map.resize()
            }
        },

        userPositionChange(position) {
            this.updateOptions(position)
        },

        evaluateMobileVersion() {
            this.isMobile = window.innerWidth < 992
        },

        updateOptions(options) {
            this.options = { ...this.options, ...options }
        },

        fetchPlaceList() {
            this.isLoading = true
            return http
                .get(`/user/list/?id=${this.id}`, {
                    params: this.options,
                })
                .then(({ data }) => {
                    this.placeList = data.data
                    this.isLoading = false
                })
        },

        fetchPlaceListPlaces() {
            this.isLoadingPlaces = true
            if (!this.placeList) {
                return
            }
            return http
                .get(`/user/list/places/?id=${this.placeList.id}`, {
                    params: this.options,
                })
                .then(({ data }) => {
                    this.placePage = data.data
                    this.isLoadingPlaces = false
                })
        },

        updatePlaceList(placeList) {
            this.placeList = placeList
            this.dirtyStore.setIsDirty(true)
        },

        updatePlacePage(placePage) {
            this.placePage = placePage
            this.dirtyStore.setIsDirty(true)
        },

        requestUserLocation(options) {
            this.getUserLocation().then(options.then).catch(options.catch)
        },

        getUserLocation() {
            return new Promise((resolve, reject) => {
                if (this.options.lat && this.options.lng) {
                    return resolve({
                        lat: this.options.lat,
                        lng: this.options.lng,
                    })
                }
                if (this.savedLat && this.savedLng) {
                    return resolve({
                        lat: this.savedLat,
                        lng: this.savedLng,
                    })
                }
                getUserPosition(
                    (pos) => {
                        if (pos.lat && pos.lng) {
                            this.savedLat = pos.lat
                            this.savedLng = pos.lng
                            return resolve(pos)
                        } else {
                            reject()
                        }
                    },
                    (error) => {
                        reject(error)
                    },
                )
            })
        },
        mapClick(map, e) {
            if (!e.originalEvent?.defaultPrevented) {
                this.activePlace = null
            }
        },
        mapMarkerClick(marker, e) {
            e.preventDefault()

            if (!marker.data?.placeId) {
                return
            }

            this.activePlace = marker.data.placeId
        },
        mapMarkerMouseEnter(marker) {
            this.highlightPlace(marker.id)
        },
        mapMarkerMouseLeave(marker) {
            this.unhighlightPlace(marker.id)
        },

        highlightPlace(place) {
            this.highlightedPlace = place
        },
        unhighlightPlace(place) {
            if (this.highlightedPlace == place) {
                this.highlightedPlace = null
            }
        },
        mapCenterPlace(place) {
            if (this.$refs.map) {
                this.$refs.map.centerPlace(place)
            }
        },

        onWindowResize() {
            this.refreshHeight()
            this.evaluateMobileVersion()
        },

        cancelEdit() {
            this.editMode = false
            this.dirtyStore.setIsDirty(false)
        },

        save(data) {
            this.editMode = false
            this.isLoadingPlaces = true
            let wasCreating = false
            if (this.createMode) {
                this.isLoading = true
                wasCreating = true
            }

            this.placePage = data.placePage

            const postData = {
                editedPlaces: data.placePage.content.filter((p) => p.edited === true),
                placeList: { ...data.placeList },
                removedPlaces: data.removedPlaces,
                addedPlaces: data.addedPlaces,
                removedOwners: data.removedOwners,
                addedOwners: data.addedOwners,
            }
            const placesChanged =
                data.placeOrderChanged || data.removedPlaces.length || data.addedPlaces.length
            if (placesChanged) {
                // Order is only necessary if place list has changed
                postData.placeOrder = data.placePage.content
                    .filter((p) => !p.isRemoved)
                    .map((p) => p.id)
                postData.orderOffset = data.placePage.paging.offset
            }

            http({
                method: this.createMode ? 'post' : 'patch',
                url: `/user/list/`,
                data: postData,
            })
                .then(({ data }) => {
                    this.dirtyStore.setIsDirty(false)
                    this.placeList = data.data

                    if (placesChanged) {
                        this.updateOptions(DEFAULT_OPTIONS)
                    } else {
                        this.isLoadingPlaces = false
                    }

                    this.errors = {}
                    this.editMode = false
                    this.isLoading = false
                    replaceState(null, this.placeList.name, this.placeList.permalink, false)
                    if (wasCreating) {
                        Eventbus.emit('place-list:created', {
                            placeList: this.placeList,
                        })
                    } else {
                        Eventbus.emit('place-list:updated', {
                            placeList: this.placeList,
                        })
                    }
                    Eventbus.emit('place-list:saved', {
                        placeList: this.placeList,
                    })
                })
                .catch((err) => {
                    this.errors = err.response.data.errors
                    this.isLoading = false
                    this.isLoadingPlaces = false
                    this.editMode = true
                })
        },

        deleteList() {
            this.isLoading = true
            http.delete('/user/list/', {
                data: { id: this.placeList.id },
            })
                .then(({ data }) => {
                    this.dirtyStore.setIsDirty(false)
                    if (data.success) {
                        location.href = this.placeList.creator.permalink + 'lists/'
                    } else {
                        this.isLoading = false
                    }
                })
                .catch(() => {
                    this.isLoading = false
                })
        },

        follow() {
            this.placeList.isFollowing = true
            http.post('/user/list/follow/', {
                id: this.placeList.id,
            })
                .then(({ data }) => {
                    if (!data.success) {
                        this.placeList.isFollowing = false
                    }
                })
                .catch(() => {
                    this.placeList.isFollowing = false
                })
        },

        async unfollow() {
            if (
                !(await confirm(
                    this.$t('placeList.unfollow_list_confirm'),
                    null,
                    null,
                    this.$t('placeList.common.no'),
                ))
            ) {
                return
            }

            this.placeList.isFollowing = false
            http.post('/user/list/unfollow/', {
                id: this.placeList.id,
            })
                .then(({ data }) => {
                    if (!data.success) {
                        this.placeList.isFollowing = true
                    }
                })
                .catch(() => {
                    this.placeList.isFollowing = true
                })
        },

        hideMapOverlay() {
            mapPopup.close()
        },

        showMapOverlay(placeId = null) {
            this.isMapPopupOpen = true

            nextTick(() => {
                mapPopup = popup({
                    content: this.$refs.mapPopupEl,
                    title: false,
                    cssClass: 'popup--full popup--full-screen',
                    width: '100%',
                    height: '100%',
                    beforeClose: () => {
                        this.isMapPopupOpen = false
                        mapPopup = null
                    },
                })
            })
        },
    },

    mounted() {
        this.onWindowResize()
        window.addEventListener('resize', this.onWindowResize)
    },
}
</script>
