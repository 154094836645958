<template>
    <div class="pretty-form">
        <header class="text-center" v-if="title">
            <div class="t-group">
                <transition :name="transitionName">
                    <div :key="title" class="inner">{{ title }}</div>
                </transition>
            </div>
            <transition name="fade-up">
                <a class="back" @click="prev" v-if="progress >= 0">
                    <i class="icon--round-left-arrow-solid"></i>
                </a>
            </transition>
            <progress v-if="type !== 'edit'" :max="maxProgress" :value="progress + 1"></progress>
            <progress v-else value="0"></progress>
        </header>

        <div
            v-if="isLoading"
            class="text-center"
            style="display: flex; flex-direction: column; height: 100%; justify-content: center"
        >
            <div class="is-loading is-loading--large"></div>
        </div>
        <div class="pb-4 wizard-components" v-else>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import BaseStepMixin from './BaseStepMixin'

export default {
    props: ['title', 'progress', 'max-progress', 'type'],
    mixins: [BaseStepMixin],

    data() {
        return {
            transitionName: '',
        }
    },

    watch: {
        progress(newVal, oldVal) {
            if (newVal > oldVal) {
                this.transitionName = 'slide-next'
            } else {
                this.transitionName = 'slide-prev'
            }
        },
    },
}
</script>

<!--<style>-->
<!--	.wizard-components input:invalid {-->
<!--		border-color: #E66378 !important;-->
<!--	}-->
<!--</style>-->

<style scoped lang="scss">
.t-group {
    overflow: hidden;
    position: relative;
}

.inner {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.t-group div:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

header {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    position: relative;
    background: #fff;

    @media screen and (max-width: 767px) {
        position: fixed;
        top: 95px;
        left: 0;
        right: 0;
        z-index: 10;

        body.small-header--force &,
        body.small-header & {
            top: 56px;
        }
    }
}

.back {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(calc(-50% + -1px));

    i {
        display: block;
        width: 29px;
        height: 29px;
    }
}

progress[value] {
    /* Reset the default appearance */
    appearance: none;
    -webkit-appearance: none;

    display: block;
    width: 100%;
    height: 2px;
}

progress[value]::-webkit-progress-bar {
    background-color: rgba(0, 0, 0, 0.05);
}

progress::-webkit-progress-value {
    transition: width 250ms;
    background-color: #2ea6d7;
}

.slide-next-leave-active,
.slide-next-enter-active {
    transition: 350ms;
}

.slide-next-enter {
    transform: translateX(50px);
    opacity: 0;
}

.slide-next-leave-to {
    transform: translateX(-50px);
    opacity: 0;
}

.slide-prev-leave-active,
.slide-prev-enter-active {
    transition: 350ms;
}

.slide-prev-enter {
    transform: translateX(-50px);
    opacity: 0;
}

.slide-prev-leave-to {
    transform: translateX(50px);
    opacity: 0;
}
</style>
