import EventEmitter from './EventEmitter'

class Viewport extends EventEmitter {
    constructor() {
        super({})
        this.height = null
        this.width = null
        this.resizeDebounce = null
        this.updateDimensions()
        window.addEventListener('resize', this.onResize.bind(this))
    }

    updateDimensions() {
        const newHeight = window.innerHeight
        const newWidth = window.innerWidth
        const heightChange = newHeight !== this.height
        const widthChange = newWidth !== this.width
        this.height = newHeight
        this.width = newWidth
        if (heightChange) {
            this.emit('heightchange', this)
        }
        if (widthChange) {
            this.emit('widthchange', this)
        }
        if (heightChange || widthChange) {
            this.emit('change', this)
        }
    }

    onResize() {
        if (this.resizeDebounce) {
            window.clearTimeout(this.resizeDebounce)
        }
        this.resizeDebounce = window.setTimeout(() => {
            this.resizeDebounce = null
            this.updateDimensions()
        }, 200)
    }

    destroy() {
        window.removeEventListener('resize', this.onResize.bind(this))
    }
}
const viewport = new Viewport()
export default viewport
