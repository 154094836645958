export const LegacyGallery = function (options) {
    let container = resolveContainerElement(options.container)

    if (!container) {
        return false
    }

    container.gallery = this
    container.dataset.gallery = this
    container.classList.add('thatsup-gallery')
    if (options.width != null) {
        container.style.width = options.width + 'px'
    }

    let initialHeight = options.height || options.maxHeight
    if (initialHeight != null && !initialHeight.toString().endsWith('%')) {
        container.style.height = options.height + 'px'
    } else if (initialHeight != null) {
        let containerHeight = parseFloat(initialHeight) * 0.01 * container.offsetWidth
        container.style.height = containerHeight + 'px'
    }

    this.visibleCount = options.visibleCount ? options.visibleCount : 6
    let list = container.querySelector('ul')
    let listItems = list.querySelectorAll('li')

    options.justify = options.justify === true
    options.countControl = options.countControl === undefined ? true : options.countControl

    this.style = options.style ? options.style : 'slide'

    this.currentIndex = options.startIndex ? options.startIndex : 0

    this.setStyle = function (style) {
        this.style = style
        this.setWidths()
    }

    this.setWidths = function () {
        options.itemSize = 1 / this.visibleCount
        let containerWidth = parseFloat(window.getComputedStyle(container).width)
        let marginWidth = options.itemMargin != null ? options.itemMargin : 3
        this.itemWidth = containerWidth * options.itemSize
        listItems.forEach((item) => {
            item.style.width = this.itemWidth - marginWidth * 2 + 'px'
            item.style.margin = '0px ' + marginWidth + 'px'
        })
        if (this.style === 'slide') {
            listItems.forEach(function (item) {
                item.style.float = 'left'
                item.style.position = ''
                item.style.left = ''
                item.style.display = ''
            })
        } else {
            listItems.forEach((item, i) => {
                item.style.position = 'absolute'
                item.style.float = ''
                item.style.display =
                    i >= this.currentIndex && i < this.currentIndex + this.visibleCount
                        ? ''
                        : 'none'
                item.style.left = this.itemWidth * (i % this.visibleCount) + 'px'
            })
        }
        list.style.width = Math.ceil(this.itemWidth * listItems.length) + 'px'
    }
    this.setWidths()

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    if (options.click) {
        listItems.forEach(function (item, index) {
            item.querySelector('a').addEventListener('click', function () {
                options.click.call(self, index)
            })
        })
    }

    this.container = container
    this.element = container
    this.list = list
    this.items = listItems
    this.slideSpeed = options.slideSpeed ? options.slideSpeed : 400
    this.endless = !!options.endless
    this.limboIndex = this.currentIndex

    this.hasSlid = false
    this.slideTo = function (index, force, callback, speed = null) {
        if (!this.hasSlid) {
            this.hasSlid = true
            this.preload()
        }
        const previousSpeed = this.slideSpeed
        if (speed !== null) {
            this.slideSpeed = speed
        }
        let maxIndex = Math.min(index, this.items.length - this.visibleCount)
        maxIndex = Math.max(maxIndex, 0)
        force = !!force
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let self = this
        if (!force && maxIndex === this.currentIndex) {
            if (callback) {
                callback.call(self, index, this.currentIndex)
            }
            return
        } else if (force) {
            index = maxIndex
        }
        const complete = function () {
            if (callback) {
                callback.call(self, index, self.currentIndex)
            }
            self.slideSpeed = previousSpeed
            self.currentIndex = index
            self.afterRotate.call(self, index, self.currentIndex)
        }
        this.beforeRotate(index, this.currentIndex)
        this.limboIndex = index
        if (this.style === 'slide') {
            let newLeft = -(maxIndex * this.itemWidth)
            if (this.slideSpeed < 10) {
                this.list.style.left = newLeft + 'px'
                complete()
            } else {
                this.list.style.transition = 'left ' + this.slideSpeed / 1000 + 's ease-out'
                this.list.style.left = newLeft + 'px'
                setTimeout(complete, this.slideSpeed)
            }
        } else {
            let i
            const outGoing = []
            for (i = this.currentIndex; i < this.currentIndex + this.visibleCount; i++) {
                if (this.items[i]) {
                    outGoing.push(this.items[i])
                }
            }
            const inGoing = []
            for (i = maxIndex; i < maxIndex + this.visibleCount; i++) {
                if (this.items[i]) {
                    inGoing.push(this.items[i])
                }
            }
            outGoing.forEach(function (item) {
                item.style.display = 'none'
            })
            inGoing.forEach(function (item) {
                item.style.display = ''
            })
            complete()
        }
    }
    this.slideNext = function () {
        let newIndex = null
        if (!this.endless) {
            newIndex = Math.min(
                this.items.length - this.visibleCount,
                this.limboIndex + this.visibleCount,
            )
        } else {
            newIndex =
                this.limboIndex + this.visibleCount >= this.items.length
                    ? 0
                    : this.limboIndex + this.visibleCount
        }

        return this.slideTo(newIndex)
    }
    this.slidePrevious = function () {
        let newIndex = null
        if (!this.endless) {
            newIndex = Math.max(0, this.limboIndex - this.visibleCount)
        } else {
            newIndex =
                this.limboIndex - this.visibleCount < 0
                    ? Math.max(0, this.items.length - this.visibleCount)
                    : this.limboIndex - this.visibleCount
        }

        return this.slideTo(newIndex)
    }

    options.constrain = options.constrain != null ? options.constrain : true

    this.setHeights = function (image = null) {
        let containerHeight = container.offsetHeight
        if (options.height != null && options.height.toString().endsWith('%')) {
            containerHeight = Math.ceil(parseFloat(options.height) * 0.01 * container.offsetWidth)
            container.style.height = containerHeight + 'px'
        }

        let images

        if (image !== null) {
            images = [image]
        } else {
            images = Array.from(this.items).map((item) => item.querySelector('img,.video-wrapper'))
        }

        images.forEach(function (img) {
            img.style.height = ''
            window.requestAnimationFrame(function () {
                if (img.tagName === 'IMG') {
                    if (options.constrain === true && img.height >= containerHeight) {
                        img.style.height = containerHeight + 'px'
                    }

                    if (img.height !== 0) {
                        img.parentNode.style.height = containerHeight + 'px'
                        img.style.marginTop = (img.height - containerHeight) / -2 + 'px'
                    }
                } else {
                    img.style.height = containerHeight + 'px'
                    img.parentNode.style.height = containerHeight + 'px'
                }
            })
        })
    }

    this.youTubePlayerPauseTimeout = null

    this.youTubePlayerStateChange = function (event) {
        if (event.data === window.YT.PlayerState.PLAYING) {
            if (self.youTubePlayerPauseTimeout) {
                window.clearTimeout(self.youTubePlayerPauseTimeout)
            }
            event.target.parentNode.parentNode.classList.add('video-playing')
        } else if (
            event.data === window.YT.PlayerState.PAUSED ||
            event.data === window.YT.PlayerState.ENDED
        ) {
            self.youTubePlayerPauseTimeout = window.setTimeout(function () {
                self.youTubePlayerPauseTimeout = null
                event.target.parentNode.parentNode.classList.remove('video-playing')
            }, 500)
        }
    }

    this.youTubeVideoPlayers = []

    this.preload = function (visible) {
        Array.from(this.items).forEach(function (item, index) {
            if (visible && index + 1 > visible) {
                return false
            }
            if (item.dataset.isLoaded) {
                return
            }
            item.dataset.isLoaded = true
            let images = item.querySelectorAll('img')
            images.forEach(function (img) {
                let loadEvent = function () {
                    self.setHeights()
                }
                img.onload = loadEvent
                img.onerror = loadEvent
                if (img.dataset.src) {
                    img.src = img.dataset.src
                }
                if (img.dataset.srcset) {
                    img.srcset = img.dataset.srcset
                }
            })
        })
    }

    this.initialize = function () {
        if (options.constrain && options.height == null) {
            let firstItem = list.querySelector('img,video')
            if (firstItem.tagName === 'IMG') {
                let preload = new Image()
                let firstOnLoad = function () {
                    options.height =
                        Math.min(
                            options.maxHeight != null ? parseFloat(options.maxHeight) : 100,
                            (this.height / this.width) * 100,
                        ) + '%'
                    self.setHeights(firstItem)
                    self.preload(self.visibleCount)
                }
                preload.onload = firstOnLoad
                preload.onerror = firstOnLoad
                preload.src = firstItem.src
                preload.srcset = firstItem.srcset
            } else {
                self.preload(self.visibleCount)
            }
        } else {
            self.preload(self.visibleCount)
        }
        container.classList.add('initialized')
    }

    this.initialize()

    if (options.showControls === true) {
        let controls = document.createElement('div')
        controls.classList.add('controls')
        if (this.items.length > 1) {
            let prevControl = document.createElement('a')
            prevControl.classList.add('prev-control')
            prevControl.innerHTML = '<span class="icon"></span>'
            prevControl.addEventListener('click', function () {
                self.slidePrevious()
                self.stopInterval()
            })
            controls.appendChild(prevControl)

            let nextControl = document.createElement('a')
            nextControl.classList.add('next-control')
            nextControl.innerHTML = '<span class="icon"></span>'
            nextControl.addEventListener('click', function () {
                self.slideNext()
                self.stopInterval()
            })
            controls.appendChild(nextControl)

            let countControl = document.createElement('span')
            countControl.classList.add('label')
            countControl.textContent = this.currentIndex + 1 + '/' + this.items.length
            if (options.countControl) {
                let countWrapper = document.createElement('a')
                countWrapper.classList.add('count-control')
                countWrapper.appendChild(countControl)
                controls.appendChild(countWrapper)
            }

            let oldBeforeRotate = options.beforeRotate || function () {}
            options.beforeRotate = function (a, b) {
                countControl.textContent = a + 1 + '/' + self.items.length
                oldBeforeRotate.apply(self, [a, b])
            }
        }

        if (options.controls != null) {
            options.controls.forEach(function (item) {
                let control = document.createElement('a')
                control.innerHTML = item.content || '<span class="icon"></span>'
                if (item.cssClass != null) {
                    control.classList.add(item.cssClass)
                }
                if (item.click != null) {
                    control.addEventListener('click', function () {
                        item.click.call(self)
                    })
                }
                controls.insertBefore(control, controls.firstChild)
            })
        }

        container.appendChild(controls)
        this.controls = controls
    }

    if (options.thumbnailControls != null) {
        let visibleCount = this.visibleCount
        let count = 0
        let imgHeight = container.querySelector('li:first-child img').height

        let pageNext = document.createElement('a')
        pageNext.classList.add('paging', 'page-next')
        pageNext.style.height = imgHeight + 'px'
        pageNext.addEventListener('click', function () {
            if (count < self.items.length - visibleCount) {
                count = count + visibleCount
                return self.slideTo(count + 1)
            } else {
                count = 0
                return self.slideTo(0)
            }
        })
        container.appendChild(pageNext)

        let pagePrev = document.createElement('a')
        pagePrev.classList.add('paging', 'page-prev')
        pagePrev.style.height = imgHeight + 'px'
        pagePrev.addEventListener('click', function () {
            if (count > 0) {
                count = count - visibleCount
                return self.slideTo(count - 1)
            }
        })
        container.appendChild(pagePrev)
    }

    if (options.pagingContainer != null) {
        let pagingContainer = document.querySelector(options.pagingContainer)
        if (Math.ceil(this.items.length / this.visibleCount) === 1) {
            pagingContainer.classList.add('one-page')
        }

        for (let i = 0; i < Math.ceil(this.items.length / this.visibleCount); i++) {
            let a = document.createElement('a')
            a.classList.add('paging', 'page-' + i)

            if (i === 0) {
                a.classList.add('active')
            }

            a.addEventListener(
                'click',
                (function (i) {
                    return function () {
                        self.slideTo(i)
                    }
                })(i * this.visibleCount),
            )
            pagingContainer.appendChild(a)
        }

        let oldBeforeRotate = options.beforeRotate || function () {}
        options.beforeRotate = (a, b) => {
            pagingContainer.querySelector('a.active').classList.remove('active')
            let activePage = Math.ceil((a + this.visibleCount) / this.visibleCount) - 1
            pagingContainer.querySelector('.page-' + activePage).classList.add('active')
            oldBeforeRotate.apply(self, [a, b])
        }
    }

    this.beforeRotate = options.beforeRotate ? options.beforeRotate : function () {}
    this.afterRotate = options.afterRotate ? options.afterRotate : function () {}

    if (options.dynamicHeight) {
        let oldAfterRotate = this.afterRotate
        this.afterRotate = function (index, oldIndex) {
            let img = self.items[index].querySelector('img')
            if (img.style.height != null) {
                container.animate({ height: img.style.height }, { complete: self.setHeights })
            }
            oldAfterRotate(index, oldIndex)
        }
    }

    this.slideIntervalCallback = function () {
        if (this.currentIndex >= this.items.length - 1) {
            this.slideTo(0)
        } else {
            this.slideTo(this.currentIndex + 1)
        }
    }
    options.intervalstart = options.intervalstart || function () {}
    this.startInterval = function (e) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let self = this
        this.slideInterval = window.setInterval(function () {
            self.slideIntervalCallback.call(self)
        }, options.slideInterval * 1000)
        if (e == null) {
            options.intervalstart({ source: this })
        }
    }
    options.intervalstop = options.intervalstop || function () {}
    this.stopInterval = function (e) {
        window.clearInterval(this.slideInterval)
        this.slideInterval = null
        if (e == null) {
            options.intervalstop({ source: this })
        }
    }

    if (this.style !== 'slide') {
        container.addEventListener('swipeleft', function () {
            self.slideNext()
            if (options.slideInterval != null) {
                self.stopInterval()
            }
        })
        container.addEventListener('swiperight', function () {
            self.slidePrevious()
            if (options.slideInterval != null) {
                self.stopInterval()
            }
        })
    }

    if (options.init) {
        options.init.apply(self, [self.currentIndex])
    }
    this.resizeTimeout = null
    window.addEventListener('resize', function () {
        if (self.resizeTimeout) {
            window.clearTimeout(self.resizeTimeout)
            self.resizeTimeout = null
        }
        self.resizeTimeout = window.setTimeout(function () {
            self.setWidths.call(self)
            self.setHeights.call(self)
        }, 300)
    })
    window.addEventListener('load', function () {
        if (options.slideInterval != null) {
            self.startInterval()
        }
    })
    window.addEventListener('orientationchange', function () {
        if (self.resizeTimeout) {
            window.clearTimeout(self.resizeTimeout)
            self.resizeTimeout = null
        }
        self.resizeTimeout = window.setTimeout(function () {
            self.setWidths.call(self)
            self.setHeights.call(self)
        }, 500)
    })
}

export const tileGallery = (options) => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    let container = resolveContainerElement(options.container)

    if (!container) {
        return false
    }

    this.currentPage = 0

    let prevControl = container.querySelector('.controls .prev-control')
    let nextControl = container.querySelector('.controls .next-control')

    this.items = container.querySelectorAll('.page')

    let updateLabel = function () {
        container.querySelector('.controls .label').textContent =
            self.currentPage + 1 + '/' + self.items.length
    }
    prevControl.addEventListener('click', function () {
        if (self.currentPage - 1 < 0 || self.items[self.currentPage - 1] == null) {
            return
        }
        self.items[self.currentPage].style.display = 'none'
        self.items[self.currentPage - 1].style.display = 'block'
        self.currentPage--
        updateLabel()
    })
    nextControl.addEventListener('click', function () {
        if (self.currentPage + 1 >= self.items.length || self.items[self.currentPage + 1] == null) {
            return
        }
        self.items[self.currentPage].style.display = 'none'
        self.items[self.currentPage + 1].style.display = 'block'
        self.currentPage++
        updateLabel()
    })
}

export const puffGallery = (options) => {
    let container = resolveContainerElement(options.container)

    if (!container) {
        return false
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    this.container = container
    this.slideSpeed = options.slideSpeed ? options.slideSpeed : 400
    this.style = options.style ? options.style : 'fade'

    this.next = function (callback) {
        if (this.container.classList.contains('loading')) {
            return
        }
        this.container.classList.add('loading')
        let puffs = this.container.querySelectorAll('.puff')
        let visible = this.container.querySelectorAll('.puff.visible')
        let last = visible[visible.length - 1]
        let coming = []
        for (let i = 0; i < visible.length; i++) {
            last = last.nextElementSibling
            if (!last) {
                last = puffs[0]
            }
            coming.push(last)
        }

        visible.forEach(function (item) {
            item.classList.remove('visible')
            item.style.display = 'none'
            self.container.appendChild(item)
        })
        coming.forEach(function (item) {
            item.classList.add('visible')
            item.style.display = ''
        })

        setTimeout(function () {
            self.container.classList.remove('loading')
            if (callback) {
                callback.apply(self)
            }
        }, self.slideSpeed)
    }
    this.previous = function (callback) {
        if (this.container.classList.contains('loading')) {
            return
        }
        this.container.classList.add('loading')
        let puffs = this.container.querySelectorAll('.puff')
        let visible = this.container.querySelectorAll('.puff.visible')
        let first = visible[0]
        let going = []
        for (let i = 0; i < visible.length; i++) {
            first = first.previousElementSibling
            if (!first) {
                first = puffs[puffs.length - 1]
            }
            going.push(first)
        }

        visible.forEach(function (item) {
            item.classList.remove('visible')
            item.style.display = 'none'
            self.container.insertBefore(item, self.container.firstChild)
        })
        going.forEach(function (item) {
            item.classList.add('visible')
            item.style.display = ''
        })

        setTimeout(function () {
            self.container.classList.remove('loading')
            if (callback) {
                callback.apply(self)
            }
        }, self.slideSpeed)
    }
    if (options.controls) {
        let prevControl = document.createElement('a')
        prevControl.classList.add('prev-control')
        prevControl.innerHTML = '<span class="icon"></span>'
        prevControl.addEventListener('click', function () {
            self.previous()
        })
        container.appendChild(prevControl)

        let nextControl = document.createElement('a')
        nextControl.classList.add('next-control')
        nextControl.innerHTML = '<span class="icon"></span>'
        nextControl.addEventListener('click', function () {
            self.next()
        })
        container.appendChild(nextControl)
    }
}

function resolveContainerElement(el) {
    if (el instanceof HTMLElement) {
        return el
    }

    return document.querySelector(el)
}
