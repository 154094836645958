<template>
    <form
        @submit.prevent="apply"
        v-show="isOpen"
        class="place-explorer__filter-modal pretty-form fixed-below-header"
    >
        <div class="place-explorer__filter-modal__backdrop" @click="$emit('close')"></div>
        <div class="place-explorer__filter-modal__wrapper">
            <ExploreFilterModalNavigation
                :has-group="!!currentGroup"
                :filter="filter"
                :has-changed="hasChanged"
                :has-params="hasParams"
                @close="$emit('close')"
                @close-group="currentGroup = null"
                @apply="apply"
                @clear="clearParams"
            />
            <div
                class="place-explorer__filter-modal-body overflow-auto"
                :class="{
                    'place-explorer__filter-modal-body--sub': !!currentGroup,
                }"
                ref="body"
            >
                <ul class="place-explorer__filter-modal-categories">
                    <li v-if="isMobile">
                        <label>{{ $t('explore.sorting.label') }}</label>
                        <select
                            class="full-width"
                            @input="updateParams('sort', $event.target.value)"
                            :value="currentParams.sort || filter.sort"
                        >
                            <option value="relevance">
                                {{ $t('explore.sorting.relevance') }}
                            </option>
                            <option value="a-z">
                                {{ $t('explore.sorting.alphabetical') }}
                            </option>
                            <option value="rating">
                                {{ $t('explore.sorting.rating') }}
                            </option>
                            <option value="review-count">
                                {{ $t('explore.sorting.review_count') }}
                            </option>
                            <option v-if="canHaveRadius" value="distance">
                                {{ $t('explore.sorting.distance') }}
                            </option>
                        </select>
                    </li>
                    <li v-if="isMobile && canHaveRadius">
                        <label>{{ $t('explore.filter.distance_label') }}</label>
                        <div class="d-flex">
                            <NonLinearRangeInput
                                class="flex-grow"
                                :values="[100, 500, 1000, 3000, 5000, 10000]"
                                :steps="[50, 100, 250, 500, 1000]"
                                @update:model-value="updateParams('radius', parseFloat($event))"
                                :model-value="currentParams.radius || filter.radius"
                            />
                            <span class="ml-1">
                                {{
                                    $t('explore.filter.distance_number', {
                                        number: currentParams.radius || filter.radius,
                                    })
                                }}
                            </span>
                        </div>
                    </li>
                    <template v-for="group in filterGroups" :key="group.key">
                        <li
                            v-if="
                                !group.dependencies?.length ||
                                group.dependencies.some((dep) => searchedProperties.includes(dep))
                            "
                            :aria-label="group.label"
                        >
                            <div class="cursor-pointer" @click="currentGroup = group">
                                <label>
                                    {{ group.label }}
                                    <i
                                        aria-hidden="true"
                                        class="icon icon--chevron-right icon--chevron-right--gray icon--x16 float-right"
                                    ></i>
                                </label>
                                <p v-if="selectedGroupLabels[group.key].length" class="m-0">
                                    {{ selectedGroupLabels[group.key].join(', ') }}
                                </p>
                            </div>
                        </li>
                    </template>
                </ul>
                <ul v-if="currentGroup" class="place-explorer__filter-modal-subcategory">
                    <li v-for="item in currentGroup.values" :key="item.key">
                        <label class="checkbox inline-block">
                            <input
                                type="checkbox"
                                v-model="currentParams[currentGroup.key]"
                                :value="item.value"
                            />
                            <span class="label">{{ item.label }}</span>
                        </label>
                        <i
                            v-if="item.description"
                            :data-tooltip="item.description"
                            :aria-label="item.description"
                            class="icon--question-mark ml-1 icon--x18"
                        ></i>
                    </li>
                </ul>
            </div>
            <div class="place-explorer__filter-modal__apply p-1" v-if="hasChanged">
                <button
                    type="submit"
                    class="flat-button flat-button--blue flat-button--small"
                    title="Spara"
                >
                    <span>{{ $t('explore.filter.apply_label') }}</span>
                </button>
            </div>
        </div>
    </form>
</template>
<script>
import NonLinearRangeInput from '../../Shared/components/NonLinearRangeInput.vue'
import ExploreFilterModalNavigation from './ExploreFilterModalNavigation.vue'
import flatten from 'lodash-es/flatten'

const PARAM_KEYS = ['sort', 'radius']

export default {
    emits: ['input', 'close', 'group-change'],
    components: { ExploreFilterModalNavigation, NonLinearRangeInput },
    props: {
        filter: Object,
        params: Object,
        group: {
            type: Object,
            required: false,
            default: null,
        },
        isOpen: Boolean,
        isMobile: Boolean,
        canHaveRadius: Boolean,
    },
    data() {
        return {
            currentParams: {},
            currentGroup: null,
        }
    },
    watch: {
        params: {
            handler(newVal) {
                this.currentParams = this.addGroupsToParams(newVal)
            },
            immediate: true,
        },
        group: {
            handler(newVal) {
                this.currentGroup = newVal
            },
            immediate: true,
        },
        isOpen(newVal) {
            if (!newVal && this.hasChanged) {
                // Reset params on close
                this.currentParams = this.fullGivenParams
            }
            try {
                this.$refs.body.scrollTop = 0
            } catch (e) {}
        },
        currentGroup: {
            handler(newVal) {
                this.$emit('group-change', newVal)
                try {
                    this.$refs.body.scrollTop = 0
                } catch (e) {}
            },
            immediate: true,
        },
    },
    computed: {
        searchedProperties() {
            const groups = [...this.filter.groups, { key: 'category' }]

            return flatten(groups.map((group) => this.groupParams(group.key)))
        },
        filterGroups() {
            return this.filter.groups || []
        },
        fullGivenParams() {
            return this.addGroupsToParams(this.params)
        },
        hasChanged() {
            return JSON.stringify(this.fullGivenParams) !== JSON.stringify(this.currentParams)
        },
        paramKeys() {
            return PARAM_KEYS.concat(this.filterGroups.map((g) => g.key))
        },
        hasParams() {
            return !!this.paramKeys.find((key) => {
                if (Array.isArray(this.currentParams[key])) {
                    return this.currentParams[key].length > 0
                } else {
                    return this.currentParams[key] !== null
                }
            })
        },
        selectedGroupLabels() {
            return this.filterGroups
                .map((group) => {
                    return {
                        key: group.key,
                        values: group.values
                            .filter((item) => {
                                return this.currentParams[group.key].indexOf(item.value) !== -1
                            })
                            .map((item) => {
                                return item.label
                            }),
                    }
                })
                .reduce((a, group) => {
                    a[group.key] = group.values
                    return a
                }, {})
        },
    },
    methods: {
        groupParams(key) {
            if (!this.params) {
                return []
            }
            return !(key in this.params) ? [] : this.params[key]
        },
        updateParams(key, value) {
            this.currentParams = { ...this.currentParams, [key]: value }
        },
        apply() {
            this.$emit('input', this.currentParams)
            this.$emit('close')
        },
        clearParams() {
            this.currentParams = this.addGroupsToParams(
                PARAM_KEYS.reduce((a, k) => ((a[k] = null), a), {}),
            )
        },
        addGroupsToParams(params) {
            let newParams = { ...params }
            this.filterGroups.forEach((group) => {
                if (!(group.key in newParams)) {
                    newParams[group.key] = []
                }
            })
            return newParams
        },
    },
}
</script>
