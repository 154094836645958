<template>
    <div
        v-bind="$attrs"
        class="place-box box shadow rounded border mb-3"
        :class="{ pointer: clickable }"
    >
        <div v-if="image" class="place-box__image artwork medium">
            <img :src="image" alt="" />
        </div>
        <div class="place-box__description">
            <span class="place-box__title">{{ alternative.title }}</span>
            <span v-if="alternative.description" class="place-box__subtitle">
                {{ alternative.description }}
            </span>
        </div>
        <slot name="after"></slot>
    </div>
</template>

<script>
export default {
    props: {
        alternative: {
            type: Object,
            required: true,
        },
        image: {
            type: String,
            required: false,
            default: null,
        },
    },

    computed: {
        clickable() {
            for (const key in this.$attrs) {
                if (key === 'onClick' || key.indexOf('onClick.') === 0) {
                    return true
                }
            }
            return false
        },
    },

    data() {
        return {}
    },
}
</script>
<style lang="scss" scoped>
.place-box {
    display: flex;
    padding: 25px;

    .place-box__title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
        display: block;
    }

    .place-box__subtitle {
        font-size: 14px;
        display: block;
    }
}

.place-box__description {
    flex: 1;
}

.place-box__image {
    width: 45px;
    height: 45px;

    & + .place-box__description {
        margin-left: 20px;
    }
}

.place-box__button {
    align-self: center;

    i {
        width: 29px;
        height: 29px;
    }
}
</style>
