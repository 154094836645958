import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useNavigationStore = defineStore('navigation', () => {
    const isMobileUserMenuOpen = ref(false)

    return {
        isMobileUserMenuOpen,
    }
})
