<template>
    <slot name="trigger" :toggle="toggle" :is-open="isOpen" :close="close" :open="open" />

    <DrawerModalBody
        v-if="isOpen || alwaysMounted"
        :as="as"
        :show="!alwaysMounted || isOpen"
        :title="title"
        :no-animation="noAnimation"
        :is-closing="isClosing"
        :full-height="fullHeight"
        v-bind="$attrs"
        @close="close"
    >
        <template #title-icon><slot name="title-icon" /></template>
        <template #before>
            <slot name="before" :toggle="toggle" :is-open="isOpen" :close="close" :open="open" />
        </template>
        <slot :toggle="toggle" :is-open="isOpen" :close="close" :open="open" />
        <template #after>
            <slot name="after" :toggle="toggle" :is-open="isOpen" :close="close" :open="open" />
        </template>
    </DrawerModalBody>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import DrawerModalBody from './DrawerModalBody.vue'

defineOptions({
    inheritAttrs: false,
})

const emit = defineEmits(['update:modelValue', 'open', 'close'])

const props = defineProps({
    as: {
        type: String,
        default: 'dialog',
    },
    modelValue: {
        type: Boolean,
        default: false,
    },
    open: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        default: null,
    },
    fullHeight: {
        type: Boolean,
        default: false,
    },
    preventScroll: {
        type: Boolean,
        default: false,
    },
    alwaysMounted: {
        type: Boolean,
        default: false,
    },
    noAnimation: {
        type: Boolean,
        default: false,
    },
    closeOnEscape: {
        type: Boolean,
        default: true,
    },
    beforeClose: {
        type: Function,
        default: null,
    },
})

const toggle = () => {
    isOpen.value = !isOpen.value
}

const isClosing = ref(false)

const close = async (condition = null) => {
    if (isClosing.value || !isOpen.value) {
        return Promise.resolve()
    }
    if (
        condition !== null &&
        (condition === false || (typeof condition === 'function' && condition() === false))
    ) {
        return Promise.reject()
    }
    if (props.beforeClose && (await props.beforeClose()) === false) {
        return Promise.reject()
    }
    isClosing.value = true
    return new Promise((resolve) => {
        window.setTimeout(() => {
            isOpen.value = false
            isClosing.value = false
            resolve()
        }, 400)
    })
}

const open = () => {
    isOpen.value = true
}

const isOpen = ref(false)

watch(
    () => props.open,
    (open) => {
        isOpen.value = open
    },
    {
        immediate: true,
    },
)

watch(
    () => props.modelValue,
    (open) => {
        isOpen.value = open
    },
    {
        immediate: true,
    },
)

watch(
    () => isOpen.value,
    (open) => {
        emit('update:modelValue', open)
        if (open) {
            emit('open')
        } else {
            emit('close')
        }
    },
)

let scrollTop = 0
const addPreventScroll = () => {
    if (props.preventScroll) {
        scrollTop = window.scrollY
        const body = document.body
        body.style.position = 'fixed'
        body.style.overflowY = 'hidden'
        body.style.width = '100%'
        body.style.height = '100%'
        body.style.top = `-${scrollTop}px`
        window.scrollTo(0, 0)
    }
}

const removePreventScroll = () => {
    if (props.preventScroll) {
        const body = document.body
        body.style.position = ''
        body.style.overflowY = ''
        body.style.width = ''
        body.style.height = ''
        body.style.top = ''
        window.scrollTo(0, scrollTop)
    }
}

const addCloseOnEscape = () => {
    if (props.closeOnEscape) {
        window.addEventListener('keydown', handleEscape)
    }
}

const removeCloseOnEscape = () => {
    if (props.closeOnEscape) {
        window.removeEventListener('keydown', handleEscape)
    }
}

const handleEscape = (e) => {
    if (e.key === 'Escape') {
        close()
    }
}

watch(
    () => isOpen.value,
    (open) => {
        if (open && props.preventScroll) {
            addPreventScroll()
        } else if (!open && props.preventScroll) {
            removePreventScroll()
        }
        if (open && props.closeOnEscape) {
            addCloseOnEscape()
        } else if (!open && props.closeOnEscape) {
            removeCloseOnEscape()
        }
    },
    {
        immediate: true,
    },
)

const openAttribute = computed(() => {
    return isOpen.value ? '' : undefined
})
</script>
