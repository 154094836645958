<template>
    <div ref="container">
        <slot />
    </div>
</template>

<script>
import { toRaw } from 'vue'

import mapMarkers from '../../../es6/src/modules/map/markers.js'

let userMarker = null
let userCircle = null

const LOGGING = false

export default {
    props: {
        center: {
            type: Array, // TODO: Accept object with lat, lng?,
            required: false,
            default: null,
        },
        zoom: {
            type: Number,
            default: 14,
        },
        pitch: {
            type: Number,
            default: 0,
        },
        bearing: {
            type: Number,
            default: 0,
        },
        markers: {
            type: Array,
            required: false,
            default: () => [],
        },
        polygons: {
            type: Array,
            required: false,
            default: () => [],
        },
        autoZoom: {
            type: Boolean,
            required: false,
            default: false,
        },
        padding: {
            type: Object,
            required: false,
            default: null,
        },
        userPosition: {
            type: Object,
            required: false,
            default: null,
        },
        userRadius: {
            type: Number,
            required: false,
            default: null,
        },
        activeMarker: {
            type: [String, Number],
            required: false,
            default: null,
        },
        options: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    emits: ['click'],

    data() {
        return {
            map: null,
        }
    },

    computed: {
        mapEvents() {
            const self = this
            const mapEvents = {
                markerEvents: {},
                events: {},
            }

            for (const key in this.$attrs) {
                const func = function () {
                    if (Array.isArray(self.$attrs[key])) {
                        return self.$attrs[key].forEach((listener) => listener(this, ...arguments))
                    }

                    return self.$attrs[key](this, ...arguments)
                }
                if (key.indexOf('onMarker') === 0) {
                    const eventName = key.replace(/onMarker/, '').toLowerCase()
                    mapEvents.markerEvents[eventName] = func
                } else if (key.indexOf('onMap') === 0) {
                    const eventName = key.replace(/onMap/, '').toLowerCase()
                    mapEvents.events[eventName] = func
                } else if (key.indexOf('on') === 0) {
                    const eventName = key.replace(/on/, '').toLowerCase()
                    mapEvents.events[eventName] = func
                }
            }
            if (LOGGING) console.log({ mapEvents })
            return mapEvents
        },
    },

    watch: {
        center(newVal) {
            if (LOGGING) console.log('center', newVal)
            if (!this.map) return
            this.panTo(this.center[0], this.center[1])
        },
        markers(newVal) {
            if (LOGGING) console.log('markers', newVal)
            if (!this.map) return
            this.map.removeMarkers((marker) => {
                return !this.userPosition || toRaw(marker) !== userMarker
            })
            this.map.renderMarkers(this.markers, this.autoZoom)
            this.setActiveMarker(this.activeMarker)
        },
        polygons(newVal) {
            if (LOGGING) console.log('polygons', newVal)
            if (!this.map) return
            this.map.removeShapes(function (shape) {
                return !userCircle || toRaw(shape) !== userCircle
            })
            this.polygons.forEach((polygon) => {
                this.map.addPolygon(polygon)
            })
        },
        zoom(newVal) {
            if (LOGGING) console.log('zoom', newVal)
            if (!this.map) return
            if (this.zoom) {
                this.map.setZoom(this.zoom)
            }
        },
        pitch() {
            if (!this.map) return
            this.map.setPitch(this.pitch)
        },
        bearing() {
            if (!this.map) return
            this.map.setBearing(this.bearing)
        },
        activeMarker(newVal) {
            if (LOGGING) console.log('activeMarker', newVal)
            if (!this.map) return
            this.setActiveMarker(newVal)
        },
        userPosition(newVal, oldVal) {
            if (LOGGING) console.log('userPosition', newVal)
            if (!this.map) return
            this.setUserPosition(newVal)
        },
        userRadius(newVal, oldVal) {
            if (LOGGING) console.log('userRadius', newVal)
            if (!this.map) return
            this.setUserCircle(newVal)
        },
        padding(newVal, oldVal) {
            if (LOGGING) console.log('padding', newVal)
            if (!this.map) return
            this.map.setPadding(newVal, this.autoZoom)
        },
    },

    mounted() {
        import('../../../es6/src/modules/map/map.js').then(({ interactiveMap }) => {
            this.map = new interactiveMap({
                zoom: this.autoZoom ? null : this.zoom,
                pitch: this.pitch,
                bearing: this.bearing,
                autoZoom: this.autoZoom,
                fitOptions: {
                    padding: 50,
                },
                container: this.$refs.container,
                markers: this.markers,
                polygons: this.polygons,
                center: this.autoZoom ? null : this.center || this.userPosition,
                padding: this.padding,
                ...this.mapEvents,
                ...this.options,
                load: this.mapLoaded,
            })
        })
    },

    methods: {
        mapLoaded() {
            this.setUserPosition(this.userPosition)
            this.setActiveMarker(this.activeMarker)
            if (this.drawControl) {
                this.addDrawControl()
            }
        },
        setActiveMarker(marker) {
            this.map.unsetActiveMarkers()
            if (!marker) {
                return
            }
            const m = this.map.getMarkerById(marker)
            if (m) {
                m.setActive(true)
            }
        },
        setUserPosition(position) {
            if (!userMarker && position) {
                // Create user marker
                const self = this
                let options = {
                    lat: position.lat,
                    lng: position.lng,
                    label: null,
                    icon: mapMarkers.USER_UNLABELED,
                }

                if ('onUserPositionChange' in this.$attrs) {
                    options.draggable = true
                    options.dragend = function () {
                        const position = this.getPosition()
                        self.$emit('user-position-change', {
                            lat: position[0],
                            lng: position[1],
                        })
                        this.map.panTo(position[0], position[1])
                    }
                    options.drag = function (e) {
                        if (userCircle) {
                            userCircle.setCenter(this.getPosition())
                        }
                    }
                }
                userMarker = this.map.renderMarker(options)
                if (this.userRadius) {
                    this.setUserCircle(this.userRadius)
                }
            } else if (position) {
                // Move marker to new position
                const currentPosition = userMarker.getPosition()
                if (
                    Math.abs(currentPosition[0] - position.lat) > 0.00001 ||
                    Math.abs(currentPosition[1] - position.lng) > 0.00001
                ) {
                    // Move marker but skip very small changes
                    userMarker.setPosition(position.lat, position.lng)
                    if (userCircle) {
                        userCircle.setCenter(position.lat, position.lng)
                    }
                }
            } else if (userMarker) {
                // Remove marker
                userMarker.remove()
                userMarker = null
                // Remove circle
                if (userCircle) {
                    userCircle.remove()
                    userCircle = null
                }
            }
            if (position) {
                // Center marker, $nextTick to allow MapBox to set zoom etc before
                this.$nextTick(() => this.map.panTo(position.lat, position.lng))
            }
        },
        setUserCircle(radius) {
            if (userCircle && (!radius || !userMarker)) {
                userCircle.remove()
                userCircle = null
                return
            }
            if (userMarker) {
                if (!userCircle) {
                    userCircle = this.map.renderCircleOverlay(
                        userMarker.getLatitude(),
                        userMarker.getLongitude(),
                        radius,
                    )
                } else {
                    userCircle.update(userMarker.getLatitude(), userMarker.getLongitude(), radius)
                }
            }
        },
        panTo(lat, lng, options = {}) {
            this.map.panTo(lat, lng, options)
        },
        resize() {
            this.map.resize()
        },
    },
}
</script>
