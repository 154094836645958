<template>
    <div v-if="data" :class="$style.wrapper">
        <component :is="as" :href="data.url" :class="[$style.hero, { [$style.heroSmall]: small }]">
            <ImageWithSizes
                v-if="data.image?.src"
                :class="$style.image"
                :src="data.image.src"
                :alt="data.image.alt"
                :srcset="data.image.srcset"
            />
            <h2 :class="$style.title">
                {{ data.heading }}
                <ContentFlipper
                    v-if="data.texts && data.texts.length"
                    :class="$style.flipText"
                    :items="data.texts"
                    :index="flipIndex"
                >
                    <template v-slot="{ item, isActive, wasActive }">
                        <span
                            v-if="false"
                            :class="[
                                $style.flipLine,
                                {
                                    [$style.flipLineActive]: isActive,
                                    [$style.flipLineWasActive]: wasActive,
                                },
                            ]"
                        >
                            <span v-for="character in item">
                                {{ character }}
                            </span>
                        </span>
                        <template v-else>{{ item }}</template>
                    </template>
                </ContentFlipper>
            </h2>
            <a
                v-if="data.url"
                :href="data.url"
                class="flat-button flat-button--white flat-button--small flat-button--no-border"
            >
                {{ data.cta }}
            </a>
        </component>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import ImageWithSizes from '../../../../Shared/components/ImageWithSizes.vue'
import ContentFlipper from '../../../../Shared/components/ContentFlipper.vue'
import BaseElement from './BaseElement.vue'

const props = defineProps({
    ...BaseElement.props,
    data: {
        type: Object,
        required: false,
        default: null,
    },
    small: Boolean,
})

const flipIndex = ref(0)

window.setInterval(() => flipIndex.value++, 1500)

const as = computed(() => {
    if (props.data.url) {
        return 'a'
    }

    return 'div'
})
</script>

<style lang="scss" module>
.wrapper {
    padding: var(--element-padding);
}

.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    color: #fff;
    padding: 40px 10px;
    aspect-ratio: 12 / 5;
    overflow: hidden;
    border-radius: 6px;
    min-height: 365px;
}

@media screen and (max-width: 767px) {
    .hero {
        aspect-ratio: auto;
    }
}

.hero::after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(7, 30, 40, 0.2);
}

.heroSmall {
    padding: 30px 30px 32px;
}

.image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.title {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.25em;
    width: 100%;
    text-align: center;
    font-style: italic;
    margin-bottom: 1em;
    max-width: 600px;
}

.flipText {
    font-style: normal;
    line-height: 1.5em;
    font-weight: 800;
    white-space: nowrap;
    text-transform: uppercase;
    display: block;
    font-size: 32px;
}

@media screen and (min-width: 768px) {
    .title {
        font-size: 30px;
    }
    .flipText {
        font-size: 56px;
    }
}

.description {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}

//
//.flipLine > * {
//  display: inline-block;
//  will-change: transform;
//  transform: translateY(80%);
//  transition: transform 0.3s;
//}
//
//.flipLineActive > * {
//  transform: translateY(0);
//}
//.flipLineWasActive > * {
//  transform: translateY(-80%);
//}
//.flipLine {
//  @for $i from 1 to 50 {
//	& > *:nth-child(#{$i}) {
//	  transition-delay: ($i - 1) * 40ms;
//	}
//  }
//}
</style>
