import { watch } from 'vue'
import { useSearchAutocompleteStore } from '../store/searchAutocomplete.js'
import { hideMobileNav } from './src/views/navigation/mobileNav.js'
import { overlayScroll, overlayText } from '@utils/overlay.js'
import { checkVisibility, onContent } from '@utils/html.js'
import { getCookie, setCookie } from '@utils/cookie.js'
import { csrfToken, localeCode } from '@utils/http.js'
import { useLocaleStore } from '../store/locale.js'
import clickOutside from '@utils/clickOutside.js'
import { useCityStore } from '../store/city.js'
import { useUserStore } from '../store/user.js'
import { requireOne } from '@utils/require.js'
import confirm from '@utils/confirm.js'
import { on } from '@utils/events.js'

/**
 * Services
 */
import './src/services/recaptcha.js'

/**
 * Views
 */
import StaticMap from '@modules/map/StaticMap.js'
import '@modules/cta-floater/cta-floater'
import './src/views/navigation'
import './src/views/404.js'

const views = [
    {
        trigger: '.article-profile',
        component: () => import('./src/views/article'),
    },
    {
        trigger: '.user-profile',
        component: () => import('./src/views/user-profile'),
    },
    {
        trigger: '.place-profile',
        component: () => import('./src/views/place'),
    },
    {
        trigger: '#user-messages',
        component: () => import('./src/views/messages'),
    },
    {
        trigger: '.blog-profile',
        component: () => import('./src/views/blog'),
    },
    {
        trigger: '#event-container',
        component: () => import('./src/views/event-calendar/index.js'),
    },
    {
        trigger: '#guide-container',
        component: () => import('./src/views/guides/index.js'),
    },
]

views.forEach((view) => {
    document.querySelectorAll(view.trigger).forEach(() => {
        view.component()
    })
})

/**
 * Popups
 */
import('@modules/popups/citySelect.js')
import('@modules/popups/newsletter.js')
import('@modules/popups/adblock.js')

/**
 * Modules
 */
import { loadPhotoswipeSize, openPhotoswipeGallery } from '@modules/photoswipe/photoswipe.js'
import { dispatchElementHooks, dispatchHooks } from '@modules/hooks/hooks.js'
import { userPhotoGallery } from '@modules/photoswipe/userPhotoGallery.js'
import { identify, trackEvent } from '@modules/tracking/tracking.js'
import { translate } from '@modules/locale/locale.js'
import { auth, initAuth } from '@modules/authentication/authentication.js'
import { storyGallery } from '@modules/gallery/story-gallery.js'
import Gallery, { StepSize } from '@modules/gallery/Gallery.ts'
import momentInterval from '@modules/date/momentInterval.js'
import Accordion from '@modules/accordion/Accordion.js'
import { setNavWidth } from '@modules/header/header.js'
import Eventbus from '@modules/eventbus/Eventbus.js'
import tooltip from '@modules/tooltip/tooltip.ts'
import { LegacyGallery } from '@modules/gallery/LegacyGallery.js'
import { initDynamicVue } from '../vue/index.js'
import datepicker from './src/modules/datepicker/datepicker.js'
import { SponsoredPlaceTracker } from './src/modules/tracking/placeSponsor.ts'

/**
 * Initialization
 */
export const initApp = () => {
    if (!window.globalVars) {
        window.globalVars = { locale: {}, auth: {} }
    }
    const searchAutocompleteStore = useSearchAutocompleteStore()

    onContent()

    Eventbus.on('search-autocomplete:open', () => {
        searchAutocompleteStore.open()
    })

    Eventbus.on('search-autocomplete:close', () => {
        searchAutocompleteStore.close()
    })

    tooltip('*[data-tooltip]')

    document.querySelectorAll('details').forEach((el) => {
        new Accordion(el)
    })

    initPlaceSponsorTracking()
    initDatepicker()
    initPopupBubbleTrigger()
    initStaticMaps()
    initPhoneReveal()
    initPageSubnav(document)
    initTextFadeOverlay(document)
    initScrollFadeOverlay(document)
    initLegacyGallery(document)
    initImageGallery()
    initHooks()
    initPhotoswipeGallery()
    initUserPhotoGallery()
    initUserUpdate()
    initModeration()
    initSignUpButton()
    initLottie()
    initPopupBubble()
    initOverlayScroll()
    initCityChange()
    initLocaleChange()
    initNavWidth()
    initHeaderButtons()
    initMobileNav()
    initPageSubnavScroll()
    initTimeZoneOffset()
    initLocaleLink()
    initUserIdentify()
    initCommunityModeration()
    initWatchers()
    initEventListeners()
    initMomentInterval()
    initRedirectLeave()
    initAuth()
}

Eventbus.on('content', async (el) => {
    await initDynamicVue(el)

    initImageGalleryOn(el)
    initPageSubnav(el)
    initTextFadeOverlay(el)
    initScrollFadeOverlay(el)
    initLegacyGallery(el)
})

const initPlaceSponsorTracking = () => {
    const elements = document.querySelectorAll('[data-place-sponsor]')

    SponsoredPlaceTracker.initSponsoredContentClick(elements)
    SponsoredPlaceTracker.observeSponsoredContent(elements)
}

const initDatepicker = () => {
    document.querySelectorAll('[data-datepicker]').forEach((el) => {
        datepicker(el, {
            range: false,
            dateFormat: 'yy-mm-dd',
        })
    })
}

const initLegacyGallery = (rootEl) => {
    rootEl.querySelectorAll('.place-card__image .image-gallery:not(.initialized)').forEach((el) => {
        new LegacyGallery({
            showControls: window.innerWidth >= 768,
            container: el,
            countControl: false,
            height: window.innerWidth < 768 ? '33.3333%' : '100%',
            visibleCount: window.innerWidth < 768 ? 3 : 1,
            itemMargin: window.innerWidth < 768 ? 3 : 0,
            endless: false,
        })
    })
}

const initPopupBubbleTrigger = () => {
    document.querySelectorAll('.popup-trigger').forEach((el) => {
        el.addEventListener('click', () => {
            const popup = Array.from(el.parentNode.children).find(
                (child) => child !== el && child.classList.contains('popup'),
            )

            if (!popup) {
                return
            }

            if (checkVisibility(popup)) {
                el.classList.remove('popup-trigger--open')
                popup.style.display = 'none'

                if (popup.clickOutsideEvent) {
                    popup.clickOutsideEvent.remove()
                }
            } else {
                el.classList.add('popup-trigger--open')
                popup.style.display = 'block'

                popup.clickOutsideEvent = clickOutside(popup, function (event) {
                    if (event.target.closest('.popup-trigger') !== el) {
                        el.classList.remove('popup-trigger--open')
                        popup.style.display = 'none'
                        document.removeEventListener('click', el.clickOutsideEvent)
                    }
                })
            }
        })
    })
}

const initStaticMaps = () => {
    document.querySelectorAll('.js-static-map').forEach((el) => {
        new StaticMap(el)
    })
}

const initPhoneReveal = () => {
    document.querySelectorAll('.phone-reveal').forEach((el) => {
        el.addEventListener('click', function () {
            el.classList.add('revealed')
        })
    })
}

const initPageSubnav = (el) => {
    el.querySelectorAll('.page-subnav-new').forEach(function (el) {
        const activeEl = el.querySelector('li.active a')

        if (!activeEl) {
            return
        }

        const rect = activeEl.getBoundingClientRect()

        if (rect.left + rect.width > window.innerWidth) {
            el.querySelector('ul').scrollTo(rect.left, 0)
        }
    })
}

const initTextFadeOverlay = (el) => {
    el.querySelectorAll('*[data-text-fade-overlay]').forEach((el) => {
        overlayText(el, el.dataset.textFadeOverlay || null, el.dataset.textFadeOverlayKey || null)
    })
}

const initScrollFadeOverlay = (el) => {
    el.querySelectorAll('*[data-scroll-fade-overlay]').forEach((el) => {
        overlayScroll(el, el.dataset.scrollFadeOverlay || '20%')
    })
}

const initImageGallery = () => {
    initImageGalleryOn(document)

    document.addEventListener('image-gallery:load', (event) => {
        initGallery(event.detail)
    })
}

const initImageGalleryOn = (rootEl) => {
    rootEl.querySelectorAll('[data-image-gallery]').forEach((el) => {
        initGallery(el)
    })
}

const initGallery = (el) => {
    if (!el || el.dataset.overflowGallery === 'true') {
        return
    }

    const element = el.querySelector('.overflow-gallery')

    if (!element) {
        return
    }

    const isMobile = window.matchMedia('(max-width: 767px)').matches && 'ontouchstart' in window

    new Gallery({
        element: element,
        loop: false,
        key: 'overflow-gallery',
        controls: isMobile ? false : 'hover',
        stepSize: StepSize.FULL,
    })

    const storyGalleryEl = el.querySelector('.story-image-gallery')

    if (storyGalleryEl) {
        new storyGallery(storyGalleryEl, {
            autoStart: true,
        })
    }
}

const initHooks = () => {
    on(
        'click',
        window.document,
        ".thook[data-hook='click'],.tchook",
        function (e) {
            dispatchElementHooks(e, e.matchingTarget)
        },
        true,
    )

    on(
        'click',
        window.document,
        '.tctrack,*[data-track]',
        function (e) {
            dispatchHooks(['track-event'], e, e.matchingTarget)
        },
        true,
    )

    if (!('ontouchstart' in window)) {
        document.addEventListener('mouseover', (e) => {
            const target = e?.target

            if (!target || !target.matches) {
                return
            }

            if (target.matches(".thook[data-hook='hover'],.thhook")) {
                dispatchElementHooks(e, target)
            } else if (target.matches('a[data-quicklook]')) {
                dispatchHooks(['quicklook'], e, target)
            }
        })
    }
}

const initPhotoswipeGallery = () => {
    on('click', window.document, 'a.photoswipe', function (e) {
        e.preventDefault()

        loadPhotoswipeSize(this).then(() => {
            openPhotoswipeGallery(this.dataset.gallery, this.href)
        })
    })

    on('mouseover', window.document, 'a.photoswipe', function () {
        loadPhotoswipeSize(this)
    })
}

const initUserPhotoGallery = () => {
    on('click', document, '*[data-user-photos] a.user-photo', function (e) {
        e.preventDefault()

        const el = e.matchingTarget.closest('*[data-user-photos]')

        userPhotoGallery({
            place: el.dataset.placeId,
            review: el.dataset.reviewId,
            first: this.dataset.id,
        })
    })
}

const initUserUpdate = () => {
    const userStore = useUserStore()
    userStore.updateUserInterval()
}

const initModeration = () => {
    const userStore = useUserStore()
    if (userStore.hasPermission('community_manager')) {
        import('@modules/moderation/moderation.js')
    }
}

const initSignUpButton = () => {
    document.getElementById('sign-up-button')?.addEventListener('click', function () {
        auth(null, {
            variation: 'default',
        })
    })
}

const initLottie = () => {
    let hasLottie = false
    const loadLottie = () => {
        if (!hasLottie && document.querySelector('lottie-player')) {
            requireOne('https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js')
            hasLottie = true
        }
    }

    loadLottie()
}

const initPopupBubble = () => {
    document.querySelectorAll(':not(ul).popup.bubble').forEach(function (element) {
        const dummySpan = document.createElement('span')
        dummySpan.classList.add('dummy')
        element.prepend(dummySpan)
    })

    document.querySelectorAll('ul.popup.bubble').forEach(function (element) {
        const dummyListItem = document.createElement('li')
        dummyListItem.classList.add('dummy')
        element.prepend(dummyListItem)
    })
}

const initOverlayScroll = () => {
    document.body.addEventListener('touchstart', function (e) {
        if (!e.target.matches('.overlay-scroll, .overlay-scroll-touch')) {
            return
        }

        const target = e.target
        target.touchY = e.touches[0].clientY

        const touchMove = function (e) {
            const touchY = e.touches[0].clientY
            if (target.dataset.nested) {
                const firstParent = e.target.closest('.overlay-scroll, .overlay-scroll-touch')
                if (firstParent && firstParent !== target) {
                    return
                }
            }
            const direction = Math.sign(target.touchY - touchY)
            if (
                (direction < 0 && target.scrollTop <= 0) ||
                (direction > 0 && target.scrollTop + target.offsetHeight >= target.scrollHeight)
            ) {
                e.preventDefault()
            }
            target.touchY = touchY
        }

        target.addEventListener('touchmove', touchMove)

        const touchEnd = function () {
            target.removeEventListener('touchmove', touchMove)
            target.removeEventListener('touchend', touchEnd)
        }

        target.addEventListener('touchend', touchEnd)
    })

    document.querySelectorAll('.overlay-scroll, .overlay-scroll-touch').forEach(function (element) {
        const parents = element.closest('.overlay-scroll, .overlay-scroll-touch')
        if (parents) {
            parents.dataset.nested = 'true'
        }
    })

    document.querySelectorAll('.overlay-scroll, .overlay-scroll-wheel').forEach(function (element) {
        element.addEventListener('wheel', function (e) {
            const delta = e.deltaY || -e.detail
            const scrollTop = element.scrollTop

            if (scrollTop < 15 && delta < 0) {
                element.scrollTop = 0
                e.preventDefault()
            } else if (scrollTop + element.offsetHeight > element.scrollHeight - 15 && delta > 0) {
                element.scrollTop = element.scrollHeight
                e.preventDefault()
            }
        })
    })
}

const initCityChange = () => {
    const cityStore = useCityStore()
    cityStore.checkBrowserLanguageSupport()

    if (cityStore.cityDidChange) {
        let placeholder, cityName

        if (!document.getElementById('city-selector').offsetParent) {
            placeholder = document.createElement('span')
            placeholder.textContent = cityStore.currentCity.name
            placeholder.style.display = 'none'
            cityName = document.querySelector('#logo svg:first-of-type')
        } else {
            placeholder = document.createElement('span')
            placeholder.textContent = translate('city_changed')
            placeholder.style.display = 'none'
            cityName = document.querySelector('#city-selector .city-selector span:first-of-type')
        }

        cityName.insertAdjacentElement('afterend', placeholder)

        cityName.style.opacity = 0
        setTimeout(function () {
            placeholder.style.display = 'inline'
            setTimeout(function () {
                placeholder.style.opacity = 0
                setTimeout(function () {
                    cityName.style.opacity = 1
                    setTimeout(function () {
                        placeholder.remove()
                    }, 500)
                }, 500)
            }, 2000)
        }, 500)
    }
}

const initLocaleChange = () => {
    const localeStore = useLocaleStore()
    localeStore.setLocale(window.globalVars.locale.code, window.globalVars.fullCode)
}
const initNavWidth = () => {
    setNavWidth()
    window.addEventListener('resize', function () {
        setNavWidth()
    })
}
const initHeaderButtons = () => {
    const siteHeaderProfileButton = document.querySelector('.js-profile-button')
    const siteHeaderListsButton = document.querySelector('.js-lists-button')
    siteHeaderProfileButton?.addEventListener('click', function () {
        trackEvent('Click', {
            category: 'Header',
            label: 'Profile',
        })
        auth(null, {
            variation: 'login',
        })
    })

    const userStore = useUserStore()

    siteHeaderListsButton?.addEventListener('click', function (e) {
        trackEvent('Click', {
            category: 'Header',
            label: 'Lists',
        })

        if (userStore.isLoggedIn) {
            return
        }

        e.preventDefault()

        auth(
            (response) => {
                if (response.user) {
                    location.href = response.user.permalink + 'lists/'
                }
            },
            {
                variation: 'lists',
            },
        )
    })
}
const initMobileNav = () => {
    document.querySelectorAll('#mobile-nav li a.expander').forEach(function (element) {
        element.addEventListener('click', function () {
            // TODO: Add transition to submenu when opening and closing
            const submenu = this.parentNode.querySelector('ul')
            submenu.style.display = checkVisibility(submenu) ? 'none' : 'block'
            this.querySelector('i').classList.toggle('rotate-180')
        })
    })
}
const initPageSubnavScroll = () => {
    document.querySelectorAll('.page-subnav').forEach(function (element) {
        const active = element.querySelector('.active:first-of-type')
        if (active) {
            const rect = active.getBoundingClientRect()
            if (rect.left + rect.width > window.innerWidth) {
                element.querySelector('ul').scrollLeft = rect.left
            }
        }
    })
}
const initTimeZoneOffset = () => {
    if (!getCookie('timeZoneOffset')) {
        const expires = new Date().getTime() + 20 * 60 * 1000
        setCookie('timeZoneOffset', new Date().getTimezoneOffset(), {
            expires: new Date(expires),
            path: '/',
        })
    }
}
const initLocaleLink = () => {
    document.querySelectorAll('.locale-link').forEach(function (element) {
        element.addEventListener('click', function (e) {
            const lang = this.getAttribute('hreflang')
            const localeStore = useLocaleStore()
            if (!lang) {
                return
            }

            if (lang === localeStore.code) {
                e.preventDefault()
                location.href = this.getAttribute('href')
                return
            }

            if (this.getAttribute('rel') !== 'alternate') {
                e.preventDefault()
                const url = this.getAttribute('href')
                hideMobileNav()

                return confirm(
                    translate('confirm_title', null, lang),
                    translate('confirm_translation_not_available', null, lang),
                    translate('confirm_ok', null, lang),
                    translate('confirm_no', null, lang),
                ).then((response) => {
                    if (response) {
                        location.href = url
                    }
                })
            }
        })
    })
}
const initUserIdentify = () => {
    const userStore = useUserStore()
    if (userStore.isLoggedIn) {
        identify(userStore.user)
    }
}
const initCommunityModeration = () => {
    const userStore = useUserStore()
    if (userStore.hasPermission('community_manager')) {
        // Load community.js with dynamic import
        import('@modules/community/community.js')
    }
}
const initWatchers = () => {
    const userStore = useUserStore()
    const localeStore = useLocaleStore()

    watch(
        () => userStore.token,
        (token) => {
            csrfToken.value = token
        },
    )
    watch(
        () => localeStore.code,
        (val) => {
            localeCode.value = val
        },
    )
}

const initEventListeners = () => {
    Eventbus.on('place-list:saved', () => {
        const userStore = useUserStore()
        userStore.updateUser(true)
    })
    Eventbus.on('review:saved', () => {
        const userStore = useUserStore()
        userStore.updateUser(true)
    })
}

const initRedirectLeave = () => {
    on(
        'click',
        window.document,
        'a.referral-page',
        (e) => {
            const originalUrl = new URL(e.matchingTarget.href)
            const currentUrl = new URL(window.location.href)

            if (originalUrl.hostname !== currentUrl.hostname) {
                const originalHref = e.matchingTarget.href
                e.matchingTarget.href = '/leave/?to=' + encodeURIComponent(originalHref)
                window.setTimeout(() => (e.matchingTarget.href = originalHref), 500)
            }
        },
        true,
    )
}

const initMomentInterval = () => {
    momentInterval()
}
