<template>
    <li class="place-list__item place-list__item--skeleton">
        <div>
            <div class="place-list__item-head">
                <h2 class="place-list__item-title">
                    <span :style="{ width: titleWidth + 'px' }">&nbsp;</span>
                </h2>
            </div>
        </div>
    </li>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {}
    },

    computed: {
        titleWidth() {
            return 50 + Math.floor(Math.random() * 300)
        },
        subtitleWidth() {
            return this.titleWidth * 0.8
        },
    },

    watch: {},

    created() {},

    destroyed() {},
}
</script>
