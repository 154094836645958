<template>
    <HeroElement v-if="props.data" :class="$style.hero" v-bind="props.data" />
    <HeroElementSkeleton v-else v-bind="props.configuration" />
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>
<script setup>
import HeroElement from './HeroElement.vue'
import HeroElementSkeleton from './HeroElementSkeleton.vue'
import BaseElement from './BaseElement.vue'

const props = defineProps({
    ...BaseElement.props,
    data: {
        type: Object,
        required: false,
        default: null,
    },
})
</script>

<style module>
.hero {
    padding: var(--element-padding);
}
</style>
