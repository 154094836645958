<template>
    <span role="button" :class="$style.wrapper" class="link-w-icon" @click="savePlace">
        <i
            class="sli sli-list"
            :class="[
                $style.icon,
                {
                    'sli-outlined': !isSaved,
                    'sli-blue': !isLoggedIn || isSavedChecked,
                    [$style.loading]: isLoggedIn && !isSavedChecked,
                },
            ]"
        ></i>
        <slot />
    </span>
</template>

<script setup>
import { computed, watch } from 'vue'
import { placeList } from '../../../es6/src/modules/place-list/placeList.js'
import { useListsStore } from '../../../store/lists.js'
import { useUserStore } from '../../../store/user.js'

const props = defineProps({
    place: {
        type: Object,
        required: true,
    },
    type: {
        type: String,
        required: false,
        default: null,
    },
})

const listsStore = useListsStore()
const userStore = useUserStore()

const isLoggedIn = computed(() => {
    return userStore.isLoggedIn
})
const isSaved = computed(() => {
    return !isLoggedIn.value ? false : listsStore.isSaved(props.place.id)
})
const isSavedChecked = computed(() => {
    return listsStore.checkedPlaces.includes(props.place.id)
})
const checkPlace = function () {
    if (isSavedChecked.value) return
    listsStore.debounceCheckContainsPlaces([props.place.id])
}
const savePlace = function () {
    return placeList.save(props.place.id)
}
watch(
    () => props.place,
    (newVal) => {
        checkPlace()
    },
    { immediate: true },
)
</script>
<style module>
.wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.icon {
    opacity: 1;
}
.loading {
    opacity: 0;
}
</style>
