<template>
    <div>
        <h3 class="text-center">{{ $t('userPoll.poll_has_ended_title') }}</h3>
        <p class="text-center">
            {{ $t('userPoll.poll_has_ended_date', { date: endDate }) }}
        </p>
        <div v-if="userPoll && userPoll.winner">
            <h3 class="text-center">{{ $t('userPoll.winner') }}</h3>
            <Alternative :alternative="userPoll.winner" />
        </div>
        <div v-if="vote && vote.verified">
            <h4 class="text-center">{{ $t('userPoll.voted_title') }}</h4>
        </div>
    </div>
</template>

<script>
import Alternative from './Alternative.vue'

export default {
    components: {
        Alternative,
    },
    props: {
        userPoll: {
            type: Object,
            required: true,
        },
        vote: {
            type: Object,
            required: false,
            default: null,
        },
    },

    data() {
        return {}
    },

    computed: {
        endDate() {
            return window.moment(this.userPoll.endDate).calendar()
        },
    },

    methods: {},
}
</script>
