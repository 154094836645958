export function appendScriptsToBody(scripts) {
    const newScripts = []
    for (var i = 0; i < scripts.length; i++) {
        const newScript = document.createElement('script')
        newScript.type = scripts[i].type || 'text/javascript'
        newScript.textContent = scripts[i].textContent
        document.body.appendChild(newScript)
        newScripts.push(newScript)
    }
    return Promise.resolve(newScripts)
}

export function extractScriptsFromElement(element) {
    const scripts = []
    element.querySelectorAll('script').forEach((script) => {
        scripts.push(script)
        script.parentNode.removeChild(script)
    })
    return Promise.resolve(scripts)
}
