<template>
    <Teleport to="body">
        <div
            v-show="show"
            class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[5000] tw-flex tw-justify-center tw-overflow-hidden tw-transition-all md:tw-items-center"
            :class="{
                'tw-bg-black/20 tw-backdrop-blur-sm': !isClosing,
                'tw-items-start': fullHeight,
                'tw-items-end': !fullHeight,
            }"
            @click="close"
        >
            <component
                :is="as"
                :open="show"
                class="tw-relative tw-m-0 tw-flex tw-min-h-[40vh] tw-w-full tw-max-w-xl tw-flex-col tw-justify-between tw-overflow-hidden tw-rounded-t-md tw-bg-white tw-text-lg tw-text-inherit md:tw-min-h-0 md:tw-rounded-b-md"
                :class="[
                    activeElementIsInsideDialog || noAnimation ? null : $style.body,
                    isClosing ? $style.bodyClosing : null,
                    {
                        'tw-h-full tw-max-h-[100dvh] md:tw-h-[70dvh] md:tw-max-h-[70dvh]':
                            fullHeight,
                        'tw-max-h-[70dvh]': !fullHeight,
                    },
                ]"
                v-bind="$attrs"
                ref="dialog"
                @click.stop="void"
            >
                <header
                    class="tw-flex tw-w-full tw-min-w-0 tw-flex-shrink-0 tw-flex-grow-0 tw-items-center tw-justify-end tw-overflow-hidden tw-border-b tw-border-b-black/10"
                >
                    <h3
                        v-if="title"
                        v-scroll-fade
                        class="no-scrollbar tw-m-0 tw-flex tw-flex-grow tw-items-center tw-gap-2 tw-overflow-auto tw-whitespace-nowrap tw-px-4 tw-py-4 tw-text-lg tw-font-bold"
                    >
                        <slot name="title-icon" />
                        {{ title }}
                    </h3>
                    <button
                        class="tw-h-12 tw-flex-grow-0 tw-px-4 tw-text-sm tw-font-medium tw-uppercase"
                        type="button"
                        @click="close"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                        >
                            <g fill="none" fill-rule="evenodd" stroke-linecap="round">
                                <g stroke="#3D3D3D" stroke-width="1.5">
                                    <g>
                                        <path
                                            d="M1 15L15 1M1 1L15 15"
                                            transform="translate(-339 -116) translate(339 116)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </header>
                <slot name="before" />
                <div v-scroll-fade class="tw-flex tw-flex-grow tw-flex-col tw-overflow-auto">
                    <slot />
                </div>
                <slot name="after" />
            </component>
        </div>
    </Teleport>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import vScrollFade from '../../directives/scrollFade.js'

const props = defineProps({
    as: {
        type: String,
        default: 'dialog',
    },
    title: {
        type: String,
        default: null,
    },
    isClosing: {
        type: Boolean,
        default: false,
    },
    fullHeight: {
        type: Boolean,
        default: false,
    },
    show: {
        type: Boolean,
        default: false,
    },
    noAnimation: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['close'])
const close = () => {
    emit('close')
}
const dialog = ref(null)
const activeElementIsInsideDialog = ref(false)
const visualViewportHeight = ref(null)
const visualViewportMaxHeight = computed(() => {
    if (visualViewportHeight.value) {
        return {
            paddingBottom: visualViewportHeight.value + 'px',
        }
    }
    return null
})
const updateMaxHeightIfVisualViewportHeightChanges = () => {
    if (window.outerHeight !== window.visualViewport.height) {
        visualViewportHeight.value = window.outerHeight - window.visualViewport.height
    } else {
        visualViewportHeight.value = null
    }
}
const bindVisualViewportHeight = () => {
    if ('visualViewport' in window) {
        window.visualViewport.addEventListener(
            'resize',
            updateMaxHeightIfVisualViewportHeightChanges,
        )
        updateMaxHeightIfVisualViewportHeightChanges()
    }
}
const unbindVisualViewportHeight = () => {
    if ('visualViewport' in window) {
        window.visualViewport.removeEventListener(
            'resize',
            updateMaxHeightIfVisualViewportHeightChanges,
        )
    }
}
onMounted(() => {
    activeElementIsInsideDialog.value = !!dialog.value?.contains(document.activeElement)
    bindVisualViewportHeight()
})
onBeforeUnmount(() => {
    unbindVisualViewportHeight()
})
</script>

<style module>
@keyframes body-appear {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes body-appear-md {
    from {
        opacity: 0;
        transform: translateY(40px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes body-disappear {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}
@keyframes body-disappear-md {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(40px);
    }
}
.body {
    transform: translateY(100%);
    animation: body-appear 0.3s cubic-bezier(0, 1.22, 0.33, 1.02) forwards;
}
.bodyClosing {
    animation: body-disappear 0.4s cubic-bezier(0, 1.22, 0.33, 1.02) forwards;
}
@screen md {
    .body {
        opacity: 0;
        transform: translateY(40px);
        animation: body-appear-md 0.2s cubic-bezier(0, 1.22, 0.33, 1.02) forwards;
    }
    .bodyClosing {
        animation: body-disappear-md 0.4s cubic-bezier(0, 1.22, 0.33, 1.02) forwards;
    }
}
</style>
