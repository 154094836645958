<script setup>
import { popup } from '@modules/popup/popup.js'
import { getRibbon } from '@/vue/components/user-trophy/utils.js'
import UserTrophy from '@/vue/components/user-trophy/UserTrophy.vue'
import { nextTick, ref, watch } from 'vue'
import { useUserStore } from '@/store/user.js'

const userStore = useUserStore()

const open = (el, trophy) => {
    popup({
        content: el,
        cssClass: 'user-trophy-message',
        ribbon: getRibbon(trophy),
        separateBody: true,
        width: 350,
        queue: true,
    })
}

const trophyEl = ref([])

watch(
    () => userStore.trophiesToShow,
    (val) => {
        if (!val.length) return

        nextTick(() => {
            val.forEach((trophy, index) => {
                const el = trophyEl.value[index]
                open(el, trophy)
            })
        })
    },
)
</script>

<template>
    <div :class="$style.wrapper">
        <div v-for="(trophy, index) in userStore.trophiesToShow" ref="trophyEl">
            <UserTrophy :trophy="trophy" :username="userStore.user.username" />
        </div>
    </div>
</template>

<style module>
.wrapper {
    display: none;
    visibility: hidden;
    opacity: 0;
}
</style>
