import { currentHeaderHeight } from '../modules/header/header.js'

export const showBlind = function (element, duration = null, display = 'block') {
    const currentStyle = window.getComputedStyle(element)

    const cleanupStyle = {
        paddingTop: currentStyle.paddingTop,
        paddingBottom: currentStyle.paddingBottom,
        minHeight: currentStyle.minHeight,
    }
    if (duration !== null) {
        element.style.transitionProperty =
            currentStyle.transitionProperty + ', height, min-height, padding'
        element.style.transitionDuration = duration + 'ms'
    }

    const willChange =
        currentStyle.willChange === 'auto'
            ? []
            : [...currentStyle.willChange.split(',').map((property) => property.trim())]
    if (!willChange.includes('height')) {
        willChange.push('height')
    }
    if (!willChange.includes('padding')) {
        willChange.push('padding')
    }
    if (!willChange.includes('min-height')) {
        willChange.push('min-height')
    }
    element.style.willChange = willChange.join(',')
    element.style.height = '0'
    element.style.minHeight = '0'
    element.style.paddingTop = '0'
    element.style.paddingBottom = '0'
    element.style.display = display
    element.style.overflow = 'hidden'

    return new Promise((resolve) => {
        window.requestAnimationFrame(() => {
            const expandedHeight =
                element.scrollHeight +
                parseInt(cleanupStyle.paddingTop) +
                parseInt(cleanupStyle.paddingBottom)

            element.style.display = null
            element.style.height = expandedHeight + 'px'
            element.style.minHeight = cleanupStyle.minHeight
            element.style.paddingTop = cleanupStyle.paddingTop
            element.style.paddingBottom = cleanupStyle.paddingBottom

            const cleanup = () => {
                element.style.minHeight = null
                element.style.overflow = null
                element.style.height = null
                element.style.paddingTop = null
                element.style.paddingBottom = null
                element.removeAttribute('hidden')
                resolve(element)
            }
            if (currentStyle.transitionDuration !== '0s') {
                const onEnd = () => {
                    cleanup()
                    element.removeEventListener('transitionend', onEnd)
                }
                element.addEventListener('transitionend', onEnd)
            } else {
                cleanup()
            }
        })
    })
}

export const hideBlind = function (element, duration = null) {
    const currentStyle = window.getComputedStyle(element)

    if (duration !== null) {
        element.style.transitionProperty =
            currentStyle.transitionProperty + ', height, min-height, padding'
        element.style.transitionDuration = duration + 'ms'
    }
    const willChange =
        currentStyle.willChange === 'auto'
            ? []
            : [...currentStyle.willChange.split(',').map((property) => property.trim())]
    if (!willChange.includes('height')) {
        willChange.push('height')
    }
    if (!willChange.includes('padding')) {
        willChange.push('padding')
    }
    if (!willChange.includes('min-height')) {
        willChange.push('min-height')
    }
    element.style.height = '0'
    element.style.minHeight = '0'
    element.style.paddingTop = '0'
    element.style.paddingBottom = '0'
    element.style.overflow = 'hidden'

    return new Promise((resolve) => {
        const cleanup = () => {
            element.style.minHeight = null
            element.style.overflow = null
            element.style.height = null
            element.style.paddingTop = null
            element.style.paddingBottom = null
            element.style.display = 'none'
            element.setAttribute('hidden', '')
            resolve(element)
        }

        if (currentStyle.transitionDuration !== '0s') {
            const onEnd = () => {
                cleanup()
                element.removeEventListener('transitionend', onEnd)
            }
            element.addEventListener('transitionend', onEnd)
        } else {
            cleanup()
        }
    })
}

export const fadeOut = (element, duration = 400) => {
    return new Promise((resolve) => {
        let opacity = 1
        const intervalId = setInterval(() => {
            opacity -= 0.05
            element.style.opacity = opacity
            if (opacity <= 0) {
                clearInterval(intervalId)
                element.style.display = 'none'
                resolve()
            }
        }, duration / 20)
    })
}

window.hideBlind = hideBlind

export const scrollTo = (x, y) => {
    return window.scrollTo(x, Math.max(0, y - currentHeaderHeight()))
}

/**
 * scroll smoothly to a element in the DOM.
 * @param elem element to scroll to
 * @param offset int offset pixels from element (default is 0)
 * @returns promise that gets resolved when scrolling is complete
 */
export function slideTo(elem, offset = 0) {
    if (typeof elem === 'string') {
        elem = window.document.querySelector(elem)
    }

    if (!elem) {
        return Promise.reject(new Error('Element not found'))
    }

    const rect = elem.getBoundingClientRect()
    const targetPosition = rect.top + window.pageYOffset + offset

    // If we're already at the target position, resolve immediately
    if (Math.abs(window.pageYOffset - targetPosition) < 1) {
        return Promise.resolve()
    }

    window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
    })

    return new Promise((resolve) => {
        let lastPosition = window.pageYOffset
        let samePositionCount = 0
        const threshold = 1 // Pixel threshold for considering scroll complete

        const scrollHandler = () => {
            const currentPosition = window.pageYOffset

            // Check if we're close enough to target
            if (Math.abs(currentPosition - targetPosition) < threshold) {
                cleanup()
                resolve()
                return
            }

            // Check if we've stopped moving
            if (Math.abs(currentPosition - lastPosition) < threshold) {
                samePositionCount++
                if (samePositionCount >= 3) {
                    // Check if position remained same for 3 consecutive checks
                    cleanup()
                    resolve()
                    return
                }
            } else {
                samePositionCount = 0
            }

            lastPosition = currentPosition
        }

        // Cleanup function to remove listeners and clear timeout
        const cleanup = () => {
            window.removeEventListener('scroll', scrollHandler)
            clearTimeout(failsafeTimeout)
        }

        // Failsafe timeout - resolve after 1000ms if scroll hasn't completed
        const failsafeTimeout = setTimeout(() => {
            cleanup()
            resolve()
        }, 1000)

        window.addEventListener('scroll', scrollHandler, { passive: true })
    })
}
