<template>
    <div :class="$style.user" v-if="localUser">
        <UserArtwork :user="localUser" :class="$style.artwork" link />

        <div>
            <a :href="localUser.permalink" :title="localUser.name" :class="$style.title">
                {{ localUser.name }}
            </a>

            <div class="-tw-ml-1 tw-mt-0.5 tw-flex tw-items-center tw-text-xs">
                <i
                    class="icon--rank-level middle x18 tw-h-8"
                    :class="[$style.rankLevel, 'level-' + localUser.rankLevel]"
                ></i>

                <span class="tw-font-semibold tw-uppercase tw-tracking-wide tw-text-blue-950">
                    {{ localUser.rankTitle }}
                </span>

                <span class="tw-ml-2 tw-font-bold tw-text-blue-600">
                    {{ cityStore.formatNumber(localUser.userXP) }}p
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import UserArtwork from '../UserArtwork.vue'
import { useUserStore } from '../../../../store/user.js'
import { useCityStore } from '../../../../store/city.js'

const props = defineProps({
    user: {
        type: Object,
        required: false,
    },
    subtitle: {
        type: String,
        required: false,
        default: null,
    },
})

const userStore = useUserStore()
const cityStore = useCityStore()

const localUser = computed(() => {
    return props.user || userStore.user
})
</script>

<style module>
.user {
    display: flex;
}
.artwork {
    height: 57px;
    width: 57px;
    margin-right: 13px;
}
.title {
    font-weight: bold;
    font-size: 22px;
    color: var(--body-font-color);
}
.rankLevel {
    width: 22px;
    height: 22px;
}
</style>
