<template>
    <aside class="inspo-box">
        <div class="inspo-box__content">
            <div>
                <!-- Title Desktop -->
                <h4
                    class="inspo-box__title"
                    :class="{
                        'd-hidden-md-down': $te(`authForm.inspo_box.${variation}.mobile.title`),
                    }"
                >
                    {{ $t(`authForm.inspo_box.${variation}.title`) }}
                </h4>

                <!-- Title Mobile -->
                <h4
                    v-if="$te(`authForm.inspo_box.${variation}.mobile.title`)"
                    class="inspo-box__title d-hidden-md-up"
                >
                    {{ $t(`authForm.inspo_box.${variation}.mobile.title`) }}
                </h4>

                <!-- Body Desktop -->
                <p
                    v-if="$te(`authForm.inspo_box.${variation}.body`)"
                    :class="{
                        'd-hidden-md-down': $te(`authForm.inspo_box.${variation}.mobile.body`),
                    }"
                    v-html="$t(`authForm.inspo_box.${variation}.body`)"
                ></p>

                <!-- Body Mobile -->
                <p
                    v-if="$te(`authForm.inspo_box.${variation}.mobile.body`)"
                    :class="{
                        'd-hidden-md-up': $te(`authForm.inspo_box.${variation}.body`),
                    }"
                    v-html="$t(`authForm.inspo_box.${variation}.mobile.body`)"
                ></p>
            </div>

            <!-- List Desktop -->
            <ul
                class="inspo-box__list"
                :class="{
                    'd-hidden-md-down': $te(`authForm.inspo_box.${variation}.mobile.usps`),
                }"
                v-if="$te(`authForm.inspo_box.${variation}.usps`)"
            >
                <li v-for="usp in $t(`authForm.inspo_box.${variation}.usps`)">
                    <i class="icon--check icon--check--white"></i>
                    {{ usp }}
                </li>
            </ul>

            <!-- List Mobile -->
            <ul
                class="inspo-box__list"
                :class="{
                    'd-hidden-md-up': $te(`authForm.inspo_box.${variation}.usps`),
                }"
                v-if="$te(`authForm.inspo_box.${variation}.mobile.usps`)"
            >
                <li v-for="usp in $t(`authForm.inspo_box.${variation}.mobile.usps`)">
                    <i class="icon--check icon--check--white"></i>
                    {{ usp }}
                </li>
            </ul>
        </div>
        <figure v-if="imageName" class="inspo-box__image">
            <img :srcset="imageSrcSet" :src="imageSrc" />
        </figure>
    </aside>
</template>
<script>
const usps = ['free', 'no_ads', 'reviews', 'lists']
export default {
    props: {
        variation: {
            type: String,
            required: false,
            default: 'default',
        },
    },
    computed: {
        imageName() {
            const key = `authForm.inspo_box.${this.variation}.image`
            return this.$te(key) ? this.$t(key) : null
        },
        imageSrc() {
            return `/media/img/auth/${this.imageName}.png`
        },
        imageSrcSet() {
            return [1, 2, 3]
                .map((size) => {
                    return `/media/img/auth/${this.imageName}${
                        size > 1 ? '@' + size + 'x' : ''
                    }.png ${size}x`
                })
                .join(',')
        },
        usps() {
            return usps
        },
    },
}
</script>
