<template>
    <div>
        <header class="navigation-header">
            <a
                :href="placeList.creator.permalink + 'lists/'"
                :title="
                    $t(placeList.isCreator ? 'placeList.my_lists' : 'placeList.user_lists', {
                        ...placeList.creator,
                    })
                "
                class="d-flex ai-center"
            >
                <i class="icon icon--chevron rotate-90"></i>
                <span>
                    {{
                        $t(placeList.isCreator ? 'placeList.my_lists' : 'placeList.user_lists', {
                            ...placeList.creator,
                        })
                    }}
                </span>
            </a>
            <a
                v-if="placeList.canEdit"
                role="button"
                :title="$t('placeList.place_add_new')"
                @click="$emit('edit')"
            >
                <i class="sli sli-plus sli-blue sli-x15 pr-3"></i>
                {{ $t('placeList.place_add_new') }}
            </a>
            <a
                v-else-if="placeList.canFollow && !placeList.isFollowing"
                role="button"
                :title="$t('placeList.follow_list')"
                @click="$emit('follow')"
            >
                {{ $t('placeList.follow_list') }}
            </a>
            <a
                v-else-if="placeList.canFollow"
                role="button"
                :title="$t('placeList.following_list')"
                @click="$emit('unfollow')"
            >
                <i class="icon icon--check icon--check--blue mr-1" style="width: 14px"></i>
                {{ $t('placeList.following_list') }}
            </a>
        </header>
        <div class="place-list__content overlay-scroll-touch">
            <div class="place-list__information" v-if="!isLoading">
                <div class="place-list__information-header">
                    <ul
                        v-if="!isLoading"
                        class="place-list__settings-button float-right position-relative"
                    >
                        <li>
                            <a role="button" class="tchook" data-action="dropdown">
                                <i class="icon--ellipsis-circle"></i>
                            </a>
                            <ul class="popup shadow bubble right hidden text-center drop-in">
                                <li>
                                    <a
                                        role="button"
                                        class="tchook"
                                        data-action="share"
                                        :data-url="placeList.permalink"
                                        :title="$t('placeList.common.share')"
                                    >
                                        {{ $t('placeList.common.share') }}
                                    </a>
                                </li>
                                <li v-if="!placeList.canEdit">
                                    <a
                                        role="button"
                                        style="color: red"
                                        class="tchook"
                                        data-action="report"
                                        data-type="user-list"
                                        :data-id="placeList.id"
                                        :title="$t('placeList.common.report')"
                                    >
                                        {{ $t('placeList.common.report') }}
                                    </a>
                                </li>
                                <li v-if="placeList.canEdit">
                                    <a
                                        href=""
                                        @click.prevent="$emit('edit')"
                                        :title="$t('placeList.common.edit')"
                                    >
                                        {{ $t('placeList.common.edit') }}
                                    </a>
                                </li>
                                <li
                                    v-if="userStore.hasPermission('community_manager')"
                                    class="separator--top"
                                >
                                    <a
                                        role="button"
                                        style="color: red"
                                        class="tchook"
                                        data-action="moderation-action"
                                        data-action-type="delete"
                                        data-type="user-place-list"
                                        :data-id="placeList.id"
                                        :title="$t('placeList.common.delete')"
                                        :data-redirect="placeList.creator.permalink + 'lists/'"
                                    >
                                        {{ $t('placeList.common.delete') }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div class="place-list__title-container d-flex flex-wrap jc-space-between">
                        <div>
                            <h1 class="place-list__title">
                                {{ placeList.name }}
                            </h1>
                            <div
                                v-if="filteredOwners.length === 1 || placeList.dateUpdated"
                                class="place-list__subtitle"
                            >
                                <span v-if="filteredOwners.length === 1">
                                    {{ $t('placeList.list_by') }}
                                    <a
                                        :href="filteredOwners[0].permalink"
                                        :title="filteredOwners[0].name"
                                    >
                                        {{ filteredOwners[0].name }}
                                    </a>
                                </span>
                                <span v-if="filteredOwners.length === 1 && placeList.dateUpdated">
                                    &middot;
                                </span>
                                <time v-if="placeList.dateUpdated" class="place-list__update-date">
                                    {{
                                        $t('placeList.last_updated', {
                                            dateUpdated: dateUpdatedString,
                                        })
                                    }}
                                </time>
                            </div>
                        </div>
                        <div class="artwork-stack" v-if="filteredOwners.length > 1">
                            <a
                                href=""
                                v-if="placeList.canEdit"
                                @click.prevent="$emit('edit')"
                                class="artwork dark-slate-blue icon--plus-circle small user-artwork"
                            ></a>
                            <a
                                v-for="user in visibleOwners"
                                :href="user.permalink"
                                :title="
                                    user.name +
                                    (user.username === placeList.creator.username
                                        ? ' (' + $t('placeList.creator') + ')'
                                        : '')
                                "
                                class="artwork user-artwork small circle"
                            >
                                <img :src="user.thumbnail" :alt="user.name" />
                            </a>
                            <span
                                v-if="additionalOwnerCount > 0"
                                class="artwork user-artwork small circle"
                                :data-annotation="'+' + additionalOwnerCount"
                            >
                                <img
                                    v-if="additionalOwner"
                                    :src="additionalOwner.thumbnail"
                                    :alt="additionalOwner.name"
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div ref="placeListDescription">
                    <nl2br
                        v-if="placeList.description"
                        tag="p"
                        :text="placeList.description"
                        class-name="place-list__description"
                    />
                </div>
            </div>
            <PlaceListInformationSkeleton v-if="isLoading" />
            <form v-if="!isLoading && !isEmpty" class="place-list__filter filter-form">
                <div
                    class="d-flex flex-wrap ai-center mt--1"
                    :class="isMobile ? 'jc-space-between' : null"
                >
                    <select
                        class="mt-1 mr-2"
                        :class="sortLoading ? 'loading' : null"
                        name="order"
                        :value="selectedSort"
                        @change="updateSort($event.target.value)"
                    >
                        <option v-for="(label, val) in placeList.sorting" :value="val" :key="val">
                            {{ label }}
                        </option>
                    </select>
                    <BaseFilter
                        class="mt-1"
                        v-if="filters.length && !isMobile"
                        :filters="filters"
                        :filter="filter"
                        @change="updateFilter($event.key, $event.value)"
                    />
                    <div v-if="isMobile" class="mt-1">
                        <a class="d-flex ai-center" role="button" @click="$emit('show-map')">
                            <i class="icon--map icon--map--blue mr-1"></i>
                            {{ $t('placeList.map') }}
                        </a>
                    </div>
                </div>
                <div v-if="isMobile && filters.length">
                    <BaseFilter
                        v-if="filters.length"
                        :filters="filters"
                        :filter="filter"
                        @change="updateFilter($event.key, $event.value)"
                    />
                </div>
            </form>
            <ol class="place-list__list">
                <template v-if="placePage && !isLoadingPlaces">
                    <PlaceListItem
                        v-for="(place, index) in placePage.content"
                        :key="place.id"
                        :place="place"
                        :is-mobile="isMobile"
                        :is-active="place.id === activePlace"
                        :is-highlighted="place.id === highlightedPlace"
                        :user-position="userPosition"
                        :index="index + placePage.paging.offset"
                        :is-close="itemIsClose(index)"
                        @mouseenter="highlight(place)"
                        @mouseleave="unhighlight(place)"
                        @address-click="addressClick(place)"
                        @click="isMobile ? null : addressClick(place)"
                        @appear="itemAppear(index)"
                        @disappear="itemDisappear(index)"
                    />
                    <PlaceListPaging
                        v-if="placePage && !isLoadingPlaces"
                        :place-page="placePage"
                        @next="nextPage"
                        @prev="prevPage"
                    />
                </template>
                <li v-if="!isLoadingPlaces && isEmpty" class="p-2 py-4 text-center">
                    <em>{{ $t('placeList.list_empty') }}</em>
                    🥺
                </li>
                <template v-if="isLoadingPlaces">
                    <PlaceListItemSkeleton />
                    <PlaceListItemSkeleton />
                    <PlaceListItemSkeleton />
                    <PlaceListItemSkeleton />
                    <PlaceListItemSkeleton />
                </template>
            </ol>
        </div>
    </div>
</template>

<script>
import Nl2br from 'vue3-nl2br'
import debounce from 'lodash-es/debounce'
import BaseFilter from '../../../Shared/components/BaseFilter.vue'
import PlaceListItem from './PlaceListItem.vue'
import PlaceListItemSkeleton from './PlaceListItemSkeleton.vue'
import PlaceListInformationSkeleton from './PlaceListInformationSkeleton.vue'
import PlaceListPaging from './PlaceListPaging.vue'
import { overlayText } from '../../../../es6/src/utils/overlay'
import { localeDate } from '../../../../es6/src/modules/locale/locale.js'
import { useUserStore } from '../../../../store/user.js'

export default {
    components: {
        BaseFilter,
        PlaceListItem,
        PlaceListItemSkeleton,
        PlaceListInformationSkeleton,
        PlaceListPaging,
        Nl2br,
    },
    props: {
        placeList: {
            type: Object,
            required: true,
        },
        placePage: {
            type: Object,
            required: false,
            default: null,
        },
        isMobile: {
            type: Boolean,
            required: true,
        },
        options: {
            type: Object,
            required: false,
            default: () => {
                return {}
            },
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        isLoadingPlaces: {
            type: Boolean,
            required: false,
            default: false,
        },
        activePlace: {
            type: String,
            required: false,
            default: null,
        },
        highlightedPlace: {
            type: String,
            required: false,
            default: null,
        },
        userPosition: {
            type: Object,
            required: false,
            default: null,
        },
        isEmpty: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        const userStore = useUserStore()

        return {
            userStore,
        }
    },
    data() {
        return {
            filter: { ...this.options.filter },
            sort: null,
            sortLoading: false,
            itemsVisible: [],
            minItemVisible: null,
            maxItemVisible: null,
        }
    },

    watch: {
        options() {
            this.sort = this.options.sort
            this.filter = { ...this.options.filter }
        },
        placePage() {
            this.itemsVisible = []
        },
    },

    methods: {
        updateFilter(key, value) {
            this.filter = { ...this.filter, [key]: value }
            this.$emit('update-options', {
                filter: this.filter,
                offset: 0,
            })
        },
        updateSort(value) {
            this.sort = value
            if (value === 'distance') {
                this.sortLoading = true
                this.$emit('request-user-location', {
                    then: (pos) => {
                        this.sortLoading = false
                        this.$emit('update-options', {
                            sort: value,
                            lat: pos.lat,
                            lng: pos.lng,
                            offset: 0,
                        })
                    },
                    catch: () => {
                        this.sortLoading = false
                        this.sort = this.options.sort
                    },
                })
            } else {
                this.$emit('update-options', {
                    sort: value,
                    lat: undefined,
                    lng: undefined,
                    offset: 0,
                })
            }
        },
        nextPage() {
            this.$emit('update-options', {
                offset: this.placePage.paging.offset + this.placePage.paging.limit,
            })
        },
        prevPage() {
            this.$emit('update-options', {
                offset: Math.max(0, this.placePage.paging.offset - this.placePage.paging.limit),
            })
        },
        highlight(place) {
            this.$emit('highlight-place', place.id)
        },
        unhighlight(place) {
            this.$emit('unhighlight-place', place.id)
        },
        addressClick(place) {
            if (this.isMobile) {
                this.$emit('show-map', place.id)
            } else {
                this.$emit('highlight-place', place.id)
                this.$emit('map-center-place', place.id)
            }
        },
        itemAppear(index) {
            this.itemsVisible.push(index)
            this.calculateMinMaxItem()
        },
        itemDisappear(index) {
            const arrayIndex = this.itemsVisible.indexOf(index)
            if (arrayIndex > -1) {
                this.itemsVisible.splice(arrayIndex, 1)
                this.calculateMinMaxItem()
            }
        },
        calculateMinMaxItem: debounce(function () {
            const sorted = this.itemsVisible.sort()
            if (sorted.length) {
                this.minItemVisible = sorted[0]
                this.maxItemVisible = sorted[sorted.length - 1]
            }
        }, 100),
        itemIsClose(index) {
            const close = 3
            return this.minItemVisible - close <= index && this.maxItemVisible + close >= index
        },
    },

    computed: {
        selectedSort() {
            return this.sort || Object.keys(this.placeList.sorting)[0]
        },
        filters() {
            const filters = []
            for (const key in this.placeList.filters) {
                if (!this.placeList.filters.hasOwnProperty(key)) {
                    continue
                }
                filters.push({
                    key: key,
                    placeholder: this.$t('placeList.filters.' + key),
                    items: this.placeList.filters[key],
                })
            }

            return filters
        },
        ownerLimit() {
            return this.isMobile ? 7 : 4
        },
        visibleOwners() {
            return this.filteredOwners.slice(
                0,
                this.filteredOwners.length === this.ownerLimit
                    ? this.ownerLimit
                    : this.ownerLimit - 1,
            )
        },
        additionalOwnerCount() {
            return Math.max(0, this.filteredOwners.length - this.visibleOwners.length)
        },
        additionalOwner() {
            const index =
                this.filteredOwners.length === this.ownerLimit
                    ? this.ownerLimit
                    : this.ownerLimit - 1
            const slice = this.filteredOwners.slice(index, index + 1)
            if (slice.length) {
                return slice[0]
            }
            return null
        },
        htmlDescription() {
            if (!this.placeList.description) {
                return null
            }
            return this.placeList.description.replace(/\n/g, '<br/>')
        },

        dateUpdatedString() {
            return this.placeList.dateUpdated
                ? localeDate(this.placeList.dateUpdated, {
                      short: false,
                  })
                : null
        },

        sortedPlaces() {
            return !this.placePage
                ? []
                : this.placePage.content.sort((a, b) => {
                      if (this.sort === 'distance' && !this.sortLoading && this.userPosition) {
                          return (
                              Math.haversine(
                                  a.latitude,
                                  a.longitude,
                                  this.userPosition.lat,
                                  this.userPosition.lng,
                              ) -
                              Math.haversine(
                                  b.latitude,
                                  b.longitude,
                                  this.userPosition.lat,
                                  this.userPosition.lng,
                              )
                          )
                      } else if (this.sort === 'a-z') {
                          return a.name.localeCompare(b.name)
                      } else if (this.sort === 'rating') {
                          return b.rating - a.rating
                      } else if (this.sort === 'latest') {
                          return new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime()
                      } else if (this.sort === 'oldest') {
                          return new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime()
                      } else if (this.sort === 'default') {
                          return a.order - b.order
                      }
                  })
        },

        filteredOwners() {
            return this.placeList.owners.filter((o) => !o.isRemoved)
        },
    },

    created() {
        this.sort = this.options.sort
    },

    mounted() {
        overlayText(this.$refs.placeListDescription, 200)
    },

    destroyed() {},
}
</script>
