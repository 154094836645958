<template>
    <FileUpload
        v-if="userStore.isLoggedIn"
        multiple
        accept="image/jpeg,image/png,image/gif,image/webp"
        :disabled="isSubmitting"
        :title="$t('PlaceRateBox.add_photos')"
        :max-file-size="9990000"
        @add="addImage"
        v-bind="$attrs"
    >
        <template #input-button="{ dragging }">
            <slot :dragging="dragging" />
        </template>
    </FileUpload>
    <div v-else role="button" :title="$t('common.login')" @click="login" v-bind="$attrs">
        <slot :dragging="false" />
    </div>

    <DrawerModal
        v-model="popupOpen"
        as="form"
        @submit.prevent="saveImages"
        :before-close="confirmCloseIfHasImages"
    >
        <div class="tw-p-2">
            <PlaceImages v-model="images" without-button />
        </div>
        <template #after>
            <div class="p-2 tw-flex-shrink-0">
                <FileUpload
                    v-if="userStore.isLoggedIn"
                    multiple
                    accept="image/jpeg,image/png,image/gif,image/webp"
                    :disabled="isSubmitting"
                    :title="$t('PlaceRateBox.add_photos')"
                    :max-file-size="9990000"
                    @add="addImage"
                >
                    <template #input-button="{ dragging }">
                        <button
                            class="flat-button flat-button--white flat-button--small flat-button--full"
                            type="button"
                            :class="{
                                white: !dragging,
                                blue: dragging,
                                disabled: isSubmitting,
                                'is-loading': loadingImages.length > 0,
                            }"
                            :disabled="isSubmitting"
                        >
                            <i
                                class="sli sli-upload sli-outlined tw-ml-2 tw-h-6 tw-w-6"
                                aria-hidden="true"
                            ></i>
                            {{ $t('review.upload_photo_button') }}
                        </button>
                    </template>
                </FileUpload>

                <button
                    type="submit"
                    class="flat-button flat-button--blue flat-button--small flat-button--full tw-mt-2"
                    :class="{ disabled: isSubmitting, 'is-loading': isSubmitting }"
                    :disabled="!canSave"
                >
                    {{ $t('common.save') }}
                </button>
            </div>
        </template>
    </DrawerModal>
    <!-- <teleport v-if="popupOpen" to="body">
        <div ref="popupEl">
            <form @submit.prevent="saveImages">
                <PlaceImages v-model="images" />
                <button
                    type="submit"
                    class="flat-button flat-button--blue flat-button--small flat-button--full tw-mt-2"
                    :class="{ disabled: isSubmitting, 'is-loading': isSubmitting}"
                    :disabled="!canSave"
                >
                    {{ $t('common.save') }}
                </button>
            </form>
        </div>
    </teleport> -->
</template>

<script setup>
import { watch, ref, computed } from 'vue'
import { popup } from '../../../es6/src/modules/popup/popup.js'
import PlaceImages from './PlaceImages.vue'
import FileUpload from '../../Shared/components/FileUpload.vue'
import { $t } from '../../helpers.js'
import http from '../../../es6/src/utils/http.js'
import { useUserStore } from '../../../store/user.js'
import { useDirtyStore } from '../../../store/dirty.js'
import confirm from '@utils/confirm.js'
import DrawerModal from '../drawer-modal/DrawerModal.vue'

defineOptions({
    inheritAttrs: false,
})

const props = defineProps({
    place: {
        type: String,
        required: true,
    },
})

const images = ref([])
const isSubmitting = ref(false)

const userStore = useUserStore()

const popupOpen = ref(false)
const popupEl = ref(null)
const popupInstance = ref(null)

const login = () => {
    userStore.auth(({ wasAsync }) => {
        if (wasAsync) {
            window.location.reload()
        }
    })
}

const addImage = (file) => {
    file.value.temp = true
    images.value = [...images.value, file.value]
    if (!popupOpen.value) {
        popupOpen.value = true
    }
}

// watch(
//     () => popupEl.value,
//     () => {
//         if(popupEl.value) {
//             openPopup()
//         }
//     },
// )

const confirmCloseIfHasImages = async () => {
    if (images.value.length && !(await confirm($t('common.dirty_confirm.title')))) {
        return false
    }
    return true
}

// const openPopup = () => {
//     popupInstance.value = popup({
//         content: popupEl.value,
//         width: '100%',
//         height: 'auto',
//         beforeClose: async () => {
//             if(images.length && !window.confirm($t('confirm_title'))) {
//                 return false
//             }
//             popupOpen.value = false
//         },
//         maxWidth: 500
//     })
// }

const dirtyStore = useDirtyStore()

watch(
    () => images.value,
    (newVal) => {
        console.log('images changed', newVal.length)
        if (!newVal.length && popupInstance.value) {
            // popupInstance.value.close()
            popupOpen.value = false
            dirtyStore.setIsDirty(false, 'place-images-' + props.place)
        }
        if (newVal.length) {
            dirtyStore.setIsDirty(true, 'place-images-' + props.place)
        }
    },
)

watch(
    () => popupOpen.value,
    (newVal) => {
        if (!newVal) {
            dirtyStore.setIsDirty(false, 'place-images-' + props.place)
            images.value = []
        }
    },
)

const validImages = computed(() => {
    return images.value.filter((image) => !image.error && !image.loading)
})

const loadingImages = computed(() => {
    return images.value.filter((image) => image.loading)
})

const canSave = computed(() => {
    return !isSubmitting.value && validImages.value.length > 0 && loadingImages.value.length === 0
})

const saveImages = async () => {
    if (!canSave.value) {
        return
    }
    isSubmitting.value = true
    try {
        await http.post(`/api/place/${props.place}/images`, {
            images: images.value.map((image) => ({
                id: image.id,
                temp: image.temp,
                caption: image.caption,
            })),
        })

        images.value = []
        // popupInstance.value.close()
        dirtyStore.setIsDirty(false, 'place-images-' + props.place)
        popupOpen.value = false
        window.location.reload()
    } catch (error) {
        console.error(error)
    } finally {
        isSubmitting.value = false
    }
}
</script>
