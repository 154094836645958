<template>
    <div class="pretty-form tw-text-center">
        <div class="tw-mb-5">
            <span v-if="!rating" class="description">
                {{ $t('review.rating_text_placeholder') }}
            </span>
            <span
                class="description"
                v-else-if="(showCustomText && ratingText) || (ratingText && !fakeRating)"
            >
                {{ ratingText }}
            </span>
            <span v-else class="description">
                {{ ratingTexts[rating] }}
                <span class="emoji">{{ ratingTextsEmoji[rating] }}</span>
            </span>
        </div>

        <div ref="stars" class="stars">
            <button
                v-for="n of 5"
                :key="n"
                class="star"
                type="button"
                :disabled="disabled"
                @click="setRating(n)"
                @mouseover="mouseOver(n)"
                @mouseout="mouseOut"
            >
                <i
                    :ref="'star-' + n"
                    class="icon--review-star"
                    :class="{ stroke: rating < n }"
                    :style="{ width: size + 'px', height: size + 'px' }"
                ></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RatingStars',
    props: {
        modelValue: {
            type: Number,
            required: false,
            default: 0,
        },
        size: {
            type: Number,
            required: false,
            default: 44,
        },
        ratingText: {
            type: String,
            required: false,
            default: '',
        },
        disabled: Boolean,
    },
    data() {
        return {
            showCustomText: false,
            realRating: this.modelValue,
            fakeRating: null, // This is used for the hover effect
            ratingTexts: {
                1: this.$t('review.rating_1'),
                2: this.$t('review.rating_2'),
                3: this.$t('review.rating_3'),
                4: this.$t('review.rating_4'),
                5: this.$t('review.rating_5'),
            },
            ratingTextsEmoji: {
                1: '😩',
                2: '😕',
                3: '🙂',
                4: '😃',
                5: '👏',
            },
        }
    },

    computed: {
        rating() {
            return this.fakeRating || this.realRating
        },
    },

    watch: {
        modelValue(val) {
            this.realRating = val
        },
        ratingText() {
            this.showCustomText = true
        },
    },

    methods: {
        setRating(n) {
            if (this.disabled) return
            // Remove rating if the same value is selected
            // if (n === this.realRating) {
            // 	return this.realRating = 0
            // }
            const previous = this.realRating
            this.realRating = n

            if (previous !== this.realRating) {
                this.update()
            }

            const animateStar = (i) => {
                this.$refs['star-' + i][0].classList.add('animate')
                if (i === n) {
                    this.$refs['star-' + i][0].classList.add('burst')
                }
            }

            this.removeStarClass('animate', 'burst')

            window.requestAnimationFrame(() => {
                // Stagger animate
                for (let i = 1; i < n + 1; i++) {
                    animateStar(i)
                }
            })
        },

        removeStarClass(...classNames) {
            for (let i = 1; i <= 5; i++) {
                classNames.forEach((className) => {
                    this.$refs['star-' + i][0].classList.remove(className)
                })
            }
        },

        update() {
            this.showCustomText = true
            this.$emit('update:modelValue', this.realRating)
        },

        mouseOver(n) {
            if (this.disabled) return
            this.fakeRating = n
        },

        mouseOut() {
            if (this.disabled) return
            this.fakeRating = null
            this.showCustomText = false
        },
    },
}
</script>

<style lang="scss" scoped>
.star {
    padding-left: 6px;
    padding-right: 6px;
    display: inline-block;

    @for $i from 1 through 5 {
        &:nth-of-type(#{$i}) {
            i {
                animation-delay: #{($i - 1) * 0.1}s;

                &:after {
                    animation-delay: #{0.4 + (($i - 1) * 0.1)}s;
                }
            }
        }
    }
}

.star {
    transition: transform 0.1s ease-in-out;
}

@media (hover: hover) {
    .star:hover:not(:disabled) {
        transform: scale(1.2);
        cursor: pointer;
    }
}

.description {
    font-weight: 600;
    line-height: 1.25rem;
}

.description .emoji {
    font-style: normal;
}
</style>
