<template>
    <div :class="$style.place">
        <a :href="place.permalink" :class="$style.thumbnail">
            <img
                v-if="imageSrc"
                :class="$style.image"
                :src="imageSrc"
                :srcset="imageSrcSet"
                alt=""
            />
            <div v-else class="bg-dark-slate-blue" :class="[$style.imagePlaceholder, $style.image]">
                <i class="icon category large dark-slate-blue" :class="[place.icon]" />
            </div>
        </a>

        <div :class="$style.main">
            <div :class="$style.header">
                <h3 :class="$style.title">
                    <a :href="place.permalink">
                        <span v-if="index">{{ index }}.</span>
                        {{ place.name }}
                    </a>
                </h3>

                <PlaceListSavedIndicator :place="place" />
            </div>

            <div class="review-star-container review-star-container--small">
                <i
                    v-for="index in 5"
                    class="icon--review-star review-star--small"
                    :class="{
                        empty: Math.round(place.rating) < index,
                        half: place.rating >= index - 0.7 && place.rating <= index - 0.3,
                    }"
                ></i>
                <span :class="$style.ratingText">{{ place.rating }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import PlaceListSavedIndicator from './PlaceListSavedIndicator.vue'

const props = defineProps({
    place: {
        type: Object,
        required: true,
    },
    index: {
        type: Number,
        required: false,
        default: null,
    },
})

const imageSrc = computed(() => {
    return props.place.artworkSrc || props.place.thumbnail
})
const imageSrcSet = computed(() => {
    return props.place.artworkSrcSet
})
</script>

<style module>
.place {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.main {
    min-width: 0;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}
.thumbnail {
    width: 100%;
    aspect-ratio: 16 / 9;
    background: #dadada;
    overflow: hidden;
    border-radius: 10px;
}
.image {
    width: 100%;
    height: 100%;
    display: block;
    aspect-ratio: 16 / 9;
    object-fit: cover;
}

.savePlace {
    width: 19px;
    flex-shrink: 0;
    margin-left: 5px;
}

.ratingText,
.title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
}

.title a {
    color: #134154;
}

.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.imagePlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
