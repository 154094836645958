<template>
    <div :class="$style.places">
        <h4 v-if="title" :class="$style.title">{{ title }}</h4>
        <PlaceCardCompact v-for="item in filteredItems" :model-value="item" />
        <button
            v-if="filteredItems.length < items.length && !expanded"
            class="flat-button box shadow flat-button--white flat-button--no-border flat-button--small m-0"
            type="button"
            @click="$emit('update:expanded', !expanded)"
        >
            {{ $t('common.show_more') }}
        </button>
        <a
            v-if="url && (expanded || filteredItems.length === items.length)"
            :href="url"
            class="flat-button box shadow flat-button--white flat-button--no-border flat-button--small"
        >
            {{ $t('common.show_all') }}
        </a>
    </div>
</template>

<script setup>
import PlaceCardCompact from '../../../../Shared/components/PlaceCardCompact.vue'
import { computed } from 'vue'

const props = defineProps({
    expanded: {
        type: Boolean,
        required: false,
        default: false,
    },
    title: {
        type: String,
        required: false,
        default: null,
    },
    url: {
        type: String,
        required: false,
        default: null,
    },
    items: {
        type: Array,
        required: true,
    },
})

const filteredItems = computed(() => {
    if (props.expanded) {
        return props.items
    }

    return props.items.slice(0, 3)
})
</script>

<style module>
.places {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
}
</style>
