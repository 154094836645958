<template>
    <CarouselElement v-if="items.length" :items="items" :title="title" :description="description" />
</template>
<script setup>
import CarouselElement from './CarouselElement.vue'
import { computed, watch } from 'vue'
import CarouselImage from './CarouselImage.vue'
import PlaceReviewCard from './../../../../Shared/components/PlaceReviewCard.vue'
import PlaceListCard from './../../../../Shared/components/PlaceListCard.vue'
import CarouselImageSkeleton from './CarouselImageSkeleton.vue'
import BaseElement from './BaseElement.vue'

const props = defineProps({
    ...BaseElement.props,
    data: {
        type: Object,
        required: false,
        default: null,
    },
})

const items = computed(() => {
    if (props.data === null) {
        const skeletons = []
        for (let i = 0; i < (props.configuration.limit || 3); i++) {
            skeletons.push({
                component: CarouselImageSkeleton,
                props: { index: i },
            })
        }
        return skeletons
    }
    return props.data.items.map((obj) => {
        if (obj.type === 'place-review') {
            return {
                component: PlaceReviewCard,
                props: {
                    placeReview: obj,
                },
            }
        } else if (obj.type === 'user-place-list') {
            return {
                component: PlaceListCard,
                props: {
                    placeList: obj,
                },
            }
        }
    })
})
</script>
