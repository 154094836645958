<template>
    <ul class="user-menu-nav mobile-user-menu overlay-scroll logged-in tw-p-7">
        <li class="clearfix actions-header tw-mb-7">
            <UserStatsHeader />
        </li>

        <li class="user-progress-slider mb-3">
            <UserProgress />
        </li>

        <li>
            <a :href="routes.userProfile">
                <img
                    src="/media/img/streamline-icons/user-outlined.svg"
                    alt=""
                    height="16"
                    width="16"
                />
                <span>{{ $t('mobileUserMenu.user_profile') }}</span>
            </a>
        </li>

        <li>
            <a :href="routes.userLists">
                <img
                    src="/media/img/streamline-icons/list-outlined.svg"
                    alt=""
                    height="16"
                    width="16"
                />
                <span>{{ $t('mobileUserMenu.user_lists') }}</span>
            </a>
        </li>

        <li>
            <a :href="routes.userFeed">
                <img
                    src="/media/img/streamline-icons/feed-outlined.svg"
                    alt=""
                    height="16"
                    width="16"
                />
                <span>{{ $t('mobileUserMenu.user_feed') }}</span>
            </a>
        </li>

        <li>
            <a href="/user/notifications/">
                <span class="notification-count">
                    {{ userStore.notificationCount }}
                </span>
                <img
                    src="/media/img/streamline-icons/alarm-bell-outlined.svg"
                    alt=""
                    height="16"
                    width="16"
                />
                <span>{{ $t('common.notifications') }}</span>
            </a>
        </li>

        <li>
            <a href="/user/messages/">
                <span class="user-message-count">
                    {{ userStore.userMessageCount }}
                </span>
                <img
                    src="/media/img/streamline-icons/messages-bubble-outlined.svg"
                    alt=""
                    height="16"
                    width="16"
                />
                <span>{{ $t('mobileUserMenu.user_messages') }}</span>
            </a>
        </li>

        <li>
            <a href="/user/settings/">
                <img src="/media/img/streamline-icons/settings.svg" alt="" height="16" width="16" />
                <span>{{ $t('common.settings') }}</span>
            </a>
        </li>

        <li v-if="userStore.user.admin">
            <a href="/admin-v2/">
                <img src="/media/img/icon/user-menu/admin.svg" alt="" height="16" width="16" />
                <span>Admin</span>
            </a>
        </li>

        <li>
            <a class="logout" :href="'/logout/?redirect=' + requestUri">
                <img
                    src="/media/img/streamline-icons/log-out-outlined.svg"
                    alt=""
                    height="16"
                    width="16"
                />
                <span>{{ $t('common.logout') }}</span>
            </a>
        </li>
    </ul>
</template>

<script setup>
import { useRoutesStore } from '../../../store/routes.js'
import { useUserStore } from '../../../store/user.js'
import { useCityStore } from '../../../store/city.js'
import UserStatsHeader from '../../Shared/components/user/UserStatsHeader.vue'
import UserProgress from '../../Shared/components/user/UserProgress.vue'

const routesStore = useRoutesStore()
const userStore = useUserStore()
const cityStore = useCityStore()
const routes = routesStore.routes
const requestUri = encodeURIComponent(window.location.pathname + window.location.search)
</script>
