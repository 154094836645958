import http from '../../utils/http.js'
import { roundNumber } from '../../utils/number.js'
import sv from '../../../../../../thatsup/lang/sv/js.json'
import en from '../../../../../../thatsup/lang/en/js.json'
import { computed } from 'vue'
import { useLocaleStore } from '../../../../store/locale.js'

export const languageContent = { sv, en }

export const translationExists = (key) => {
    const localeStore = useLocaleStore()

    return !!languageContent[localeStore.code][key]
}

const text = (key, params, localeCode = null) => {
    const localeStore = useLocaleStore()

    const defaultText = key
    const code = localeCode || localeStore.code
    const value = languageContent[code][key]

    if (!value) {
        return defaultText
    } else {
        if (typeof params === 'string' || typeof params === 'number') {
            return value.replace('{1}', params)
        } else if (typeof params === 'object') {
            return value.replace(/\{([A-Za-z0-9]+)\}/g, function (match, key) {
                return params[key] !== undefined ? params[key] : match
            })
        } else {
            return value
        }
    }
}

export const translate = (key, params = null, localeCode = null) => {
    return text(key, params, localeCode)
}

export const getRemoteText = async (locale, keys) => {
    try {
        const response = await http.get(`/locale/${locale}/text/`, {
            params: {
                k: Array.isArray(keys) ? keys.join(',') : keys,
            },
        })

        return response.data
    } catch (error) {
        console.error('Error fetching remote text:', error)
        throw error
    }
}

export const numberFormat = (number, decimals = 0, forceDecimals = false) => {
    if (typeof number !== 'number') {
        number = parseFloat(number)
    }
    try {
        const localeStore = useLocaleStore()

        return number.toLocaleString(localeStore.code, {
            maximumFractionDigits: decimals,
            minimumFractionDigits: forceDecimals ? decimals : undefined,
        })
    } catch (e) {
        return roundNumber(number, decimals)
    }
}

export const dayName = (dayOfWeek) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    return text(days[dayOfWeek])
}

export const monthNames = computed(() => {
    // Get all month names of the year in long and short format
    const monthNames = new Array(12)
    const monthNamesShort = new Array(12)
    const localeStore = useLocaleStore()

    for (let i = 0; i < 12; i++) {
        const date = new Date(2021, i, 1)
        monthNames[i] = date.toLocaleDateString(localeStore.code, { month: 'long' })
        monthNamesShort[i] = date.toLocaleDateString(localeStore.code, { month: 'short' })
    }

    return { long: monthNames, short: monthNamesShort }
})

export const localeDate = (date, options = {}) => {
    if (!(date instanceof Date)) {
        date = new Date(date)
    }

    options.type = options.type || 'auto'

    // TODO: Replace moment with module import
    if (typeof window.moment !== 'undefined') {
        if (options.type === 'fromNow') {
            return window.moment(date).fromNow(!!options.short)
        } else if (options.type === 'auto') {
            const now = new Date()
            const diff = now.getTime() - date.getTime()
            if (diff < 1000 * 60 * 60 * 24) {
                return window.moment(date).fromNow(!!options.short)
            } else if (diff < 1000 * 60 * 60 * 24 * 7) {
                return window.moment(date).calendar()
            } else {
                return window
                    .moment(date)
                    .format(options.format || 'll' + (options.short ? '' : 'l'))
            }
        } else if (options.type === 'format') {
            return window.moment(date).format(options.format || 'lll')
        } else if (options.type === 'calendar') {
            return window.moment(date).calendar()
        }
    } else {
        return date.toLocaleString()
    }
}
