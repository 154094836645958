<template>
    <div>
        <h3>{{ $t('userPoll.vote_confirm_title') }}</h3>

        <Alternative :alternative="vote.alternative" />

        <button
            @click.prevent="confirm"
            class="flat-button flat-button--blue flat-button--large flat-button--full flat-button--next mb-2"
            :class="{ 'is-loading': isLoading }"
        >
            {{ $t('userPoll.vote_confirm_yes') }}
        </button>
        <button
            @click.prevent="$emit('cancel')"
            class="flat-button flat-button--red flat-button--large flat-button--full mb-2"
        >
            {{ $t('userPoll.vote_confirm_no') }}
        </button>
    </div>
</template>

<script>
import Alternative from './Alternative.vue'

export default {
    components: {
        Alternative,
    },
    props: {
        userPoll: {
            type: Object,
            required: true,
        },
        vote: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isLoading: false,
        }
    },

    computed: {},

    methods: {
        confirm() {
            this.$emit('confirm')
            this.isLoading = true
        },
    },
}
</script>
<style lang="scss"></style>
