<template>
    <div>
        <div v-if="userPoll.configuration.icon" class="text-center">
            <img :src="userPoll.configuration.icon" alt="" />
        </div>
        <h1 class="text-left text-strong">
            {{ userPoll.title }}
        </h1>
        <div v-if="userPoll.description" class-name="text-left" v-html="userPoll.description" />
    </div>
</template>

<script>
import Nl2br from 'vue3-nl2br'
export default {
    components: {
        Nl2br,
    },
    props: {
        userPoll: {
            type: Object,
            required: true,
        },
    },
}
</script>
