<template>
    <component
        v-bind="$attrs"
        class="auth-button auth-button--full auth-button--w-icon mb-2"
        :is="tagName"
        :title="$t('authForm.continue_with_' + provider)"
        :class="'auth-button--' + provider"
        :href="href"
        :rel="href ? 'nofollow' : null"
    >
        <i class="icon--auth" :class="'icon--auth-' + provider"></i>
        {{ $t('authForm.continue_with_' + provider) }}
    </component>
</template>
<script>
export default {
    props: {
        href: {
            type: String,
            required: false,
        },
        provider: {
            type: String,
            required: true,
        },
    },

    computed: {
        tagName() {
            return !!this.href ? 'a' : 'button'
        },
    },
}
</script>
