<template>
    <div class="wrapper" ref="wrapper">
        <ul class="slider">
            <li v-for="image in images" class="item">
                <figure>
                    <a
                        :href="image.src"
                        class="photoswipe"
                        :data-size="
                            image.height && image.width
                                ? [image.width, image.height].join('x')
                                : undefined
                        "
                        :data-caption="image.captionHtml"
                        :data-gallery="gallery"
                    >
                        <img
                            :src="image.src"
                            :srcset="image.srcset"
                            :sizes="
                                image.height && image.width
                                    ? Math.ceil(ratio(image) * wrapperHeight) + 'px'
                                    : undefined
                            "
                        />
                    </a>
                </figure>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        images: {
            type: Array,
            required: true,
        },
        options: {
            type: Object,
            required: false,
            default: () => {
                return {}
            },
        },
        gallery: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            wrapperHeight: null,
        }
    },
    methods: {
        imageLoaded(e) {
            const img = e.target
            if (img.sizes !== '1px') {
                return
            }
            const ratio = img.width / img.height
            img.sizes = Math.ceil(((this.wrapperHeight * ratio) / window.innerWidth) * 100) + 'vw'
        },
        ratio(image) {
            return image.height && image.width ? image.width / image.height : null
        },
    },
    computed: {},
    mounted() {
        const rect = this.$refs.wrapper.getBoundingClientRect()
        this.wrapperHeight = rect.height
    },
}
</script>

<style lang="scss" scoped>
.wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
.slider {
    display: flex;
    height: 100%;
    flex-wrap: nowrap;
}
.item {
    display: block;
    &,
    li,
    figure,
    a {
        height: 100%;
    }

    figure {
        display: block;
        margin: 0;
    }
    a {
        display: block;
    }
    img {
        max-height: 100%;
        display: block;
        border-radius: 6px;
    }
}
</style>
