<template>
    <div
        v-bind="$attrs"
        ref="body"
        class="place-explorer__body-wrapper"
        :class="{
            'place-explorer__body-wrapper--dragging': !!dragOffset,
            'place-explorer__body-wrapper--scroll-offset': hasScrollOffset,
            'place-explorer__body-wrapper--has-tab': !!activeTab,
            'place-explorer__body-wrapper--loading': isLoading,
            [`place-explorer__body-wrapper--tab-${activeTab}`]: !!activeTab,
        }"
        :style="style"
        @touchstart="touchstart"
        @touchmove="touchmove"
        @touchend="touchend"
    >
        <slot v-if="!component" />
        <component ref="componentWrapper" v-else :is="component" />
        <div v-show="isLoading">
            <ol>
                <li>
                    <div class="place-explorer__skeleton">
                        <div class="d-flex">
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--image"
                            ></div>
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--image"
                            ></div>
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--image"
                            ></div>
                        </div>

                        <div class="mt-2">
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--title"
                                style="width: 237px"
                            ></div>
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--title"
                                style="width: 190px"
                            ></div>
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--title"
                                style="width: 180px"
                            ></div>

                            <div class="d-flex gap-1 mt-2">
                                <div
                                    class="place-explorer__skeleton-item place-explorer__skeleton-item--title"
                                    style="width: 138px"
                                ></div>
                                <div
                                    class="place-explorer__skeleton-item place-explorer__skeleton-item--title"
                                    style="width: 111px"
                                ></div>
                            </div>
                        </div>
                    </div>
                </li>

                <li>
                    <div class="place-explorer__skeleton">
                        <div class="d-flex">
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--image"
                            ></div>
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--image"
                            ></div>
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--image"
                            ></div>
                        </div>

                        <div class="mt-2">
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--title"
                                style="width: 237px"
                            ></div>
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--title"
                                style="width: 190px"
                            ></div>
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--title"
                                style="width: 180px"
                            ></div>

                            <div class="d-flex gap-1 mt-2">
                                <div
                                    class="place-explorer__skeleton-item place-explorer__skeleton-item--title"
                                    style="width: 138px"
                                ></div>
                                <div
                                    class="place-explorer__skeleton-item place-explorer__skeleton-item--title"
                                    style="width: 111px"
                                ></div>
                            </div>
                        </div>
                    </div>
                </li>

                <li>
                    <div class="place-explorer__skeleton">
                        <div class="d-flex">
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--image"
                            ></div>
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--image"
                            ></div>
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--image"
                            ></div>
                        </div>

                        <div class="mt-2">
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--title"
                                style="width: 237px"
                            ></div>
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--title"
                                style="width: 190px"
                            ></div>
                            <div
                                class="place-explorer__skeleton-item place-explorer__skeleton-item--title"
                                style="width: 180px"
                            ></div>

                            <div class="d-flex gap-1 mt-2">
                                <div
                                    class="place-explorer__skeleton-item place-explorer__skeleton-item--title"
                                    style="width: 138px"
                                ></div>
                                <div
                                    class="place-explorer__skeleton-item place-explorer__skeleton-item--title"
                                    style="width: 111px"
                                ></div>
                            </div>
                        </div>
                    </div>
                </li>
            </ol>
        </div>
    </div>
</template>
<script>
import { on, off } from '../../../es6/src/utils/events'
import viewport from '../../../es6/src/utils/Viewport'
import { markRaw } from 'vue'
import http from '@utils/http'
import { trackEvent } from '../../../es6/src/modules/tracking/tracking.js'
import Eventbus from '../../../es6/src/modules/eventbus/Eventbus.js'
import { initPlaceGallery } from '../../../es6/src/views/place/gallery.js'
import { SponsoredPlaceTracker } from '../../../es6/src/modules/tracking/placeSponsor.ts'

export default {
    props: {
        placePage: Object,
        sponsoredPlaces: {
            type: Array,
            required: false,
            default: () => [],
        },
        view: String,
        isMobile: Boolean,
        isLoading: Boolean,
        windowHeight: Number,
        pageHeaderHeight: Number,
        html: {
            type: String,
            required: false,
            default: null,
        },
        highlightedPlace: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            titleHeight: 0,

            hasScrollOffset: false,

            dragStart: null,
            dragMove: null,
            dragOffset: 0,
            dragDelta: 0,
            shouldDrag: true,

            draggingBaseOffset: 0,

            activeTab: null,

            component: null,

            highlightedPlaceId: null,
        }
    },
    computed: {
        actualBaseOffset(newVal) {
            if (!this.isMobile || !this.windowHeight) {
                return 0
            }
            switch (this.view) {
                case 'map':
                    return this.titleHeight !== null
                        ? this.windowHeight - this.titleHeight - this.pageHeaderHeight
                        : this.windowHeight * 0.8
                case 'hybrid':
                    return this.windowHeight * (this.activeTab === 'guides' ? 0.1 : 0.2)
                default:
                    return 0
            }
        },
        baseOffset() {
            // We don't want the base offset to change in the middle of dragging
            // This can happen if the viewport changes mid-drag (iOS 15)
            return this.isDragging ? this.draggingBaseOffset : this.actualBaseOffset
        },
        style() {
            if (!this.isMobile) {
                return {}
            }
            return {
                transform: `translateY(${this.baseOffset + this.dragOffset}px)`,
                'min-height': `${
                    this.windowHeight - (this.baseOffset + this.dragOffset) - this.pageHeaderHeight
                }px`,
            }
        },
        isDragging() {
            return !!this.dragOffset
        },
    },
    watch: {
        isDragging(newVal) {
            if (newVal) {
                this.draggingBaseOffset = this.actualBaseOffset
                this.$emit('dragstart')
            } else {
                this.$emit('dragend')
            }
        },
        actualBaseOffset: {
            handler(newVal) {
                this.$emit('base-offset-change', newVal)
            },
            immediate: true,
        },
        html(newVal) {
            this.component = markRaw({
                template: newVal,
                data() {
                    return {
                        isRoot: false,
                    }
                },
                mounted: (a) => {
                    this.setTitleHeight()
                    this.addDescriptionToggle()
                    this.addPhoneNumberToggle()
                    this.observeSponsoredResults()
                    this.initSponsoredResultsClick()
                    this.initGallery()
                    if (this.activeTab) {
                        this.setActiveTab(this.activeTab)
                    }
                    Eventbus.emit('content', this.$refs.componentWrapper.$el)
                },
            })
        },
        activeTab(newVal) {
            this.$emit('tab-change', newVal)
        },
        highlightedPlace(newVal, oldVal) {
            if (this.isMobile) {
                return
            }
            if (oldVal) {
                const elm = this.placeList().querySelector("[data-id='" + oldVal.id + "']")
                if (elm) {
                    elm.classList.remove('place-card--highlight')
                }
            }
            if (newVal) {
                if (newVal.id === this.highlightedPlaceId) {
                    return
                }
                const elm = this.placeList().querySelector("[data-id='" + newVal.id + "']")
                if (elm) {
                    window.requestAnimationFrame(() => {
                        elm.classList.add('place-card--highlight')
                        elm.scrollIntoView({
                            behavior: 'smooth',
                        })
                    })
                }
            }
        },
        highlightedPlaceId(newVal) {
            this.$emit(
                'highlight-place',
                newVal === null
                    ? null
                    : [...this.placePage.data, ...this.sponsoredPlaces].find(
                          (p) => p.id === newVal,
                      ),
            )
        },
        view(newVal) {
            this.$nextTick(() => this.setTitleHeight())
        },
    },
    mounted() {
        this.setTitleHeight()
        this.addDescriptionToggle()
        this.addPhoneNumberToggle()
        this.observeSponsoredResults()
        this.initSponsoredResultsClick()
        this.initGallery()
        this.bindEvents()
    },
    beforeDestroy() {
        this.unbindEvents()
    },
    methods: {
        initGallery() {
            initPlaceGallery(this.$refs.body)
        },
        addPhoneNumberToggle() {
            const phoneRevealElements = this.$refs.body.querySelectorAll('.phone-reveal')
            phoneRevealElements.forEach((el) =>
                el.addEventListener('click', function (e) {
                    el.classList.add('revealed')
                }),
            )
        },
        addDescriptionToggle() {
            const elm = this.$refs.body.querySelector('.place-explorer__description')
            if (!elm) {
                return
            }
            elm.addEventListener('click', (e) => {
                e.preventDefault()
                const text = elm.querySelector('.place-explorer__description__text')
                text.classList.remove('line-clamp-2')
            })
        },
        setTitleHeight() {
            this.titleHeight =
                this.$refs.body.querySelector('.place-explorer__title-container')?.offsetHeight ||
                null
        },
        onGoToUserPosition() {
            this.$emit('go-to-user-position')
        },
        onSetCity(e) {
            this.$emit('set-location', {
                city: e.matchingTarget.dataset.exploreCity,
            })
        },
        bindEvents() {
            viewport.on('change', this.setTitleHeight)
            on('click', this.$refs.body, 'a[rel]', this.onRelClick)
            on('click', this.$refs.body, 'a[data-tab]', this.onTabClick)
            on('click', this.$refs.body, '*[data-go-to-user-position]', this.onGoToUserPosition)
            on('click', this.$refs.body, '*[data-explore-city]', this.onSetCity)
            on('change', this.$refs.body, 'input,select,textarea', this.onInputChange)
            this.$refs.body.addEventListener('mouseenter', this.onMouseEnter, true)
            this.$refs.body.addEventListener('mouseleave', this.onMouseLeave, true)
        },
        unbindEvents() {
            viewport.off('change', this.setTitleHeight)
            off('click', this.$refs.body, 'a[rel]', this.onRelClick)
            off('click', this.$refs.body, 'a[data-tab]', this.onTabClick)
            off('click', this.$refs.body, '*[data-go-to-user-position]', this.onGoToUserPosition)
            off('change', this.$refs.body, 'input,select,textarea', this.onInputChange)
            this.$refs.body.removeEventListener('mouseenter', this.onMouseEnter, true)
            this.$refs.body.removeEventListener('mouseleave', this.onMouseLeave, true)
        },
        onRelClick(e) {
            const rel = e.matchingTarget.rel
            const rels = {
                next: 1,
                prev: -1,
            }
            if (rel in rels) {
                e.preventDefault()
                this.$emit('step-page', rels[rel])
            }
        },
        onTabClick(e) {
            this.setActiveTab(e.matchingTarget.dataset.tab)
        },
        onInputChange(e) {
            this.$emit('input', {
                [e.matchingTarget.name]: e.matchingTarget.value,
            })
        },
        onMouseEnter(e) {
            let id = this.getItemIdFromEvent(e)
            if (id) {
                this.highlightedPlaceId = id
            }
        },
        onMouseLeave(e) {
            const id = this.getItemIdFromEvent(e)
            if (id === this.highlightedPlaceId) {
                this.highlightedPlaceId = null
            }
        },
        placeList() {
            return this.$refs.body.querySelector(
                '.place-explorer__tab[data-tab="places"] .place-explorer__list',
            )
        },
        getItemIdFromEvent(e) {
            const list = this.placeList()
            if (!list || e.target.parentNode !== list) {
                return
            }
            return e.target.dataset.id
        },
        setActiveTab(tab) {
            this.activeTab = tab
            const elms = this.$refs.body.querySelectorAll(`[data-tab]`)
            ;[].forEach.call(elms, (elm) => {
                elm.classList.toggle('active', elm.dataset.tab === tab)
            })
        },
        touchstart(e) {
            if (!this.isMobile) {
                this.dragDelta = 0
                return
            }
            this.hasScrollOffset = false
            this.shouldDrag = true
            let target = e.target
            while (target) {
                if (target === this.$refs.body) {
                    break
                }
                if (['auto', 'scroll'].includes(window.getComputedStyle(target).overflowX)) {
                    this.shouldDrag = false
                }
                target = target.parentNode
            }
            if (!this.shouldDrag) {
                this.dragDelta = 0
                return
            }
            const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
            if (scrollTop <= 0 || this.view === 'map') {
                this.dragStart = e.touches[0].pageY
                this.dragMove = this.dragStart
            }
        },
        touchmove(e) {
            if (!this.isMobile || !this.shouldDrag) {
                this.dragDelta = 0
                return
            }
            const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
            if (this.view === 'map') {
                // Prevent scrolling when in map mode
                e.preventDefault()
            }

            const previous = this.dragMove
            this.dragMove = e.touches[0].pageY

            // Only drag if we're at the top or in map view
            if (previous && (scrollTop <= 0 || this.view === 'map')) {
                // this.isDragging = true;
                this.dragDelta = Math.sign(this.dragMove - previous)

                if (this.dragStart === null) {
                    this.dragStart = this.dragMove
                }
                if (this.dragDelta === 1 && scrollTop <= 0) {
                    // Prevent pull-to-refresh
                    e.preventDefault()
                }
            } else {
                this.dragDelta = 0
            }
            // if(this.dragStart === null && (scrollTop <= 0 || this.view === 'map')) {
            // this.dragStart = this.dragMove;
            // }
            const dragOffset = this.dragMove - this.dragStart
            if ((dragOffset > 0 && scrollTop <= 0) || this.view === 'map') {
                this.dragOffset = dragOffset
            }
        },
        touchend(e) {
            // this.isDragging = false;
            this.dragStart = null
            this.dragMove = null
            if (this.dragDelta === 1) {
                this.$emit('dragdown')
            } else if (this.dragDelta === -1) {
                this.$emit('dragup')
                const baseBefore = this.baseOffset
                const dragOffset = this.dragOffset
                this.$nextTick(() => {
                    const baseChange = baseBefore - this.baseOffset
                    if (baseChange + dragOffset < 0) {
                        this.hasScrollOffset = true
                        // this.$emit('scroll-to', { left: 0, top: -1 * (baseChange + dragOffset) });
                        window.scrollTo(0, -1 * (baseChange + dragOffset))
                    } else {
                        this.hasScrollOffset = false
                    }
                })
            }
            this.dragDelta = 0
            this.dragOffset = 0
        },

        addUtmIfNeeded(link, params) {
            if (!link) return
            const url = new URL(link.href)
            const currentParams = url.searchParams
            for (const key in params) {
                if (params[key] && !currentParams.has(key)) {
                    currentParams.set(key, params[key])
                }
            }
            link.href = url
        },

        initSponsoredResultsClick() {
            const targets = document.querySelectorAll('.place-card--sponsored')

            SponsoredPlaceTracker.initSponsoredContentClick(targets)
        },
        observeSponsoredResults() {
            const targets = document.querySelectorAll('.place-card--sponsored')

            SponsoredPlaceTracker.observeSponsoredContent(targets)
        },
    },
}
</script>
