import { ref } from 'vue'
import viewport from '../../../es6/src/utils/Viewport'

export default function () {
    const width = ref(viewport.width)
    const height = ref(viewport.height)
    viewport.on('change', () => {
        width.value = viewport.width
        height.value = viewport.height
    })
    return {
        width,
        height,
    }
}
