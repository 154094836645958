<template>
    <div class="review-form">
        <div v-if="review.loading">
            <i class="icon icon--loading-ring icon--loading-ring--blue icon--x24"></i>
        </div>
        <div v-else-if="!isEditing && review && review.rating" class="review-thank-you">
            <span class="review-form-container__title">
                {{
                    review.bodyText
                        ? $t('review.thank_you_title')
                        : $t('review.thank_you_title_rating')
                }}
            </span>

            <div class="existing-review">
                <RatingStars
                    v-model="review.rating"
                    :disabled="!!review.bodyText"
                    :size="30"
                    @update:model-value="setRating"
                />

                <div
                    v-if="review.bodyText"
                    class="button-container tw-flex tw-flex-wrap tw-justify-center tw-gap-2"
                >
                    <button
                        type="button"
                        class="flat-button flat-button--small white small tchook tw-mx-0"
                        @click="editReview"
                    >
                        {{ $t('review.update_review') }}
                    </button>
                    <button
                        type="button"
                        @click="clearForm"
                        class="flat-button flat-button--small new-review-button white tw-mx-0"
                    >
                        {{ $t('review.write_new') }}
                    </button>
                </div>

                <div
                    v-else
                    class="button-container tw-flex tw-flex-wrap tw-justify-center tw-gap-2"
                >
                    <button
                        type="button"
                        class="flat-button flat-button--small white small tw-mx-0"
                        @click="editReview"
                    >
                        {{ $t('review.add_review') }}
                        <i class="icon icon--point">+7</i>
                    </button>
                </div>
            </div>
        </div>
        <form v-else class="pretty-form review--rating" @submit.prevent="submitForm">
            <RatingStars
                v-model="editingReview.rating"
                @update:model-value="onRatingUpdate"
                :size="40"
            />

            <div
                class="form-body clearfix"
                style="display: block"
                v-if="editingReview && editingReview.rating"
            >
                <div class="tw-relative">
                    <textarea
                        :placeholder="$t('review.write_a_review')"
                        v-model="editingReview.bodyText"
                        v-autofocus
                        v-auto-height
                        @keydown="onKeydown"
                    ></textarea>
                </div>

                <!-- Response from form submission -->
                <p class="response-message message-container error" v-show="responseMessage">
                    {{ responseMessage }}
                </p>

                <!-- Show reminder to be honest etc. -->
                <div class="content-reminder" v-show="showContentReminder">
                    <button type="button" class="closer" @click="hideContentReminder">
                        <span class="icon--close-x-small"></span>
                    </button>

                    <p class="message-container">
                        {{ $t('review.content_reminder') }}
                        <a href="/guidelines" target="_blank">
                            {{ $t('review.see_our_guidelines_here') }}
                        </a>
                        <br />
                        <br />
                        {{ $t('review.content_reminder_honesty_pays') }}
                    </p>
                </div>

                <!-- Uploaded images list -->
                <FileUpload @add="addImage" multiple accept="image/*" class="tw-grid tw-gap-2">
                    <SortableList
                        v-if="editingReview.images.length > 0"
                        v-model="editingReview.images"
                        class="tw-grid tw-gap-2"
                    >
                        <template #item="{ item, index, isDragging }">
                            <div class="tw-flex tw-items-center tw-gap-2">
                                <span
                                    class="handle tw-flex tw-h-8 tw-w-8 tw-cursor-move tw-items-center tw-justify-center"
                                >
                                    <i class="icon--move-handle black"></i>
                                </span>
                                <div
                                    class="tw-relative tw-h-16 tw-w-16 tw-overflow-hidden tw-rounded-md tw-bg-gray-300"
                                >
                                    <img
                                        v-if="!item.loading"
                                        :src="item.src || item.url"
                                        :srcset="item.srcset"
                                        class="tw-h-full tw-w-full tw-flex-shrink-0 tw-object-cover"
                                    />
                                    <img
                                        v-else-if="item.thumbnail"
                                        :src="item.thumbnail"
                                        class="tw-h-full tw-w-full tw-flex-shrink-0 tw-object-cover tw-blur"
                                    />
                                    <div
                                        v-if="item.loading"
                                        class="is-loading is-loading--white tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-white/30"
                                    ></div>
                                </div>
                                <input
                                    class="tw-flex-grow"
                                    type="text"
                                    v-model="item.caption"
                                    :placeholder="$t('review.image_caption_placeholder')"
                                />
                                <div>
                                    <button
                                        type="button"
                                        class="tw-ml-2 tw-text-red-500"
                                        @click="removeImage(index)"
                                    >
                                        <i class="icon--remove-bin"></i>
                                    </button>
                                </div>
                            </div>
                        </template>
                    </SortableList>
                    <template #input-button="{ dragging }">
                        <button
                            class="flat-button flat-button--white flat-button--small flat-button--full"
                            :class="{ white: !dragging, blue: dragging }"
                        >
                            <i
                                class="sli sli-upload sli-outlined tw-ml-2 tw-h-6 tw-w-6"
                                aria-hidden="true"
                            ></i>
                            {{ $t('review.upload_photo_button') }}
                        </button>
                    </template>
                </FileUpload>

                <div v-if="Object.keys(errors).length" class="tw-text-sm tw-text-red-800">
                    <ul>
                        <li v-for="error in Object.values(errors)">
                            {{ error.join(', ') }}
                        </li>
                    </ul>
                </div>

                <!-- Button to submit the form -->
                <div>
                    <button
                        type="submit"
                        class="flat-button blue submit-button"
                        :class="{ disabled: isSubmitting }"
                    >
                        {{ $t('common.publish') }}
                    </button>
                </div>

                <button
                    type="button"
                    class="abort-button"
                    :title="$t('common.cancel')"
                    @click="cancel"
                >
                    {{ $t('common.cancel') }}
                </button>
            </div>
        </form>
    </div>
</template>

<script setup>
import RatingStars from '../../Shared/components/RatingStars.vue'
import FileUpload from '../../Shared/components/FileUpload.vue'
import confirm from '@utils/confirm.js'
import { nextTick, ref, watch } from 'vue'
import { interaction } from '../../../es6/src/modules/tracking/tracking.js'
import { debounce, once } from 'lodash-es'
import { useReviewsStore } from '../../../store/reviews.js'
import vAutoHeight from '../../directives/autoHeight.js'
import SortableList from '../../Shared/components/SortableList.vue'
import { auth } from '../../../es6/src/modules/authentication/authentication.js'
import { useDirtyStore } from '../../../store/dirty.js'
import { $t } from '../../../vue/helpers.js'

const props = defineProps({
    place: {
        type: String,
        required: true,
    },
})

const reviewStore = useReviewsStore()
const review = reviewStore.getReviewForUser(props.place)
const storageKey = 'place_review_' + props.place

const editingReview = ref({
    bodyText: '',
    rating: null,
    images: [],
})
const isEditing = ref(false)
if (localStorage.getItem(storageKey)) {
    try {
        editingReview.value = {
            ...editingReview.value,
            ...JSON.parse(localStorage.getItem(storageKey)),
        }
        isEditing.value = true
    } catch (e) {
        console.error('Error parsing stored review', e)
        throw e
    }
}
const dirtyStore = useDirtyStore()

const showContentReminder = ref(true)
const errors = ref({})

const editReview = () => {
    isEditing.value = true
    editingReview.value = {
        bodyText: review.value.bodyText || '',
        rating: review.value.rating,
        images: review.value.images,
        id: review.value.id,
    }
}

const setRating = (val) => {
    reviewStore.storeRating(props.place, { ...review.value, rating: val })
}

const responseMessage = ref('')

// Update showContentReminder if the user has already seen the reminder
if (window.localStorage.getItem('review_content_reminder')) {
    showContentReminder.value = false
}

watch(
    () => review.value.isEditing,
    (newVal) => {
        if (!isEditing.value && newVal) {
            editReview()
        }
    },
)

watch(
    () => [editingReview.value.bodyText, editingReview.value.rating, editingReview.value.images],
    debounce((newValue) => {
        if (!newValue || (!newValue[0] && !newValue[1] && !newValue[2].length)) {
            localStorage.removeItem(storageKey)
            dirtyStore.setIsDirty(false, storageKey)
            return
        }
        dirtyStore.setIsDirty(true, storageKey)

        localStorage.setItem(
            storageKey,
            JSON.stringify({
                bodyText: editingReview.value.bodyText,
                rating: editingReview.value.rating,
                images: editingReview.value.images,
            }),
        )
    }, 300),
)

const addImage = (file) => {
    file.value.temp = true
    editingReview.value.images.push(file.value)
}

const removeImage = async (index) => {
    if (!(await confirm($t('confirm_title'), $t('review.image_delete_confirm')))) {
        return
    }
    editingReview.value.images.splice(index, 1)
}

const isSubmitting = ref(false)
function submitForm() {
    isSubmitting.value = true

    return auth(async ({ wasAsync }) => {
        try {
            await reviewStore.storeReview(props.place, {
                bodyText: editingReview.value.bodyText,
                rating: editingReview.value.rating,
                images: editingReview.value.images.map((image) => {
                    return {
                        id: image.id,
                        temp: !!image.temp,
                        caption: image.caption,
                    }
                }),
                id: editingReview.value.id,
            })
            isEditing.value = false
            editingReview.value = {
                bodyText: '',
                rating: null,
                images: [],
                id: null,
            }
            nextTick(() => {
                dirtyStore.setIsDirty(false, storageKey)
                localStorage.removeItem(storageKey)
                if (wasAsync) {
                    // Reload the page to update user state
                    window.location.reload()
                }
            })
        } catch (error) {
            console.error('Error storing review', error)
            if (error?.response?.data?.errors) {
                errors.value = error.response.data.errors
            }
        } finally {
            isSubmitting.value = false
        }
    })
}

function clearStoredReview() {
    localStorage.removeItem(storageKey)
    // storedReview.value = null
}

function clearForm() {
    isEditing.value = true
    editingReview.value = {
        bodyText: '',
        rating: null,
        images: review?.value?.images || [],
        id: null,
    }

    // TODO: Use global dirty state
    dirtyStore.setIsDirty(false, storageKey)

    clearStoredReview()
}

function onKeydown() {
    once(() => {
        interaction('Review - started typing', {
            placeId: props.place,
            rating: reviewStore.currentReview.rating,
        })
    })
}

function cancel() {
    responseMessage.value = ''
    dirtyStore.setIsDirty(false, storageKey)
    clearStoredReview()

    nextTick(() => {
        isEditing.value = false
        review.value.isEditing = false

        editingReview.value = {
            bodyText: '',
            rating: null,
            images: review?.value?.images || [],
            id: null,
        }

        // if (reviewStore.currentReview.id) {
        //     reviewStore.fetchReviewForUser(props.place)
        // } else {
        //     reviewStore.clearReview()
        // }
    })
}

function onRatingUpdate(val) {
    interaction('Review - rating click', {
        placeId: props.place,
        rating: val,
    })
}

function hideContentReminder() {
    window.localStorage.setItem('review_content_reminder', 'true')
    showContentReminder.value = false
}
</script>
