/**
 * Returns a cookie value if a name is specified. Otherwise returns the entire cookies as an object
 * @param [name] - The name of the cookie to fetch the value for. Returns the entire map of cookies if not specified
 * @returns {string|Object} - The value of the cookie specified by `name` if specified. Otherwise returns a name value map of the available cookies
 */
export function getCookie(name) {
    const cookies = document.cookie.split(';').reduce((acc, cookieString) => {
        const [key, value] = cookieString.split('=').map((s) => s.trim())
        if (key && value) {
            acc[key] = decodeURIComponent(value)
        }
        return acc
    }, {})
    return name ? cookies[name] || '' : cookies
}

export function setCookie(name, value, days = null) {
    document.cookie = `${name}=${encodeURIComponent(value)};path=/${
        days ? `;max-age=${24 * 60 * 60 * days}` : ''
    }`
}
