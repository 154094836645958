import 'photoswipe/dist/photoswipe.css'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import { activePopup } from '../popup/popup.js'

export const getPhotoSwipeItemsOfGallery = (galleryName) => {
    const links = window.document.querySelectorAll(
        'a.photoswipe' + (galleryName ? '[data-gallery="' + galleryName + '"]' : ''),
    )
    return [].map
        .call(links, (link) => {
            let size = (link.dataset.size || '').split('x').filter((s) => !!s)
            const img = link.querySelector('img,canvas')
            if (size.length !== 2) {
                size = ['', '']
            }

            const w = parseInt(size[0]),
                h = parseInt(size[1])

            link.setAttribute('data-pswp-width', !isNaN(w) ? w : '')
            link.setAttribute('data-pswp-height', !isNaN(h) ? h : '')
            link.setAttribute('data-cropped', !!link.dataset.cropped)
            if (!link.dataset.cropped && !link.hasAttribute('data-pswp-srcset') && img.srcset) {
                link.setAttribute('data-pswp-srcset', img.srcset)
            }
            return link
        })
        .filter((item) => item !== null)
}

export const openPhotoswipeGallery = function (galleryName, src = null) {
    const gallery = getPhotoSwipeItemsOfGallery(galleryName)
    if (!gallery || !gallery.length) {
        return false
    }

    let index = 0

    gallery.forEach((item, i) => {
        if (gallery[i].href === src) {
            index = i
        }
        item.thatsupGallery = item.closest('.thatsup-gallery')
    })

    const photoswipeInstance = photoswipe(gallery)
    photoswipeInstance.addFilter('thumbBounds', (thumbBounds) => {
        const thatsupGallery =
            photoswipeInstance.pswp.currSlide?.data?.element?.closest('.thatsup-gallery')
        if (thatsupGallery && thatsupGallery.gallery && thatsupGallery.gallery.visibleCount === 1) {
            // Images with a ratio other than the gallery need offsets
            const bounds = thatsupGallery.getBoundingClientRect()
            const currRatio =
                photoswipeInstance.pswp.currSlide.width / photoswipeInstance.pswp.currSlide.height
            const boundRatio = bounds.width / bounds.height

            if (currRatio === boundRatio) return thumbBounds

            const finalWidth = bounds.width * (currRatio / boundRatio)
            const finalHeight = bounds.height / (currRatio / boundRatio)

            return {
                x: bounds.x + (bounds.width - finalWidth) / 2,
                y: thumbBounds.y - (bounds.height - finalHeight) / 2,
                w: finalWidth,
            }
        }
        return thumbBounds
    })

    photoswipeInstance.on('contentLoad', ({ content }) => {
        const item = photoswipeInstance?.pswp?.options?.dataSource[content.index]
        if (item && item.tagName === 'A' && !item.dataset.pswpWidth) {
            loadPhotoswipeSize(item).then(() =>
                photoswipeInstance.pswp.refreshSlideContent(content.index),
            )
        }
    })

    photoswipeInstance.on('closingAnimationEnd', () => {
        if (!photoswipeInstance.pswp) return

        const thatsupGallery =
            photoswipeInstance.pswp.currSlide?.data?.element?.closest('.thatsup-gallery')
        if (thatsupGallery && thatsupGallery.gallery) {
            const index = [].findIndex.call(thatsupGallery.querySelectorAll('li'), (li) => {
                return li.querySelector('a')?.href === photoswipeInstance.pswp.currSlide.data.src
            })
            if (index > -1) {
                const slideIndex = Math.ceil(index / (thatsupGallery.gallery.visibleCount || 1))
                thatsupGallery.gallery.slideTo(slideIndex, null, null, 1)
            }
        }
    })

    photoswipeInstance.init()
    photoswipeInstance.loadAndOpen(index)
    return photoswipeInstance
}

export const photoswipe = (items) => {
    const lightbox = new PhotoSwipeLightbox({
        dataSource: items,
        pswpModule: () => import('photoswipe'),
    })

    lightbox.on('beforeOpen', () => {
        if (activePopup && activePopup.clickOutsideEvent) {
            // If we have an active popup, pswp should not trigger closing when interacting
            activePopup.clickOutsideEvent.addElement(lightbox.pswp.element)
        }
    })
    lightbox.on('close', () => {
        if (activePopup && activePopup.clickOutsideEvent) {
            // If we have an active popup, remove pswp from click outside
            activePopup.clickOutsideEvent.removeElement(lightbox.pswp.element)
        }
    })
    lightbox.on('uiRegister', function () {
        lightbox.pswp.ui.registerElement({
            name: 'custom-caption',
            order: 9,
            isButton: false,
            appendTo: 'root',
            html: '<figcaption></figcaption>',
            onInit: (el) => {
                const figcaption = el.firstElementChild
                lightbox.pswp.on('change', () => {
                    const currSlide = lightbox.pswp.currSlide
                    let captionIsHtml = false
                    let caption = null

                    if (
                        currSlide.data.captionHtml ||
                        currSlide.data.element?.dataset?.captionHtml
                    ) {
                        caption =
                            currSlide.data.captionHtml ||
                            currSlide.data.element?.dataset?.captionHtml
                        captionIsHtml = true
                    } else if (
                        currSlide.data.caption ||
                        currSlide.data.element?.dataset?.caption ||
                        currSlide.data.title
                    ) {
                        caption =
                            currSlide.data.caption ||
                            currSlide.data.element?.dataset?.caption ||
                            currSlide.data.title
                        captionIsHtml = false
                    } else if (currSlide.data.alt) {
                        caption = currSlide.data.alt
                        captionIsHtml = false
                    }
                    if (captionIsHtml) {
                        figcaption.innerHTML = caption || ''
                    } else {
                        figcaption.innerText = caption || ''
                    }
                    el.style.display = caption ? 'block' : 'none'
                })
            },
        })
    })
    return lightbox
}

export const getPhotoswipeSize = function (link) {
    return (link.dataset.size || '').split('x').filter((size) => !!size)
}

export const hasPhotoswipeSize = function (link) {
    return getPhotoswipeSize(link).length === 2
}
export const loadPhotoswipeSize = function (link) {
    return new Promise((resolve, reject) => {
        if (hasPhotoswipeSize(link)) {
            resolve(link)
            return
        } else if (link.dataset.sizeError) {
            reject(link.dataset.sizeError)
            return
        }
        const img = new Image()
        img.onload = () => {
            link.dataset.size = [img.width, 'x', img.height].join('')
            link.setAttribute('data-pswp-width', img.width)
            link.setAttribute('data-pswp-height', img.height)
            link.dataset.sizeError = false
            resolve(link)
        }
        img.onerror = (e) => {
            link.dataset.sizeError = typeof e === 'string' ? e : 'Error loading image'
            reject(e)
        }
        img.src = link.href
    })
}
