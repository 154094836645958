<template>
    <!-- Uploaded images list -->
    <FileUpload
        @add="addImage"
        multiple
        accept="image/jpeg,image/png,image/gif,image/webp"
        :max-file-size="9990000"
        class="tw-grid tw-gap-2"
    >
        <SortableList v-if="images.length > 0" v-model="images" class="tw-grid tw-gap-2">
            <template #item="{ item, index, isDragging }">
                <div class="tw-flex tw-items-center tw-gap-2">
                    <span
                        class="handle tw-flex tw-h-8 tw-w-8 tw-cursor-move tw-items-center tw-justify-center"
                    >
                        <i class="icon--move-handle black"></i>
                    </span>
                    <div
                        class="tw-relative tw-h-16 tw-w-16 tw-flex-shrink-0 tw-overflow-hidden tw-rounded-md tw-bg-gray-300"
                    >
                        <img
                            v-if="!item.loading && !item.error"
                            :src="item.src || item.url"
                            :srcset="item.srcset"
                            class="tw-h-full tw-w-full tw-flex-shrink-0 tw-object-cover"
                        />
                        <img
                            v-else-if="item.thumbnail"
                            :src="item.thumbnail"
                            class="tw-h-full tw-w-full tw-flex-shrink-0 tw-object-cover tw-blur"
                        />
                        <div
                            v-if="item.loading"
                            class="is-loading is-loading--white tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-white/30"
                        ></div>
                    </div>
                    <input
                        v-if="!item.error && !item.loading"
                        class="tw-flex-grow"
                        type="text"
                        v-model="item.caption"
                        :placeholder="$t('review.image_caption_placeholder')"
                    />
                    <div v-else-if="item.error" class="tw-flex-grow">
                        <div class="tw-text-sm tw-font-bold tw-text-red-500">{{ item.error }}</div>
                    </div>
                    <div v-else class="tw-flex-grow"></div>
                    <div>
                        <button
                            type="button"
                            class="tw-ml-2 tw-text-red-500"
                            @click="removeImage(index)"
                        >
                            <i class="icon--remove-bin"></i>
                        </button>
                    </div>
                </div>
            </template>
        </SortableList>
        <template v-if="!withoutButton" #input-button="{ dragging }">
            <button
                class="flat-button flat-button--white flat-button--small flat-button--full"
                type="button"
                :class="{ white: !dragging, blue: dragging }"
            >
                <i class="sli sli-upload sli-outlined tw-ml-2 tw-h-6 tw-w-6" aria-hidden="true"></i>
                {{ $t('review.upload_photo_button') }}
            </button>
        </template>
    </FileUpload>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import FileUpload from '../../Shared/components/FileUpload.vue'
import SortableList from '../../Shared/components/SortableList.vue'
import confirm from '@utils/confirm.js'
import { $t } from '../../helpers.js'

const props = defineProps({
    modelValue: {
        type: Array,
        required: true,
    },
    withoutButton: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['update:modelValue'])

const images = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
})

const localImages = ref([])

watch(
    () => props.modelValue,
    (newVal) => {
        localImages.value = newVal
    },
    { immediate: true },
)

const addImage = (file) => {
    file.value.temp = true
    localImages.value = [...localImages.value, file.value]
    images.value = [...localImages.value]
}

const removeImage = async (index) => {
    const image = images.value[index]
    if (!(await confirm($t('confirm_title'), $t('review.image_delete_confirm')))) {
        return
    }
    const newImages = [...props.modelValue]
    newImages.splice(index, 1)
    images.value = newImages
}
</script>
